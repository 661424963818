import React, { useState, useEffect } from 'react';
import axios from 'axios';
import myLogo from '../../images/salary.jpg'; // Updated image reference for loans

function DepartmentAttendancePreview({ record, onClose }) {
  const selectedMonth = record.selectedMonth;
  const selectedYear = record.selectedYear;
  const selectedDepartment = record.selectedDepartment;
  const [employees, setEmployees] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [filteredAttendance, setFilteredAttendance] = useState([]);
  const [daysInMonth, setDaysInMonth] = useState(31); // Default to 31, will be updated based on month
  const [combinedData, setCombinedData] = useState([]);
  const [setting, setSetting] = useState({});

  useEffect(() => {
    const fetchSetting = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
        setSetting(response.data);
      } catch (error) {
        console.error('Error fetching settings', error);
      }
    };
    fetchSetting();
  }, []);

  useEffect(() => {
    if (selectedDepartment) {
      const fetchData = async () => {
        try {
          const [employeeResponse, attendanceResponse] = await Promise.all([
            axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employees/report?departmentId=${selectedDepartment}`),
            axios.get(`${process.env.REACT_APP_LOCAL_URL}/attendance/report?departmentId=${selectedDepartment}`)
          ]);
          setEmployees(employeeResponse.data);
          setAttendanceData(attendanceResponse.data);
          filterAttendanceRecords(selectedMonth, selectedYear, attendanceResponse.data);
        } catch (error) {
          console.error('Error fetching employees or attendance data:', error);
        }
      };
      fetchData();
    }
  }, [selectedDepartment, selectedMonth, selectedYear]);

  useEffect(() => {
    const getDaysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();
    setDaysInMonth(getDaysInMonth(selectedMonth, selectedYear));
  }, [selectedMonth, selectedYear]);

  const filterAttendanceRecords = (month, year, data) => {
    const filteredRecords = data.filter(record => {
      const recordDate = new Date(record.date);
      return recordDate.getMonth() === month && recordDate.getFullYear() === year;
    });

    setFilteredAttendance(filteredRecords);
  };

  const statusMap = {
    'weekly off': 'W',
    'overtime': 'OT',
    'unpaid leave': 'UL',
    'paid leave': 'PL',
    'half day': 'H',
    'absent': 'A',
    'present': 'P'
  };

  const getAttendanceForDay = (employeeId, day) => {
    const record = filteredAttendance.find(record => {
      const recordDate = new Date(record.date);
      return record.employee_id === employeeId && recordDate.getDate() === day;
    });
    return record ? statusMap[record.status.toLowerCase()] || '' : '';
  };

  useEffect(() => {
    const calculateCombinedData = () => {
      const counts = employees.map(employee => {
        // Filter attendance records for the current employee
        const employeeAttendance = filteredAttendance.filter(record => record.employee_id === employee.id);

        // Initialize counts for each status
        const statusCounts = {
          presentCount: 0,
          absentCount: 0,
          paidLeaveCount: 0,
          halfDayCount: 0,
          unpaidLeaveCount: 0,
          overtimeCount: 0,
          weeklyOffCount: 0,
        };

        // Count occurrences of each attendance status
        employeeAttendance.forEach(record => {
          switch (record.status.toLowerCase()) { // Make comparison case-insensitive
            case 'present':
              statusCounts.presentCount += 1;
              break;
            case 'absent':
              statusCounts.absentCount += 1;
              break;
            case 'paid leave':
              statusCounts.paidLeaveCount += 1;
              break;
            case 'half day':
              statusCounts.halfDayCount += 1;
              break;
            case 'unpaid leave':
              statusCounts.unpaidLeaveCount += 1;
              break;
            case 'overtime':
              statusCounts.overtimeCount += 1;
              break;
            case 'weekly off':
              statusCounts.weeklyOffCount += 1;
              break;
            default:
              break;
          }
        });

        // Return the combined data for the current employee
        return {
          id: employee.id,
          employeeName: employee.employeeName,
          ...statusCounts
        };
      });

      setCombinedData(counts);
    };

    if (employees.length && filteredAttendance.length) {
      calculateCombinedData();
    }
  }, [employees, filteredAttendance]);

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="container-fluid p-0 m-0">
      <div  className="row p-1">
        <div className="modal-content" >
          <div className="modal-header m-0 p-2 d-flex align-items-center justify-content-between px-3">
            <div>
              <div style={{ height: "50px", width: "100%" }} className='logo p-1'>
                <img
                  src={setting.landingPageLogo
                    ? `${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${setting.landingPageLogo}`
                    : myLogo}
                  style={{ height: "100%", objectFit: "cover" }}
                  alt="LOGO"
                />
              </div>
            </div>
            <h4 style={{ color: "#3F4D67" }} className='title-detail text-uppercase fw-bolder font-bold m-0'>Department Attendance REPORT</h4>
            <div>
              <h5 style={{ color: "#3F4D67" }} className='title-detail text-uppercase fw-bolder font-bold m-0'>{setting.title || 'Title'}</h5>
              <small style={{ color: "#3F4D67" }} className='title-detail text-uppercase fw-bolder font-bold m-0'>Date - {selectedMonth} {selectedYear}</small>
            </div>
          </div>
          <div className="modal-body py-2 px-0">
            <div className="row">
              <div className='col-md-12' >
                <table className="table table-bordered " >
                  <thead >
                    <tr>
                      <th style={{ fontSize: "10px" ,padding:"1px" }}>Name</th>
                      {[...Array(daysInMonth).keys()].map(day => (
                        <th style={{ fontSize: "10px" ,padding:"1px" }} key={day}>{day + 1}</th>
                      ))}
                      <th style={{ fontSize: "10px" ,padding:"1px" }}>Present</th>
                      <th style={{ fontSize: "10px" ,padding:"1px" }}>Absent</th>
                      <th style={{ fontSize: "10px" ,padding:"1px" }}>Paid Leave</th>
                      <th style={{ fontSize: "10px" ,padding:"1px" }}>Half Day</th>
                      <th style={{ fontSize: "10px" ,padding:"1px" }}>Unpaid Leave</th>
                      <th style={{ fontSize: "10px" ,padding:"1px" }}>Overtime</th>
                      <th style={{ fontSize: "10px" ,padding:"1px" }}>Weekly Off</th>
                    </tr>
                  </thead>
                  <tbody>
                    {employees.map(employee => (
                      <tr key={employee.id}>
                        <td style={{ fontSize: "10px",padding:"2px" }}>{employee.employeeName}</td>
                        {[...Array(daysInMonth).keys()].map(day => {
                          const attendance = getAttendanceForDay(employee.id, day + 1);

                          // Define styles based on attendance status
                          const styles = {
                            'W': { backgroundColor: 'yellow', color: 'black' },
                            'OT': { backgroundColor: 'lightgreen', color: 'black' },
                            'UL': { backgroundColor: 'red', color: 'black' },
                            'PL': { backgroundColor: 'lightgreen', color: 'black' },
                            'H': { backgroundColor: 'lightblue', color: 'black' },
                            'A': { backgroundColor: 'red', color: 'black' },
                            'P': { backgroundColor: 'lightgreen', color: 'black' },
                          };

                          // Merge specific styles with default styles
                          const cellStyle = {
                            fontSize: "10px",padding:"1px",
                            ...styles[attendance] // Apply styles based on attendance, default is an empty object if not found
                          };

                          return (
                            <td className='text-center' key={day} style={cellStyle}>
                              {attendance}
                            </td>
                          );
                        })}
                        <td className='text-center' style={{ fontSize: "10px" ,padding:"0px"}}>{combinedData.find(data => data.id === employee.id)?.presentCount || 0}</td>
                        <td className='text-center' style={{ fontSize: "10px" ,padding:"0px"}}>{combinedData.find(data => data.id === employee.id)?.absentCount || 0}</td>
                        <td className='text-center' style={{ fontSize: "10px" ,padding:"0px"}}>{combinedData.find(data => data.id === employee.id)?.halfDayCount || 0}</td>
                        <td className='text-center' style={{ fontSize: "10px" ,padding:"0px"}}>{combinedData.find(data => data.id === employee.id)?.paidLeaveCount || 0}</td>
                        <td className='text-center' style={{ fontSize: "10px" ,padding:"0px"}}>{combinedData.find(data => data.id === employee.id)?.unpaidLeaveCount || 0}</td>
                        <td className='text-center' style={{ fontSize: "10px" ,padding:"0px"}}>{combinedData.find(data => data.id === employee.id)?.overtimeCount || 0}</td>
                        <td className='text-center' style={{ fontSize: "10px" ,padding:"0px"}}>{combinedData.find(data => data.id === employee.id)?.weeklyOffCount || 0}</td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer p-1 d-flex align-items-center justify-content-between">
              <div>
                <small className='p-0 m-0'>Powered By - Salary Fal</small>
              </div>
              <div className='d-flex gap-2'>
                <button onClick={handlePrint} className="btn btn-success print-button"><i className="fa fa-download"></i> Print</button>
                <button onClick={onClose} className="btn btn-danger print-button">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DepartmentAttendancePreview;


