import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Dashboard.css';
import E_RequestLeave from '../../pages/UserDetails/E_RequestLeave';

function SidebarEmployee() {
    const [style, setStyle] = useState("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");

    const changeStyle = () => {
        if (style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
            setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled");
        }
        else {
            setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
        }
    };
    const changeStyle1 = () => {
        if (style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
            setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1");
        }
        else {
            setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
        }
    };

    // Add Request Leave 
    const [isRequestLeaveModalOpen, setIsRequestLeaveModalOpen] = useState(false);
    const [dashboardLogo, setDashboardLogo] = useState([]);

    useEffect(() => {
        const fetchDashboardLogo = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
                setDashboardLogo(response.data);
            } catch (error) {
                console.error('Error fetching Dashboard Logo', error);
            }
        };

        fetchDashboardLogo();
    }, []);



    // Apply for Leave 
    const handleAddRequestLeave = () => {
        setIsRequestLeaveModalOpen(true);
    };

    const handleCloseRequestLeave = () => {
        setIsRequestLeaveModalOpen(false);
    };

    //   Handle Update 
    const handleUpdate = () => {
        toast.success("successfully uploaded");
        // window.location.reload();
    }

    const handleListClick = (path) => {
        if (window.location.pathname === path) {
            window.location.reload();
        }
    };

    return (
        <div style={{ position: "relative" }}>
            <body style={{ backgroundColor: "#3F4D67" }} id="page-top">

                {/*  <!-- Page Wrapper --> */}
                <div id="wrapper">

                    {/*  <!-- Sidebar --> */}
                    <ul className={style} id="accordionSidebar">

                        {/*  <!-- Sidebar - Brand --> */}
                        <a className="sidebar-brand d-flex align-items-center justify-content-center" href="#">
                            <div className="sidebar-brand-text text-dark mx-3">
                                <div style={{ height: "100%", width: "100%" }} className='logo'>
                                    <img
                                        src={dashboardLogo.landingPageLogo
                                            ? `${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${dashboardLogo.landingPageLogo}`
                                            : <h4>HRM</h4>}
                                        className='w-100 h-100'
                                        alt="LOGO"
                                    />
                                </div>

                            </div>
                            <div className="text-center d-none d-md-inline ">
                                <button className="rounded-circle border-0" id="sidebarToggle" onClick={changeStyle}></button>
                            </div>
                        </a>

                        {/*   <!-- Divider --> */}
                        <hr className="sidebar-divider my-0" />

                        {/*  <!-- Nav Item - Dashboard --> */}
                        <li style={{zIndex:"999"}} className="nav-item active">
                            <Link to="/userdashboard" className="nav-link">
                                <i className="fas fa-fw fa-tachometer-alt"></i>
                                <span>User Dashboard</span>
                            </Link>
                        </li>

                        {/*  <!-- Divider --> */}
                        <hr className="sidebar-divider" />

                        {/*   <!-- Heading --> */}
                        <div className="sidebar-heading">
                            Interface
                        </div>
                        {/*  <!-- Nav Item - Pages Collapse Menu --> */}
                        <li style={{zIndex:"999"}} className="nav-item">
                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseAttendance"
                                aria-expanded="true" aria-controls="collapseAttendance">
                                <i className="fas fa-fw fa-cog"></i>
                                <span>Attendance Details</span>
                            </a>
                            <div id="collapseAttendance" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">Attendance:</h6>
                                    <Link to="/eattendancelist" className="collapse-item" onClick={() => handleListClick("/eattendancelist")}>
                                        <span>Attendance List</span>
                                    </Link>
                                </div>
                            </div>
                        </li>
                        {/*  <!-- Nav Item - Pages Collapse Menu --> */}
                        <li style={{zIndex:"999"}} className="nav-item">
                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseAdvancePayment"
                                aria-expanded="true" aria-controls="collapseAdvancePayment">
                                <i className="fas fa-fw fa-cog"></i>
                                <span>Salary Details</span>
                            </a>
                            <div id="collapseAdvancePayment" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">Salary:</h6>
                                    <Link to="/esalaryledger" className="collapse-item" onClick={() => handleListClick("/esalaryledger")}>
                                        <span>Salary Ledger</span>
                                    </Link>
                                    <h6 className="collapse-header">Payment & Repayment:</h6>
                                    <Link to="/eadvancelist" className="collapse-item" onClick={() => handleListClick("/eadvancelist")}>
                                        <span>Payment & Repayment</span>
                                    </Link>
                                </div>
                            </div>
                        </li>

                        {/*  <!-- Nav Item - Pages Collapse Menu --> */}
                        <li style={{zIndex:"999"}} className="nav-item">
                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseLoan"
                                aria-expanded="true" aria-controls="collapseLoan">
                                <i className="fas fa-fw fa-cog"></i>
                                <span>Loan Details</span>
                            </a>
                            <div id="collapseLoan" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">Loan:</h6>
                                    <Link to="/eloanlist" className="collapse-item" onClick={() => handleListClick("/eloanlist")}>
                                        <span>Loan List</span>
                                    </Link>
                                </div>
                            </div>
                        </li>

                        {/*  <!-- Nav Item - Pages Collapse Menu --> */}
                        <li style={{zIndex:"999"}} className="nav-item">
                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseBonous"
                                aria-expanded="true" aria-controls="collapseBonous">
                                <i className="fas fa-fw fa-cog"></i>
                                <span>Bonous Details</span>
                            </a>
                            <div id="collapseBonous" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">Bonous:</h6>
                                    <Link to="/ebonouslist" className="collapse-item" onClick={() => handleListClick("/ebonouslist")}>
                                        <span>Bonous List</span>
                                    </Link>
                                </div>
                            </div>
                        </li>
                        {/*  <!-- Nav Item - Pages Collapse Menu --> */}
                        <li style={{zIndex:"999"}} className="nav-item">
                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseLeave"
                                aria-expanded="true" aria-controls="collapseLeave">
                                <i className="fas fa-fw fa-cog"></i>
                                <span>Leave Details</span>
                            </a>
                            <div id="collapseLeave" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">Leave:</h6>
                                    <a className="collapse-item" href="#" onClick={handleAddRequestLeave}>Apply Leave Request</a>
                                    <Link to="/eemployeeleavelist" className="collapse-item" onClick={() => handleListClick("/eemployeeleavelist")}>
                                        <span>Leave List</span>
                                    </Link>
                                    <Link to="/echeckstatus" className="collapse-item" onClick={() => handleListClick("/echeckstatus")}>
                                        <span>Check Status</span>
                                    </Link>
                                </div>
                            </div>
                        </li>
                        <hr className="sidebar-divider d-none d-md-block" />
                    </ul>
                    <button style={{ border: "2px solid black", position: "absolute", left: "30px", top: "10px", zIndex: "999" }}  id="sidebarToggleTop" className="btn btn-link d-md-none" onClick={changeStyle}>
                        <i className="fa fa-bars"></i>
                    </button>
                </div>
            </body>
            {isRequestLeaveModalOpen && <E_RequestLeave onClose={handleCloseRequestLeave} onUpdate={handleUpdate} />}
        </div>
    )
}

export default SidebarEmployee;








// import React, { useState, useEffect } from 'react';
// import axios from "axios";
// import { Link } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import './Dashboard.css';
// import RequestLeave from '../../pages/LeaveSection/RequestLeave';

// function SidebarEmployee() {
//     const [style, setStyle] = useState("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");

//     const changeStyle = () => {
//         if (style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
//             setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled");
//         }
//         else {
//             setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
//         }
//     };
//     const changeStyle1 = () => {
//         if (style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
//             setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1");
//         }
//         else {
//             setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
//         }
//     };

//     // Add Request Leave 
//     const [isRequestLeaveModalOpen, setIsRequestLeaveModalOpen] = useState(false);
//     const [dashboardLogo, setDashboardLogo] = useState([]);

//     useEffect(() => {
//         const fetchDashboardLogo = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
//                 setDashboardLogo(response.data);
//             } catch (error) {
//                 console.error('Error fetching Dashboard Logo', error);
//             }
//         };

//         fetchDashboardLogo();
//     }, []);



//     // Apply for Leave 
//     const handleAddRequestLeave = () => {
//         setIsRequestLeaveModalOpen(true);
//     };

//     const handleCloseRequestLeave = () => {
//         setIsRequestLeaveModalOpen(false);
//     };

//     //   Handle Update 
//     const handleUpdate = () => {

//         toast.success("successfully uploaded");
//         // window.location.reload();
//     }



//     const handleListClick = (path) => {
//         if (window.location.pathname === path) {
//             window.location.reload();
//         }
//     };



//     return (
//         <div>
//             <body id="page-top">

//                 {/*  <!-- Page Wrapper --> */}
//                 <div id="wrapper">

//                     {/*  <!-- Sidebar --> */}
//                     <ul className={style} id="accordionSidebar">

//                         {/*  <!-- Sidebar - Brand --> */}
//                         <a className="sidebar-brand d-flex align-items-center justify-content-center" href="#">
//                             <div className="sidebar-brand-icon rotate-n-15">
//                                 <i className="fas fa-laugh-wink"></i>
//                             </div>
//                             <div className="sidebar-brand-text mx-3">SB Admin <sup>2</sup></div>
//                             <div className="text-center d-none d-md-inline">
//                                 <button className="rounded-circle border-0" id="sidebarToggle" onClick={changeStyle}></button>
//                             </div>
//                         </a>

//                         {/*   <!-- Divider --> */}
//                         <hr className="sidebar-divider my-0" />

//                         {/*  <!-- Nav Item - Dashboard --> */}
//                         <li style={{zIndex:"999"}} className="nav-item active">
//                             <a className="nav-link" href="index.html">
//                                 <i className="fas fa-fw fa-tachometer-alt"></i>
//                                 <span>Dashboard</span></a>
//                         </li>

//                         {/*  <!-- Divider --> */}
//                         <hr className="sidebar-divider" />

//                         {/*   <!-- Heading --> */}
//                         <div className="sidebar-heading">
//                             Interface
//                         </div>

//                         {/*  <!-- Nav Item - Pages Collapse Menu --> */}
//                         <li style={{zIndex:"999"}} className="nav-item">
//                             <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseAttendance"
//                                 aria-expanded="true" aria-controls="collapseAttendance">
//                                 <i className="fas fa-fw fa-cog"></i>
//                                 <span>Attendance Details</span>
//                             </a>
//                             <div id="collapseAttendance" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
//                                 <div className="bg-white py-2 collapse-inner rounded">
//                                     <h6 className="collapse-header">Attendance:</h6>
//                                     <Link to="/eattendancelist" className="collapse-item" onClick={() => handleListClick("/eattendancelist")}>
//                                         <span>Attendance List</span>
//                                     </Link>
//                                 </div>
//                             </div>
//                         </li>
//                         {/*  <!-- Nav Item - Pages Collapse Menu --> */}
//                         <li style={{zIndex:"999"}} className="nav-item">
//                             <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseAdvancePayment"
//                                 aria-expanded="true" aria-controls="collapseAdvancePayment">
//                                 <i className="fas fa-fw fa-cog"></i>
//                                 <span>Salary Details</span>
//                             </a>
//                             <div id="collapseAdvancePayment" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
//                                 <div className="bg-white py-2 collapse-inner rounded">
//                                     <h6 className="collapse-header">Salary:</h6>
//                                     <Link to="/esalaryledger" className="collapse-item" onClick={() => handleListClick("/esalaryledger")}>
//                                         <span>Salary Ledger</span>
//                                     </Link>
//                                     <h6 className="collapse-header">Payment & Repayment:</h6>
//                                     <Link to="/eadvancelist" className="collapse-item" onClick={() => handleListClick("/eadvancelist")}>
//                                         <span>Payment & Repayment</span>
//                                     </Link>
//                                 </div>
//                             </div>
//                         </li>

//                         {/*  <!-- Nav Item - Pages Collapse Menu --> */}
//                         <li style={{zIndex:"999"}} className="nav-item">
//                             <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseLoan"
//                                 aria-expanded="true" aria-controls="collapseLoan">
//                                 <i className="fas fa-fw fa-cog"></i>
//                                 <span>Loan Details</span>
//                             </a>
//                             <div id="collapseLoan" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
//                                 <div className="bg-white py-2 collapse-inner rounded">
//                                     <h6 className="collapse-header">Loan:</h6>
//                                     <Link to="/eloanlist" className="collapse-item" onClick={() => handleListClick("/eloanlist")}>
//                                         <span>Loan List</span>
//                                     </Link>
//                                 </div>
//                             </div>
//                         </li>

//                         {/*  <!-- Nav Item - Pages Collapse Menu --> */}
//                         <li style={{zIndex:"999"}} className="nav-item">
//                             <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseBonous"
//                                 aria-expanded="true" aria-controls="collapseBonous">
//                                 <i className="fas fa-fw fa-cog"></i>
//                                 <span>Bonous Details</span>
//                             </a>
//                             <div id="collapseBonous" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
//                                 <div className="bg-white py-2 collapse-inner rounded">
//                                     <h6 className="collapse-header">Bonous:</h6>
//                                     <Link to="/ebonouslist" className="collapse-item" onClick={() => handleListClick("/ebonouslist")}>
//                                         <span>Bonous List</span>
//                                     </Link>
//                                 </div>
//                             </div>
//                         </li>

//                         {/*  <!-- Nav Item - Pages Collapse Menu --> */}
//                         <li style={{zIndex:"999"}} className="nav-item">
//                             <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseLeave"
//                                 aria-expanded="true" aria-controls="collapseLeave">
//                                 <i className="fas fa-fw fa-cog"></i>
//                                 <span>Leave Details</span>
//                             </a>
//                             <div id="collapseLeave" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
//                                 <div className="bg-white py-2 collapse-inner rounded">
//                                     <h6 className="collapse-header">Leave:</h6>
//                                     <a className="collapse-item" href="#" onClick={handleAddRequestLeave}>Apply Leave Request</a>
//                                     <Link to="/eemployeeleavelist" className="collapse-item" onClick={() => handleListClick("/eemployeeleavelist")}>
//                                         <span>Leave List</span>
//                                     </Link>
//                                 </div>
//                             </div>
//                         </li>

//                         {/* <!-- Divider --> */}
//                         <hr className="sidebar-divider d-none d-md-block" />

//                     </ul>
//                     {/*  <!-- End of Sidebar --> */}

//                     {/*  <!-- Content Wrapper --> */}
//                     <div id="content-wrapper" className="d-flex flex-column">

//                         {/*  <!-- Main Content --> */}
//                         <div id="content">

//                             {/*  <!-- Topbar --> */}
//                             <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

//                                 {/*  <!-- Sidebar Toggle (Topbar) --> */}
//                                 <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3" onClick={changeStyle1}>
//                                     <i className="fa fa-bars"></i>
//                                 </button>

//                                 {/*  <!-- Topbar Search --> */}
//                                 <form
//                                     className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
//                                     <div className="input-group">
//                                         <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..."
//                                             aria-label="Search" aria-describedby="basic-addon2" />
//                                         <div className="input-group-append">
//                                             <button className="btn btn-primary" type="button">
//                                                 <i className="fas fa-search fa-sm"></i>
//                                             </button>
//                                         </div>
//                                     </div>
//                                 </form>

//                                 {/*  <!-- Topbar Navbar --> */}
//                                 <ul className="navbar-nav ml-auto">

//                                     {/*  <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
//                                     <li style={{zIndex:"999"}} className="nav-item dropdown no-arrow d-sm-none">
//                                         <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
//                                             data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//                                             <i className="fas fa-search fa-fw"></i>
//                                         </a>
//                                         {/*   <!-- Dropdown - Messages --> */}
//                                         <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
//                                             aria-labelledby="searchDropdown">
//                                             <form className="form-inline mr-auto w-100 navbar-search">
//                                                 <div className="input-group">
//                                                     <input type="text" className="form-control bg-light border-0 small"
//                                                         placeholder="Search for..." aria-label="Search"
//                                                         aria-describedby="basic-addon2" />
//                                                     <div className="input-group-append">
//                                                         <button className="btn btn-primary" type="button">
//                                                             <i className="fas fa-search fa-sm"></i>
//                                                         </button>
//                                                     </div>
//                                                 </div>
//                                             </form>
//                                         </div>
//                                     </li>

//                                 </ul>

//                             </nav>
//                         </div>
//                     </div>
                  
//                 </div>
               
//                 <a className="scroll-to-top rounded" href="#page-top">
//                     <i className="fas fa-angle-up"></i>
//                 </a>
//             </body>
//             {isRequestLeaveModalOpen && <RequestLeave onClose={handleCloseRequestLeave} onUpdate={handleUpdate} />}
//         </div>
//     )
// }

// export default SidebarEmployee;

