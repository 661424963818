import React, { useEffect } from 'react';

const LoanDetailsModal = ({ onClose, employee, loan }) => {
    useEffect(() => {
        console.log("employee", employee);
        console.log("loan", loan);
    }, [employee, loan]);

    const handleClose = () => {
        onClose();
    };

    // Format loan date and repayment date to display in the desired format
    const formattedLoanDate = new Date(loan.loanDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });

    const formattedRepaymentDate = new Date(loan.loanRepaymentDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });

    return (
        <div id="addRepayment" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Loan Details</h4>
                        <button type="button" className="close" onClick={handleClose}>&times;</button>
                    </div>
                    <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                        <h5 className='text-black fw-bold'>Employee Details</h5>
                        <div className=' d-flex align-items-center justify-content-between'>
                            <div>
                                <p><strong >Name:</strong> {employee.employeeName}</p>
                                <p><strong >Department:</strong> {employee.departmentName}</p>
                                <p><strong >Email:</strong> {employee.employeeEmail}</p>
                                <p><strong >Phone:</strong> {employee.employeePhone}</p>
                            </div>
                            <div className="">
                                <img
                                    src={`${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${employee.passportSizePhoto}`}
                                    style={{ width: "200px" }}
                                    alt="Employee image"
                                />
                            </div>
                        </div>
                        <h5 className='text-black fw-bold'>Loan Details</h5>
                        <div className=' d-flex p-1  justify-content-between'>
                            <div>
                                <p><strong >Loan Number:</strong> {loan.loanNumber}</p>
                                <p><strong >Amount:</strong> {loan.loanAmount}</p>
                                <p><strong >Date:</strong> {formattedLoanDate}</p>
                                <p><strong >Repayment Date:</strong> {formattedRepaymentDate}</p>
                                <p><strong >Repay Type:</strong> {loan.loanRepayType}</p>
                            </div>
                            <div>
                               
                                <p><strong >Approved By:</strong> {loan.loanApprovedByName}</p>
                                <p><strong >Loan For:</strong> {loan.loanFor}</p>
                                <p><strong >Description:</strong> {loan.loanDescription}</p>
                                <p><strong >Remark:</strong> {loan.remark}</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" onClick={handleClose}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoanDetailsModal;
