import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import PaymentModeDesc from './PaymentModeDesc'; // Replace with actual PaymentModeDesc component
import DeleteConfirmationModal from '../DeleteConfirmationModal'; // Replace with actual DeleteConfirmationModal path
import Sidebar from '../../components/sidebar/Sidebar'; // Replace with actual Sidebar path
import SearchBar from '../../components/sidebar/SearchBar'; // Replace with actual SearchBar path
import { toast, ToastContainer } from 'react-toastify'; // Import toast components
import 'react-toastify/dist/ReactToastify.css';
import AddPaymentMode from './AddPaymentMode';
import PaymentDetails from './PaymentDetails';

function PaymentModeList({ handleLogout, username }) {
    const [paymentModes, setPaymentModes] = useState([]);
    const [selectedPaymentMode, setSelectedPaymentMode] = useState(null);
    const [showPaymentModeDetails, setShowPaymentModeDetails] = useState(false);
    const [isAddPaymentModeModalOpen, setIsAddPaymentModeModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editPaymentMode, setEditPaymentMode] = useState(null);
    const [deletePaymentMode, setDeletePaymentMode] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");

    const fetchPaymentModes = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/paymentmodes`);
            setPaymentModes(response.data);
        } catch (error) {
            console.error('Error fetching payment modes:', error);
        }
    };

    useEffect(() => {
        fetchPaymentModes();
    }, []);

    const handleAddPaymentMode = () => {
        setIsAddPaymentModeModalOpen(true);
    };

    const handleClosePaymentModeModal = () => {
        setIsAddPaymentModeModalOpen(false);
        setIsEditModalOpen(false);
        setIsDeleteModalOpen(false);
    };

    const handlePaymentModeDetails = (paymentMode) => {
        setSelectedPaymentMode(paymentMode);
        setShowPaymentModeDetails(true);
    };

    const handleEditPaymentMode = (paymentMode) => {
        setEditPaymentMode(paymentMode);
        setIsEditModalOpen(true);
    };

    const handleDeletePaymentMode = (paymentMode) => {
        setDeletePaymentMode(paymentMode);
        setIsDeleteModalOpen(true);
    };

    const confirmDeletePaymentMode = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/paymentmodes/${deletePaymentMode.id}`);
            const deletedPaymentMode = { ...deletePaymentMode, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedPaymentMode);
            setPaymentModes((prevPaymentModes) =>
                prevPaymentModes.filter((paymentMode) => paymentMode.id !== deletePaymentMode.id)
            );
            setIsDeleteModalOpen(false);
            toast.success('Successfully Deleted');

        } catch (error) {
            console.error("Error deleting Payment Mode:", error);
        }
    };

    const handleUpdatePaymentMode = async (updatedPaymentMode) => {
        try {
            await axios.put(`${process.env.REACT_APP_LOCAL_URL}/paymentmodes/${updatedPaymentMode.id}`, updatedPaymentMode);
            setPaymentModes((prevPaymentModes) =>
                prevPaymentModes.map((paymentMode) => (paymentMode.id === updatedPaymentMode.id ? updatedPaymentMode : paymentMode))
            );
            setIsEditModalOpen(false);
        } catch (error) {
            console.error("Error updating payment mode:", error);
        }
    };

    const handleUpdatePaymentModes = () => {
        toast.success('Payment mode data updated successfully'); // Display toast notification
        fetchPaymentModes();
    };

    return (
        <div className='d-flex w-100 h-100'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} /> {/* Pass username and handleLogout props */}
                <div className="container-fluid">
                    <ToastContainer />
                    {showPaymentModeDetails ? (
                        <PaymentDetails paymentMode={selectedPaymentMode} onClose={() => setShowPaymentModeDetails(false)} />
                    ) : (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">Payment Mode List</h6>

                                        <button onClick={handleAddPaymentMode} className="btn btn-outline-primary">
                                            Add New Payment Mode
                                        </button>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th>Payment Mode Name</th>
                                                    <th>Payment Type</th>
                                                    {/* <th>Action</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <style>
                                                    {`.hyperlink:hover {color: blue;}`}
                                                </style>
                                                {paymentModes.map((paymentMode) => (
                                                    <tr key={paymentMode.id}>
                                                        <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handlePaymentModeDetails(paymentMode)}>{paymentMode.paymentModeName}</td>
                                                        <td>{paymentMode.paymentType}</td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {isAddPaymentModeModalOpen && <AddPaymentMode onClose={handleClosePaymentModeModal} onUpdate={handleUpdatePaymentModes} />}
                    {/* {isEditModalOpen && <AddPaymentModeModal paymentMode={editPaymentMode} onClose={handleClosePaymentModeModal} onUpdate={handleUpdatePaymentMode} />} */}
                    <DeleteConfirmationModal
                        isOpen={isDeleteModalOpen}
                        itemName={deletePaymentMode ? deletePaymentMode.paymentModeName : ""}
                        onDelete={confirmDeletePaymentMode}
                        onClose={() => setIsDeleteModalOpen(false)}
                        deleteReason={deleteReason}
                        setDeleteReason={setDeleteReason}
                    />
                </div>
            </div>
        </div>
    );
}

export default PaymentModeList;
