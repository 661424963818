// import React, { useState } from 'react';
// import axios from 'axios';

// const AddApprovedModal = ({ onClose, onUpdate, employee }) => {
//     const [status, setStatus] = useState('');
//     const [description, setDescription] = useState('');
//     const [error, setError] = useState('');
//     console.log("ee", employee);

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         // Convert dates to local time zone
//         const convertToLocalDate = (dateString) => {
//             const date = new Date(dateString);
//             return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
//         };

//         const localFromDate = convertToLocalDate(employee.activeInactiveDetails.fromDate);
//         const localToDate = convertToLocalDate(employee.activeInactiveDetails.toDate);

//         // Determine statuses based on selected status 
//         let employeeStatus, activeInactiveStatus, approvedStatus;

//         if (status === 'Leave') {
//             employeeStatus = 'leave';
//             activeInactiveStatus = 'leave';
//             approvedStatus = 'approved';
//         } else if (status === 'reject') {
//             employeeStatus = 'active';
//             activeInactiveStatus = 'active';
//             approvedStatus = 'reject';
//         } else {
//             setError('Invalid status selected.');
//             return;
//         }

//         try {
//             const formData = {
//                 employeeId: employee?.id,
//                 employeeName: employee?.employeeName,
//                 activeInactiveDetails_id: employee.activeInactiveDetails.id,
//                 fromDate: localFromDate, // use local date
//                 toDate: localToDate,     // use local date
//                 status: approvedStatus,
//                 description
//             };
//             console.log(formData);

//             await axios.put(`${process.env.REACT_APP_LOCAL_URL}/employee_status/${employee.id}`, { status: employeeStatus });
//             await axios.put(`${process.env.REACT_APP_LOCAL_URL}/activeinactive_status/${employee.id}`, { status: activeInactiveStatus });
//             await axios.post(`${process.env.REACT_APP_LOCAL_URL}/approved`, formData);

//             console.log('Approved status added successfully');
//             onUpdate();
          
//             setTimeout(() => {
//                 onClose();
//                 window.location.reload();
//             }, 1000); // 1 second delay
//         } catch (error) {
//             console.error('Error adding approved status:', error);
//             setError('Failed to add approved status. Please try again.');
//         }
//     };

//     const handleClose = () => {
//         onClose();
//     };

//     return (
//         <div id="approvedModal" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
//             <div className="modal-dialog modal-lg">
//                 <div className="modal-content">
//                     <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
//                         <div className="modal-header">
//                             <h5 className="modal-title">Add Approved Status</h5>
//                             <button type="button" className="close" onClick={handleClose}>&times;</button>
//                         </div>
//                         <div className="modal-body">
//                             <div className="form-group">
//                                 <label>Name<span style={{ color: "red" }}>*</span></label>
//                                 <input name="entityName" type="text" className="form-control" value={employee?.employeeName} readOnly />
//                             </div>
//                             <div className="form-group">
//                                 <label>Status<span style={{ color: "red" }}>*</span></label>
//                                 <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)} >
//                                     <option value="" disabled hidden>Select status</option>
//                                     <option value="Leave">Approved</option>
//                                     <option value="reject">Reject</option>
//                                 </select>
//                             </div>
//                             <div className="form-group">
//                                 <label>Description<span style={{ color: "red" }}>*</span></label>
//                                 <textarea className="form-control" placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} ></textarea>
//                             </div>
//                         </div>
//                         <div className="modal-footer">
//                             <button type="submit" className="btn btn-primary">Save</button>
//                             <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AddApprovedModal;




import React, { useState } from 'react';
import axios from 'axios';

const AddApprovedModal = ({ onClose, onUpdate, employee }) => {
    const [status, setStatus] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');

    // Convert dates to local time zone
    const convertToLocalDate = (dateString) => {
        const date = new Date(dateString);
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate if status is selected
        if (!status) {
            setError('Please select a valid status.');
            return;
        }

        const localFromDate = convertToLocalDate(employee.activeInactiveDetails.fromDate);
        const localToDate = convertToLocalDate(employee.activeInactiveDetails.toDate);

        // Determine statuses based on selected status
        let activeInactiveStatus, approvedStatus;

        switch (status) {
            case 'approved':
                activeInactiveStatus = 'leave';
                approvedStatus = 'approved';
                break;
            case 'reject':
                activeInactiveStatus = 'reject';
                approvedStatus = 'reject';
                break;
            default:
                setError('Invalid status selected.');
                return;
        }
        try {
            const formData = {
                employeeId: employee?.id,
                employeeName: employee?.employeeName,
                employeeCode: employee?.employeeCode,
                activeInactiveDetails_id: employee.activeInactiveDetails.id,
                fromDate: localFromDate, // use local date
                toDate: localToDate,     // use local date
                status: approvedStatus,
                description,
            };

            // Update active/inactive status
            await axios.put(`${process.env.REACT_APP_LOCAL_URL}/api/activeinactive_status/leave/${employee.id}`, { status: activeInactiveStatus });
            // Add approved status
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/api/approved/leave`, formData);

            console.log('Approved status added successfully');
            onUpdate();
            onClose();

        } catch (error) {
            console.error('Error adding approved status:', error);
            setError('Failed to add approved status. Please try again.');
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div id="approvedModal" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                        <div className="modal-header">
                            <h5 className="modal-title">Add Approved Status</h5>
                            <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {error && <div className="alert alert-danger">{error}</div>}
                            <div className="form-group">
                                <label>Name<span style={{ color: "red" }}>*</span></label>
                                <input
                                    name="entityName"
                                    type="text"
                                    className="form-control"
                                    value={employee?.employeeName || ''}
                                    readOnly
                                />
                            </div>
                            <div className="form-group">
                                <label>Status<span style={{ color: "red" }}>*</span></label>
                                <select
                                    className="form-control"
                                    value={status}
                                    onChange={(e) => {
                                        setStatus(e.target.value);
                                        setError('');
                                    }}
                                >
                                    <option value="" disabled hidden>Select status</option>
                                    <option value="approved">Approved</option>
                                    <option value="reject">Reject</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Description<span style={{ color: "red" }}>*</span></label>
                                <textarea
                                    className="form-control"
                                    placeholder="Description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                ></textarea>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary">Save</button>
                            <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddApprovedModal;
