
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const AddLoan = ({ onClose, onUpdate }) => {
//     const [formData, setFormData] = useState({
//         departmentId: '',
//         departmentName: '',
//         employeeId: '',
//         employeeName: '',
//         loanAmount: '',
//         loanDate: '',
//         loanFor: '',
//         otherLoanForReason: '',
//         loanDescription: '',
//         loanApprovedById: '',
//         loanApprovedByName: '',
//         loanRepayType: '',
//         loanRepaymentDate: '',
//         remark: '',
//         loanNumber:'',
//         username: localStorage.getItem('username'),
//     });
//     const [departments, setDepartments] = useState([]);
//     const [employees, setEmployees] = useState([]);
//     const [loanApprovedBys, setLoanApprovedBys] = useState([]);
//     const [errors, setErrors] = useState({});
//     const [otherLoanFor, setOtherLoanFor] = useState(false);

//     const fetchDepartments = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/departments`);
//             setDepartments(response.data);
//         } catch (error) {
//             console.error('Error fetching departments:', error);
//         }
//     };

//     const fetchEmployees = async (departmentId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employee_department/${departmentId}`);
//             setEmployees(response.data);
//         } catch (error) {
//             console.error('Error fetching employees:', error);
//         }
//     };

//     const fetchLoanApprovedByOptions = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
//             setLoanApprovedBys(response.data);
//         } catch (error) {
//             console.error('Error fetching employees for loan approval:', error);
//         }
//     };

//     useEffect(() => {
//         fetchDepartments();
//         fetchLoanApprovedByOptions();
//     }, []);

//     const handleChange = (e) => {
//         const { name, value } = e.target;

//         if (name === 'departmentId') {
//             const department = departments.find(dep => dep.id === parseInt(value, 10));
//             setFormData({
//                 ...formData,
//                 departmentId: value,
//                 departmentName: department ? department.name : '',
//             });
//             fetchEmployees(value);
//         } else if (name === 'employeeId') {
//             const employee = employees.find(emp => emp.id === parseInt(value, 10));
//             setFormData({
//                 ...formData,
//                 employeeId: value,
//                 employeeName: employee ? employee.employeeName : '',
//             });
//         } else if (name === 'loanApprovedById') {
//             const loanApproved = loanApprovedBys.find(emp => emp.id === parseInt(value, 10));
//             setFormData({
//                 ...formData,
//                 loanApprovedById: value,
//                 loanApprovedByName: loanApproved ? loanApproved.employeeName : '',
//             });
//         } else if (name === 'loanFor') {
//             setFormData({
//                 ...formData,
//                 loanFor: value,
//                 otherLoanForReason: '', // Reset other loan reason when loanFor changes
//             });
//             setOtherLoanFor(value === 'other');
//         } else {
//             setFormData({
//                 ...formData,
//                 [name]: value
//             });
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         try {
//             const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/addLoan`, formData);
//             console.log('Data uploaded successfully:', response.data);
//             onClose();
//             onUpdate();
//         } catch (error) {
//             console.error('Error uploading data:', error);
//         }
//     };

//     const handleClose = () => {
//         onClose();
//     };

//     return (
//         <div id="add" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
//             <div className="modal-dialog modal-lg">
//                 <div className="modal-content">
//                     <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
//                         <div className="modal-header">
//                             <h5 className="modal-title">Add Loan</h5>
//                             <button type="button" className="close" onClick={handleClose}>&times;</button>
//                         </div>
//                         <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
//                             <div className="row">
//                                 <div className="form-group col-md-4">
//                                     <label>Department<span style={{ color: "red" }}>*</span></label>
//                                     <select className="form-control" name="departmentId" value={formData.departmentId} onChange={handleChange}>
//                                         <option value="">Select Department</option>
//                                         {departments.map(dept => (
//                                             <option key={dept.id} value={dept.id}>{dept.name}</option>
//                                         ))}
//                                     </select>
//                                     {errors.departmentId && <span style={{ color: "red" }}>{errors.departmentId}</span>}
//                                 </div>
//                                 <div className="form-group col-md-4">
//                                     <label>Employee<span style={{ color: "red" }}>*</span></label>
//                                     <select className="form-control" name="employeeId" value={formData.employeeId} onChange={handleChange}>
//                                         <option value="">Select Employee</option>
//                                         {employees.map(emp => (
//                                             <option key={emp.id} value={emp.id}>{emp.employeeName}</option>
//                                         ))}
//                                     </select>
//                                     {errors.employeeId && <span style={{ color: "red" }}>{errors.employeeId}</span>}
//                                 </div>
//                                 <div className="form-group col-md-4">
//                                     <label>Loan Number<span style={{ color: "red" }}>*</span></label>
//                                     <input name="loanNumber" type="text" className="form-control" placeholder="Loan Number" value={formData.loanNumber} onChange={handleChange} />
//                                     {errors.loanNumber && <span style={{ color: "red" }}>{errors.loanNumber}</span>}
//                                 </div>
//                             </div>
//                             <div className="row">
//                                 <div className="form-group col-md-4">
//                                     <label>Loan Amount<span style={{ color: "red" }}>*</span></label>
//                                     <input name="loanAmount" type="number" className="form-control" placeholder="Loan Amount" value={formData.loanAmount} onChange={handleChange} />
//                                     {errors.loanAmount && <span style={{ color: "red" }}>{errors.loanAmount}</span>}
//                                 </div>
//                                 <div className="form-group col-md-4">
//                                     <label>Loan Date<span style={{ color: "red" }}>*</span></label>
//                                     <input name="loanDate" type="date" className="form-control" value={formData.loanDate} onChange={handleChange} />
//                                     {errors.loanDate && <span style={{ color: "red" }}>{errors.loanDate}</span>}
//                                 </div>
//                                 <div className="form-group col-md-4">
//                                     <label>Loan For<span style={{ color: "red" }}>*</span></label>
//                                     <select name="loanFor" className="form-control" value={formData.loanFor} onChange={handleChange}>
//                                         <option value="">Select Loan For</option>
//                                         <option value="medical">Medical</option>
//                                         <option value="house">House</option>
//                                         <option value="education">Education</option>
//                                         <option value="other">Other</option>
//                                     </select>
//                                     {formData.loanFor === 'other' && (
//                                         <div className="mt-2">
//                                             <input
//                                                 type="text"
//                                                 className="form-control"
//                                                 placeholder="Enter reason"
//                                                 name="otherLoanForReason"
//                                                 value={formData.otherLoanForReason}
//                                                 onChange={handleChange}
//                                             />
//                                         </div>
//                                     )}
//                                 </div>
//                             </div>
//                             <div className="form-group">
//                                 <label>Description<span style={{ color: "red" }}>*</span></label>
//                                 <textarea name="loanDescription" className="form-control" placeholder="Loan Description" value={formData.loanDescription} onChange={handleChange}></textarea>
//                                 {errors.loanDescription && <span style={{ color: "red" }}>{errors.loanDescription}</span>}
//                             </div>
//                             <div className="row">
//                                 <div className="form-group col-md-4">
//                                     <label>Loan Approved By<span style={{ color: "red" }}>*</span></label>
//                                     <select className="form-control" name="loanApprovedById" value={formData.loanApprovedById} onChange={handleChange}>
//                                         <option value="">Select Loan Approved By</option>
//                                         {loanApprovedBys.map(emp => (
//                                             <option key={emp.id} value={emp.id}>{emp.employeeName}</option>
//                                         ))}
//                                     </select>
//                                     {errors.loanApprovedBy && <span style={{ color: "red" }}>{errors.loanApprovedBy}</span>}
//                                 </div>
//                                 <div className="form-group col-md-4">
//                                     <label>Loan Repay Type<span style={{ color: "red" }}>*</span></label>
//                                     <input name="loanRepayType" type="text" className="form-control" placeholder="Loan Repay Type" value={formData.loanRepayType} onChange={handleChange} />
//                                     {errors.loanRepayType && <span style={{ color: "red" }}>{errors.loanRepayType}</span>}
//                                 </div>
//                                 <div className="form-group col-md-4">
//                                     <label>Loan Repayment Date<span style={{ color: "red" }}>*</span></label>
//                                     <input name="loanRepaymentDate" type="date" className="form-control" value={formData.loanRepaymentDate} onChange={handleChange} />
//                                     {errors.loanRepaymentDate && <span style={{ color: "red" }}>{errors.loanRepaymentDate}</span>}
//                                 </div>
//                             </div>

//                             <div className="form-group">
//                                 <label>Remark<span style={{ color: "red" }}>*</span></label>
//                                 <textarea name="remark" className="form-control" placeholder="Remark" value={formData.remark} onChange={handleChange}></textarea>
//                                 {errors.remark && <span style={{ color: "red" }}>{errors.remark}</span>}
//                             </div>
//                         </div>
//                         <div className="modal-footer">
//                             <button type="submit" className="btn btn-primary">Save</button>
//                             <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AddLoan;




import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddLoan = ({ onClose, onUpdate }) => {
    const [formData, setFormData] = useState({
        departmentId: '',
        departmentName: '',
        employeeId: '',
        employeeName: '',
        loanAmount: '',
        loanDate: '',
        loanFor: '',
        otherLoanForReason: '',
        loanDescription: '',
        loanApprovedById: '',
        loanApprovedByName: '',
        loanRepayType: '',
        loanRepaymentDate: '',
        remark: '',
        loanNumber: '',
        principalAmount: '',
        interestAmount: '',
        username: localStorage.getItem('username'),
    });
    const [departments, setDepartments] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [loanApprovedBys, setLoanApprovedBys] = useState([]);
    const [errors, setErrors] = useState({});
    const [lastLoanId, setLastLoanId] = useState(0);
    const [otherLoanFor, setOtherLoanFor] = useState(false);

    const fetchDepartments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/departments`);
            setDepartments(response.data);
        } catch (error) {
            console.error('Error fetching departments:', error);
        }
    };

    const fetchEmployees = async (departmentId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employee_department/${departmentId}`);
            setEmployees(response.data);
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    const fetchLoanApprovedByOptions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            setLoanApprovedBys(response.data);
        } catch (error) {
            console.error('Error fetching employees for loan approval:', error);
        }
    };

    const fetchLastLoanId = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/lastloanId`);
            console.log("respond data", response.data)
            console.log("responde data last", response.data)
            setLastLoanId(response.data);
        } catch (error) {
            console.error('Error fetching last Loan ID:', error);
        }
    };

    useEffect(() => {
        fetchDepartments();
        fetchLastLoanId();
        fetchLoanApprovedByOptions();
    }, []);

    useEffect(() => {
        const principalAmount = parseFloat(formData.principalAmount) || 0;
        const interestAmount = parseFloat(formData.interestAmount) || 0;
        setFormData((prevData) => ({
            ...prevData,
            loanAmount: (principalAmount + interestAmount).toString(),
        }));
    }, [formData.principalAmount, formData.interestAmount]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'departmentId') {
            const department = departments.find(dep => dep.id === parseInt(value, 10));
            setFormData({
                ...formData,
                departmentId: value,
                departmentName: department ? department.name : '',
            });
            fetchEmployees(value);
            console.log(fetchEmployees(value));
        } else if (name === 'employeeId') {
            const employee = employees.find(emp => emp.id === parseInt(value, 10));
            setFormData({
                ...formData,
                employeeId: value,
                employeeName: employee ? employee.employeeName : '',
                employeeCode: employee ? employee.employeeCode : '',
                loanNumber: `LOAN${(lastLoanId + 1).toString().padStart(3, '0')}`, // Generate loan number
            });
        } else if (name === 'loanApprovedById') {
            const loanApproved = loanApprovedBys.find(emp => emp.id === parseInt(value, 10));
            setFormData({
                ...formData,
                loanApprovedById: value,
                loanApprovedByName: loanApproved ? loanApproved.employeeName : '',
            });
        } else if (name === 'loanFor') {
            setFormData({
                ...formData,
                loanFor: value,
                otherLoanForReason: '', // Reset other loan reason when loanFor changes
            });
            setOtherLoanFor(value === 'other');
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/addLoan`, formData);
            console.log('Data uploaded successfully:', response.data);
          
            onUpdate();
            setTimeout(() => {
                onClose();
                window.location.reload();
            }, 1000); // 1 second delay
        } catch (error) {
            console.error('Error uploading data:', error);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div id="add" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Loan</h5>
                            <button type="button" className="close" onClick={handleClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label>Department<span style={{ color: "red" }}>*</span></label>
                                    <select className="form-control" name="departmentId" value={formData.departmentId} onChange={handleChange}>
                                        <option value="">Select Department</option>
                                        {departments.map(dept => (
                                            <option key={dept.id} value={dept.id}>{dept.name}</option>
                                        ))}
                                    </select>
                                    {errors.departmentId && <span style={{ color: "red" }}>{errors.departmentId}</span>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Employee<span style={{ color: "red" }}>*</span></label>
                                    <select className="form-control" name="employeeId" value={formData.employeeId} onChange={handleChange}>
                                        <option value="">Select Employee</option>
                                        {employees.map(emp => (
                                            <option key={emp.id} value={emp.id}>{emp.employeeName}</option>
                                        ))}
                                    </select>
                                    {errors.employeeId && <span style={{ color: "red" }}>{errors.employeeId}</span>}
                                </div>

                            </div>
                            <div className="row">
                                <div className="form-group col-md-4">
                                    <label>Loan Number<span style={{ color: "red" }}>*</span></label>
                                    <input name="loanNumber" type="text" className="form-control" placeholder="Loan Number" value={formData.loanNumber} onChange={handleChange} readOnly />
                                    {errors.loanNumber && <span style={{ color: "red" }}>{errors.loanNumber}</span>}
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Principal Amount<span style={{ color: "red" }}>*</span></label>
                                    <input name="principalAmount" type="text" className="form-control" placeholder="Principal Amount" value={formData.principalAmount} onChange={handleChange} />
                                    {errors.principalAmount && <span style={{ color: "red" }}>{errors.principalAmount}</span>}
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Interest Amount<span style={{ color: "red" }}>*</span></label>
                                    <input name="interestAmount" type="text" className="form-control" placeholder="Interest Amount" value={formData.interestAmount} onChange={handleChange} />
                                    {errors.interestAmount && <span style={{ color: "red" }}>{errors.interestAmount}</span>}
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Loan Amount<span style={{ color: "red" }}>*</span></label>
                                    <input name="loanAmount" type="number" className="form-control" placeholder="Loan Amount" value={formData.loanAmount} readOnly />
                                    {errors.loanAmount && <span style={{ color: "red" }}>{errors.loanAmount}</span>}
                                </div>

                                <div className="form-group col-md-4">
                                    <label>Loan Date<span style={{ color: "red" }}>*</span></label>
                                    <input name="loanDate" type="date" className="form-control" value={formData.loanDate} onChange={handleChange} />
                                    {errors.loanDate && <span style={{ color: "red" }}>{errors.loanDate}</span>}
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Loan For<span style={{ color: "red" }}>*</span></label>
                                    <select name="loanFor" className="form-control" value={formData.loanFor} onChange={handleChange}>
                                        <option value="">Select Loan For</option>
                                        <option value="medical">Medical</option>
                                        <option value="house">House</option>
                                        <option value="education">Education</option>
                                        <option value="other">Other</option>
                                    </select>
                                    {formData.loanFor === 'other' && (
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter reason"
                                                name="otherLoanForReason"
                                                value={formData.otherLoanForReason}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Description<span style={{ color: "red" }}>*</span></label>
                                <textarea name="loanDescription" className="form-control" placeholder="Loan Description" value={formData.loanDescription} onChange={handleChange}></textarea>
                                {errors.loanDescription && <span style={{ color: "red" }}>{errors.loanDescription}</span>}
                            </div>
                            <div className="row">
                                <div className="form-group col-md-4">
                                    <label>Loan Approved By<span style={{ color: "red" }}>*</span></label>
                                    <select className="form-control" name="loanApprovedById" value={formData.loanApprovedById} onChange={handleChange}>
                                        <option value="">Select Loan Approved By</option>
                                        {loanApprovedBys.map(emp => (
                                            <option key={emp.id} value={emp.id}>{emp.employeeName}</option>
                                        ))}
                                    </select>
                                    {errors.loanApprovedBy && <span style={{ color: "red" }}>{errors.loanApprovedBy}</span>}
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Loan Repay Type<span style={{ color: "red" }}>*</span></label>
                                    <input name="loanRepayType" type="text" className="form-control" placeholder="Loan Repay Type" value={formData.loanRepayType} onChange={handleChange} />
                                    {errors.loanRepayType && <span style={{ color: "red" }}>{errors.loanRepayType}</span>}
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Loan Repayment Date<span style={{ color: "red" }}>*</span></label>
                                    <input name="loanRepaymentDate" type="date" className="form-control" value={formData.loanRepaymentDate} onChange={handleChange} />
                                    {errors.loanRepaymentDate && <span style={{ color: "red" }}>{errors.loanRepaymentDate}</span>}
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Remark<span style={{ color: "red" }}>*</span></label>
                                <textarea name="remark" className="form-control" placeholder="Remark" value={formData.remark} onChange={handleChange}></textarea>
                                {errors.remark && <span style={{ color: "red" }}>{errors.remark}</span>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary">Save</button>
                            <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddLoan;


