// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import SearchBar from '../../components/sidebar/SearchBar';
// import Sidebar from '../../components/sidebar/Sidebar';
// import { Navigate, useNavigate } from 'react-router-dom';

// const AddSalarySlip = ({ handleLogout, username }) => {
//     const [departments, setDepartments] = useState([]);
//     const [departmentName, setDepartmentName] = useState('');
//     const [employeeName, setEmployeeName] = useState('');
//     const [employees, setEmployees] = useState([]);
//     const [payroll, setPayroll] = useState([]);
//     const [selectedDepartment, setSelectedDepartment] = useState('');
//     const [selectedEmployee, setSelectedEmployee] = useState('');
//     const [selectedMonth, setSelectedMonth] = useState(''); // Initialize with current month
//     const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initialize with current year
//     const [attendanceRecords, setAttendanceRecords] = useState([]);
//     const [filteredAttendance, setFilteredAttendance] = useState([]);
//     const [allTotalPresent, setAllTotalPresent] = useState(0);
//     const [allTotalAbsent, setAllTotalAbsent] = useState(0);
//     const [totalPresent, setTotalPresent] = useState(0);
//     const [totalAbsent, setTotalAbsent] = useState(0);
//     const [totalHalfDay, setTotalHalfDay] = useState(0);
//     const [totalPaidLeave, setTotalPaidLeave] = useState(0);
//     const [totalUnpaidLeave, setTotalUnpaidLeave] = useState(0);
//     const [totalOvertime, setTotalOvertime] = useState(0);
//     const [totalWeeklyOff, setTotalWeeklyOff] = useState(0);
//     const [advanceBalances, setadvanceBalances] = useState([]);
//     const [filteredadvanceBalances, setfilteredadvanceBalances] = useState([]);
//     const [totalAdvanceAmount, setTotalAdvanceAmount] = useState(0);
//     // employeedetails 
//     const [employeeDetails, setEmployeeDetails] = useState(null);
//     const [basicSalaryMonth, setBasicSalaryMonth] = useState(0);
//     const [VDAmonth, setVDAmonth] = useState(0);
//     const [allowancesMonth, setAllowancesMonth] = useState(0);
//     const [conveyanceAllowancesMonth, setConveyanceAllowancesMonth] = useState(0);
//     const [specialallowancesMonth, setSpecialAllowancesMonth] = useState(0);
//     const [houseRentallowancesMonth, setHouseRentAllowancesMonth] = useState(0);
//     const [dearnessallowancesMonth, setDearnessAllowancesMonth] = useState(0);
//     const [grossSalaryMonth, setGrossSalaryMonth] = useState(0);
//     // epf esicEmployee 
//     const [totalEmployerContributionMonth, settotalEmployerContributionMonth] = useState(0)
//     const [totalEmployeeDeductionMonth, settotalEmployeeDeductionMonth] = useState(0)
//     const [totalTdsDeductionMonth, settotalTdsDeductionMonth] = useState(0)
//     const [totalPayableSalaryMonth, settotalPayableSalaryMonth] = useState(0)
//     const [totalInHandSalaryMonth, settotalInHandSalaryMonth] = useState(0)
//     const [grossPayableSalaryMonth, setGrossPayableSalaryMonth] = useState(0)
//     const [grossInHandSalaryMonth, setGrossInHandSalaryMonth] = useState(0)
//     const [totalAdvanceAmountMonth, settotalAdvanceAmountMonth] = useState(0)
//     const [netSalaryPayableMonth, setNetSalaryPayableMonth] = useState(0)
//     const [totalNetSalaryPayableMonth, setTotalNetSalaryPayableMonth] = useState(0)
//     const [epfEmployerMonth, setEpfEmployerMonth] = useState(0);
//     const [epfEmployeeMonth, setEpfEmployeeMonth] = useState(0);
//     const [esicEmployeeMonth, setEsicEmployeeMonth] = useState(0);
//     const [esicEmployerMonth, setEsicEmployerMonth] = useState(0);
//     const [overtimeMonth, setovertimeMonth] = useState(0);
//     const [halfDayMonth, sethalfDayMonth] = useState(0);
//     const [epfesicApplicableMonth, setepfesicApplicableMonth] = useState(0)
//     const [tdsApplicableMonth, settdsApplicableMonth] = useState(0)
//     // Loan Records 
//     const [loanRecords, setLoanRecords] = useState([]);
//     const [repaymentRecords, setRepaymentRecords] = useState([]);
//     // New state variables for additional allowances and deductions
//     const [additionalAllowance, setAdditionalAllowance] = useState(0);
//     const [allowanceDescription, setAllowanceDescription] = useState('');
//     const [salaryDeduction, setSalaryDeduction] = useState(0);
//     const [deductionDescription, setDeductionDescription] = useState('');
//     const [showAllowanceDescription, setShowAllowanceDescription] = useState(false);
//     const [showDeductionDescription, setShowDeductionDescription] = useState(false);
//     // Total Attendance Check 
//     const [totalAttencance, setTotalAttencance] = useState(0);
//     const [daysAttendance, setDaysAttendance] = useState(false);
//     const [totalDays, setTotalDays] = useState(0); // State for total days in selected month
//     // Advance Deduction 
//     const [advanceDeduction, setAdvanceDeduction] = useState(0);
//     const navigate = useNavigate();
//     // Hr Manager 
//     const [hrManager, setHrManager] = useState([]);

//     useEffect(() => {
//         fetchDepartments();
//         fetchHrManager();
//     }, []);

//     useEffect(() => {
//         if (selectedDepartment) {
//             fetchEmployees(selectedDepartment);
//         }
//     }, [selectedDepartment]);

//     useEffect(() => {
//         if (selectedEmployee) {
//             fetchAttendanceRecords(selectedEmployee);
//             fetchadvanceBalances(selectedEmployee);
//             fetchEmployeeDetails(selectedEmployee);
//             fetchLoanDetails(selectedEmployee);
//             fetchRepaymentDetails(selectedEmployee);
//             fetchPayroll(selectedEmployee);
//         }
//     }, [selectedEmployee]);

//     useEffect(() => {
//         filterAttendanceRecords(selectedMonth, selectedYear);
//     }, [selectedMonth, selectedYear, attendanceRecords]);

//     useEffect(() => {
//         filteradvanceBalances(selectedMonth, selectedYear);
//     }, [selectedMonth, selectedYear, advanceBalances]);

//     useEffect(() => {
//         calculateMonthlySalaries(employeeDetails);
//     }, [selectedEmployee, selectedMonth, selectedYear, attendanceRecords, advanceBalances, filteredadvanceBalances, filteredAttendance, salaryDeduction, additionalAllowance, advanceDeduction]);

//     useEffect(() => {
//         // Calculate total days in selected month and update state
//         const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
//         console.log("Total Attendance", totalAttencance)
//         setTotalDays(daysInMonth);
//     }, [selectedEmployee, selectedMonth, selectedYear]);

//     useEffect(() => {
//         const checkPayrollMonth = () => {
//             const selectedMonthAdjusted = selectedMonth + 1; // Adjust selected month since JavaScript Date months are 0-based
//             const payrollMonthExists = payroll.some(entry => new Date(entry.date).getMonth() + 1 === selectedMonthAdjusted);

//             if (payrollMonthExists) {
//                 alert("Salary Slip for the selected month already exists.");
//             } else {
//                 console.log("No payroll found for the selected month.");
//             }
//         };

//         if (selectedEmployee && selectedMonth && selectedYear) {
//             checkPayrollMonth();
//         }
//     }, [selectedEmployee, selectedMonth, selectedYear, payroll]);




//     const fetchDepartments = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/departments`);
//             setDepartments(response.data);
//         } catch (error) {
//             console.error('Error fetching departments:', error);
//         }
//     };

//     const fetchHrManager = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/hrmanager`);
//             setHrManager(response.data);
//         } catch (error) {
//             console.error("Error fetching timelines:", error);
//         }
//     };

//     const fetchEmployees = async (departmentId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employee_department/${departmentId}`);
//             setEmployees(response.data);
//         } catch (error) {
//             console.error('Error fetching employees:', error);
//         }
//     };

//     const fetchPayroll = async (employeeId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/payroll/${employeeId}`);
//             setPayroll(response.data);
//         } catch (error) {
//             console.error('Error fetching Payroll:', error);
//         }
//     };

//     const fetchAttendanceRecords = async (employeeId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/attendance/${employeeId}`);
//             const attendanceData = response.data || [];
//             setAttendanceRecords(attendanceData);
//             filterAttendanceRecords(selectedMonth, selectedYear, attendanceData);
//         } catch (error) {
//             console.error('Error fetching attendance records:', error);
//         }
//     };

//     const fetchadvanceBalances = async (employeeId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/advancebalance/${employeeId}`);
//             setadvanceBalances(response.data);
//             filteradvanceBalances(selectedMonth, selectedYear, response.data);
//         } catch (error) {
//             console.error('Error fetching advance balance:', error);
//         }
//     };
//     const fetchEmployeeDetails = async (employeeId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`);
//             setEmployeeDetails(response.data);
//             console.log('Employee details fetched:', response.data);
//         } catch (error) {
//             console.error('Error fetching employee details:', error);
//         }
//     };

//     const fetchLoanDetails = async (employeeId) => {
//         try {
//             const response = await axios.get(
//                 `${process.env.REACT_APP_LOCAL_URL}/api/loandetails/${employeeId}`
//             );
//             setLoanRecords(response.data);
//         } catch (error) {
//             console.error("Error fetching loan details:", error);
//         }
//     };
//     const fetchRepaymentDetails = async (employeeId) => {
//         try {

//             const response = await axios.get(
//                 `${process.env.REACT_APP_LOCAL_URL}/api/repaymentdetails/${employeeId}`
//             );
//             setRepaymentRecords(response.data);
//         } catch (error) {
//             console.error("Error fetching repayment details:", error);
//         }
//     };

//     const filterAttendanceRecords = (month, year, data = attendanceRecords) => {
//         const filteredRecords = data.filter(record => {
//             const recordDate = new Date(record.date);
//             return recordDate.getMonth() === month && recordDate.getFullYear() === year;
//         });

//         const presentCount = filteredRecords.filter(record =>
//             ['present'].includes(record.status.toLowerCase())
//         ).length;
//         const absentCount = filteredRecords.filter(record =>
//             ['absent'].includes(record.status.toLowerCase())
//         ).length;
//         const halfDayCount = filteredRecords.filter(record =>
//             ['half day'].includes(record.status.toLowerCase())
//         ).length;
//         const paidLeaveCount = filteredRecords.filter(record =>
//             ['paid leave'].includes(record.status.toLowerCase())
//         ).length;
//         const unpaidLeaveCount = filteredRecords.filter(record =>
//             ['unpaid leave'].includes(record.status.toLowerCase())
//         ).length;
//         const overtimeCount = filteredRecords.filter(record =>
//             ['overtime'].includes(record.status.toLowerCase())
//         ).length;
//         const weeklyOffCount = filteredRecords.filter(record =>
//             ['weekly off'].includes(record.status.toLowerCase())
//         ).length;

//         const TotalAttendance = presentCount + absentCount + halfDayCount + paidLeaveCount + unpaidLeaveCount + overtimeCount + weeklyOffCount;
//         const AllTotalPresent = presentCount + paidLeaveCount + weeklyOffCount + overtimeCount;
//         const AllTotalAbsent = absentCount + unpaidLeaveCount + halfDayCount;
//         setAllTotalPresent(AllTotalPresent);
//         setAllTotalAbsent(AllTotalAbsent);
//         setTotalAttencance(TotalAttendance)
//         setTotalPresent(presentCount);
//         setTotalAbsent(absentCount);
//         setTotalHalfDay(halfDayCount);
//         setTotalPaidLeave(paidLeaveCount);
//         setTotalUnpaidLeave(unpaidLeaveCount);
//         setTotalOvertime(overtimeCount);
//         setTotalWeeklyOff(weeklyOffCount);
//         setFilteredAttendance(filteredRecords);
//         // Check if total attendance does not match total days

//         // Check if total attendance does not match total day
//         if (TotalAttendance !== totalDays) {
//             setDaysAttendance(true)
//             // toast.error(`Total attendance (${TotalAttendance}) does not match total days (${totalDays}).`);
//         }
//     };

//     const filteradvanceBalances = (month, year, data = advanceBalances) => {
//         // Create Date object for the specified month and year
//         const currentMonth = new Date(year, month, 1); // month is zero-based (0 = January, 11 = December)

//         // Find the balance for the current month
//         const currentMonthBalance = data.find(balance => {
//             const balanceDate = new Date(balance.month);
//             return balanceDate.getFullYear() === currentMonth.getFullYear() && balanceDate.getMonth() === currentMonth.getMonth();
//         }) || { balance: 0 }; // Default to { balance: 0 } if not found

//         // Set the total advance amount
//         setTotalAdvanceAmount(currentMonthBalance.balance);
//     };
//     const calculateLoanDue = (loanAmount, totalRepaymentAmount) => {
//         const loanDue = parseFloat(loanAmount) - parseFloat(totalRepaymentAmount);
//         return loanDue.toFixed(2); // Adjust as per your requirement
//     };

//     // Laon Details 
//     const getTotalRepaymentAmount = (loanId) => {
//         // Filter repayment records for the specific loanId
//         const repaymentsForLoan = repaymentRecords.filter(repayment => repayment.loanId === loanId);

//         // Calculate total repayment amount
//         const totalRepaymentAmount = repaymentsForLoan.reduce((total, repayment) => {
//             return total + parseFloat(repayment.repaymentAmount || 0);
//         }, 0);

//         return totalRepaymentAmount.toFixed(2); // Adjust as per your requirement
//     };

//     const calculateMonthlySalaries = () => {

//         if (employeeDetails && employeeDetails.length > 0) {
//             const selectedEmployeeObj = employeeDetails[0];
//             const basicSalary = selectedEmployeeObj.basicSalary || 0;
//             const VDA = selectedEmployeeObj.vda || 0;
//             const specialallowances = selectedEmployeeObj.specialallowances || 0;
//             const dearnessallowances = selectedEmployeeObj.dearnessallowances || 0;
//             const conveyanceallowances = selectedEmployeeObj.conveyanceallowances || 0;
//             const houserentallowances = selectedEmployeeObj.houserentallowances || 0;
//             const grossSalary = selectedEmployeeObj.grossSalary || 0;
//             const epfesicApplicable = selectedEmployeeObj.epfesicApplicable || false;
//             const tdsApplicable = selectedEmployeeObj.tdsApplicable || false;
//             const employeeName = selectedEmployeeObj.employeeName;
//             const departmentName = selectedEmployeeObj.departmentName;
//             console.log("totabss", totalAbsent)
//             console.log("totleave", totalPaidLeave)
//             console.log("cal", basicSalary, totalUnpaidLeave, totalAbsent)
//             console.log("employeeName", employeeName)
//             console.log("departmentName", departmentName)
//             // Calculate deductions
//             // const submissionofovertimehalfday = Math.floor((totalOvertime+totalHalfDay)/2);
//             const deductionBasic = basicSalary - (basicSalary / 30) * (totalUnpaidLeave + totalAbsent + totalHalfDay);
//             const deductionVDA = VDA - (VDA / 30) * (totalUnpaidLeave + totalAbsent + totalHalfDay);
//             const deductionHouseRentAllowances = houserentallowances - (houserentallowances / 30) * (totalUnpaidLeave + totalAbsent + totalHalfDay);
//             const deductionConveyanceAllowances = conveyanceallowances - (conveyanceallowances / 30) * (totalUnpaidLeave + totalAbsent + totalHalfDay);
//             const deductionDearnessAllowances = dearnessallowances - (dearnessallowances / 30) * (totalUnpaidLeave + totalAbsent + totalHalfDay);
//             const deductionSpecialAllowances = specialallowances - (specialallowances / 30) * (totalUnpaidLeave + totalAbsent + totalHalfDay);

//             console.log("dedcal", deductionBasic)
//             const grossSalaryMonth = deductionBasic + deductionVDA + deductionHouseRentAllowances + deductionConveyanceAllowances + deductionDearnessAllowances + deductionSpecialAllowances;
//             // overtime  
//             const deductionovertime = ((grossSalary / 30) / 2) * totalOvertime;
//             // half day
//             const deductionhalfDay = ((grossSalary / 30) / 2) * totalHalfDay;

//             console.log("bas", basicSalary);
//             console.log("div", basicSalary / 30)
//             console.log("tot3", totalUnpaidLeave + totalAbsent + totalHalfDay)
//             console.log("ded", deductionBasic)


//             setBasicSalaryMonth(deductionBasic);
//             setVDAmonth(deductionVDA);
//             setConveyanceAllowancesMonth(deductionConveyanceAllowances)
//             setSpecialAllowancesMonth(deductionSpecialAllowances)
//             setHouseRentAllowancesMonth(deductionHouseRentAllowances)
//             setDearnessAllowancesMonth(deductionDearnessAllowances)
//             // setGrossSalaryMonth(grossSalaryMonth);

//             let epfEmployer = 0;
//             let epfEmployee = 0;
//             let esicEmployer = 0;
//             let esicEmployee = 0;
//             let tdsDeduction = 0;

//             if (epfesicApplicable) {
//                 epfEmployer = (0.12 * deductionBasic).toFixed(2);
//                 epfEmployee = (0.12 * deductionBasic).toFixed(2);
//                 esicEmployer = (0.0325 * grossSalaryMonth).toFixed(2);
//                 esicEmployee = (0.0075 * grossSalaryMonth).toFixed(2);
//             }

//             if (tdsApplicable) {
//                 tdsDeduction = (0.1 * grossSalaryMonth).toFixed(2);
//             }

//             const totalEmployerContribution = (parseFloat(epfEmployer) + parseFloat(esicEmployer)).toFixed(2);
//             const totalEmployeeDeduction = (parseFloat(epfEmployee) + parseFloat(esicEmployee)).toFixed(2);
//             const totalTdsDeduction = parseFloat(tdsDeduction).toFixed(2);
//             const grossPayableSalary = (grossSalaryMonth + deductionovertime + deductionhalfDay + parseFloat(totalEmployerContribution)).toFixed(2);
//             const grossInHandSalary = (deductionovertime + deductionhalfDay + grossSalaryMonth - parseFloat(totalEmployeeDeduction) - parseFloat(totalTdsDeduction)).toFixed(2);

//             // Parse the fixed numbers back to float for further calculations
//             const parsedGrossPayableSalary = parseFloat(grossPayableSalary);
//             const parsedGrossInHandSalary = parseFloat(grossInHandSalary);
//             const parsedAdditionalAllowance = parseFloat(additionalAllowance) || 0; // Default to 0 if additionalAllowance is not a valid number
//             const parsedSalaryDeduction = parseFloat(salaryDeduction) || 0; // Default to 0 if salaryDeduction is not a valid number
//             const parsedAdvanceDeduction = parseFloat(advanceDeduction) || 0; // Default to 0 if salaryDeduction is not a valid number

//             console.log("additionalAllowance", parsedAdditionalAllowance);
//             console.log("grosssalary", (parsedGrossPayableSalary + parsedAdditionalAllowance).toFixed(2));

//             const totalPayableSalary = (parsedGrossPayableSalary + parsedAdditionalAllowance - parsedSalaryDeduction).toFixed(2);
//             const totalInHandSalary = (parsedGrossInHandSalary + parsedAdditionalAllowance - parsedSalaryDeduction).toFixed(2);

//             console.log("totalPayableSalary", totalPayableSalary);
//             console.log("totalInHandSalary", totalInHandSalary);
//             console.log("totaladvance", totalAdvanceAmount);


//             const NetSalaryPayable = (totalInHandSalary - parsedAdvanceDeduction.toFixed(2));
//             console.log("NetSalaryPayable", NetSalaryPayable)

//             setGrossSalaryMonth(grossSalaryMonth);
//             setepfesicApplicableMonth(epfesicApplicable);
//             settdsApplicableMonth(tdsApplicable);
//             setEpfEmployerMonth(epfEmployer);
//             setEpfEmployeeMonth(epfEmployee);
//             setEsicEmployeeMonth(esicEmployee);
//             setEsicEmployerMonth(esicEmployer);
//             settotalEmployerContributionMonth(totalEmployerContribution);
//             settotalEmployeeDeductionMonth(totalEmployeeDeduction);
//             settotalTdsDeductionMonth(totalTdsDeduction);
//             sethalfDayMonth(deductionhalfDay);
//             setovertimeMonth(deductionovertime);
//             setGrossPayableSalaryMonth(grossPayableSalary);
//             setGrossInHandSalaryMonth(grossInHandSalary);
//             settotalPayableSalaryMonth(totalPayableSalary);
//             settotalInHandSalaryMonth(totalInHandSalary);
//             settotalAdvanceAmountMonth(totalAdvanceAmount);
//             setNetSalaryPayableMonth(NetSalaryPayable);
//             setEmployeeName(employeeName);
//             setDepartmentName(departmentName);
//         }
//     };

//     // For Add the allowances and salary Deduction 
//     const toggleAllowanceDescription = () => {
//         setShowAllowanceDescription(!showAllowanceDescription);
//         if (showDeductionDescription) {
//             setShowDeductionDescription(false);
//         }
//     };
//     const toggleDeductionDescription = () => {
//         setShowDeductionDescription(!showDeductionDescription);
//         if (showAllowanceDescription) {
//             setShowAllowanceDescription(false);
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         // Example validation (you can modify this based on your form requirements)
//         if (!selectedEmployee || !selectedMonth || !selectedYear) {
//             toast.error('Please select employee, month, and year.');
//             return;
//         }

//         const today = new Date();
//         const formattedDay = String(today.getDate()).padStart(2, '0');
//         const formattedMonth = String(selectedMonth + 1).padStart(2, '0');

//         // Check if the payroll for the selected month already exists
//         const payrollMonthExists = payroll.some(entry => new Date(entry.date).getMonth() + 1 === selectedMonth + 1);

//         if (payrollMonthExists) {
//             alert("Payroll for the selected month already exists.");
//             return; // Prevent form submission if payroll month exists
//         }

//         try {
//             // Example of how you might structure your API call using Axios
//             const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/payroll`, {
//                 employeeId: selectedEmployee,
//                 month: selectedMonth + 1,
//                 year: selectedYear,
//                 hr_id: hrManager.id,
//                 hrManagerName: hrManager.employeeName,
//                 employeeName,
//                 departmentName,
//                 tdsApplicable:
//                     // Include other relevant form data here as needed
//                     departments,
//                 employees,
//                 selectedDepartment,
//                 selectedEmployee,
//                 selectedMonth,
//                 selectedYear,
//                 date: `${selectedYear}-${formattedMonth}-${formattedDay}`,
//                 attendanceRecords,
//                 filteredAttendance,
//                 totalPresent,
//                 totalAbsent,
//                 totalHalfDay,
//                 totalPaidLeave,
//                 totalUnpaidLeave,
//                 totalOvertime,
//                 totalWeeklyOff,
//                 advanceBalances,
//                 allTotalAbsent,
//                 allTotalPresent,
//                 filteredadvanceBalances,
//                 totalAdvanceAmount,
//                 employeeDetails,
//                 basicSalaryMonth,
//                 VDAmonth,
//                 allowancesMonth,
//                 conveyanceAllowancesMonth,
//                 houseRentallowancesMonth,
//                 dearnessallowancesMonth,
//                 specialallowancesMonth,
//                 grossSalaryMonth,
//                 totalEmployerContributionMonth,
//                 totalEmployeeDeductionMonth,
//                 totalTdsDeductionMonth,
//                 totalPayableSalaryMonth,
//                 totalInHandSalaryMonth,
//                 totalAdvanceAmountMonth,
//                 netSalaryPayableMonth,
//                 totalNetSalaryPayableMonth,
//                 epfEmployerMonth,
//                 epfEmployeeMonth,
//                 esicEmployeeMonth,
//                 esicEmployerMonth,
//                 overtimeMonth,
//                 halfDayMonth,
//                 epfesicApplicableMonth,
//                 tdsApplicableMonth,
//                 loanRecords,
//                 repaymentRecords,
//                 additionalAllowance,
//                 allowanceDescription,
//                 salaryDeduction,
//                 deductionDescription,
//                 showAllowanceDescription,
//                 showDeductionDescription,
//                 advanceDeduction,
//                 receivingMode: "salary",
//                 grossPayableSalaryMonth,
//                 grossInHandSalaryMonth,
//                 totalAttencance
//             });

//             const advanceresponse = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/api/salary/repayment`, {
//                 employee_id: selectedEmployee,
//                 amount: advanceDeduction,
//                 month: selectedMonth + 1,
//                 year: selectedYear,
//                 date: `${selectedYear}-${formattedMonth}-${formattedDay}`,
//                 receivingMode: "salary",
//             });

//             // Handle success (e.g., show a success toast)
//             console.log("Response Data :", response);
//             console.log("Advance Data :", advanceresponse.data);
//             setDepartmentName("");
//             setEmployeeName("");
//             setSelectedMonth("");
//             setSelectedDepartment("");
//             setSelectedEmployee("");
//             toast.success('Salary slip added successfully!');
//             setTimeout(() => {
//                 window.location.reload();
//             }, 1000); // 1 second delay

//             // You can optionally reset your form state or do any other post-submission tasks
//         } catch (error) {
//             // Handle error (e.g., show an error toast)
//             console.error('Error adding salary slip:', error);
//             toast.error('Failed to add salary slip. Please try again.');
//         }
//     };


//     return (
//         <div className='d-flex w-100 h-100'>
//             <Sidebar />
//             <div className='w-100'>
//                 <SearchBar username={username} handleLogout={handleLogout} />
//                 <div className="container-fluid">
//                     <ToastContainer />
//                     <div className="row bg-dark bg-white p-2 rounded border">
//                         <div className="col-sm-12 col-md-12" >
//                             <div className="panel panel-bd">
//                                 <div className="panel-heading">
//                                     <div className="panel-title">
//                                         <h4 className='p-1 text-black fw-bolder text-capitalize m-0'>Salary Slip</h4>
//                                     </div>
//                                 </div>
//                                 <div className="panel-body">
//                                     <form onSubmit={handleSubmit} className="bg-white p-2 rounded " encType="multipart/form-data" acceptCharset="utf-8">
//                                         <div className="form-row">
//                                             <div className="form-group col-md-4">
//                                                 <label htmlFor="department" className="col-xs-3 col-form-label">Department<span style={{ color: "red" }}>*</span></label>
//                                                 <div className="col-xs-9">
//                                                     <select className="form-control" id="department" value={selectedDepartment} onChange={(e) => setSelectedDepartment(e.target.value)}>
//                                                         <option value="">Select Department</option>
//                                                         {departments.map(dept => (
//                                                             <option key={dept.id} value={dept.id}>{dept.name}</option>
//                                                         ))}
//                                                     </select>
//                                                 </div>
//                                             </div>
//                                             <div className="form-group col-md-4">
//                                                 <label htmlFor="employee" className="col-xs-3 col-form-label">Employee<span style={{ color: "red" }}>*</span></label>
//                                                 <div className="col-xs-9">
//                                                     <select className="form-control" id="employee" value={selectedEmployee} onChange={(e) => setSelectedEmployee(e.target.value)}>
//                                                         <option value="">Select Employee</option>
//                                                         {employees.map(emp => (
//                                                             <option key={emp.id} value={emp.id}>{emp.employeeName}</option>
//                                                         ))}
//                                                     </select>
//                                                 </div>
//                                             </div>
//                                             <div className="form-group col-md-4">
//                                                 <label className="col-xs-3 text-black col-form-label">Filter :</label>
//                                                 <div className='col-xs-6 d-flex gap-2'>
//                                                     <select className="form-control" value={selectedMonth} onChange={(e) => setSelectedMonth(parseInt(e.target.value))}>
//                                                         <option value="">Select Month</option>
//                                                         {[...Array(12).keys()].map(i => (
//                                                             <option key={i} value={i}>{new Date(0, i).toLocaleString('default', { month: 'long' })}</option>
//                                                         ))}
//                                                     </select>
//                                                     <select className="form-control" value={selectedYear} onChange={(e) => setSelectedYear(parseInt(e.target.value))}>
//                                                         <option value="">Select Year</option>
//                                                         {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i).map(year => (
//                                                             <option key={year} value={year}>{year}</option>
//                                                         ))}
//                                                     </select>
//                                                 </div>
//                                             </div>

//                                         </div>
//                                         <div className=' p-2 bg-light shadow-sm  rounded mt-3'>
//                                             <div className='d-flex align-items-center justify-content-between p-2'>
//                                                 <h4 className='text-black fw-bolder '>Attendance/Salary Details</h4>
//                                                 {daysAttendance == true ? (
//                                                     <small className="m-0 font-weight-bold text-danger">Please fill the attendance first - {totalDays - totalAttencance}</small>
//                                                 ) : null}
//                                             </div>
//                                             <div className='rounded border bg-white' style={{ maxHeight: "calc(100vh - 120px)", overflowY: "auto", overflowX: "hidden" }}>
//                                                 <div className='d-flex'>
//                                                     <div className='col-md-6 p-2'>
//                                                         <h6 style={{ backgroundColor: "#3F4D67" }} className='text-white w-100 fw-bolder m-0 p-2'> Total Attendance Details :</h6>
//                                                         <table style={{ backgroundColor: "#3F4D67" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
//                                                             <tbody >
//                                                                 <div className='d-flex justify-content-between bg-danger'>
//                                                                     <div className='p-0 w-100 bg-dark'>
//                                                                         <table className="table table-striped table-bordered m-0">
//                                                                             <tbody>
//                                                                                 <tr>
//                                                                                     <td bgcolor="#f2f3f4" >
//                                                                                         <p className="mb-0 fw-bold text-success"> Present </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0"> {totalPresent || '0'} Days</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                                 <tr>
//                                                                                     <td bgcolor="#f2f3f4" >
//                                                                                         <p className="mb-0 fw-bold text-success"> Over Time </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0"> {totalOvertime || '0'} Days</p>
//                                                                                     </td>
//                                                                                 </tr>

//                                                                                 <tr>
//                                                                                     <td bgcolor="#f2f3f4" >
//                                                                                         <p className="mb-0 fw-bold text-success"> Weekly Off </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0"> {totalWeeklyOff || '0'} Days</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                                 <tr>
//                                                                                     <td bgcolor="#f2f3f4" >
//                                                                                         <p className="mb-0 fw-bold text-success"> Half Day </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0"> {totalHalfDay || '0'} Days</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                             </tbody>
//                                                                         </table>
//                                                                     </div>
//                                                                     <div className='p-0 w-100'>
//                                                                         <table className="table table-striped table-bordered">
//                                                                             <tbody>
//                                                                                 <tr>
//                                                                                     <td bgcolor="#f2f3f4" >
//                                                                                         <p className="mb-0 fw-bold text-success"> Paid Leave </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0"> {totalPaidLeave || '0'} Days</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                                 <tr>
//                                                                                     <td bgcolor="#f2f3f4" >
//                                                                                         <p className="mb-0 fw-bold text-danger"> Absent</p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0"> {totalAbsent || '0'} Days</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                                 <tr>
//                                                                                     <td bgcolor="#f2f3f4" >
//                                                                                         <p className="mb-0 fw-bold text-danger"> UnPaid Leave </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0"> {totalUnpaidLeave || '0'} Days</p>
//                                                                                     </td>
//                                                                                 </tr>

//                                                                                 <tr>
//                                                                                     <td bgcolor="#f2f3f4">
//                                                                                         <p className="mb-0 fw-bold text-black"> Total Days </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0"> {totalAttencance || '0'} Days</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                             </tbody>
//                                                                         </table>
//                                                                     </div>
//                                                                 </div>
//                                                             </tbody>
//                                                         </table>
//                                                         <h6 style={{ backgroundColor: "#3F4D67" }} className='text-white w-100 fw-bolder m-0 p-2'> Salary Details :</h6>
//                                                         <table style={{ backgroundColor: "#3F4D67" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
//                                                             <tbody >
//                                                                 <div className='d-flex justify-content-between bg-danger'>
//                                                                     <div className='p-0 w-100'>
//                                                                         <table className="table table-striped table-bordered ">
//                                                                             <tbody>
//                                                                                 <tr>
//                                                                                     <td>
//                                                                                         <p className="mb-0 fw-bold text-black"> Basic Salary </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0">&#x20B9;{basicSalaryMonth.toFixed(2) || '0'}</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                                 <tr>
//                                                                                     <td>
//                                                                                         <p className="mb-0  text-black" style={{cursor:"pointer"}} title="Variable Dearness Allowance"> VDA </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0">&#x20B9;{VDAmonth.toFixed(2) || '0'}</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                                 <tr>
//                                                                                     <td>
//                                                                                         <p className="mb-0  text-black" style={{cursor:"pointer"}} title="House Rent Allowance"> HRA </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0">&#x20B9;{houseRentallowancesMonth.toFixed(2) || '0'}</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                             </tbody>
//                                                                         </table>
//                                                                         <tr >
//                                                                             <td>
//                                                                                 <p className="mb-0 fw-bolder font-bold">Gross Salary </p>
//                                                                             </td>
//                                                                         </tr>

//                                                                     </div>
//                                                                     <div className='p-0 w-100'>
//                                                                         <table className="table table-striped table-bordered">
//                                                                             <tbody>
//                                                                                 <tr>
//                                                                                     <td>
//                                                                                         <p className="mb-0  text-black" style={{cursor:"pointer"}} title="Conveyance Allowance"> CA </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0">&#x20B9;{conveyanceAllowancesMonth.toFixed(2) || '0'}</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                                 <tr>
//                                                                                     <td>
//                                                                                         <p className="mb-0  text-black" style={{cursor:"pointer"}} title="Dearness Allowance"> DA</p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0"> &#x20B9;{dearnessallowancesMonth.toFixed(2) || '0'}</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                                 <tr>
//                                                                                     <td>
//                                                                                         <p className="mb-0  text-black" style={{cursor:"pointer"}} title="Special Allowance"> SA </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0">&#x20B9;{specialallowancesMonth.toFixed(2) || '0'}</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                             </tbody>
//                                                                         </table>
//                                                                         <tr>
//                                                                             <td className=''>
//                                                                                 <p className="mb-0 fw-bolder ">: &#x20B9;{grossSalaryMonth || '0'}</p>
//                                                                             </td>
//                                                                         </tr>
//                                                                     </div>
//                                                                 </div>
//                                                             </tbody>
//                                                         </table>
//                                                     </div>
//                                                     <div className='col-md-6 p-2'>
//                                                         <table style={{ backgroundColor: "#3F4D67" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
//                                                             <tbody >
//                                                                 <h6 className='text-white fw-bolder m-0 p-2'>EPF & ESIC Contribution :</h6>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="300">
//                                                                         <p className="mb-0">EPF - ESIC Applicable</p>
//                                                                     </td>
//                                                                     <td>
//                                                                         <p className="mb-0">:
//                                                                             {epfesicApplicableMonth === 1 || epfesicApplicableMonth === true ? 'Yes' : (epfesicApplicableMonth === 0 || epfesicApplicableMonth === false ? 'No' : '0')}
//                                                                         </p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <h6 className='text-white fw-bolder m-0 p-2'>Employeer Contribution :</h6>

//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="300">
//                                                                         <p className="mb-0">EPF 12%  <span className="mx-2">: &#x20B9;{epfEmployerMonth || '0'}</span> </p>
//                                                                     </td>

//                                                                     <td bgcolor="#f2f3f4" width="300">
//                                                                         <p className="mb-0"> ESIC 3.25%  <span className="mx-2">: &#x20B9;{esicEmployerMonth || '0'}</span> </p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td className='bg-body-secondary' bgcolor="#f2f3f4" width="300">
//                                                                         <p className="mb-0 fw-bolder">Total Employer Contribution </p>
//                                                                     </td>
//                                                                     <td className='bg-body-secondary'>
//                                                                         <p className="mb-0 fw-bolder">: &#x20B9;{totalEmployerContributionMonth || '0'}</p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <h6 className='text-white fw-bolder m-0 p-2'>Employee Deduction :</h6>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="300">
//                                                                         <p className="mb-0">EPF 12% <span className="mb-0 mx-2">: &#x20B9;{epfEmployeeMonth || '0'}</span></p>
//                                                                     </td>
//                                                                     <td bgcolor="#f2f3f4" width="300">
//                                                                         <p className="mb-0">ESIC 0.75% <span className="mb-0 mx-2">: &#x20B9;{esicEmployeeMonth || '0'}</span></p>
//                                                                     </td>

//                                                                 </tr>

//                                                                 <tr>
//                                                                     <td className='bg-body-secondary    ' bgcolor="#f2f3f4" width="300">
//                                                                         <p className="mb-0 fw-bolder">Total Employee Deduction </p>
//                                                                     </td>
//                                                                     <td className='bg-body-secondary'>
//                                                                         <p className="mb-0 fw-bolder">: &#x20B9;{totalEmployeeDeductionMonth || '0'}</p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <h6 className='text-white fw-bolder m-0 p-2'>TDS :</h6>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="300">
//                                                                         <p className="mb-0">TDS Applicable </p>
//                                                                     </td>
//                                                                     <td>
//                                                                         <p className="mb-0">:
//                                                                             {tdsApplicableMonth === 1 || tdsApplicableMonth === true ? 'Yes' : (tdsApplicableMonth === 0 || tdsApplicableMonth === false ? 'No' : '0')}
//                                                                         </p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td className='bg-body-secondary' bgcolor="#f2f3f4" width="300">
//                                                                         <p className="mb-0 fw-bolder">Total TDS @10% Deduction</p>
//                                                                     </td>
//                                                                     <td className='bg-body-secondary'>
//                                                                         <p className="mb-0 fw-bolder">: &#x20B9;{totalTdsDeductionMonth || '0'}</p>
//                                                                     </td>
//                                                                 </tr>
//                                                             </tbody>
//                                                         </table>
//                                                     </div>
//                                                 </div>
//                                                 <hr />
//                                                 <div className='d-flex'>
//                                                     <div className='col-md-6 p-2'>
//                                                         <table style={{ backgroundColor: "#3F4D67" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
//                                                             <tbody >
//                                                                 <h6 className='text-white fw-bolder m-0 p-2'>Overtime/Half Day :</h6>

//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 font-bold">Over Time</p>
//                                                                     </td>
//                                                                     <td>
//                                                                         <p className="mb-0">: &#x20B9;{overtimeMonth.toFixed(2) || '0'}</p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 font-bold">Half Day</p>
//                                                                     </td>
//                                                                     <td>
//                                                                         <p className="mb-0">: &#x20B9;{halfDayMonth.toFixed(2) || '0'}</p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <h6 className='text-white fw-bolder m-0 p-2'>Total Salary :</h6>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 font-bold">Gross Payable Salary </p>
//                                                                     </td>
//                                                                     <td>
//                                                                         <p className="mb-0 ">: &#x20B9;{grossPayableSalaryMonth || '0'}  </p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 font-bold">Grsoss In-Hand Salary </p>
//                                                                     </td>
//                                                                     <td>
//                                                                         <p className="mb-0">: &#x20B9;{grossInHandSalaryMonth || '0'} </p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <label htmlFor="additionalAllowance">Additional Allowance:</label>
//                                                                     </td>
//                                                                     <td >
//                                                                         <div className='d-flex gap-2'>
//                                                                             <input
//                                                                                 type="number"
//                                                                                 id="additionalAllowance"
//                                                                                 value={additionalAllowance}
//                                                                                 onChange={(e) => setAdditionalAllowance(e.target.value)}
//                                                                                 className="form-control"
//                                                                                 placeholder='Additional Allowance'
//                                                                                 required
//                                                                             />
//                                                                             <button onClick={toggleAllowanceDescription} className="btn btn-success"><i class="fa fa-plus" aria-hidden="true"></i></button>
//                                                                         </div>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td colSpan="2" className='p-0'>
//                                                                         {showAllowanceDescription && (
//                                                                             <textarea
//                                                                                 type="text"
//                                                                                 id="allowanceDescription"
//                                                                                 value={allowanceDescription}
//                                                                                 onChange={(e) => setAllowanceDescription(e.target.value)}
//                                                                                 className="form-control "
//                                                                                 placeholder='Additional Allowance Description'
//                                                                                 required
//                                                                             />
//                                                                         )}
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <label htmlFor="salaryDeduction">Additional Deduction:</label>
//                                                                     </td>
//                                                                     <td >
//                                                                         <div className='d-flex gap-2'>
//                                                                             <input
//                                                                                 type="number"
//                                                                                 id="salaryDeduction"
//                                                                                 value={salaryDeduction}
//                                                                                 onChange={(e) => setSalaryDeduction(e.target.value)}
//                                                                                 className="form-control"
//                                                                                 placeholder='Salary Deduction'
//                                                                                 required
//                                                                             />
//                                                                             <button onClick={toggleDeductionDescription} className="btn btn-success"><i class="fa fa-plus" aria-hidden="true"></i></button>
//                                                                         </div>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td colSpan="2" className=' p-0'>
//                                                                         {showDeductionDescription && (
//                                                                             <textarea
//                                                                                 type="text"
//                                                                                 id="deductionDescription"
//                                                                                 value={deductionDescription}
//                                                                                 onChange={(e) => setDeductionDescription(e.target.value)}
//                                                                                 className="form-control"
//                                                                                 placeholder='Additional Deduction Description'
//                                                                                 required
//                                                                             />
//                                                                         )}
//                                                                     </td>
//                                                                 </tr>
//                                                                 {/* <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 font-bold">Total Payable Salary </p>
//                                                                     </td>
//                                                                     <td>
//                                                                         <p className="mb-0 ">: &#x20B9;{totalPayableSalaryMonth || '0'}  <span className='text-secondary'> (Gross+Contribution+Overtime/halfDay)</span></p>
//                                                                     </td>
//                                                                 </tr> */}
//                                                                 <tr >
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 font-bold">Total In-Hand Salary </p>
//                                                                     </td>
//                                                                     <td>
//                                                                         <p className="mb-0">: &#x20B9;{totalInHandSalaryMonth || '0'} </p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 font-bold">Total Advance Paid     </p>
//                                                                     </td>
//                                                                     <td>
//                                                                         <p className="mb-0">: &#x20B9;{totalAdvanceAmount.toFixed(2) || '0'}</p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <label htmlFor="advanceDeduction">Advance Deduction:</label>
//                                                                     </td>
//                                                                     <td>
//                                                                         <div className='d-flex gap-2'>
//                                                                             <input
//                                                                                 type="number"
//                                                                                 id="advanceDeduction"
//                                                                                 value={advanceDeduction}
//                                                                                 onChange={(e) => {
//                                                                                     const value = parseFloat(e.target.value) || 0;
//                                                                                     if (value <= totalAdvanceAmount) {
//                                                                                         setAdvanceDeduction(value);
//                                                                                     } else {
//                                                                                         alert(`Advance Deduction cannot exceed ${totalAdvanceAmount}`);
//                                                                                     }
//                                                                                 }}
//                                                                                 className="form-control"
//                                                                                 placeholder='Advance Deduction'
//                                                                                 required
//                                                                             />
//                                                                         </div>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td className='bg-body-secondary' bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 fw-bolder  font-bold">Net Salary Payable </p>
//                                                                     </td>
//                                                                     <td className='bg-body-secondary'>
//                                                                         <p className="mb-0 fw-bolder">: &#x20B9;{netSalaryPayableMonth.toFixed(2) || '0'} </p>
//                                                                     </td>
//                                                                 </tr>
//                                                             </tbody>
//                                                         </table>
//                                                     </div>
//                                                     <div className='col-md-6 p-2 '>
//                                                         <div className='bg-danger'>
//                                                             <table className="table table-striped table-bordered" style={{ width: "100%" }}>
//                                                                 <thead >
//                                                                     <tr >
//                                                                         <th style={{ backgroundColor: "#3F4D67", color: "white" }}>Loan No.</th>
//                                                                         <th style={{ backgroundColor: "#3F4D67", color: "white" }}>Loan Amt.</th>
//                                                                         <th style={{ backgroundColor: "#3F4D67", color: "white" }}>Loan Due</th>
//                                                                     </tr>
//                                                                 </thead>
//                                                                 <tbody>
//                                                                     {loanRecords.length === 0 ? (
//                                                                         <tr>
//                                                                             <td colSpan="8" className="text-center text-black bg-white p-1">No loan records found.</td>
//                                                                         </tr>
//                                                                     ) : (
//                                                                         loanRecords.filter(record => {
//                                                                             const totalRepayment = calculateLoanDue(record.loanAmount, getTotalRepaymentAmount(record.id));
//                                                                             return totalRepayment !== 0 && totalRepayment !== "0.00" && totalRepayment !== "0" && totalRepayment !== null && totalRepayment !== undefined;
//                                                                         }).map(record => (
//                                                                             <tr className="bg-dark text-capitalize" key={`${record.id}-${record.date}`}>
//                                                                                 <td className="text-black bg-white p-1">{record.loanNumber}</td>
//                                                                                 <td className="text-black bg-white p-1">{record.loanAmount}</td>
//                                                                                 {/* <td className="text-black bg-white p-1">{getTotalRepaymentAmount(record.id)}</td> */}
//                                                                                 <td className="text-black bg-white p-1">{calculateLoanDue(record.loanAmount, getTotalRepaymentAmount(record.id))}</td>
//                                                                             </tr>
//                                                                         ))
//                                                                     )}
//                                                                 </tbody>
//                                                             </table>
//                                                         </div>
//                                                         <div className='bg-light my-1 p-3'>
//                                                             <div className='d-flex w-100 align-align-items-center justify-content-around '>
//                                                                 <div className='shadow-sm bg-white border w-50 rounded p-1 m-1 d-flex align-items-center justify-content-center flex-column'>
//                                                                     <h5 className='text-dark m-0 fw-bolder'>Salary Of the Month</h5>
//                                                                     <small className='text-center border-bottom'>(Gross+Contribution+OT/HD-TDS)</small>
//                                                                     <h1 style={{ fontSize: "30px" }} className='font-bold text-center text-black fw-bold text-primary'> &#x20B9;{grossInHandSalaryMonth || '0'}</h1>
//                                                                 </div>
//                                                                 <div className='shadow-sm bg-white border w-50 rounded p-1 m-1 d-flex align-items-center justify-content-center flex-column'>
//                                                                     <h5 className='text-dark m-0 fw-bolder'>Total in Hand Salary</h5>
//                                                                     <small className='text-center border-bottom'>(Gross + AA - AD)</small>
//                                                                     <h1 style={{ fontSize: "30px" }} className='font-bold text-center text-black fw-bold text-primary'> &#x20B9;{totalInHandSalaryMonth || '0'}</h1>
//                                                                 </div>

//                                                             </div>
//                                                             <div className='d-flex rounded align-items-center justify-content-center p-1 flex-column w-100'>
//                                                                 <div className='shadow-sm bg-white border w-100 rounded p-1 m-1 d-flex align-items-center justify-content-center flex-column'>
//                                                                     <h5 className='text-dark m-0 fw-bolder'>Net Payable Salary</h5>
//                                                                     <small className='text-center border-bottom'>(InHandSalary - Advance)</small>
//                                                                     <h1 style={{ fontSize: "30px" }} className='font-bold text-center text-black fw-bold text-primary'> &#x20B9;{netSalaryPayableMonth.toFixed(2) || '0'}</h1>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                         <small className=' fw-bold'>HR Manager - {hrManager.employeeName}</small>
//                                                     </div>

//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="form-group mt-2">
//                                             <button type="submit" className="btn btn-primary mt-2">Submit</button>
//                                         </div>
//                                     </form>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AddSalarySlip;










import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchBar from '../../components/sidebar/SearchBar';
import Sidebar from '../../components/sidebar/Sidebar';
import { Navigate, useNavigate } from 'react-router-dom';

const AddSalarySlip = ({ handleLogout, username }) => {
    const [departments, setDepartments] = useState([]);
    const [departmentName, setDepartmentName] = useState('');
    const [employeeName, setEmployeeName] = useState('');
    const [employees, setEmployees] = useState([]);
    const [employeesDetails, setEmployeesDetails] = useState([]);
    const [payroll, setPayroll] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [selectedMonth, setSelectedMonth] = useState(''); // Initialize with current month
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initialize with current year
    const [attendanceRecords, setAttendanceRecords] = useState([]);
    const [filteredAttendance, setFilteredAttendance] = useState([]);
    const [allTotalPresent, setAllTotalPresent] = useState(0);
    const [allTotalAbsent, setAllTotalAbsent] = useState(0);
    const [totalPresent, setTotalPresent] = useState(0);
    const [totalAbsent, setTotalAbsent] = useState(0);
    const [totalHalfDay, setTotalHalfDay] = useState(0);
    const [totalPaidLeave, setTotalPaidLeave] = useState(0);
    const [totalUnpaidLeave, setTotalUnpaidLeave] = useState(0);
    const [totalOvertime, setTotalOvertime] = useState(0);
    const [totalWeeklyOff, setTotalWeeklyOff] = useState(0);
    const [advanceBalances, setadvanceBalances] = useState([]);
    const [filteredadvanceBalances, setfilteredadvanceBalances] = useState([]);
    const [totalAdvanceAmount, setTotalAdvanceAmount] = useState(0);
    // employeedetails 
    const [employeeDetails, setEmployeeDetails] = useState(null);
    const [basicSalaryMonth, setBasicSalaryMonth] = useState(0);
    const [VDAmonth, setVDAmonth] = useState(0);
    const [allowancesMonth, setAllowancesMonth] = useState(0);
    const [conveyanceAllowancesMonth, setConveyanceAllowancesMonth] = useState(0);
    const [specialallowancesMonth, setSpecialAllowancesMonth] = useState(0);
    const [houseRentallowancesMonth, setHouseRentAllowancesMonth] = useState(0);
    const [dearnessallowancesMonth, setDearnessAllowancesMonth] = useState(0);
    const [grossSalaryMonth, setGrossSalaryMonth] = useState(0);
    // epf esicEmployee 
    const [totalEmployerContributionMonth, settotalEmployerContributionMonth] = useState(0)
    const [totalEmployeeDeductionMonth, settotalEmployeeDeductionMonth] = useState(0)
    const [totalTdsDeductionMonth, settotalTdsDeductionMonth] = useState(0)
    const [totalPayableSalaryMonth, settotalPayableSalaryMonth] = useState(0)
    const [totalInHandSalaryMonth, settotalInHandSalaryMonth] = useState(0)
    const [grossPayableSalaryMonth, setGrossPayableSalaryMonth] = useState(0)
    const [grossInHandSalaryMonth, setGrossInHandSalaryMonth] = useState(0)
    const [totalAdvanceAmountMonth, settotalAdvanceAmountMonth] = useState(0)
    const [netSalaryPayableMonth, setNetSalaryPayableMonth] = useState(0)
    const [totalNetSalaryPayableMonth, setTotalNetSalaryPayableMonth] = useState(0)
    const [epfEmployerMonth, setEpfEmployerMonth] = useState(0);
    const [epfEmployeeMonth, setEpfEmployeeMonth] = useState(0);
    const [esicEmployeeMonth, setEsicEmployeeMonth] = useState(0);
    const [esicEmployerMonth, setEsicEmployerMonth] = useState(0);
    const [overtimeMonth, setovertimeMonth] = useState(0);
    const [halfDayMonth, sethalfDayMonth] = useState(0);
    const [epfesicApplicableMonth, setepfesicApplicableMonth] = useState(0)
    const [tdsApplicableMonth, settdsApplicableMonth] = useState()
    const [tdsDeductionPercentage, setTdsDeductionPercentage] = useState(0);
    // Loan Records 
    const [loanRecords, setLoanRecords] = useState([]);
    const [repaymentRecords, setRepaymentRecords] = useState([]);
    // New state variables for additional allowances and deductions
    const [additionalAllowance, setAdditionalAllowance] = useState(0);
    const [allowanceDescription, setAllowanceDescription] = useState('');
    const [salaryDeduction, setSalaryDeduction] = useState(0);
    const [deductionDescription, setDeductionDescription] = useState('');
    const [showAllowanceDescription, setShowAllowanceDescription] = useState(false);
    const [showDeductionDescription, setShowDeductionDescription] = useState(false);
    // Salary Details  
    const [totalsalary, setTotalsalary] = useState(0)
    const [salaryWithContribution, setSalaryWithContribution] = useState(0)
    const [salaryAfterDeduction, setsalaryAfterDeduction] = useState(0)
    // Total Attendance Check 
    const [totalAttencance, setTotalAttencance] = useState(0);
    const [daysAttendance, setDaysAttendance] = useState(false);
    const [totalDays, setTotalDays] = useState(0); // State for total days in selected month
    // Advance Deduction 
    const [advanceDeduction, setAdvanceDeduction] = useState(0);
    const navigate = useNavigate();
    // Hr Manager 
    const [hrManager, setHrManager] = useState([]);

    useEffect(() => {
        fetchDepartments();
        fetchHrManager();
    }, []);

    useEffect(() => {
        if (selectedDepartment) {
            fetchEmployees(selectedDepartment);
        }
    }, [selectedDepartment]);

    useEffect(() => {
        if (selectedEmployee) {
            fetchAttendanceRecords(selectedEmployee);
            fetchadvanceBalances(selectedEmployee);
            fetchEmployeeDetails(selectedEmployee);
            fetchLoanDetails(selectedEmployee);
            fetchRepaymentDetails(selectedEmployee);
            fetchPayroll(selectedEmployee);
            fetchEmployeesDetails(selectedEmployee)
        }
    }, [selectedEmployee]);

    useEffect(() => {
        filterAttendanceRecords(selectedMonth, selectedYear);
    }, [selectedMonth, selectedYear, attendanceRecords]);

    useEffect(() => {
        filteradvanceBalances(selectedMonth, selectedYear);
    }, [selectedMonth, selectedYear, advanceBalances]);

    useEffect(() => {
        calculateMonthlySalaries(employeeDetails);
    }, [selectedEmployee, selectedMonth, selectedYear, attendanceRecords, advanceBalances, filteredadvanceBalances, filteredAttendance, salaryDeduction, additionalAllowance, advanceDeduction, tdsDeductionPercentage]);

    useEffect(() => {
        // Calculate total days in selected month and update state
        const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
        console.log("Total Attendance", totalAttencance)
        setTotalDays(daysInMonth);
    }, [selectedEmployee, selectedMonth, selectedYear]);

    useEffect(() => {
        const checkPayrollMonth = () => {
            const selectedMonthAdjusted = selectedMonth + 1; // Adjust selected month since JavaScript Date months are 0-based
            const payrollMonthExists = payroll.some(entry => new Date(entry.date).getMonth() + 1 === selectedMonthAdjusted);

            if (payrollMonthExists) {
                alert("Salary Slip for the selected month already exists.");
            } else {
                console.log("No payroll found for the selected month.");
            }
        };

        if (selectedEmployee && selectedMonth && selectedYear) {
            checkPayrollMonth();
        }
    }, [selectedEmployee, selectedMonth, selectedYear, payroll]);

    const fetchDepartments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/departments`);
            setDepartments(response.data);
        } catch (error) {
            console.error('Error fetching departments:', error);
        }
    };

    const fetchHrManager = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/hrmanager`);
            setHrManager(response.data);
        } catch (error) {
            console.error("Error fetching timelines:", error);
        }
    };

    const fetchEmployees = async (departmentId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employee_department/${departmentId}`);
            setEmployees(response.data);
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    const fetchPayroll = async (employeeId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/payroll/${employeeId}`);
            setPayroll(response.data);
        } catch (error) {
            console.error('Error fetching Payroll:', error);
        }
    };

    const fetchAttendanceRecords = async (employeeId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/attendance/${employeeId}`);
            const attendanceData = response.data || [];
            setAttendanceRecords(attendanceData);
            filterAttendanceRecords(selectedMonth, selectedYear, attendanceData);
        } catch (error) {
            console.error('Error fetching attendance records:', error);
        }
    };

    const fetchadvanceBalances = async (employeeId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/advancebalance/${employeeId}`);
            setadvanceBalances(response.data);
            filteradvanceBalances(selectedMonth, selectedYear, response.data);
        } catch (error) {
            console.error('Error fetching advance balance:', error);
        }
    };
    const fetchEmployeeDetails = async (employeeId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`);
            setEmployeeDetails(response.data);
            console.log('Employee details fetched:', response.data);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    const fetchLoanDetails = async (employeeId) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_LOCAL_URL}/api/loandetails/${employeeId}`
            );
            setLoanRecords(response.data);
        } catch (error) {
            console.error("Error fetching loan details:", error);
        }
    };
    const fetchRepaymentDetails = async (employeeId) => {
        try {

            const response = await axios.get(
                `${process.env.REACT_APP_LOCAL_URL}/api/repaymentdetails/${employeeId}`
            );
            setRepaymentRecords(response.data);
        } catch (error) {
            console.error("Error fetching repayment details:", error);
        }
    };

    const filterAttendanceRecords = (month, year, data = attendanceRecords) => {
        const filteredRecords = data.filter(record => {
            const recordDate = new Date(record.date);
            return recordDate.getMonth() === month && recordDate.getFullYear() === year;
        });

        const presentCount = filteredRecords.filter(record =>
            ['present'].includes(record.status.toLowerCase())
        ).length;
        const absentCount = filteredRecords.filter(record =>
            ['absent'].includes(record.status.toLowerCase())
        ).length;
        const halfDayCount = filteredRecords.filter(record =>
            ['half day'].includes(record.status.toLowerCase())
        ).length;
        const paidLeaveCount = filteredRecords.filter(record =>
            ['paid leave'].includes(record.status.toLowerCase())
        ).length;
        const unpaidLeaveCount = filteredRecords.filter(record =>
            ['unpaid leave'].includes(record.status.toLowerCase())
        ).length;
        const overtimeCount = filteredRecords.filter(record =>
            ['overtime'].includes(record.status.toLowerCase())
        ).length;
        const weeklyOffCount = filteredRecords.filter(record =>
            ['weekly off'].includes(record.status.toLowerCase())
        ).length;

        const TotalAttendance = presentCount + absentCount + halfDayCount + paidLeaveCount + unpaidLeaveCount + overtimeCount + weeklyOffCount;
        const AllTotalPresent = presentCount + paidLeaveCount + weeklyOffCount + overtimeCount;
        const AllTotalAbsent = absentCount + unpaidLeaveCount + halfDayCount;
        setAllTotalPresent(AllTotalPresent);
        setAllTotalAbsent(AllTotalAbsent);
        setTotalAttencance(TotalAttendance)
        setTotalPresent(presentCount);
        setTotalAbsent(absentCount);
        setTotalHalfDay(halfDayCount);
        setTotalPaidLeave(paidLeaveCount);
        setTotalUnpaidLeave(unpaidLeaveCount);
        setTotalOvertime(overtimeCount);
        setTotalWeeklyOff(weeklyOffCount);
        setFilteredAttendance(filteredRecords);
        // Check if total attendance does not match total days

        // Check if total attendance does not match total day
        if (TotalAttendance !== totalDays) {
            setDaysAttendance(true)
            // toast.error(`Total attendance (${TotalAttendance}) does not match total days (${totalDays}).`);
        }
    };

    const filteradvanceBalances = (month, year, data = advanceBalances) => {
        // Create Date object for the specified month and year
        const currentMonth = new Date(year, month, 1); // month is zero-based (0 = January, 11 = December)

        // Find the balance for the current month
        const currentMonthBalance = data.find(balance => {
            const balanceDate = new Date(balance.month);
            return balanceDate.getFullYear() === currentMonth.getFullYear() && balanceDate.getMonth() === currentMonth.getMonth();
        }) || { balance: 0 }; // Default to { balance: 0 } if not found

        // Set the total advance amount
        setTotalAdvanceAmount(currentMonthBalance.balance);
    };
    const calculateLoanDue = (loanAmount, totalRepaymentAmount) => {
        const loanDue = parseFloat(loanAmount) - parseFloat(totalRepaymentAmount);
        return loanDue.toFixed(2); // Adjust as per your requirement
    };

    // Laon Details 
    const getTotalRepaymentAmount = (loanId) => {
        // Filter repayment records for the specific loanId
        const repaymentsForLoan = repaymentRecords.filter(repayment => repayment.loanId === loanId);

        // Calculate total repayment amount
        const totalRepaymentAmount = repaymentsForLoan.reduce((total, repayment) => {
            return total + parseFloat(repayment.repaymentAmount || 0);
        }, 0);

        return totalRepaymentAmount.toFixed(2); // Adjust as per your requirement
    };

    const calculateMonthlySalaries = () => {

        if (employeeDetails && employeeDetails.length > 0) {
            const selectedEmployeeObj = employeeDetails[0];
            const basicSalary = selectedEmployeeObj.basicSalary || 0;
            const VDA = selectedEmployeeObj.vda || 0;
            const specialallowances = selectedEmployeeObj.specialallowances || 0;
            const dearnessallowances = selectedEmployeeObj.dearnessallowances || 0;
            const conveyanceallowances = selectedEmployeeObj.conveyanceallowances || 0;
            const houserentallowances = selectedEmployeeObj.houserentallowances || 0;
            const grossSalary = selectedEmployeeObj.grossSalary || 0;
            const epfesicApplicable = selectedEmployeeObj.epfesicApplicable || false;
            const tdsApplicable = selectedEmployeeObj.tdsApplicable || false;
            const employeeName = selectedEmployeeObj.employeeName;
            const departmentName = selectedEmployeeObj.departmentName;

            console.log("totabss", totalAbsent)
            console.log("totleave", totalPaidLeave)
            console.log("cal", basicSalary, totalUnpaidLeave, totalAbsent)
            console.log("employeeName", employeeName)
            console.log("departmentName", departmentName)

            // Calculate deductions
            // const submissionofovertimehalfday = Math.floor((totalOvertime+totalHalfDay)/2);
            const deductionBasic = basicSalary - (basicSalary / 30) * (totalUnpaidLeave + totalAbsent + totalHalfDay);
            const deductionVDA = VDA - (VDA / 30) * (totalUnpaidLeave + totalAbsent + totalHalfDay);
            const deductionHouseRentAllowances = houserentallowances - (houserentallowances / 30) * (totalUnpaidLeave + totalAbsent + totalHalfDay);
            const deductionConveyanceAllowances = conveyanceallowances - (conveyanceallowances / 30) * (totalUnpaidLeave + totalAbsent + totalHalfDay);
            const deductionDearnessAllowances = dearnessallowances - (dearnessallowances / 30) * (totalUnpaidLeave + totalAbsent + totalHalfDay);
            const deductionSpecialAllowances = specialallowances - (specialallowances / 30) * (totalUnpaidLeave + totalAbsent + totalHalfDay);

            const grossSalaryMonth = deductionBasic + deductionVDA + deductionHouseRentAllowances + deductionConveyanceAllowances + deductionDearnessAllowances + deductionSpecialAllowances;
            // overtime  
            const deductionovertime = ((grossSalary / 30) / 2) * totalOvertime;
            // half day
            const deductionhalfDay = ((grossSalary / 30) / 2) * totalHalfDay;
            // Total Salary 
            const totalsalary = (grossSalaryMonth + deductionovertime + deductionhalfDay)
            console.log("---------------------------------------------------------")
            console.log("dedbasic 1", deductionBasic)
            console.log("gross slary 7", grossSalaryMonth)
            console.log("overtime 8", deductionovertime)
            console.log("halfDay 9", deductionhalfDay)

            console.log("Total Salary 7+8+9 = 10 = ", totalsalary)


            setTotalsalary(totalsalary);
            setBasicSalaryMonth(deductionBasic);
            setVDAmonth(deductionVDA);
            setConveyanceAllowancesMonth(deductionConveyanceAllowances)
            setSpecialAllowancesMonth(deductionSpecialAllowances)
            setHouseRentAllowancesMonth(deductionHouseRentAllowances)
            setDearnessAllowancesMonth(deductionDearnessAllowances)
            // usestate 
            const parsedtdsDeductionPercentage = parseFloat(tdsDeductionPercentage) || 0; // Default to 0 if tdsDeductionPercentage is not a valid number

            let epfEmployer = 0;
            let epfEmployee = 0;
            let esicEmployer = 0;
            let esicEmployee = 0;
            let tdsDeduction = 0;

            if (epfesicApplicable) {
                // EPF Calculation
                const epfLimit = 15000;
                const epfBase = Math.min(deductionBasic, epfLimit);
                epfEmployer = (0.12 * epfBase).toFixed(2);
                epfEmployee = (0.12 * epfBase).toFixed(2);

                // ESIC Calculation
                const esicLimit = 21000;
                if (grossSalaryMonth <= esicLimit) {
                    esicEmployer = (0.0325 * grossSalaryMonth).toFixed(2);
                    esicEmployee = (0.0075 * grossSalaryMonth).toFixed(2);
                } else {
                    esicEmployer = 0;
                    esicEmployee = 0;
                }
            }
            console.log("tdsApplicable", tdsApplicable)
            console.log("grossSalaryMonth", grossSalaryMonth)
            console.log("parsedtdsDeductionPercentage", parsedtdsDeductionPercentage)

            if (tdsApplicable) {
                tdsDeduction = ((grossSalaryMonth * parsedtdsDeductionPercentage) / 100).toFixed(2);
            }
            
            console.log("tdsDeduction", tdsDeduction)
            const totalEmployerContribution = (parseFloat(epfEmployer) + parseFloat(esicEmployer)).toFixed(2);
            const totalEmployeeDeduction = (parseFloat(epfEmployee) + parseFloat(esicEmployee)).toFixed(2);
            const totalTdsDeduction = parseFloat(tdsDeduction).toFixed(2);

            const grossPayableSalary = (grossSalaryMonth + deductionovertime + deductionhalfDay + parseFloat(totalEmployerContribution)).toFixed(2);
            const grossInHandSalary = (deductionovertime + deductionhalfDay + grossSalaryMonth - parseFloat(totalEmployeeDeduction) - parseFloat(totalTdsDeduction)).toFixed(2);
            const parsedAdvanceDeduction = parseFloat(advanceDeduction) || 0; // Default to 0 if salaryDeduction is not a valid number
            // const totalPayableSalary = (parsedGrossPayableSalary + parsedAdditionalAllowance - parsedSalaryDeduction).toFixed(2);

            const parsedGrossPayableSalary = parseFloat(grossPayableSalary);
            const parsedGrossInHandSalary = parseFloat(grossInHandSalary);
            const parsedAdditionalAllowance = parseFloat(additionalAllowance) || 0; // Default to 0 if additionalAllowance is not a valid number
            const parsedSalaryDeduction = parseFloat(salaryDeduction) || 0; // Default to 0 if salaryDeduction is not a valid number

            // Salary Contribution 
            const salarywithcontribution = (parseFloat(totalsalary) + parseFloat(totalEmployerContribution)).toFixed(2);

            // Salary Deduction 
            const salaryAfterDeduction = parseFloat((parseFloat(totalsalary) - parseFloat(totalEmployeeDeduction) - parseFloat(totalTdsDeduction)).toFixed(2));

            // Gross In Hand Salary 
            const totalPayableSalary = (parsedGrossPayableSalary + parsedAdditionalAllowance - parsedSalaryDeduction).toFixed(2);

            // Here we parse salaryAfterDeduction back to a number before adding parsedAdditionalAllowance and subtracting parsedSalaryDeduction
            const totalInHandSalary = (salaryAfterDeduction + parsedAdditionalAllowance - parsedSalaryDeduction).toFixed(2);

            // Net Payable Salary 
            const NetSalaryPayable = (parseFloat(totalInHandSalary) - parseFloat(parsedAdvanceDeduction)).toFixed(2);



            // Parse the fixed numbers back to float for further calculations netSalaryPayableMonth.toFixed
            // const totalInHandSalary = (parsedGrossInHandSalary + parsedAdditionalAllowance - parsedSalaryDeduction).toFixed(2);
            // const NetSalaryPayable = (totalInHandSalary - parsedAdvanceDeduction.toFixed(2));

            console.log("totalInHandSalary", totalInHandSalary)
            console.log("totalPayableSalary", totalPayableSalary)
            // console.log("NetSalaryPayable", NetSalaryPayable)
            console.log("Employeer Contribution", totalEmployerContribution)
            console.log("Employee Deduction", totalEmployeeDeduction)
            console.log("salarywith contribution 11 = ", grossPayableSalary)
            console.log("Salary After Deduction 12 = 10-21-22 = ", grossInHandSalary)
            console.log("Gross in hand Salary (12) + 12 - 13 = ", totalPayableSalary)
            console.log("Net Payable Salary 13 - 18 = ", NetSalaryPayable)


            // salary with contribution 
            setsalaryAfterDeduction(salaryAfterDeduction);
            setSalaryWithContribution(salarywithcontribution);
            setGrossSalaryMonth(grossSalaryMonth);

            setepfesicApplicableMonth(epfesicApplicable);
            settdsApplicableMonth(tdsApplicable);
            setEpfEmployerMonth(epfEmployer);
            setEpfEmployeeMonth(epfEmployee);
            setEsicEmployeeMonth(esicEmployee);
            setEsicEmployerMonth(esicEmployer);
            settotalEmployerContributionMonth(totalEmployerContribution);
            settotalEmployeeDeductionMonth(totalEmployeeDeduction);
            settotalTdsDeductionMonth(totalTdsDeduction);
            sethalfDayMonth(deductionhalfDay);
            setovertimeMonth(deductionovertime);
            setGrossPayableSalaryMonth(grossPayableSalary);
            setGrossInHandSalaryMonth(grossInHandSalary);
            settotalPayableSalaryMonth(totalPayableSalary);
            settotalInHandSalaryMonth(totalInHandSalary);
            settotalAdvanceAmountMonth(totalAdvanceAmount);
            setNetSalaryPayableMonth(NetSalaryPayable);
            setEmployeeName(employeeName);
            setDepartmentName(departmentName);
        }
    };

    // For Add the allowances and salary Deduction epfEmployerMonth.toFixed
    const toggleAllowanceDescription = () => {
        setShowAllowanceDescription(!showAllowanceDescription);
        if (showDeductionDescription) {
            setShowDeductionDescription(false);
        }
    };
    const toggleDeductionDescription = () => {
        setShowDeductionDescription(!showDeductionDescription);
        if (showAllowanceDescription) {
            setShowAllowanceDescription(false);
        }
    };

    const fetchEmployeesDetails = async (employeeId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`);
            
            // Assuming response.data is an array of employee objects
            const employeeData = response.data;
    
            // If you want to ensure you only process the first employee or handle multiple employees
            if (Array.isArray(employeeData) && employeeData.length > 0) {
                // For example, set the first employee details
                setEmployeesDetails(employeeData[0]);
            } else {
                setEmployeesDetails(null); // Or handle an empty array case as needed
            }
    
            console.log("empde", employeeData);
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Example validation (you can modify this based on your form requirements)
        if (!selectedEmployee || !selectedMonth || !selectedYear) {
            toast.error('Please select employee, month, and year.');
            return;
        }

        const today = new Date();
        const formattedDay = String(today.getDate()).padStart(2, '0');
        const formattedMonth = String(selectedMonth + 1).padStart(2, '0');

        // Check if the payroll for the selected month already exists
        const payrollMonthExists = payroll.some(entry => new Date(entry.date).getMonth() + 1 === selectedMonth + 1);

        if (payrollMonthExists) {
            alert("Payroll for the selected month already exists.");
            return; // Prevent form submission if payroll month exists
        }

        try {
            // Example of how you might structure your API call using Axios
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/payroll`, {
                employeeId: selectedEmployee,
                month: selectedMonth + 1,
                year: selectedYear,
                hr_id: hrManager.id,
                hrManagerName: hrManager.employeeName,
                employeeName,
                employeeCode: employeesDetails.employeeCode,
                departmentName,
                // tdsApplicable:
                // Include other relevant form data here as needed
                departments,
                employees,
                selectedDepartment,
                selectedEmployee,
                selectedMonth,
                selectedYear,
                date: `${selectedYear}-${formattedMonth}-${formattedDay}`,
                attendanceRecords,
                filteredAttendance,
                totalPresent,
                totalAbsent,
                totalHalfDay,
                totalPaidLeave,
                totalUnpaidLeave,
                totalOvertime,
                totalWeeklyOff,
                advanceBalances,
                allTotalAbsent,
                allTotalPresent,
                filteredadvanceBalances,
                totalAdvanceAmount,
                employeeDetails,
                basicSalaryMonth,
                VDAmonth,
                allowancesMonth,
                conveyanceAllowancesMonth,
                houseRentallowancesMonth,
                dearnessallowancesMonth,
                specialallowancesMonth,
                grossSalaryMonth,
                totalEmployerContributionMonth,
                totalEmployeeDeductionMonth,
                totalTdsDeductionMonth,
                totalPayableSalaryMonth,
                totalInHandSalaryMonth,
                totalAdvanceAmountMonth,
                netSalaryPayableMonth,
                totalNetSalaryPayableMonth,
                epfEmployerMonth,
                epfEmployeeMonth,
                esicEmployeeMonth,
                esicEmployerMonth,
                overtimeMonth,
                halfDayMonth,
                epfesicApplicableMonth,
                tdsApplicableMonth,
                loanRecords,
                repaymentRecords,
                additionalAllowance,
                allowanceDescription,
                salaryDeduction,
                deductionDescription,
                showAllowanceDescription,
                showDeductionDescription,
                advanceDeduction,
                receivingMode: "salary",
                grossPayableSalaryMonth,
                grossInHandSalaryMonth,
                totalAttencance,
                // slary details 
                tdsDeductionPercentage,
                salaryAfterDeduction,
                salaryWithContribution,
                totalsalary,
                
            });

            const advanceresponse = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/api/salary/repayment`, {
                employee_id: selectedEmployee,
                employeeCode: employeesDetails.employeeCode,
                amount: advanceDeduction,
                month: selectedMonth + 1,
                year: selectedYear,
                date: `${selectedYear}-${formattedMonth}-${formattedDay}`,
                receivingMode: "salary",
            });

            // Handle success (e.g., show a success toast)
            console.log("Response Data :", response);
            console.log("Advance Data :", advanceresponse.data);
            setDepartmentName("");
            setEmployeeName("");
            setSelectedMonth("");
            setSelectedDepartment("");
            setSelectedEmployee("");
            toast.success('Salary slip added successfully!');
            setTimeout(() => {
                window.location.reload();
            }, 1000); // 1 second delay

            // You can optionally reset your form state or do any other post-submission tasks
        } catch (error) {
            // Handle error (e.g., show an error toast)
            console.error('Error adding salary slip:', error);
            toast.error('Failed to add salary slip. Please try again.');
        }
    };


    return (
        <div className='d-flex w-100 h-100'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    <div className="row bg-dark bg-white p-2 rounded border">
                        <div className="col-sm-12 col-md-12" >
                            <div className="panel panel-bd">
                                <div className="panel-heading">
                                    <div className="panel-title">
                                        <h4 className='p-1 text-black fw-bolder text-capitalize m-0'>Salary Slip</h4>
                                    </div>
                                </div>
                                <div className="panel-body">
                                    <form onSubmit={handleSubmit} className="bg-white p-2 rounded " encType="multipart/form-data" acceptCharset="utf-8">
                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <label htmlFor="department" className="col-xs-3 col-form-label">Department<span style={{ color: "red" }}>*</span></label>
                                                <div className="col-xs-9">
                                                    <select className="form-control" id="department" value={selectedDepartment} onChange={(e) => setSelectedDepartment(e.target.value)}>
                                                        <option value="">Select Department</option>
                                                        {departments.map(dept => (
                                                            <option key={dept.id} value={dept.id}>{dept.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="employee" className="col-xs-3 col-form-label">Employee<span style={{ color: "red" }}>*</span></label>
                                                <div className="col-xs-9">
                                                    <select className="form-control" id="employee" value={selectedEmployee} onChange={(e) => setSelectedEmployee(e.target.value)}>
                                                        <option value="">Select Employee</option>
                                                        {employees.map(emp => (
                                                            <option key={emp.id} value={emp.id}>{emp.employeeName}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label className="col-xs-3 text-black col-form-label">Filter :</label>
                                                <div className='col-xs-6 d-flex gap-2'>
                                                    <select className="form-control" value={selectedMonth} onChange={(e) => setSelectedMonth(parseInt(e.target.value))}>
                                                        <option value="">Select Month</option>
                                                        {[...Array(12).keys()].map(i => (
                                                            <option key={i} value={i}>{new Date(0, i).toLocaleString('default', { month: 'long' })}</option>
                                                        ))}
                                                    </select>
                                                    <select className="form-control" value={selectedYear} onChange={(e) => setSelectedYear(parseInt(e.target.value))}>
                                                        <option value="">Select Year</option>
                                                        {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i).map(year => (
                                                            <option key={year} value={year}>{year}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                        <div className=' p-2 bg-light shadow-sm  rounded mt-3'>
                                            <div className='d-flex align-items-center justify-content-between p-2'>
                                                <h4 className='text-black fw-bolder '>Attendance/Salary Details</h4>
                                                {daysAttendance == true ? (
                                                    <small className="m-0 font-weight-bold text-danger">Please fill the attendance first - {totalDays - totalAttencance}</small>
                                                ) : null}
                                            </div>
                                            <div className='rounded border bg-white' style={{ maxHeight: "calc(100vh - 120px)", overflowY: "auto", overflowX: "hidden" }}>
                                                <div className='d-flex'>
                                                    <div className='col-md-6 p-2'>
                                                        <h6 style={{ backgroundColor: "#3F4D67" }} className='text-white w-100 fw-bolder m-0 p-2'> Total Attendance Details :</h6>
                                                        <table style={{ backgroundColor: "#3F4D67" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
                                                            <tbody >
                                                                <div className='d-flex justify-content-between bg-danger'>
                                                                    <div className='p-0 w-100 bg-dark'>
                                                                        <table className="table table-striped table-bordered m-0">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td bgcolor="#f2f3f4" >
                                                                                        <p className="mb-0 fw-bold text-success"> Present </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0"> {totalPresent || '0'} Days</p>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td bgcolor="#f2f3f4" >
                                                                                        <p className="mb-0 fw-bold text-success"> Over Time </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0"> {totalOvertime || '0'} Days</p>
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td bgcolor="#f2f3f4" >
                                                                                        <p className="mb-0 fw-bold text-success"> Weekly Off </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0"> {totalWeeklyOff || '0'} Days</p>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td bgcolor="#f2f3f4" >
                                                                                        <p className="mb-0 fw-bold text-success"> Half Day </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0"> {totalHalfDay || '0'} Days</p>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className='p-0 w-100'>
                                                                        <table className="table table-striped table-bordered">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td bgcolor="#f2f3f4" >
                                                                                        <p className="mb-0 fw-bold text-success"> Paid Leave </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0"> {totalPaidLeave || '0'} Days</p>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td bgcolor="#f2f3f4" >
                                                                                        <p className="mb-0 fw-bold text-danger"> Absent</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0"> {totalAbsent || '0'} Days</p>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td bgcolor="#f2f3f4" >
                                                                                        <p className="mb-0 fw-bold text-danger"> UnPaid Leave </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0"> {totalUnpaidLeave || '0'} Days</p>
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td bgcolor="#f2f3f4">
                                                                                        <p className="mb-0 fw-bold text-black"> Total Days </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0"> {totalAttencance || '0'} Days</p>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </tbody>
                                                        </table>
                                                        <h6 style={{ backgroundColor: "#3F4D67" }} className='text-white w-100 fw-bolder m-0 p-2'> Salary Details :</h6>
                                                        <table style={{ backgroundColor: "#3F4D67" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
                                                            <tbody >
                                                                <div className='d-flex justify-content-between bg-danger'>
                                                                    <div className='p-0 w-100'>
                                                                        <table className="table table-striped table-bordered ">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        <p className="mb-0 fw-bold text-black">1. Basic </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0">&#x20B9;{basicSalaryMonth != null ? basicSalaryMonth.toFixed(2) : '0.00'}</p>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <p className="mb-0  text-black" style={{ cursor: "pointer" }} title="Variable Dearness Allowance">3. VDA </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0">&#x20B9;{VDAmonth != null ? VDAmonth.toFixed(2) : '0.00'}</p>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <p className="mb-0  text-black" style={{ cursor: "pointer" }} title="House Rent Allowance">5. HRA </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0">&#x20B9;{houseRentallowancesMonth != null ? houseRentallowancesMonth.toFixed(2) : '0.00'}</p>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <tr >
                                                                            <td>
                                                                                <p className="mb-0 fw-bolder font-bold">7. Gross Salary </p>
                                                                            </td>
                                                                        </tr>

                                                                    </div>
                                                                    <div className='p-0 w-100'>
                                                                        <table className="table table-striped table-bordered">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        <p className="mb-0  text-black" style={{ cursor: "pointer" }} title="Conveyance Allowance">2. CA </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0">&#x20B9;{conveyanceAllowancesMonth != null ? conveyanceAllowancesMonth.toFixed(2) : '0.00'}</p>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <p className="mb-0  text-black" style={{ cursor: "pointer" }} title="Dearness Allowance">4. DA</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0"> &#x20B9;{dearnessallowancesMonth != null ? dearnessallowancesMonth.toFixed(2) : '0.00'}</p>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <p className="mb-0  text-black" style={{ cursor: "pointer" }} title="Special Allowance">6. SA </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0">&#x20B9;{specialallowancesMonth != null ? specialallowancesMonth.toFixed(2) : '0.00'}</p>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <tr>
                                                                            <td className=''>
                                                                                <p className="mb-0 fw-bolder ">: &#x20B9;{grossSalaryMonth != null ? grossSalaryMonth.toFixed(2) : '0.00'}</p>
                                                                            </td>
                                                                        </tr>
                                                                    </div>
                                                                </div>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='col-md-6 p-2'>
                                                        <table style={{ backgroundColor: "#3F4D67" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
                                                            <tbody >
                                                                <h6 className='text-white fw-bolder m-0 p-2'>EPF & ESIC Contribution :</h6>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="300">
                                                                        <p className="mb-0">EPF - ESIC Applicable</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="mb-0">:
                                                                            {epfesicApplicableMonth === 1 || epfesicApplicableMonth === true ? 'Yes' : (epfesicApplicableMonth === 0 || epfesicApplicableMonth === false ? 'No' : '0')}
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <h6 className='text-white fw-bolder m-0 p-2'>Employeer Contribution :</h6>

                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="300">
                                                                        <p className="mb-0">EPF 12%  <span className="mx-2">: &#x20B9;{epfEmployerMonth != null ? epfEmployerMonth : '0.00'}</span> </p>
                                                                    </td>

                                                                    <td bgcolor="#f2f3f4" width="300">
                                                                        <p className="mb-0"> ESIC 3.25%  <span className="mx-2">: &#x20B9;{esicEmployerMonth != null ? esicEmployerMonth : '0.00'}</span> </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='bg-body-secondary' bgcolor="#f2f3f4" width="300">
                                                                        <p className="mb-0 fw-bolder">18. Total Employer Contribution </p>
                                                                    </td>
                                                                    <td className='bg-body-secondary'>
                                                                        <p className="mb-0 fw-bolder">: &#x20B9;{totalEmployerContributionMonth != null ? totalEmployerContributionMonth : '0.00'}</p>
                                                                    </td>
                                                                </tr>
                                                                <h6 className='text-white fw-bolder m-0 p-2'>Employee Deduction :</h6>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="300">
                                                                        <p className="mb-0">EPF 12% <span className="mb-0 mx-2">: &#x20B9;{epfEmployeeMonth != null ? epfEmployeeMonth : '0.00'}</span></p>
                                                                    </td>
                                                                    <td bgcolor="#f2f3f4" width="300">
                                                                        <p className="mb-0">ESIC 0.75% <span className="mb-0 mx-2">: &#x20B9;{esicEmployeeMonth != null ? esicEmployeeMonth : '0.00'}</span></p>
                                                                    </td>

                                                                </tr>

                                                                <tr>
                                                                    <td className='bg-body-secondary' bgcolor="#f2f3f4" width="300">
                                                                        <p className="mb-0 fw-bolder">19. Total Employee Deduction </p>
                                                                    </td>
                                                                    <td className='bg-body-secondary'>
                                                                        <p className="mb-0 fw-bolder">: &#x20B9;{totalEmployeeDeductionMonth != null ? totalEmployeeDeductionMonth : '0.00'}</p>
                                                                    </td>
                                                                </tr>
                                                                <h6 className='text-white fw-bolder m-0 p-2'>TDS :</h6>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="300">
                                                                        <p className="mb-0">TDS Applicable</p>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex align-items-center justify-content-between gap-4">
                                                                            <p className='m-0' >
                                                                                :{tdsApplicableMonth ? 'Yes' : 'No'}
                                                                            </p>
                                                                            {tdsApplicableMonth && (
                                                                                <input
                                                                                    type="number"
                                                                                    id="tdsDeductionPercentage"
                                                                                    value={tdsDeductionPercentage}
                                                                                    onChange={(e) => setTdsDeductionPercentage(parseFloat(e.target.value))}
                                                                                    className="form-control"
                                                                                    placeholder='TDS %'
                                                                                    required
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='bg-body-secondary' bgcolor="#f2f3f4" width="300">
                                                                        <p className="mb-0 fw-bolder">20. Total TDS Deduction</p>
                                                                    </td>
                                                                    <td className='bg-body-secondary'>
                                                                        <p className="mb-0 fw-bolder">: &#x20B9;{totalTdsDeductionMonth}</p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className='d-flex'>
                                                    <div className='col-md-6 p-2'>
                                                        <table style={{ backgroundColor: "#3F4D67" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
                                                            <tbody >
                                                                {/* <h6 className='text-white fw-bolder m-0 p-2'>Overtime/Half Day :</h6> */}

                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 font-bold">8. Over Time</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="mb-0">: &#x20B9;{overtimeMonth != null ? overtimeMonth.toFixed(2) : '0.00'}</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 font-bold">9. Half Day</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="mb-0">: &#x20B9;{halfDayMonth != null ? halfDayMonth.toFixed(2) : '0.00'}</p>
                                                                    </td>
                                                                </tr>
                                                                {/* <h6 className='text-white fw-bolder m-0 p-2'>Total Salary :</h6> */}
                                                                <tr>
                                                                    <td className='bg-body-secondary' bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 font-bold">10. Total Salary </p>
                                                                    </td>
                                                                    <td className='bg-body-secondary' bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 ">: &#x20B9;{totalsalary != null ? totalsalary.toFixed(2) : '0.00'}  </p>
                                                                    </td>
                                                                </tr>
                                                                {/* <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 font-bold">10. Gross Payable Salary </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="mb-0 ">: &#x20B9;{grossPayableSalaryMonth != null ? grossPayableSalaryMonth : '0.00'}  </p>
                                                                    </td>
                                                                </tr> */}
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 font-bold">11. Salary With Contribution </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="mb-0">: &#x20B9;{salaryWithContribution != null ? salaryWithContribution : '0.00'} </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 font-bold">12. Salary After Deduction </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="mb-0">: &#x20B9;{salaryAfterDeduction != null ? salaryAfterDeduction : '0.00'} </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <label htmlFor="additionalAllowance">13. Additional Allowance:</label>
                                                                    </td>
                                                                    <td >
                                                                        <div className='d-flex gap-2'>
                                                                            <input
                                                                                type="number"
                                                                                id="additionalAllowance"
                                                                                value={additionalAllowance}
                                                                                onChange={(e) => setAdditionalAllowance(e.target.value)}
                                                                                className="form-control"
                                                                                placeholder='Additional Allowance'
                                                                                required
                                                                            />
                                                                            <button onClick={toggleAllowanceDescription} className="btn btn-success"><i class="fa fa-plus" aria-hidden="true"></i></button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="2" className='p-0'>
                                                                        {showAllowanceDescription && (
                                                                            <textarea
                                                                                type="text"
                                                                                id="allowanceDescription"
                                                                                value={allowanceDescription}
                                                                                onChange={(e) => setAllowanceDescription(e.target.value)}
                                                                                className="form-control "
                                                                                placeholder='Additional Allowance Description'
                                                                                required
                                                                            />
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <label htmlFor="salaryDeduction">14. Additional Deduction:</label>
                                                                    </td>
                                                                    <td >
                                                                        <div className='d-flex gap-2'>
                                                                            <input
                                                                                type="number"
                                                                                id="salaryDeduction"
                                                                                value={salaryDeduction}
                                                                                onChange={(e) => setSalaryDeduction(e.target.value)}
                                                                                className="form-control"
                                                                                placeholder='Salary Deduction'
                                                                                required
                                                                            />
                                                                            <button onClick={toggleDeductionDescription} className="btn btn-success"><i class="fa fa-plus" aria-hidden="true"></i></button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="2" className=' p-0'>
                                                                        {showDeductionDescription && (
                                                                            <textarea
                                                                                type="text"
                                                                                id="deductionDescription"
                                                                                value={deductionDescription}
                                                                                onChange={(e) => setDeductionDescription(e.target.value)}
                                                                                className="form-control"
                                                                                placeholder='Additional Deduction Description'
                                                                                required
                                                                            />
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr >
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 font-bold">15. Gross in Hand Salary </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="mb-0">: &#x20B9;{totalInHandSalaryMonth != null ? totalInHandSalaryMonth : '0.00'} </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 font-bold">Total Advance Amt.</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="mb-0">: &#x20B9;{totalAdvanceAmount != null ? totalAdvanceAmount.toFixed(2) : '0.00'}</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <label htmlFor="advanceDeduction">16. Advance Deduction:</label>
                                                                    </td>
                                                                    <td>
                                                                        <div className='d-flex gap-2'>
                                                                            <input
                                                                                type="number"
                                                                                id="advanceDeduction"
                                                                                value={advanceDeduction}
                                                                                onChange={(e) => {
                                                                                    const value = parseFloat(e.target.value) || 0;
                                                                                    if (value <= totalAdvanceAmount) {
                                                                                        setAdvanceDeduction(value);
                                                                                    } else {
                                                                                        alert(`Advance Deduction cannot exceed ${totalAdvanceAmount}`);
                                                                                    }
                                                                                }}
                                                                                className="form-control"
                                                                                placeholder='Advance Deduction'
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='bg-body-secondary' bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 fw-bolder  font-bold">17. Net Salary Payable </p>
                                                                    </td>
                                                                    <td className='bg-body-secondary'>
                                                                        <p className="mb-0 fw-bolder">: &#x20B9;{netSalaryPayableMonth != null ? netSalaryPayableMonth : '0.00'} </p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='col-md-6 p-2 '>
                                                        <div className='bg-danger'>
                                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                                <thead >
                                                                    <tr >
                                                                        <th style={{ backgroundColor: "#3F4D67", color: "white" }}>Loan No.</th>
                                                                        <th style={{ backgroundColor: "#3F4D67", color: "white" }}>Loan Amt.</th>
                                                                        <th style={{ backgroundColor: "#3F4D67", color: "white" }}>Loan Due</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {loanRecords.length === 0 ? (
                                                                        <tr>
                                                                            <td colSpan="8" className="text-center text-black bg-white p-1">No loan records found.</td>
                                                                        </tr>
                                                                    ) : (
                                                                        loanRecords.filter(record => {
                                                                            const totalRepayment = calculateLoanDue(record.loanAmount, getTotalRepaymentAmount(record.id));
                                                                            return totalRepayment !== 0 && totalRepayment !== "0.00" && totalRepayment !== "0" && totalRepayment !== null && totalRepayment !== undefined;
                                                                        }).map(record => (
                                                                            <tr className="bg-dark text-capitalize" key={`${record.id}-${record.date}`}>
                                                                                <td className="text-black bg-white p-1">{record.loanNumber}</td>
                                                                                <td className="text-black bg-white p-1">{record.loanAmount}</td>
                                                                                {/* <td className="text-black bg-white p-1">{getTotalRepaymentAmount(record.id)}</td> */}
                                                                                <td className="text-black bg-white p-1">{calculateLoanDue(record.loanAmount, getTotalRepaymentAmount(record.id))}</td>
                                                                            </tr>
                                                                        ))
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className='bg-light my-1 p-3'>
                                                            <div className='d-flex w-100 align-align-items-center justify-content-around '>
                                                                <div className='shadow-sm bg-white border w-50 rounded p-1 m-1 d-flex align-items-center justify-content-center flex-column'>
                                                                    <h5 className='text-dark m-0 fw-bolder'>Salary With Contribution</h5>
                                                                    <small className='text-center border-bottom'>(10-19-20)</small>
                                                                    <h1 style={{ fontSize: "30px" }} className='font-bold text-center text-black fw-bold text-primary'> &#x20B9;{salaryWithContribution != null ? salaryWithContribution : '0.00'}</h1>
                                                                </div>
                                                                <div className='shadow-sm bg-white border w-50 rounded p-1 m-1 d-flex align-items-center justify-content-center flex-column'>
                                                                    <h5 className='text-dark m-0 fw-bolder'>Salary After Deduction</h5>
                                                                    <small className='text-center border-bottom'>(12+13-14)</small>
                                                                    <h1 style={{ fontSize: "30px" }} className='font-bold text-center text-black fw-bold text-primary'> &#x20B9;{salaryAfterDeduction != null ? salaryAfterDeduction : '0.00'}</h1>
                                                                </div>

                                                            </div>
                                                            <div className='d-flex rounded align-items-center justify-content-center p-1 flex-column w-100'>
                                                                <div className='shadow-sm bg-white border w-100 rounded p-1 m-1 d-flex align-items-center justify-content-center flex-column'>
                                                                    <h5 className='text-dark m-0 fw-bolder'>Net Payable Salary</h5>
                                                                    <small className='text-center border-bottom'>(15-16)</small>
                                                                    <h1 style={{ fontSize: "30px" }} className='font-bold text-center text-black fw-bold text-primary'> &#x20B9;{netSalaryPayableMonth != null ? netSalaryPayableMonth : '0.00'}</h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <small className=' fw-bold'>HR Manager - {hrManager.employeeName}</small>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <button type="submit" className="btn btn-primary mt-2">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddSalarySlip;











