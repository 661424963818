// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const Payment_Bonous_Insentive = ({ onClose, bonousinsentive }) => {
//     const [formData, setFormData] = useState({
//         ...bonousinsentive,
//         paymentAmount: '',
//         paymentDate: '',
//         bonousinsentivepaymentModeId: '',
//         bonousinsentivepaymentModeName: '',
//         employeeType: '', // Add this state for employee type
//     });

//     const [paymentModes, setPaymentModes] = useState([]);
//     useEffect(() => {
//         console.log("a,djsjfn", bonousinsentive)
//         fetchPaymentModes();
//         fetchBonusPaymentHistory();
//     }, []);

//     const fetchPaymentModes = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/addPaymentModes`);
//             setPaymentModes(response.data);
//         } catch (error) {
//             console.error('Error fetching payment modes:', error);

//         }
//     };

//     const fetchBonusPaymentHistory = async () => {
//         try {
//             const response = await axios.get(
//                 `${process.env.REACT_APP_LOCAL_URL}/bonousinsentive/viewbonous/${bonousinsentive.id}`
//             );
//             setPaymentBonusIncentive(response.data);
//         } catch (error) {
//             console.error("Error fetching bonus/incentive payment history:", error);
//         }
//     };

//     const handleChange = (e) => {
//         const { name, value } = e.target;

//         if (name === 'paymentAmount') {
//             if (Number(value) >= 0 && Number(value) <= formData.amount) {
//                 setFormData({
//                     ...formData,
//                     [name]: value,
//                 });
//             }
//         } else if (name === 'bonousinsentivepaymentModeId') {
//             const paymentMode = paymentModes.find(mode => mode.id === parseInt(value, 10));
//             setFormData({
//                 ...formData,
//                 bonousinsentivepaymentModeId: value,
//                 bonousinsentivepaymentModeName: paymentMode ? paymentMode.paymentModeName : '',
//             });
//         } else {
//             setFormData({
//                 ...formData,
//                 [name]: value,
//             });
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         try {
//             const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/submitbonousinsentivePayment`, formData);
//             console.log('Payment submitted:', response.data);

//             onClose(); // Close modal on successful submission
//         } catch (error) {
//             console.error('Error submitting payment:', error);


//         }
//     };

//     const handleClose = () => {
//         onClose();
//     };

//     return (
//         <div>
//             <div id="changeSalaryModal" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
//                 <div className="modal-dialog modal-lg">
//                     <div className="modal-content">
//                         <form onSubmit={handleSubmit} encType="multipart/form-data" autoComplete="off" noValidate="novalidate">
//                             <div className="modal-header">
//                                 <h5 className="modal-title">Bonus/Incentive Payment</h5>
//                                 <button type="button" className="close" onClick={handleClose}>&times;</button>
//                             </div>
//                             <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
//                                 <div className="form-row">
//                                     <div className="form-group col-md-6">
//                                         <label>Employee Name <span style={{ color: "red" }}>*</span></label>
//                                         <input name="employeeName" type="text" className="form-control" value={formData.employeeName} readOnly />
//                                     </div>
//                                     <div className="form-group col-md-6">
//                                         <label>Employee Code <span style={{ color: "red" }}>*</span></label>
//                                         <input name="employeeCode" type="text" className="form-control" value={formData.employeeCode} readOnly />
//                                     </div>
//                                     <div className="form-group col-md-6">
//                                         <label>Payment Type<span style={{ color: "red" }}>*</span></label>
//                                         <input name="paymentType" type="text" className="form-control" value={formData.paymentType} readOnly />
//                                     </div>
//                                     <div className="form-group col-md-6">
//                                         <label>Bonus/Incentive Amount <span style={{ color: "red" }}>*</span></label>
//                                         <input name="bonusAmount" type="number" className="form-control" value={formData.amount} readOnly />
//                                     </div>
//                                     <div className="form-group col-md-4">
//                                         <label>Payment Amount</label>
//                                         <input name="paymentAmount" type="number" className="form-control" placeholder="Enter payment amount" onChange={handleChange} value={formData.paymentAmount} required />
//                                     </div>
//                                     <div className="form-group col-md-4">
//                                         <label>Payment Date</label>
//                                         <input name="paymentDate" type="date" className="form-control" onChange={handleChange} value={formData.paymentDate} required />
//                                     </div>
//                                     <div className="form-group col-md-4">
//                                         <label>Payment Mode<span style={{ color: "red" }}>*</span></label>
//                                         <select className="form-control" name="bonousinsentivepaymentModeId" value={formData.bonousinsentivepaymentModeId} onChange={handleChange} required>
//                                             <option value="">Select Payment Mode</option>
//                                             {paymentModes.map(mode => (
//                                                 <option key={mode.id} value={mode.id}>{mode.paymentModeName}</option>
//                                             ))}
//                                         </select>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="modal-footer">
//                                 <button type="submit" className="btn btn-primary" id="saveSalary" >Save</button>
//                                 <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Payment_Bonous_Insentive;



import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Payment_Bonous_Insentive = ({ onClose, bonousinsentive }) => {
    const [formData, setFormData] = useState({
        ...bonousinsentive,
        paymentAmount: '',
        paymentDate: '',
        bonousinsentivepaymentModeId: '',
        bonousinsentivepaymentModeName: '',
        employeeType: '', // Add this state for employee type
    });

    const [paymentModes, setPaymentModes] = useState([]);
    const [totalPaidAmount, setTotalPaidAmount] = useState(0); // State to store the total paid amount

    useEffect(() => {
        fetchPaymentModes();
        fetchBonusPaymentHistory();
    }, []);

    const fetchPaymentModes = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/addPaymentModes`);
            setPaymentModes(response.data);
        } catch (error) {
            console.error('Error fetching payment modes:', error);
        }
    };

    const fetchBonusPaymentHistory = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_LOCAL_URL}/bonousinsentive/viewbonous/${bonousinsentive.id}`
            );
            const paymentHistory = response.data;
            const totalPaid = paymentHistory.reduce((sum, record) => sum + record.paymentAmount, 0);
            setTotalPaidAmount(totalPaid);
        } catch (error) {
            console.error("Error fetching bonus/incentive payment history:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'paymentAmount') {
            const remainingAmount = formData.amount - totalPaidAmount;
            if (Number(value) >= 0 && Number(value) <= remainingAmount) {
                setFormData({
                    ...formData,
                    [name]: value,
                });
            }
        } else if (name === 'bonousinsentivepaymentModeId') {
            const paymentMode = paymentModes.find(mode => mode.id === parseInt(value, 10));
            setFormData({
                ...formData,
                bonousinsentivepaymentModeId: value,
                bonousinsentivepaymentModeName: paymentMode ? paymentMode.paymentModeName : '',
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/submitbonousinsentivePayment`, formData);
            console.log('Payment submitted:', response.data);

            onClose(); // Close modal on successful submission
        } catch (error) {
            console.error('Error submitting payment:', error);
        }
    };

    const handleClose = () => {
        onClose();
    };

    const remainingAmount = formData.amount - totalPaidAmount;

    return (
        <div>
            <div id="changeSalaryModal" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <form onSubmit={handleSubmit} encType="multipart/form-data" autoComplete="off" noValidate="novalidate">
                            <div className="modal-header">
                                <h5 className="modal-title">Bonus/Incentive Payment</h5>
                                <button type="button" className="close" onClick={handleClose}>&times;</button>
                            </div>
                            <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Employee Name <span style={{ color: "red" }}>*</span></label>
                                        <input name="employeeName" type="text" className="form-control" value={formData.employeeName} readOnly />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Employee Code <span style={{ color: "red" }}>*</span></label>
                                        <input name="employeeCode" type="text" className="form-control" value={formData.employeeCode} readOnly />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Payment Type<span style={{ color: "red" }}>*</span></label>
                                        <input name="paymentType" type="text" className="form-control" value={formData.paymentType} readOnly />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Bonus/Incentive Amount <span style={{ color: "red" }}>*</span></label>
                                        <input name="bonusAmount" type="number" className="form-control" value={formData.amount} readOnly />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Payment Amount</label>
                                        <input name="paymentAmount" type="number" className="form-control" placeholder="Enter payment amount" onChange={handleChange} value={formData.paymentAmount} required />
                                        <small className='text-danger'>Remaining amount: {remainingAmount.toFixed(2)}</small>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Payment Date</label>
                                        <input name="paymentDate" type="date" className="form-control" onChange={handleChange} value={formData.paymentDate} required />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Payment Mode<span style={{ color: "red" }}>*</span></label>
                                        <select className="form-control" name="bonousinsentivepaymentModeId" value={formData.bonousinsentivepaymentModeId} onChange={handleChange} required>
                                            <option value="">Select Payment Mode</option>
                                            {paymentModes.map(mode => (
                                                <option key={mode.id} value={mode.id}>{mode.paymentModeName}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary" id="saveSalary" >Save</button>
                                <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Payment_Bonous_Insentive;
