import React, { useEffect, useState } from 'react';
import axios from 'axios';

const E_RequestLeave = ({ onClose, onUpdate }) => {
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [status, setStatus] = useState('');
    const [leaveRequest, setLeaveRequest] = useState('');
    const [reason, setReason] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');

    const employeeId = localStorage.getItem('employeeId');

    useEffect(() => {
        if (employeeId) {
            fetchEmployeeDetails(employeeId);
        }
    }, [employeeId]);

    const fetchEmployeeDetails = async (employeeId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`);
            if (response.data.length > 0) {
                const employeeData = response.data[0];
                setSelectedEmployee(employeeData);
                setStatus(employeeData.status);
                console.log("Employee Details:", employeeData);
            }
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate required fields
        const formData = { selectedEmployee, leaveRequest, reason, fromDate, description };
        for (const [key, value] of Object.entries(formData)) {
            if (!value) {
                setError(`Please fill in the ${key.replace(/([A-Z])/g, ' $1').toLowerCase()}`);
                return;
            }
        }

        if (status !== 'active') {
            setError('The selected employee is inactive. Please select an active employee.');
            return;
        }

        try {
            const requestLeaveData = {
                employeeId: selectedEmployee.id,
                employeeName: selectedEmployee.employeeName,
                status: leaveRequest,
                reason,
                fromDate,
                toDate,
                description
            };

            // Update employee status
            await axios.put(`${process.env.REACT_APP_LOCAL_URL}/employee_status/${selectedEmployee.id}`, { status: leaveRequest });

            // Submit leave request
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/activeinactive`, requestLeaveData);

            console.log('Leave request submitted successfully');
            onUpdate();
            setTimeout(() => {
                onClose();
                window.location.reload();
            }, 1000); // 1 second delay
        } catch (error) {
            console.error('Error submitting leave request:', error);
            setError('Failed to submit leave request. Please try again.');
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div id="requestLeaveModal" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                        <div className="modal-header">
                            <h5 className="modal-title">Request Leave</h5>
                            <button type="button" className="close" onClick={handleClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>Employee<span style={{ color: "red" }}>*</span></label>
                                    <input name="employeeName" type="text" className="form-control" value={selectedEmployee?.employeeName || ''} readOnly />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Current Status<span style={{ color: "red" }}>*</span></label>
                                    <input name="status" type="text" className="form-control" placeholder="Current Status" value={status} readOnly />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Apply To Leave<span style={{ color: "red" }}>*</span></label>
                                    <select className="form-control" value={leaveRequest} onChange={(e) => setLeaveRequest(e.target.value)} required>
                                        <option value="" disabled hidden>Select a leave type</option>
                                        <option value="request_leave">Request Leave</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Reason<span style={{ color: "red" }}>*</span></label>
                                    <select className="form-control" value={reason} onChange={(e) => setReason(e.target.value)} required>
                                        <option value="" disabled hidden>Select a reason</option>
                                        <option value="On Paid Leave">On Paid Leave</option>
                                        <option value="On Non Paid Leave">On Non Paid Leave</option>
                                        <option value="On Holidays">On Holidays</option>
                                        <option value="Deputation to other Company">Deputation to other Company</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>From Date<span style={{ color: "red" }}>*</span></label>
                                    <input type="date" className="form-control" value={fromDate} onChange={(e) => setFromDate(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>To Date</label>
                                    <input type="date" className="form-control" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Description<span style={{ color: "red" }}>*</span></label>
                                    <textarea className="form-control" placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} required></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary">Submit</button>
                            <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default E_RequestLeave;
