// import axios from 'axios';
// import React from 'react';
// import { useEffect, useState } from 'react';

// function AttendanceDetailsModal({ record, onClose }) {
//     console.log("record", record);
//     const [setting, setSetting] = useState({});


//     useEffect(() => {
//         const fetchSetting = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
//                 setSetting(response.data);
//             } catch (error) {
//                 console.error('Error fetching Dashboard Logo', error);
//             }
//         };
//         fetchSetting();
//     }, []);

//     const handlePrint = () => {
//         window.print();
//     };


//     return (
//         <div className="container-fluid" >
//             <div className="row py-2 px-3">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                     <div>
//                         <div className='logo w-100 h-40 p-2'>
//                             <img className='w-50 h-75' src={`${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${setting.landingPageLogo}`} alt="LOGO" />
//                         </div>
//                     </div>
//                         <button type="button" className="btn-close" onClick={onClose}></button>
//                     </div>
//                     <div className="modal-body">
//                         <div className="row mt-3">
//                             <div className="col-md-12">
//                                 <h5 className=' fw-semibold p-1 text-capitalize'>Attendance Records: {record.employeeName}</h5>
//                                 <table className="table table-bordered">
//                                     <thead>
//                                         <tr>

//                                             <th>Name</th>
//                                             <th>Date</th>
//                                             <th>Status</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {record.attendanceData.filteredAttendance.map((attendance, index) => (
//                                             <tr key={index}>
//                                                 <td>{attendance.employeeName}</td>
//                                                 <td>{new Date(attendance.date).toLocaleDateString()}</td>
//                                                 <td>{attendance.status}</td>
//                                             </tr>
//                                         ))}
//                                     </tbody>
//                                 </table>
//                             </div>
//                         </div>
//                         <div className="row">
//                             <div className="col-md-12">
//                                 <div className="card">
//                                     <div className="card-body">
//                                     <div className="d-flex gap-3">
//                                 <div className="bg-success text-white p-2 text-center border rounded">Present: {record.totalPresent}</div>
//                                 <div className="bg-danger text-white p-2 text-center border rounded">Absent: {record.totalAbsent}</div>
//                                 <div className="bg-info text-white p-2 text-center border rounded">Half Day: {record.totalHalfDay}</div>
//                                 <div className="bg-warning text-white p-2 text-center border rounded">Weekly Off: {record.totalWeeklyOff}</div>
//                                 <div className="bg-warning text-white p-2 text-center border rounded">Unpaid Leave: {record.totalUnpaidLeave}</div>
//                                 <div className="bg-warning text-white p-2 text-center border rounded">Paid Leave: {record.totalPaidLeave}</div>
//                                 <div className="bg-primary text-white p-2 text-center border rounded">Overtime: {record.totalOvertime}</div>

//                             </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="modal-footer">
//                     <button onClick={handlePrint} className="btn btn-success print-button"><i className="fa fa-download"></i> Print</button>
//                     <button onClick={onClose} className="btn btn-danger print-button"><i className="fa fa-arrow-left"></i> Back</button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default AttendanceDetailsModal;


import axios from 'axios';
import React from 'react';
import { useEffect, useState } from 'react';
import myImage from '../../images/employee_profile.png';

function AttendanceDetailsModal({ record, onClose }) {
    console.log("record", record);
    const [setting, setSetting] = useState({});
    const [employeeDetails, setEmployeeDetails] = useState({});


    useEffect(() => {
        const fetchSetting = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);

                setSetting(response.data);
            } catch (error) {
                console.error('Error fetching Dashboard Logo', error);
            }
        };
        fetchSetting();
    }, []);

    useEffect(() => {
        const fetchEmployeeDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${record.employeeId}`);
                const employeeData = response.data.length > 0 ? response.data[0] : null;
                setEmployeeDetails(employeeData);
            } catch (error) {
                console.error('Error fetching Dashboard Logo', error);
            }
        };
        fetchEmployeeDetails();
    }, []);

    const handlePrint = () => {
        window.print();
    };
    useEffect(() => {
        console.log(employeeDetails)
    }, [])


    // Helper function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    };

    // Sort the attendance data by date in ascending order
    const sortedAttendance = [...record.attendanceData.filteredAttendance].sort((a, b) => new Date(a.date) - new Date(b.date));

    return (
        <div className="container-fluid" >
            <div className="row p-1">
                <div className="modal-content">
                    <div className="modal-header m-0 p-2">
                        <div>
                            <div style={{ height: "50px", width: "100%" }} className='logo p-1'>
                                <img className='w-100 h-100' src={`${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${setting.landingPageLogo}`} alt="LOGO" />
                            </div>
                            <h5 style={{ color: "#3F4D67" }} className='title-detail text-uppercase fw-bolder font-bold m-0'>{setting.title}</h5>
                        </div>
                        <div>
                            <h2 style={{ color: "#3F4D67" }} className="title-detail fw-bolder font-bold m-0">
                                {employeeDetails.employeeName}
                            </h2>

                            <h5 className="title-detail m-0">
                                Employee Code: {employeeDetails.employeeCode}
                            </h5>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className='col-md-12 '>
                                <div className="card-footer rounded border col-md-12 ">
                                    <h5 className="text-primary text-center fw-semibold">Attendance Summary Days</h5>
                                    <hr />
                                    <div className="d-flex gap-3 align-items-center justify-content-center ">
                                        <div className="bg-success text-white p-1 text-center border rounded">Present: {record.totalPresent}</div>
                                        <div className="bg-success text-white p-1 text-center border rounded">Weekly Off: {record.totalWeeklyOff}</div>
                                        <div className="bg-success text-white p-1 text-center border rounded">Paid Leave: {record.totalPaidLeave}</div>
                                        <div className="bg-success text-white p-1 text-center border rounded">Overtime: {record.totalOvertime}</div>
                                        <div className="bg-danger text-white p-1 text-center border rounded">Absent: {record.totalAbsent}</div>
                                        <div className="bg-danger text-white p-1 text-center border rounded">Half Day: {record.totalHalfDay}</div>
                                        <div className="bg-danger text-white p-1 text-center border rounded">Unpaid Leave: {record.totalUnpaidLeave}</div>
                                        <div className="bg-primary text-white p-1 text-center border rounded">Total Days: {record.totalAttencance}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 '>
                                <table className="table table-striped table-bordered rounded border">
                                    <thead>
                                        <tr>
                                            <th>Serial No</th>
                                            <th>Name</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sortedAttendance.map((attendance, index) => (
                                            <tr key={`${attendance.id}-${attendance.date}`}>
                                                <td>{index + 1}</td>
                                                <td>{attendance.employeeName}</td>
                                                <td>{formatDate(attendance.date)}</td>
                                                <td>{attendance.status}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button onClick={handlePrint} className="btn btn-success print-button"><i className="fa fa-download"></i> Print</button>
                            <button onClick={onClose} className="btn btn-danger print-button"><i className="fa fa-arrow-left"></i> Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AttendanceDetailsModal;
