import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddTransferEmployee = ({ employee, onClose, onUpdate }) => {
    const [transferFrom, setTransferFrom] = useState('');
    const [transferTo, setTransferTo] = useState('');
    const [transferDate, setTransferDate] = useState('');
    const [description, setDescription] = useState('');
    const [offices, setOffices] = useState([]);
    const [projects, setProjects] = useState([]);
    const [category, setCategory] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        // Fetch offices initially
        axios.get(`${process.env.REACT_APP_LOCAL_URL}/offices`)
            .then(response => {
                setOffices(response.data);
            })
            .catch(error => {
                console.error('Error fetching offices:', error);
            });
        axios.get(`${process.env.REACT_APP_LOCAL_URL}/projects`)
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
            });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        try {
            let selectedLocationName, selectedLocationId;

            // Extract selected location name and id based on category
            if (category === 'office') {
                const [selectedOfficeName, selectedOfficeId] = transferTo.split('-');
                selectedLocationName = selectedOfficeName.trim();
                selectedLocationId = selectedOfficeId.trim();
            } else if (category === 'project') {
                const [selectedProjectName, selectedProjectId] = transferTo.split('-');
                selectedLocationName = selectedProjectName.trim();
                selectedLocationId = selectedProjectId.trim();
            }

            const transferHistoryData = {
                employeeName:employee.employeeName,
                employeeId: employee.id,
                transferFrom: employee.joiningOffice,
                transferTo: selectedLocationName,
                transferToId: selectedLocationId,
                transferFromId:employee.office_id,
                transferDate,
                description
            };

            const employeeData = {
                transferFrom: employee.joiningOffice,
                transferFromId: employee.joiningOfficeId,
                transferTo: selectedLocationName,
                transferToId: selectedLocationId,
                transferDate,
                description
            };

            // Update employee data and transfer history
            await axios.all([
                axios.put(`${process.env.REACT_APP_LOCAL_URL}/employees_update/${employee.id}`, employeeData),
                axios.post(`${process.env.REACT_APP_LOCAL_URL}/transferHistory`, transferHistoryData)
            ]);

            // Assuming onUpdate is a function passed as a prop to handle updates
           
            // Close the modal
         
            onUpdate();
            setTimeout(() => {
                onClose();
                window.location.reload();
            }, 1000); // 1 second delay
        } catch (error) {
            console.error('Error transferring employee:', error);
            setError('Failed to transfer employee');
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div id="addTransferEmployee" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
                        <div className="modal-header">
                            <h5 className="modal-title">Employee Transfer</h5>
                            <button type="button" className="close" onClick={onClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                            <div className="form-group">
                                <label htmlFor="employeeName">Employee Name</label>
                                <input
                                    name="employeeName"
                                    type="text"
                                    readOnly
                                    id="employeeName"
                                    className="form-control"
                                    value={employee.employeeName || ''}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="currentLocation">Current Location</label>
                                <input
                                    readOnly
                                    id="currentLocation"
                                    className="form-control"
                                    value={employee.joiningOffice || ''}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="category">Transfer To<span style={{ color: "red" }}>*</span></label>
                                <select
                                    className="form-control"
                                    id="category"
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    required
                                >
                                    <option value="" disabled hidden>Select Transfer To</option>
                                    <option value="office">Office</option>
                                    <option value="project">Project</option>
                                </select>
                            </div>
                            {category === 'office' && (
                                <div className="form-group">
                                    <label htmlFor="selectOffice">Select Office<span style={{ color: "red" }}>*</span></label>
                                    <select
                                        className="form-control"
                                        id="selectOffice"
                                        value={transferTo}
                                        onChange={(e) => setTransferTo(e.target.value)}
                                        required
                                    >
                                        <option value="" disabled hidden>Select Office</option>
                                        {offices.map(office => (
                                            <option key={office.id} value={`${office.officeName}-${office.id}`}>{office.officeName}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            {category === 'project' && (
                                <div className="form-group">
                                    <label htmlFor="selectProject">Select Project<span style={{ color: "red" }}>*</span></label>
                                    <select
                                        className="form-control"
                                        id="selectProject"
                                        value={transferTo}
                                        onChange={(e) => setTransferTo(e.target.value)}
                                        required
                                    >
                                        <option value="" disabled hidden>Select Project</option>
                                        {projects.map(project => (
                                            <option key={project.id} value={`${project.projectName}-${project.id}`}>{project.projectName}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            <div className="form-group">
                                <label htmlFor="transferDate">Transfer Date</label>
                                <input
                                    className="form-control"
                                    required
                                    placeholder="Transfer Date"
                                    id="transferDate"
                                    name="transferDate"
                                    type="date"
                                    value={transferDate}
                                    onChange={(e) => setTransferDate(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <textarea
                                    className="form-control"
                                    id="description"
                                    name="description"
                                    placeholder="Description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" id="saveTransfer">
                                Transfer
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={onClose}>
                                Close
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {isLoading && <div className="loader">Loading...</div>}
        </div>
    );
};

export default AddTransferEmployee;
