// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import '../style1.css';

// function ApprovedStatus({ onClose }) {
//     const [currentPage, setCurrentPage] = useState(1);
//     const [itemsPerPage, setItemsPerPage] = useState(10);
//     const [ApprovedStatus, setApprovedStatus] = useState([]);

//     useEffect(() => {
//         fetchCombinedData();
//     }, []);

//     const fetchCombinedData = async () => {
//         try {
//             const employeesResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
//             const ApprovedStatusResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/approved`);

//             const ApprovedStatusData = ApprovedStatusResponse.data;

//             const combinedData = employeesResponse.data
//                 .filter(employee => ApprovedStatusData.some(ai => ai.employeeId === employee.id))
//                 .map(employee => {
//                     const matchingApprovedStatus = ApprovedStatusData.find(ai => ai.employeeId === employee.id);
//                     return {
//                         ...employee,
//                         activeInactiveDetails: matchingApprovedStatus || {}
//                     };
//                 });

//             setApprovedStatus(combinedData);
//         } catch (error) {
//             console.error("Error fetching combined data:", error);
//             toast.error("Error fetching data. Please try again later.");
//         }
//     };
//         // Function to format the date
//         const formatDate = (dateString) => {
//             return new Date(dateString).toLocaleDateString('en-US', {
//                 year: 'numeric',
//                 month: '2-digit',
//                 day: '2-digit'
//             });
//         };    

//     // Logic to get current items
//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItems = ApprovedStatus.slice(indexOfFirstItem, indexOfLastItem);

//     // Change page
//     const paginate = (pageNumber) => setCurrentPage(pageNumber);

//     const handleBack = () => {
//         onClose(); // Call the onClose function passed from the parent component
//     };

//     return (
//         <div className="container-fluid p-0">
//             <ToastContainer />
//             <div className="row">
//                 <div className="col-xl-12">
//                     <div className="card shadow mb-4">
//                         <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
//                             <h6 className="m-0 font-weight-bold text-primary">
//                                 Check Status
//                             </h6>
//                             <button className="btn btn-outline-success" onClick={handleBack}>Leave List</button>
//                         </div>
//                         <div className="card-body">
//                             <table className="table table-striped table-bordered" style={{ width: "100%" }}>
//                                 <thead>
//                                     <tr>
//                                         <th>Employee Photo</th>
//                                         <th>Employee Name</th>
//                                         <th>Status</th>
//                                         <th>Date</th>
//                                         <th>Description</th>                                    
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {currentItems.map((status) => (
//                                         <tr key={status.id}>
//                                             <td>
//                                                 <img
//                                                     src={`${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${status.passportSizePhoto}`}
//                                                     style={{ width: "90px" }}
//                                                     alt="Employee"
//                                                 />
//                                             </td>
//                                             <td>{status.employeeName}</td>
//                                             <td>{status.activeInactiveDetails.status}</td>
//                                             <td>{formatDate(status.activeInactiveDetails.createdAt)}</td>
//                                             <td>{status.activeInactiveDetails.description}</td>
//                                         </tr>
//                                     ))}
//                                 </tbody>
//                             </table>
//                             {/* Pagination */}
//                             <ul className="pagination">
//                                 <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                                     <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
//                                 </li>
//                                 {Array.from({ length: Math.ceil(ApprovedStatus.length / itemsPerPage) }, (_, i) => (
//                                     <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
//                                         <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
//                                     </li>
//                                 ))}
//                                 <li className={`page-item ${currentPage === Math.ceil(ApprovedStatus.length / itemsPerPage) ? 'disabled' : ''}`}>
//                                     <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
//                                 </li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default ApprovedStatus;




import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../style1.css';
import DateChangeApproved from "./DateChangeApproved";
import myImage from '../../images/employee_profile.png';

function ApprovedStatus({ onClose, showRejectStatusModal }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [ApprovedStatus, setApprovedStatus] = useState([]);
    const [dateChange, setDateChange] = useState(null);
    const [isAddDateChangeModal, setIsAddDateChangeModal] = useState(false);

    useEffect(() => {
        fetchCombinedData();
    }, []);

    const fetchCombinedData = async () => {
        try {
            const employeesResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            const ApprovedStatusResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/approved`);

            const ApprovedStatusData = ApprovedStatusResponse.data;

            const combinedData = employeesResponse.data
                .filter(employee => ApprovedStatusData.some(ai => ai.employeeId === employee.id))
                .map(employee => {
                    const matchingApprovedStatus = ApprovedStatusData.find(ai => ai.employeeId === employee.id);
                    return {
                        ...employee,
                        activeInactiveDetails: matchingApprovedStatus || {}
                    };
                });

            setApprovedStatus(combinedData);
        } catch (error) {
            console.error("Error fetching combined data:", error);
            toast.error("Error fetching data. Please try again later.");
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDateChange = (ApprovedStatus, activeInactiveDetails) => {
        const dateChange = { ...ApprovedStatus, activeInactiveDetails };
        setDateChange(dateChange);
        setIsAddDateChangeModal(true);
    };

    const handleCloseDateChange = () => {
        setIsAddDateChangeModal(false);
    };

    const handleUpdateApproveList = () => {
        fetchCombinedData();
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = ApprovedStatus.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className="container-fluid p-0">
            <ToastContainer />
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center gap-3">
                    <h6 className="m-0 font-weight-bold text-primary">
                        Approved Status
                    </h6>
                    <h6 style={{ cursor: "pointer" }} className="m-0 font-weight-bold text-info" onClick={() => onClose()}>
                        Requests Leave
                    </h6>
                    <h6 style={{ cursor: "pointer" }} className="m-0 font-weight-bold text-info" onClick={() => showRejectStatusModal()}>
                        Reject Status
                    </h6>
                </div>
                <div className="card-body">
                    <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <th>Employee Photo</th>
                                <th>Employee Name</th>
                                <th>Status</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Description</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((ApprovedStatus, index) => (
                                <tr key={index}>
                                    <td>
                                       
                                        <img
                                            src={ApprovedStatus.passportSizePhoto
                                                ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${ApprovedStatus.passportSizePhoto}`
                                                : myImage}
                                            style={{ width: "90px" }}
                                            alt="Employee"
                                        />
                                    </td>
                                    <td>{ApprovedStatus.employeeName}</td>
                                    <td>{ApprovedStatus.activeInactiveDetails.status}</td>
                                    <td>{formatDate(ApprovedStatus.activeInactiveDetails.fromDate)}</td>
                                    <td>{formatDate(ApprovedStatus.activeInactiveDetails.toDate)}</td>
                                    <td>{ApprovedStatus.activeInactiveDetails.description}</td>
                                    <td>
                                        <button onClick={() => handleDateChange(ApprovedStatus, ApprovedStatus.activeInactiveDetails)} className="btn btn-outline-primary">
                                            Action
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <ul className="pagination">
                        {Array.from({ length: Math.ceil(ApprovedStatus.length / itemsPerPage) }, (_, i) => (
                            <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                <button className="page-link" onClick={() => handlePageChange(i + 1)}>
                                    {i + 1}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
                {isAddDateChangeModal && <DateChangeApproved dateChange={dateChange} onUpdate={handleUpdateApproveList} onClose={handleCloseDateChange} />}
            </div>
        </div>
    );
}

export default ApprovedStatus;
