// import axios from 'axios';
// import React, { useEffect, useState } from 'react';

// const AddEmployeeModal = ({ onClose, onUpdate }) => {
//     const [currentSection, setCurrentSection] = useState('basicInfo');
//     const [formData, setFormData] = useState({
//         employeeName: '',
//         employeeCode: '',
//         employeeEmail: '',
//         employeePhone: '',
//         employeeAltPhone: '',
//         employeeDOB: '',
//         employeeGender: '',
//         employeeMaritalStatus: '',
//         employeePan: '',
//         employeeAadhar: '',
//         employeeType: '',
//         employeeAddress1: '',
//         employeeCity1: '',
//         employeeState1: '',
//         employeePincode1: '',
//         employeeAddress2: '',
//         employeeCity2: '',
//         employeeState2: '',
//         employeePincode2: '',
//         employeeBloodGroup: '',
//         accountHolderName: '',
//         accountNumber: '',
//         bankName: '',
//         ifscCode: '',
//         branchName: '',
//         basicSalary: '',
//         vda: '',
//         epf: '',
//         esic: '',
//         otherAllowances: '',
//         grossSalary: '',
//         department: '',
//         departmentName: '',
//         position: '',
//         positionName: '',
//         interncontractual: '',
//         joiningDate: '',
//         medical: false,
//         travel: false,
//         insurance: false,
//         fatherName: '',
//         motherName: '',
//         emergencyContactPerson1: '',
//         emergencyContactNumber1: '',
//         emergencyContactPerson2: '',
//         emergencyContactNumber2: '',
//         panCardPhoto: null,
//         aadharCardPhoto: null,
//         passportSizePhoto: null,
//         resumePhoto: null,
//         otherPhoto: null,
//         marksheet10thPhoto: null,
//         marksheet12thPhoto: null,
//         degreePhoto: null,
//         drivinglicense:null,
//         passport:null,
//         graductionmarksheet:null,
//         postgraductionmarksheet:null,
//         professionaldegree:null,
//         offerletter:null,
//         joiningletter:null,
//         appointmentletter:null,
//         employeementletter:null,
//         experienceletter:null,
//         passbook_check:null,
//         status: 'active',
//         username: localStorage.getItem('username'),
//         wifeName: '',
//         childrenAges: [],
//         joiningCompany: '',
//         company_id: '',
//         joiningOffice: '',
//         office_id: '',

//     });
//     const [validationErrors, setValidationErrors] = useState({});
//     const [states, setStates] = useState([]);
//     const [companies, setCompanies] = useState([]);
//     const [offices, setOffices] = useState([]);
//     const [departments, setDepartments] = useState([]);
//     const [positions, setPositions] = useState([]);
//     const [filteredPositions, setFilteredPositions] = useState([]);
//     const [error, setError] = useState('');

//     useEffect(() => {
//         const fetchStates = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/states`);
//                 setStates(response.data);
//             } catch (error) {
//                 console.error("Error fetching states:", error);
//             }
//         };

//         const fetchCompanies = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/companies`);
//                 setCompanies(response.data);
//             } catch (error) {
//                 console.error("Error fetching companies:", error);
//             }
//         };

//         const fetchOffices = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/offices`);
//                 setOffices(response.data);
//             } catch (error) {
//                 console.error("Error fetching offices:", error);
//             }
//         };

//         const fetchDepartments = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/departments`);
//                 setDepartments(response.data);
//             } catch (error) {
//                 console.error("Error fetching departments:", error);
//             }
//         };

//         const fetchPositions = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/positions`);
//                 setPositions(response.data);
//             } catch (error) {
//                 console.error("Error fetching positions:", error);
//             }
//         };

//         fetchStates();
//         fetchCompanies();
//         fetchOffices();
//         fetchDepartments();
//         fetchPositions();
//     }, []);

//     useEffect(() => {
//         if (formData.department) {
//             const filtered = positions.filter(position => position.department_id === parseInt(formData.department));
//             setFilteredPositions(filtered);
//         } else {
//             setFilteredPositions([]);
//         }
//     }, [formData.department, positions]);

//     const handleChange = (e) => {
//         const { name, value, type, checked, files } = e.target;
//         if (type === 'checkbox') {
//             setFormData((prevData) => ({
//                 ...prevData,
//                 [name]: checked,
//             }));
//         } else if (type === 'file') {
//             setFormData((prevData) => ({
//                 ...prevData,
//                 [name]: files[0],
//             }));
//         } else if (name === "joiningCompany") {
//             const selectedCompany = companies.find(company => company.companyName === value);
//             setFormData((prevData) => ({
//                 ...prevData,
//                 [name]: value,
//                 company_id: selectedCompany ? selectedCompany.id : "",
//             }));
//         } else if (name === "joiningOffice") {
//             const selectedOffice = offices.find(office => office.officeName === value);
//             setFormData((prevData) => ({
//                 ...prevData,
//                 [name]: value,
//                 office_id: selectedOffice ? selectedOffice.id : "",
//             }));
//         } else if (name === "department") {
//             const selectedDepartment = departments.find(department => department.id === parseInt(value));
//             setFormData((prevData) => ({
//                 ...prevData,
//                 department: value,
//                 departmentName: selectedDepartment ? selectedDepartment.name : "",
//             }));
//         } else if (name === "position") {
//             const selectedPosition = filteredPositions.find(position => position.id === parseInt(value));
//             setFormData((prevData) => ({
//                 ...prevData,
//                 position: value,
//                 positionName: selectedPosition ? selectedPosition.positionName : "",
//             }));
//         } else {
//             setFormData((prevData) => ({
//                 ...prevData,
//                 [name]: value,
//             }));
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const errors = {};
//         if (!formData.employeeName) errors.employeeName = 'Employee name is required';
//         if (!formData.employeeCode) errors.employeeCode = 'Employee code is required';
//         if (!formData.employeePhone) errors.employeePhone = 'Employee phone number is required';
//         if (!formData.employeeDOB) errors.employeeDOB = 'Date of birth is required';
//         if (!formData.employeeGender) errors.employeeGender = 'Gender is required';
//         if (!formData.employeeAadhar) errors.employeeAadhar = 'Aadhar number is required';
//         if (!formData.department) errors.department = 'Department is required';
//         if (!formData.position) errors.position = 'Position is required';


//         if (Object.keys(errors).length > 0) {
//             setValidationErrors(errors);
//             return;
//         }

//         console.log('Form data:', formData);

//         const formDataToSend = new FormData();
//         Object.keys(formData).forEach(key => {
//             formDataToSend.append(key, formData[key]);
//         });

//         try {
//             const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/empdata`, formDataToSend);
//             console.log('Data uploaded successfully:', response.data);

//             onUpdate();
//             setTimeout(() => {
//                 onClose();
//                 window.location.reload();
//             }, 1000); // 1 second delay
//         } catch (error) {
//             console.error('Error uploading data:', error);
//         }
//     };

//     const inputClass = (field) => validationErrors[field] ? 'form-control is-invalid' : 'form-control';

//     return (
//         <div id="add" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
//             <div className="modal-dialog modal-lg">
//                 <div className="modal-content">
//                     <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
//                         <div className="modal-header">
//                             <h5 className="modal-title">Add Employee</h5>
//                             <button type="button" className="close" onClick={onClose}>&times;</button>
//                         </div>
//                         <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
//                             {error && <div className="alert alert-danger">{error}</div>}
//                             <div className="nav nav-tabs">
//                                 <button type="button" className={`nav-link px-2 ${currentSection === 'basicInfo' ? 'active' : ''}`} onClick={() => setCurrentSection('basicInfo')}>Basic Info</button>
//                                 <button type="button" className={`nav-link px-2 ${currentSection === 'jobDetails' ? 'active' : ''}`} onClick={() => setCurrentSection('jobDetails')}>Positional Info</button>
//                                 <button type="button" className={`nav-link px-2 ${currentSection === 'otherDetails' ? 'active' : ''}`} onClick={() => setCurrentSection('otherDetails')}>Family Info</button>
//                                 <button type="button" className={`nav-link px-2 ${currentSection === 'accountDetails' ? 'active' : ''}`} onClick={() => setCurrentSection('accountDetails')}>Account Details</button>
//                                 <button type="button" className={`nav-link px-2 ${currentSection === 'documents' ? 'active' : ''}`} onClick={() => setCurrentSection('documents')}>Uploads</button>
//                             </div>
//                             <div className="tab-content">
//                                 {currentSection === 'basicInfo' && (
//                                     <div>
//                                         <h4 className='text-dark py-2 fw-bolder'>Basic Info</h4>
//                                         <div className="form-row">
//                                             <div className="form-group col-md-6">
//                                                 <label>Employee Name <span style={{ color: "red" }}>*</span></label>
//                                                 <input name="employeeName" type="text" className={inputClass('employeeName')} placeholder="Enter Employee Name " onChange={handleChange} value={formData.employeeName} />
//                                                 {validationErrors.employeeName && <div className="invalid-feedback">{validationErrors.employeeName}</div>}
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Employee Code <span style={{ color: "red" }}>*</span></label>
//                                                 <input name="employeeCode" type="text" className={inputClass('employeeCode')} placeholder="Enter Employee Code" onChange={handleChange} value={formData.employeeCode} />
//                                                 {validationErrors.employeeCode && <div className="invalid-feedback">{validationErrors.employeeCode}</div>}
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Phone <span style={{ color: "red" }}>*</span></label>
//                                                 <input name="employeePhone" type="number" className={inputClass('employeePhone')} placeholder="Enter phone number" onChange={handleChange} value={formData.employeePhone} />
//                                                 {validationErrors.employeePhone && <div className="invalid-feedback">{validationErrors.employeePhone}</div>}
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Alternative Phone</label>
//                                                 <input name="employeeAltPhone" type="number" className="form-control" placeholder="Enter alternative phone number" onChange={handleChange} value={formData.employeeAltPhone} />
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Email</label>
//                                                 <input name="employeeEmail" type="email" className="form-control" placeholder="Enter email" onChange={handleChange} value={formData.employeeEmail} />
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Date of Birth <span style={{ color: "red" }}>*</span></label>
//                                                 <input name="employeeDOB" type="date" className={inputClass('employeeDOB')} placeholder="Enter date of birth" onChange={handleChange} value={formData.employeeDOB} />
//                                                 {validationErrors.employeeDOB && <div className="invalid-feedback">{validationErrors.employeeDOB}</div>}
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Gender <span style={{ color: "red" }}>*</span></label>
//                                                 <select name="employeeGender" className={inputClass('employeeGender')} onChange={handleChange} value={formData.employeeGender}>
//                                                     <option value="">Select Gender</option>
//                                                     <option value="male">Male</option>
//                                                     <option value="female">Female</option>
//                                                     <option value="other">Other</option>
//                                                 </select>
//                                                 {validationErrors.employeeGender && <div className="invalid-feedback">{validationErrors.employeeGender}</div>}
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Blood Group <span style={{ color: "red" }}>*</span></label>
//                                                 <select
//                                                     name="employeeBloodGroup"
//                                                     className={inputClass('employeeBloodGroup')}
//                                                     onChange={handleChange}
//                                                     value={formData.employeeBloodGroup}
//                                                 >
//                                                     <option value="" disabled>Select Blood Group</option>
//                                                     <option value="A+">A+</option>
//                                                     <option value="A-">A-</option>
//                                                     <option value="B+">B+</option>
//                                                     <option value="B-">B-</option>
//                                                     <option value="AB+">AB+</option>
//                                                     <option value="AB-">AB-</option>
//                                                     <option value="O+">O+</option>
//                                                     <option value="O-">O-</option>
//                                                 </select>
//                                                 {validationErrors.employeeBloodGroup &&
//                                                     <div className="invalid-feedback">
//                                                         {validationErrors.employeeBloodGroup}
//                                                     </div>
//                                                 }
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>PAN Number</label>
//                                                 <input name="employeePan" type="text" className="form-control" placeholder="Enter PAN number" onChange={handleChange} value={formData.employeePan} />
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Aadhar Number <span style={{ color: "red" }}>*</span></label>
//                                                 <input name="employeeAadhar" type="number" className={inputClass('employeeAadhar')} placeholder="Enter Aadhar number" onChange={handleChange} value={formData.employeeAadhar} />
//                                                 {validationErrors.employeeAadhar && <div className="invalid-feedback">{validationErrors.employeeAadhar}</div>}
//                                             </div>
//                                             <div className="form-row">
//                                                 <h6 className='fw-bold'>Current Address:-</h6>
//                                                 <div className="form-group col-md-12">
//                                                     <label>Address <span style={{ color: "red" }}>*</span></label>
//                                                     <textarea name="employeeAddress1" className={inputClass('employeeAddress1')} placeholder="Enter address" onChange={handleChange} value={formData.employeeAddress1} />
//                                                     {validationErrors.employeeAddress1 && <div className="invalid-feedback">{validationErrors.employeeAddress1}</div>}
//                                                 </div>
//                                                 <div className="form-group col-md-4">
//                                                     <label>State<span style={{ color: "red" }}>*</span></label>
//                                                     <select
//                                                         name="employeeState1"
//                                                         id="employeeState1"
//                                                         className={inputClass('employeeState1')}
//                                                         value={formData.employeeState1}
//                                                         onChange={handleChange}
//                                                         required
//                                                     >
//                                                         <option value="" disabled hidden>Select State</option>
//                                                         {states.map(employeeState1 => (
//                                                             <option key={employeeState1.id} value={`${employeeState1.statename}`}>{employeeState1.statename}</option>
//                                                         ))}
//                                                     </select>
//                                                     {validationErrors.employeeState1 && <div className="invalid-feedback">{validationErrors.employeeState1}</div>}
//                                                 </div>
//                                                 <div className="form-group col-md-4">
//                                                     <label>City <span style={{ color: "red" }}>*</span></label>
//                                                     <input name="employeeCity1" type="text" className={inputClass('employeeCity1')} placeholder="Enter city" onChange={handleChange} value={formData.employeeCity1} />
//                                                     {validationErrors.employeeCity1 && <div className="invalid-feedback">{validationErrors.employeeCity1}</div>}
//                                                 </div>

//                                                 <div className="form-group col-md-4">
//                                                     <label>Pincode</label>
//                                                     <input name="employeePincode1" type="text" className="form-control" placeholder="Enter pincode" onChange={handleChange} value={formData.employeePincode1} />
//                                                 </div>
//                                             </div>

//                                             <h6 className='fw-bold'>Permanent Address:-</h6>
//                                             <div className="form-group col-md-12">
//                                                 <label>Address <span style={{ color: "red" }}>*</span></label>
//                                                 <textarea name="employeeAddress2" className={inputClass('employeeAddress2')} placeholder="Enter address" onChange={handleChange} value={formData.employeeAddress2} />
//                                                 {validationErrors.employeeAddress2 && <div className="invalid-feedback">{validationErrors.employeeAddress2}</div>}
//                                             </div>
//                                             <div className="form-group col-md-4">
//                                                 <label>State<span style={{ color: "red" }}>*</span></label>
//                                                 <select
//                                                     name="employeeState2"
//                                                     id="employeeState2"
//                                                     className={inputClass('employeeState2')}
//                                                     value={formData.employeeState2}
//                                                     onChange={handleChange}
//                                                     required
//                                                 >
//                                                     <option value="" disabled hidden>Select State</option>
//                                                     {states.map(employeeState2 => (
//                                                         <option key={employeeState2.id} value={`${employeeState2.statename}`}>{employeeState2.statename}</option>
//                                                     ))}
//                                                 </select>
//                                                 {validationErrors.employeeState2 && <div className="invalid-feedback">{validationErrors.employeeState2}</div>}
//                                             </div>
//                                             <div className="form-group col-md-4">
//                                                 <label>City <span style={{ color: "red" }}>*</span></label>
//                                                 <input name="employeeCity2" type="text" className={inputClass('employeeCity2')} placeholder="Enter city" onChange={handleChange} value={formData.employeeCity2} />
//                                                 {validationErrors.employeeCity2 && <div className="invalid-feedback">{validationErrors.employeeCity2}</div>}
//                                             </div>

//                                             <div className="form-group col-md-4">
//                                                 <label>Pincode</label>
//                                                 <input name="employeePincode2" type="text" className="form-control" placeholder="Enter pincode" onChange={handleChange} value={formData.employeePincode2} />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 )}
//                                 {currentSection === 'accountDetails' && (
//                                     <div>
//                                         <h4 className='text-dark py-2 fw-bolder'>Account Details</h4>
//                                         <div className="form-row">
//                                             <div className="form-group col-md-6">
//                                                 <label>Account Holder Name</label>
//                                                 <input name="accountHolderName" type="text" className="form-control" placeholder="Enter account holder name" onChange={handleChange} value={formData.accountHolderName} />
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Account Number <span style={{ color: "red" }}>*</span></label>
//                                                 <input name="accountNumber" type="number" className={inputClass('accountNumber')} placeholder="Enter account number" onChange={handleChange} value={formData.accountNumber} />
//                                                 {validationErrors.accountNumber && <div className="invalid-feedback">{validationErrors.accountNumber}</div>}
//                                             </div>
//                                             <div className="form-group col-md-4">
//                                                 <label>Bank Name</label>
//                                                 <input name="bankName" type="text" className="form-control" placeholder="Enter bank name" onChange={handleChange} value={formData.bankName} />
//                                             </div>
//                                             <div className="form-group col-md-4">
//                                                 <label>IFSC Code <span style={{ color: "red" }}>*</span></label>
//                                                 <input name="ifscCode" type="text" className={inputClass('ifscCode')} placeholder="Enter IFSC code" onChange={handleChange} value={formData.ifscCode} />
//                                                 {validationErrors.ifscCode && <div className="invalid-feedback">{validationErrors.ifscCode}</div>}
//                                             </div>
//                                             <div className="form-group col-md-4">
//                                                 <label>Branch Name</label>
//                                                 <input name="branchName" type="text" className="form-control" placeholder="Enter branch name" onChange={handleChange} value={formData.branchName} />
//                                             </div>
//                                             <div className="form-group col-md-12">
//                                                 <label>PassBook / Check</label>
//                                                 <input
//                                                     name="passbook_check"
//                                                     type="file"
//                                                     className="form-control"
//                                                     onChange={handleChange}
//                                                 />

//                                             </div>
//                                         </div>
//                                     </div>
//                                 )}
//                                 {currentSection === 'jobDetails' && (
//                                     <div>
//                                         <h4 className='text-dark py-2 fw-bolder'>Job Details</h4>
//                                         <div className="form-row">
//                                             <div className="form-group col-md-6">
//                                                 <label>Department <span style={{ color: "red" }}>*</span></label>
//                                                 <select name="department" className={inputClass('department')} onChange={handleChange} value={formData.department}>
//                                                     <option value="">Select Department</option>
//                                                     {departments.map(department => (
//                                                         <option key={department.id} value={department.id}>{department.name}</option>
//                                                     ))}
//                                                 </select>
//                                                 {validationErrors.department && <div className="invalid-feedback">{validationErrors.department}</div>}
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Designation <span style={{ color: "red" }}>*</span></label>
//                                                 <select name="position" className={inputClass('position')} onChange={handleChange} value={formData.position}>
//                                                     <option value="">Select Designation</option>
//                                                     {filteredPositions.map(position => (
//                                                         <option key={position.id} value={position.id}>{position.positionName}</option>
//                                                     ))}
//                                                 </select>
//                                                 {validationErrors.position && <div className="invalid-feedback">{validationErrors.position}</div>}
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Joining Company<span style={{ color: "red" }}>*</span></label>
//                                                 <select
//                                                     name="joiningCompany"
//                                                     id="joiningCompany"
//                                                     className="form-control"
//                                                     value={formData.joiningCompany}
//                                                     onChange={handleChange}
//                                                     required
//                                                     placeholder="Joining Company"
//                                                 >
//                                                     <option value="" disabled hidden>Joining Company</option>
//                                                     {companies.map((company) => (
//                                                         <option key={company.id} value={company.companyName}>
//                                                             {company.companyName}
//                                                         </option>
//                                                     ))}
//                                                 </select>
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Joining Office<span style={{ color: "red" }}>*</span></label>
//                                                 <select
//                                                     name="joiningOffice"
//                                                     id="joiningOffice"
//                                                     className="form-control"
//                                                     value={formData.joiningOffice}
//                                                     onChange={handleChange}
//                                                     required
//                                                     placeholder="Joining Office"
//                                                 >
//                                                     <option value="" disabled hidden>Joining Office</option>
//                                                     {offices.map((office) => (
//                                                         <option key={office.id} value={office.officeName}>
//                                                             {office.officeName}
//                                                         </option>
//                                                     ))}
//                                                 </select>
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Employee Type</label>
//                                                 <select name="employeeType" className={inputClass('employeeType')} onChange={handleChange} value={formData.employeeType}>
//                                                     <option value="" disabled>Select Type</option>
//                                                     <option value="intern">Intern</option>
//                                                     <option value="contractual">Contractual</option>
//                                                     <option value="fulltime">Full Time</option>
//                                                 </select>
//                                             </div>

//                                             <div className="form-group col-md-6">
//                                                 <label>Joining Date <span style={{ color: "red" }}>*</span></label>
//                                                 <input name="joiningDate" type="date" className={inputClass('joiningDate')} onChange={handleChange} value={formData.joiningDate} />
//                                             </div>

//                                             {(formData.employeeType === 'intern' || formData.employeeType === 'contractual') && (
//                                                 <div className="form-group col-md-12">
//                                                     <label>Intern/Contractual End Date<span style={{ color: "red" }}>*</span></label>
//                                                     <input
//                                                         name="interncontractual"
//                                                         type="date"
//                                                         className="form-control"
//                                                         onChange={handleChange}
//                                                         value={formData.interncontractual}
//                                                     />
//                                                 </div>
//                                             )}
//                                         </div>
//                                     </div>
//                                 )}
//                                 {currentSection === 'otherDetails' && (
//                                     <div>
//                                         <h4 className='text-dark py-2 fw-bolder'>Family Details</h4>
//                                         <div className="form-row">
//                                             <div className="form-group col-md-6">
//                                                 <label>Father's Name</label>
//                                                 <input name="fatherName" type="text" className="form-control" placeholder="Enter father's name" onChange={handleChange} value={formData.fatherName} />
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Mother's Name</label>
//                                                 <input name="motherName" type="text" className="form-control" placeholder="Enter mother's name" onChange={handleChange} value={formData.motherName} />
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Marital Status</label>
//                                                 <select
//                                                     name="employeeMaritalStatus"
//                                                     className="form-control"
//                                                     onChange={handleChange}
//                                                     value={formData.employeeMaritalStatus}
//                                                 >
//                                                     <option value="">Select Status</option>
//                                                     <option value="single">Single</option>
//                                                     <option value="married">Married</option>
//                                                     <option value="divorced">Divorced</option>
//                                                     <option value="widowed">Widowed</option>
//                                                     {/* <option value="other">Other</option> */}
//                                                 </select>
//                                             </div>
//                                             {formData.employeeMaritalStatus === 'married' && (
//                                                 <>
//                                                     <div className="form-group col-md-6">
//                                                         <label>Wife Name</label>
//                                                         <input
//                                                             name="wifeName"
//                                                             type="text"
//                                                             placeholder='Wife Name'
//                                                             className="form-control"
//                                                             onChange={handleChange}
//                                                             value={formData.wifeName}
//                                                         />
//                                                     </div>
//                                                     <div className="form-group col-md-6">
//                                                         <label>Children Name/Age (Name-12,Name-11)</label>
//                                                         <input
//                                                             name="childrenAges"
//                                                             type="text"
//                                                             placeholder='Children Name&Age'
//                                                             className="form-control"
//                                                             onChange={handleChange}
//                                                             value={formData.childrenAges}
//                                                         />
//                                                     </div>
//                                                 </>
//                                             )}
//                                             <div className="form-group col-md-6">
//                                                 <label>Emergency Contact Person 1</label>
//                                                 <input name="emergencyContactPerson1" type="text" className="form-control" placeholder="Enter emergency contact person" onChange={handleChange} value={formData.emergencyContactPerson1} />
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Emergency Contact No. 1</label>
//                                                 <input name="emergencyContactNumber1" type="number" className="form-control" placeholder="Enter emergency contact relationship" onChange={handleChange} value={formData.emergencyContactNumber1} />
//                                             </div>

//                                             <div className="form-group col-md-6">
//                                                 <label>Emergency Contact Person 2</label>
//                                                 <input name="emergencyContactPerson2" type="text" className="form-control" placeholder="Enter alternate emergency contact person" onChange={handleChange} value={formData.emergencyContactPerson2} />
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Emergency Contact No. 2</label>
//                                                 <input name="emergencyContactNumber2" type="number" className="form-control" placeholder="Enter alternate emergency home phone number" onChange={handleChange} value={formData.emergencyContactNumber2} />
//                                             </div>

//                                         </div>
//                                     </div>
//                                 )}
//                                 {currentSection === 'documents' && (
//                                     <div>
//                                         <h4 className='text-dark py-2 fw-bolder'>Upload Documents</h4>
//                                         <div className="form-row">
//                                             <div className="form-group col-md-6">
//                                                 <label>Passport Size Photo <span style={{ color: "red" }}>*</span></label>
//                                                 <input
//                                                     name="passportSizePhoto"
//                                                     type="file"
//                                                     className={`form-control-file ${inputClass('passportSizePhoto')}`}
//                                                     onChange={handleChange}
//                                                 />
//                                                 {validationErrors.passportSizePhoto && <div className="invalid-feedback">{validationErrors.passportSizePhoto}</div>}
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>PAN Card Photo</label>
//                                                 <input
//                                                     name="panCardPhoto"
//                                                     type="file"
//                                                     className={`form-control-file ${inputClass('aadharCardPhoto')}`}
//                                                     onChange={handleChange}
//                                                 />
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Aadhar Card Photo <span style={{ color: "red" }}>*</span></label>
//                                                 <input
//                                                     name="aadharCardPhoto"
//                                                     type="file"
//                                                     className={`form-control-file ${inputClass('aadharCardPhoto')}`}
//                                                     onChange={handleChange}
//                                                 />
//                                                 {validationErrors.aadharCardPhoto && <div className="invalid-feedback">{validationErrors.aadharCardPhoto}</div>}
//                                             </div>

//                                             <div className="form-group col-md-6">
//                                                 <label>Driving Licence<span style={{ color: "red" }}>*</span></label>
//                                                 <input
//                                                     name="drivinglicense"
//                                                     type="file"
//                                                     className={`form-control-file ${inputClass('drivinglicense')}`}
//                                                     onChange={handleChange}
//                                                 />
//                                                 {validationErrors.drivinglicense && <div className="invalid-feedback">{validationErrors.drivinglicense}</div>}
//                                             </div>

//                                             <div className="form-group col-md-6">
//                                                 <label>Pass Port<span style={{ color: "red" }}>*</span></label>
//                                                 <input
//                                                     name="passport"
//                                                     type="file"
//                                                     className={`form-control-file ${inputClass('passport')}`}
//                                                     onChange={handleChange}
//                                                 />
//                                                 {validationErrors.passport && <div className="invalid-feedback">{validationErrors.passport}</div>}
//                                             </div>



//                                             <div className="form-group col-md-6">
//                                                 <label>10th Marksheet Photo</label>
//                                                 <input
//                                                     name="marksheet10thPhoto"
//                                                     type="file"
//                                                     className={`form-control-file ${inputClass('aadharCardPhoto')}`}
//                                                     onChange={handleChange}
//                                                 />
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>12th Marksheet Photo</label>
//                                                 <input
//                                                     name="marksheet12thPhoto"
//                                                     type="file"
//                                                     className={`form-control-file ${inputClass('aadharCardPhoto')}`}
//                                                     onChange={handleChange}
//                                                 />
//                                             </div>

//                                             <div className="form-group col-md-6">
//                                                 <label>Graduction Marksheet</label>
//                                                 <input
//                                                     name="graductionmarksheet"
//                                                     type="file"
//                                                     className={`form-control-file ${inputClass('aadharCardPhoto')}`}
//                                                     onChange={handleChange}
//                                                 />
//                                             </div>

//                                             <div className="form-group col-md-6">
//                                                 <label>Post Graduction Marksheet</label>
//                                                 <input
//                                                     name="postgraductionmarksheet"
//                                                     type="file"
//                                                     className={`form-control-file ${inputClass('aadharCardPhoto')}`}
//                                                     onChange={handleChange}
//                                                 />
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Professional Degree</label>
//                                                 <input
//                                                     name="professionaldegree"
//                                                     type="file"
//                                                     className={`form-control-file ${inputClass('aadharCardPhoto')}`}
//                                                     onChange={handleChange}
//                                                 />
//                                             </div>

//                                             <div className="form-group col-md-6">
//                                                 <label>Resume Photo <span style={{ color: "red" }}>*</span></label>
//                                                 <input
//                                                     name="resumePhoto"
//                                                     type="file"
//                                                     className={`form-control-file ${inputClass('resumePhoto')}`}
//                                                     onChange={handleChange}
//                                                 />
//                                                 {validationErrors.resumePhoto && <div className="invalid-feedback">{validationErrors.resumePhoto}</div>}
//                                             </div>

//                                             <div className="form-group col-md-6">
//                                                 <label>Offer Letter<span style={{ color: "red" }}>*</span></label>
//                                                 <input
//                                                     name="offerletter"
//                                                     type="file"
//                                                     className={`form-control-file ${inputClass('offerletter')}`}
//                                                     onChange={handleChange}
//                                                 />
//                                                 {validationErrors.offerletter && <div className="invalid-feedback">{validationErrors.offerletter}</div>}
//                                             </div>

//                                             <div className="form-group col-md-6">
//                                                 <label>Joining Letter<span style={{ color: "red" }}>*</span></label>
//                                                 <input
//                                                     name="joiningletter"
//                                                     type="file"
//                                                     className={`form-control-file ${inputClass('joiningletter')}`}
//                                                     onChange={handleChange}
//                                                 />
//                                                 {validationErrors.joiningletter && <div className="invalid-feedback">{validationErrors.joiningletter}</div>}
//                                             </div>

//                                             <div className="form-group col-md-6">
//                                                 <label>Appointment Letter<span style={{ color: "red" }}>*</span></label>
//                                                 <input
//                                                     name="appointmentletter"
//                                                     type="file"
//                                                     className={`form-control-file ${inputClass('appointmentletter')}`}
//                                                     onChange={handleChange}
//                                                 />
//                                                 {validationErrors.appointmentletter && <div className="invalid-feedback">{validationErrors.appointmentletter}</div>}
//                                             </div>

//                                             <div className="form-group col-md-6">
//                                                 <label>Employeement Letter<span style={{ color: "red" }}>*</span></label>
//                                                 <input
//                                                     name="employeementletter"
//                                                     type="file"
//                                                     className={`form-control-file ${inputClass('employeementletter')}`}
//                                                     onChange={handleChange}
//                                                 />
//                                                 {validationErrors.employeementletter && <div className="invalid-feedback">{validationErrors.employeementletter}</div>}
//                                             </div>

//                                             <div className="form-group col-md-6">
//                                                 <label>Experience Letter<span style={{ color: "red" }}>*</span></label>
//                                                 <input
//                                                     name="experienceletter"
//                                                     type="file"
//                                                     className={`form-control-file ${inputClass('experienceletter')}`}
//                                                     onChange={handleChange}
//                                                 />
//                                                 {validationErrors.experienceletter && <div className="invalid-feedback">{validationErrors.experienceletter}</div>}
//                                             </div>

//                                             <div className="form-group col-md-6">
//                                                 <label>Other Photo</label>
//                                                 <input
//                                                     name="otherPhoto"
//                                                     type="file"
//                                                     className={`form-control-file ${inputClass('aadharCardPhoto')}`}
//                                                     onChange={handleChange}
//                                                 />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//                         <div className="modal-footer">
//                             <button type="submit" className="btn btn-primary">Save</button>
//                             <button type="button" className="btn btn-default" onClick={onClose}>Close</button>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AddEmployeeModal;




import axios from 'axios';
import React, { useEffect, useState } from 'react';

const AddEmployeeModal = ({ onClose, onUpdate }) => {
    const [currentSection, setCurrentSection] = useState('basicInfo');
    const [formData, setFormData] = useState({
        employeeName: '',
        employeeCode: '',
        employeeEmail: '',
        employeePhone: '',
        employeeAltPhone: '',
        employeeDOB: '',
        employeeGender: '',
        employeeMaritalStatus: '',
        employeePan: '',
        employeeAadhar: '',
        employeeType: '',
        employeeAddress1: '',
        employeeCity1: '',
        employeeState1: '',
        employeePincode1: '',
        employeeAddress2: '',
        employeeCity2: '',
        employeeState2: '',
        employeePincode2: '',
        employeeBloodGroup: '',
        accountHolderName: '',
        accountNumber: '',
        bankName: '',
        ifscCode: '',
        branchName: '',
        basicSalary: '',
        vda: '',
        epf: '',
        esic: '',
        otherAllowances: '',
        grossSalary: '',
        department: '',
        departmentName: '',
        position: '',
        positionName: '',
        interncontractual: '',
        joiningDate: '',
        medical: false,
        travel: false,
        insurance: false,
        fatherName: '',
        motherName: '',
        emergencyContactName1: '',
        emergencyContactRelation1: '',
        emergencyContactNumber1: '',
        emergencyContactName2: '',
        emergencyContactRelation2: '',
        emergencyContactNumber2: '',
        // emergencyContactPerson1: '',
        // emergencyContactNumber1: '',
        // emergencyContactPerson2: '',
        // emergencyContactNumber2: '',
        panCardPhoto: null,
        aadharCardPhoto: null,
        passportSizePhoto: null,
        resumePhoto: null,
        otherPhoto: null,
        marksheet10thPhoto: null,
        marksheet12thPhoto: null,
        degreePhoto: null,
        drivinglicense: null,
        passport: null,
        graductionmarksheet: null,
        postgraductionmarksheet: null,
        professionaldegree: null,
        offerletter: null,
        joiningletter: null,
        appointmentletter: null,
        employeementletter: null,
        experienceletter: null,
        passbook_check: null,
        status: 'active',
        username: localStorage.getItem('username'),
        haveChildren: '',
        spouseName: '',
        children: [],
        childrenAges: [],
        joiningCompany: '',
        company_id: '',
        joiningOffice: '',
        office_id: '',

    });
    const [validationErrors, setValidationErrors] = useState({});
    const [states, setStates] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [offices, setOffices] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [positions, setPositions] = useState([]);
    const [filteredPositions, setFilteredPositions] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/states`);
                setStates(response.data);
            } catch (error) {
                console.error("Error fetching states:", error);
            }
        };

        const fetchCompanies = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/companies`);
                setCompanies(response.data);
            } catch (error) {
                console.error("Error fetching companies:", error);
            }
        };

        const fetchOffices = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/offices`);
                setOffices(response.data);
            } catch (error) {
                console.error("Error fetching offices:", error);
            }
        };

        const fetchDepartments = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/departments`);
                setDepartments(response.data);
            } catch (error) {
                console.error("Error fetching departments:", error);
            }
        };

        const fetchPositions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/positions`);
                setPositions(response.data);
            } catch (error) {
                console.error("Error fetching positions:", error);
            }
        };

        fetchStates();
        fetchCompanies();
        fetchOffices();
        fetchDepartments();
        fetchPositions();
    }, []);

    useEffect(() => {
        if (formData.department) {
            const filtered = positions.filter(position => position.department_id === parseInt(formData.department));
            setFilteredPositions(filtered);
        } else {
            setFilteredPositions([]);
        }
    }, [formData.department, positions]);

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        if (type === 'checkbox') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: checked,
            }));
        } else if (type === 'file') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: files[0],
            }));
        } else if (name === "joiningCompany") {
            const selectedCompany = companies.find(company => company.companyName === value);
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                company_id: selectedCompany ? selectedCompany.id : "",
            }));
        } else if (name === "joiningOffice") {
            const selectedOffice = offices.find(office => office.officeName === value);
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                office_id: selectedOffice ? selectedOffice.id : "",
            }));
        } else if (name === "department") {
            const selectedDepartment = departments.find(department => department.id === parseInt(value));
            setFormData((prevData) => ({
                ...prevData,
                department: value,
                departmentName: selectedDepartment ? selectedDepartment.name : "",
            }));
        } else if (name === "position") {
            const selectedPosition = filteredPositions.find(position => position.id === parseInt(value));
            setFormData((prevData) => ({
                ...prevData,
                position: value,
                positionName: selectedPosition ? selectedPosition.positionName : "",
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    // Upload images
    const handleImageChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];

        // Validation conditions
        const maxSize = 200 * 1024; // 200KB
        const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];

        if (file) {
            // Check file type
            if (!allowedTypes.includes(file.type)) {
                setValidationErrors(prevErrors => ({
                    ...prevErrors,
                    [name]: 'Only JPG, PNG, and PDF files are allowed.'
                }));
                return;
            }

            // Check file size
            if (file.size > maxSize) {
                setValidationErrors(prevErrors => ({
                    ...prevErrors,
                    [name]: 'File size should be less than or equal to 200KB.'
                }));
                return;
            }

            // Clear previous error if the file is valid
            setValidationErrors(prevErrors => ({
                ...prevErrors,
                [name]: ''
            }));
        }

        // Process the file as needed, e.g., set the file in state or form data
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: file
        }));
    };
    // Upload images 

    // Add Childrens 
    const handleChildrenChange = (index, e) => {
        const { name, value } = e.target;
        const updatedChildren = [...formData.children];
        updatedChildren[index] = { ...updatedChildren[index], [name]: value };
        setFormData((prevState) => ({
            ...prevState,
            children: updatedChildren
        }));
    };

    const addChild = () => {
        setFormData((prevState) => ({
            ...prevState,
            children: [...prevState.children, { name: '', dob: '' }]
        }));
    };

    const removeChild = (index) => {
        const updatedChildren = [...formData.children];
        updatedChildren.splice(index, 1);
        setFormData((prevState) => ({
            ...prevState,
            children: updatedChildren
        }));
    };

    // Add Childrens 

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = {};
        if (!formData.employeeName) errors.employeeName = 'Employee name is required';
        if (!formData.employeeCode) errors.employeeCode = 'Employee code is required';
        if (!formData.employeePhone) errors.employeePhone = 'Employee phone number is required';
        if (!formData.employeeDOB) errors.employeeDOB = 'Date of birth is required';
        if (!formData.employeeGender) errors.employeeGender = 'Gender is required';
        if (!formData.employeeAadhar) errors.employeeAadhar = 'Aadhar number is required';
        if (!formData.department) errors.department = 'Department is required';
        if (!formData.position) errors.position = 'Position is required';


        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }

        console.log('Form data:', formData);
        const formDataToSend = new FormData();
        Object.keys(formData).forEach(key => {
            if (key === 'children') {
                formDataToSend.append(key, JSON.stringify(formData[key]));
            } else {
                formDataToSend.append(key, formData[key]);
            }
        });

        try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/empdata`, formDataToSend);
            console.log('Data uploaded successfully:', response.data);

            onUpdate();
            // onClose();
            setTimeout(() => {
                onClose();
                window.location.reload();
            }, 1000); // 1 second delay
        } catch (error) {
            console.error('Error uploading data:', error);
        }
    };

    const inputClass = (field) => validationErrors[field] ? 'form-control is-invalid' : 'form-control';

    return (
        <div id="add" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Employee</h5>
                            <button type="button" className="close" onClick={onClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <div className="nav nav-tabs">
                                <button type="button" className={`nav-link px-2 ${currentSection === 'basicInfo' ? 'active' : ''}`} onClick={() => setCurrentSection('basicInfo')}>Basic Info</button>
                                <button type="button" className={`nav-link px-2 ${currentSection === 'jobDetails' ? 'active' : ''}`} onClick={() => setCurrentSection('jobDetails')}>Positional Info</button>
                                <button type="button" className={`nav-link px-2 ${currentSection === 'otherDetails' ? 'active' : ''}`} onClick={() => setCurrentSection('otherDetails')}>Family Info</button>
                                <button type="button" className={`nav-link px-2 ${currentSection === 'accountDetails' ? 'active' : ''}`} onClick={() => setCurrentSection('accountDetails')}>Account Details</button>
                                <button type="button" className={`nav-link px-2 ${currentSection === 'documents' ? 'active' : ''}`} onClick={() => setCurrentSection('documents')}>Uploads</button>
                            </div>
                            <div className="tab-content">
                                {currentSection === 'basicInfo' && (
                                    <div>
                                        <h4 className='text-dark py-2 fw-bolder'>Basic Info</h4>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>Employee Name <span style={{ color: "red" }}>*</span></label>
                                                <input name="employeeName" type="text" className={inputClass('employeeName')} placeholder="Enter Employee Name " onChange={handleChange} value={formData.employeeName} />
                                                {validationErrors.employeeName && <div className="invalid-feedback">{validationErrors.employeeName}</div>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Employee Code <span style={{ color: "red" }}>*</span></label>
                                                <input name="employeeCode" type="text" className={inputClass('employeeCode')} placeholder="Enter Employee Code" onChange={handleChange} value={formData.employeeCode} />
                                                {validationErrors.employeeCode && <div className="invalid-feedback">{validationErrors.employeeCode}</div>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Phone <span style={{ color: "red" }}>*</span></label>
                                                <input name="employeePhone" type="number" className={inputClass('employeePhone')} placeholder="Enter phone number" onChange={handleChange} value={formData.employeePhone} />
                                                {validationErrors.employeePhone && <div className="invalid-feedback">{validationErrors.employeePhone}</div>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Alternative Phone</label>
                                                <input name="employeeAltPhone" type="number" className="form-control" placeholder="Enter alternative phone number" onChange={handleChange} value={formData.employeeAltPhone} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Email</label>
                                                <input name="employeeEmail" type="email" className="form-control" placeholder="Enter email" onChange={handleChange} value={formData.employeeEmail} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Date of Birth <span style={{ color: "red" }}>*</span></label>
                                                <input name="employeeDOB" type="date" className={inputClass('employeeDOB')} placeholder="Enter date of birth" onChange={handleChange} value={formData.employeeDOB} />
                                                {validationErrors.employeeDOB && <div className="invalid-feedback">{validationErrors.employeeDOB}</div>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Gender <span style={{ color: "red" }}>*</span></label>
                                                <select name="employeeGender" className={inputClass('employeeGender')} onChange={handleChange} value={formData.employeeGender}>
                                                    <option value="">Select Gender</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                    <option value="other">Other</option>
                                                </select>
                                                {validationErrors.employeeGender && <div className="invalid-feedback">{validationErrors.employeeGender}</div>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Blood Group <span style={{ color: "red" }}>*</span></label>
                                                <select
                                                    name="employeeBloodGroup"
                                                    className={inputClass('employeeBloodGroup')}
                                                    onChange={handleChange}
                                                    value={formData.employeeBloodGroup}
                                                >
                                                    <option value="" disabled>Select Blood Group</option>
                                                    <option value="A+">A+</option>
                                                    <option value="A-">A-</option>
                                                    <option value="B+">B+</option>
                                                    <option value="B-">B-</option>
                                                    <option value="AB+">AB+</option>
                                                    <option value="AB-">AB-</option>
                                                    <option value="O+">O+</option>
                                                    <option value="O-">O-</option>
                                                </select>
                                                {validationErrors.employeeBloodGroup &&
                                                    <div className="invalid-feedback">
                                                        {validationErrors.employeeBloodGroup}
                                                    </div>
                                                }
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>PAN Number</label>
                                                <input name="employeePan" type="text" className="form-control" placeholder="Enter PAN number" onChange={handleChange} value={formData.employeePan} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Aadhar Number <span style={{ color: "red" }}>*</span></label>
                                                <input name="employeeAadhar" type="number" className={inputClass('employeeAadhar')} placeholder="Enter Aadhar number" onChange={handleChange} value={formData.employeeAadhar} />
                                                {validationErrors.employeeAadhar && <div className="invalid-feedback">{validationErrors.employeeAadhar}</div>}
                                            </div>
                                            <div className="form-row">
                                                <h6 className='fw-bold'>Current Address:-</h6>
                                                <div className="form-group col-md-12">
                                                    <label>Address <span style={{ color: "red" }}>*</span></label>
                                                    <textarea name="employeeAddress1" className={inputClass('employeeAddress1')} placeholder="Enter address" onChange={handleChange} value={formData.employeeAddress1} />
                                                    {validationErrors.employeeAddress1 && <div className="invalid-feedback">{validationErrors.employeeAddress1}</div>}
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label>State<span style={{ color: "red" }}>*</span></label>
                                                    <select
                                                        name="employeeState1"
                                                        id="employeeState1"
                                                        className={inputClass('employeeState1')}
                                                        value={formData.employeeState1}
                                                        onChange={handleChange}
                                                        required
                                                    >
                                                        <option value="" disabled hidden>Select State</option>
                                                        {states.map(employeeState1 => (
                                                            <option key={employeeState1.id} value={`${employeeState1.statename}`}>{employeeState1.statename}</option>
                                                        ))}
                                                    </select>
                                                    {validationErrors.employeeState1 && <div className="invalid-feedback">{validationErrors.employeeState1}</div>}
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label>City <span style={{ color: "red" }}>*</span></label>
                                                    <input name="employeeCity1" type="text" className={inputClass('employeeCity1')} placeholder="Enter city" onChange={handleChange} value={formData.employeeCity1} />
                                                    {validationErrors.employeeCity1 && <div className="invalid-feedback">{validationErrors.employeeCity1}</div>}
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label>Pincode</label>
                                                    <input name="employeePincode1" type="text" className="form-control" placeholder="Enter pincode" onChange={handleChange} value={formData.employeePincode1} />
                                                </div>
                                            </div>

                                            <h6 className='fw-bold'>Permanent Address:-</h6>
                                            <div className="form-group col-md-12">
                                                <label>Address <span style={{ color: "red" }}>*</span></label>
                                                <textarea name="employeeAddress2" className={inputClass('employeeAddress2')} placeholder="Enter address" onChange={handleChange} value={formData.employeeAddress2} />
                                                {validationErrors.employeeAddress2 && <div className="invalid-feedback">{validationErrors.employeeAddress2}</div>}
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>State<span style={{ color: "red" }}>*</span></label>
                                                <select
                                                    name="employeeState2"
                                                    id="employeeState2"
                                                    className={inputClass('employeeState2')}
                                                    value={formData.employeeState2}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="" disabled hidden>Select State</option>
                                                    {states.map(employeeState2 => (
                                                        <option key={employeeState2.id} value={`${employeeState2.statename}`}>{employeeState2.statename}</option>
                                                    ))}
                                                </select>
                                                {validationErrors.employeeState2 && <div className="invalid-feedback">{validationErrors.employeeState2}</div>}
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>City <span style={{ color: "red" }}>*</span></label>
                                                <input name="employeeCity2" type="text" className={inputClass('employeeCity2')} placeholder="Enter city" onChange={handleChange} value={formData.employeeCity2} />
                                                {validationErrors.employeeCity2 && <div className="invalid-feedback">{validationErrors.employeeCity2}</div>}
                                            </div>

                                            <div className="form-group col-md-4">
                                                <label>Pincode</label>
                                                <input name="employeePincode2" type="text" className="form-control" placeholder="Enter pincode" onChange={handleChange} value={formData.employeePincode2} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {currentSection === 'accountDetails' && (
                                    <div>
                                        <h4 className='text-dark py-2 fw-bolder'>Account Details</h4>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>Account Holder Name</label>
                                                <input name="accountHolderName" type="text" className="form-control" placeholder="Enter account holder name" onChange={handleChange} value={formData.accountHolderName} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Account Number <span style={{ color: "red" }}>*</span></label>
                                                <input name="accountNumber" type="number" className={inputClass('accountNumber')} placeholder="Enter account number" onChange={handleChange} value={formData.accountNumber} />
                                                {validationErrors.accountNumber && <div className="invalid-feedback">{validationErrors.accountNumber}</div>}
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>Bank Name</label>
                                                <input name="bankName" type="text" className="form-control" placeholder="Enter bank name" onChange={handleChange} value={formData.bankName} />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>IFSC Code <span style={{ color: "red" }}>*</span></label>
                                                <input name="ifscCode" type="text" className={inputClass('ifscCode')} placeholder="Enter IFSC code" onChange={handleChange} value={formData.ifscCode} />
                                                {validationErrors.ifscCode && <div className="invalid-feedback">{validationErrors.ifscCode}</div>}
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>Branch Name</label>
                                                <input name="branchName" type="text" className="form-control" placeholder="Enter branch name" onChange={handleChange} value={formData.branchName} />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>PassBook / Check</label>
                                                <input
                                                    name="passbook_check"
                                                    type="file"
                                                    className={`form-control-file ${inputClass('passbook_check')}`}
                                                    onChange={handleImageChange}
                                                />
                                                {validationErrors.passbook_check && <div className="invalid-feedback">{validationErrors.passbook_check}</div>}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {currentSection === 'jobDetails' && (
                                    <div>
                                        <h4 className='text-dark py-2 fw-bolder'>Job Details</h4>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>Department <span style={{ color: "red" }}>*</span></label>
                                                <select name="department" className={inputClass('department')} onChange={handleChange} value={formData.department}>
                                                    <option value="">Select Department</option>
                                                    {departments.map(department => (
                                                        <option key={department.id} value={department.id}>{department.name}</option>
                                                    ))}
                                                </select>
                                                {validationErrors.department && <div className="invalid-feedback">{validationErrors.department}</div>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Designation <span style={{ color: "red" }}>*</span></label>
                                                <select name="position" className={inputClass('position')} onChange={handleChange} value={formData.position}>
                                                    <option value="">Select Designation</option>
                                                    {filteredPositions.map(position => (
                                                        <option key={position.id} value={position.id}>{position.positionName}</option>
                                                    ))}
                                                </select>
                                                {validationErrors.position && <div className="invalid-feedback">{validationErrors.position}</div>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Joining Company<span style={{ color: "red" }}>*</span></label>
                                                <select
                                                    name="joiningCompany"
                                                    id="joiningCompany"
                                                    className="form-control"
                                                    value={formData.joiningCompany}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Joining Company"
                                                >
                                                    <option value="" disabled hidden>Joining Company</option>
                                                    {companies.map((company) => (
                                                        <option key={company.id} value={company.companyName}>
                                                            {company.companyName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Joining Office<span style={{ color: "red" }}>*</span></label>
                                                <select
                                                    name="joiningOffice"
                                                    id="joiningOffice"
                                                    className="form-control"
                                                    value={formData.joiningOffice}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Joining Office"
                                                >
                                                    <option value="" disabled hidden>Joining Office</option>
                                                    {offices.map((office) => (
                                                        <option key={office.id} value={office.officeName}>
                                                            {office.officeName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Employee Type</label>
                                                <select name="employeeType" className={inputClass('employeeType')} onChange={handleChange} value={formData.employeeType}>
                                                    <option value="" disabled>Select Type</option>
                                                    <option value="intern">Intern</option>
                                                    <option value="contractual">Contractual</option>
                                                    <option value="fulltime">Full Time</option>
                                                </select>
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label>Joining Date <span style={{ color: "red" }}>*</span></label>
                                                <input name="joiningDate" type="date" className={inputClass('joiningDate')} onChange={handleChange} value={formData.joiningDate} />
                                            </div>

                                            {(formData.employeeType === 'intern' || formData.employeeType === 'contractual') && (
                                                <div className="form-group col-md-12">
                                                    <label>Intern/Contractual End Date<span style={{ color: "red" }}>*</span></label>
                                                    <input
                                                        name="interncontractual"
                                                        type="date"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        value={formData.interncontractual}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {currentSection === 'otherDetails' && (
                                    <div>
                                        <h4 className='text-dark py-2 fw-bolder'>Family Details</h4>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>Father's Name</label>
                                                <input name="fatherName" type="text" className="form-control" placeholder="Enter father's name" onChange={handleChange} value={formData.fatherName} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Mother's Name</label>
                                                <input name="motherName" type="text" className="form-control" placeholder="Enter mother's name" onChange={handleChange} value={formData.motherName} />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Marital Status</label>
                                                <select
                                                    name="employeeMaritalStatus"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    value={formData.employeeMaritalStatus}
                                                >
                                                    <option value="">Select Status</option>
                                                    <option value="single">Single</option>
                                                    <option value="married">Married</option>
                                                    <option value="divorced">Divorced</option>
                                                    <option value="widowed">Widowed</option>
                                                </select>
                                            </div>


                                            {(formData.employeeMaritalStatus === 'married') && (
                                                <div className="form-group col-md-12">
                                                    <label>Spouse Name</label>
                                                    <input
                                                        name="spouseName"
                                                        type="text"
                                                        placeholder="Spouse Name"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        value={formData.spouseName}
                                                    />
                                                </div>
                                            )}


                                            <div className="form-group col-md-4">
                                                <label>Emergency Call Name (1)</label>
                                                <input
                                                    name="emergencyContactName1"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter emergency contact name"
                                                    onChange={handleChange}
                                                    value={formData.emergencyContactName1}
                                                />
                                            </div>

                                            <div className="form-group col-md-4">
                                                <label>Relation (1)</label>
                                                <select
                                                    name="emergencyContactRelation1"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    value={formData.emergencyContactRelation1}
                                                >
                                                    <option value="">Select relation</option>
                                                    <option value="Father">Father</option>
                                                    <option value="Mother">Mother</option>
                                                    <option value="Brother">Brother</option>
                                                    <option value="Sister">Sister</option>
                                                    <option value="Uncle">Uncle</option>
                                                    <option value="Aunt">Aunt</option>
                                                    <option value="Cousin">Cousin</option>
                                                    <option value="Friend">Friend</option>
                                                    {/* <!-- Add more options as needed --> */}
                                                </select>
                                            </div>

                                            <div className="form-group col-md-4">
                                                <label>Emergency call Number (1)</label>
                                                <input
                                                    name="emergencyContactNumber1"
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Enter emergency contact phone number"
                                                    onChange={handleChange}
                                                    value={formData.emergencyContactNumber1}
                                                />
                                            </div>

                                            <div className="form-group col-md-4">
                                                <label>Emergency Call Name (2)</label>
                                                <input
                                                    name="emergencyContactName2"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter alternate emergency contact name"
                                                    onChange={handleChange}
                                                    value={formData.emergencyContactName2}
                                                />
                                            </div>

                                            <div className="form-group col-md-4">
                                                <label>Relation (2)</label>
                                                <select
                                                    name="emergencyContactRelation2"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    value={formData.emergencyContactRelation2}
                                                >
                                                    <option value="">Select relation</option>
                                                    <option value="Father">Father</option>
                                                    <option value="Mother">Mother</option>
                                                    <option value="Brother">Brother</option>
                                                    <option value="Sister">Sister</option>
                                                    <option value="Uncle">Uncle</option>
                                                    <option value="Aunt">Aunt</option>
                                                    <option value="Cousin">Cousin</option>
                                                    <option value="Friend">Friend</option>
                                                    {/* <!-- Add more options as needed --> */}
                                                </select>
                                            </div>

                                            <div className="form-group col-md-4">
                                                <label>Emergency Call Number (2)</label>
                                                <input
                                                    name="emergencyContactNumber2"
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Enter alternate emergency contact phone number"
                                                    onChange={handleChange}
                                                    value={formData.emergencyContactNumber2}
                                                />
                                            </div>

                                            <div className="form-group col-md-12">
                                                {(formData.employeeMaritalStatus === 'married' ||
                                                    formData.employeeMaritalStatus === 'divorced' ||
                                                    formData.employeeMaritalStatus === 'widowed') && (
                                                        <>
                                                            <div className="">
                                                                <label>Have Children?</label>
                                                                <select
                                                                    name="haveChildren"
                                                                    className="form-control"
                                                                    onChange={handleChange}
                                                                    value={formData.haveChildren}
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option value="yes">Yes</option>
                                                                    <option value="no">No</option>
                                                                </select>
                                                            </div>


                                                            {formData.haveChildren === 'yes' && (
                                                                <div className=''>
                                                                    {formData.children.map((child, index) => (
                                                                        <div className="form-row " key={index}>
                                                                            <div className="form-group col-md-4">
                                                                                <label>Child {index + 1} Name</label>
                                                                                <input
                                                                                    name="name"
                                                                                    type="text"
                                                                                    placeholder="Child Name"
                                                                                    className="form-control"
                                                                                    onChange={(e) => handleChildrenChange(index, e)}
                                                                                    value={child.name}
                                                                                />
                                                                            </div>

                                                                            <div className="form-group col-md-4">
                                                                                <label>Child {index + 1} Date of Birth</label>
                                                                                <input
                                                                                    name="dob"
                                                                                    type="date"
                                                                                    className="form-control"
                                                                                    onChange={(e) => handleChildrenChange(index, e)}
                                                                                    value={child.dob}
                                                                                />
                                                                            </div>
                                                                            {index > 0 && (
                                                                                <div className="form-group col-md-2 d-flex align-items-end">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-danger"
                                                                                        onClick={() => removeChild(index)}
                                                                                    >
                                                                                        - Remove
                                                                                    </button>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                    <button className='btn btn-success' type="button" onClick={addChild}>Add </button>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {currentSection === 'documents' && (
                                    <div>
                                        <h4 className='text-dark py-2 fw-bolder'>Upload Documents</h4>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>Passport Size Photo <span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    name="passportSizePhoto"
                                                    type="file"
                                                    className={`form-control-file ${inputClass('passportSizePhoto')}`}
                                                    onChange={handleImageChange}
                                                />
                                                {validationErrors.passportSizePhoto && <div className="invalid-feedback">{validationErrors.passportSizePhoto}</div>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>PAN Card</label>
                                                <input
                                                    name="panCardPhoto"
                                                    type="file"
                                                    className={`form-control-file ${inputClass('panCardPhoto')}`}
                                                    onChange={handleImageChange}
                                                />
                                                {validationErrors.panCardPhoto && <div className="invalid-feedback">{validationErrors.panCardPhoto}</div>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Aadhar Card<span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    name="aadharCardPhoto"
                                                    type="file"
                                                    className={`form-control-file ${inputClass('aadharCardPhoto')}`}
                                                    onChange={handleImageChange}
                                                />
                                                {validationErrors.aadharCardPhoto && <div className="invalid-feedback">{validationErrors.aadharCardPhoto}</div>}
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label>Driving Licence<span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    name="drivinglicense"
                                                    type="file"
                                                    className={`form-control-file ${inputClass('drivinglicense')}`}
                                                    onChange={handleImageChange}
                                                />
                                                {validationErrors.drivinglicense && <div className="invalid-feedback">{validationErrors.drivinglicense}</div>}
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label>Pass Port<span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    name="passport"
                                                    type="file"
                                                    className={`form-control-file ${inputClass('passport')}`}
                                                    onChange={handleImageChange}
                                                />
                                                {validationErrors.passport && <div className="invalid-feedback">{validationErrors.passport}</div>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>10th Marksheet</label>
                                                <input
                                                    name="marksheet10thPhoto"
                                                    type="file"
                                                    className={`form-control-file ${inputClass('marksheet10thPhoto')}`}
                                                    onChange={handleImageChange}
                                                />
                                                {validationErrors.marksheet10thPhoto && <div className="invalid-feedback">{validationErrors.marksheet10thPhoto}</div>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>12th Marksheet</label>
                                                <input
                                                    name="marksheet12thPhoto"
                                                    type="file"
                                                    className={`form-control-file ${inputClass('marksheet12thPhoto')}`}
                                                    onChange={handleImageChange}
                                                />
                                                {validationErrors.marksheet12thPhoto && <div className="invalid-feedback">{validationErrors.marksheet12thPhoto}</div>}
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label>Graduction Marksheet</label>
                                                <input
                                                    name="graductionmarksheet"
                                                    type="file"
                                                    className={`form-control-file ${inputClass('graductionmarksheet')}`}
                                                    onChange={handleImageChange}
                                                />
                                                {validationErrors.graductionmarksheet && <div className="invalid-feedback">{validationErrors.graductionmarksheet}</div>}
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label>Post Graduction Marksheet</label>
                                                <input
                                                    name="postgraductionmarksheet"
                                                    type="file"
                                                    className={`form-control-file ${inputClass('postgraductionmarksheet')}`}
                                                    onChange={handleImageChange}
                                                />
                                                {validationErrors.postgraductionmarksheet && <div className="invalid-feedback">{validationErrors.postgraductionmarksheet}</div>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Professional Degree</label>
                                                <input
                                                    name="professionaldegree"
                                                    type="file"
                                                    className={`form-control-file ${inputClass('professionaldegree')}`}
                                                    onChange={handleImageChange}
                                                />
                                                {validationErrors.professionaldegree && <div className="invalid-feedback">{validationErrors.professionaldegree}</div>}
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label>Resume<span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    name="resumePhoto"
                                                    type="file"
                                                    className={`form-control-file ${inputClass('resumePhoto')}`}
                                                    onChange={handleImageChange}
                                                />
                                                {validationErrors.resumePhoto && <div className="invalid-feedback">{validationErrors.resumePhoto}</div>}
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label>Offer Letter<span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    name="offerletter"
                                                    type="file"
                                                    className={`form-control-file ${inputClass('offerletter')}`}
                                                    onChange={handleImageChange}
                                                />
                                                {validationErrors.offerletter && <div className="invalid-feedback">{validationErrors.offerletter}</div>}
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label>Joining Letter<span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    name="joiningletter"
                                                    type="file"
                                                    className={`form-control-file ${inputClass('joiningletter')}`}
                                                    onChange={handleImageChange}
                                                />
                                                {validationErrors.joiningletter && <div className="invalid-feedback">{validationErrors.joiningletter}</div>}
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label>Appointment Letter<span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    name="appointmentletter"
                                                    type="file"
                                                    className={`form-control-file ${inputClass('appointmentletter')}`}
                                                    onChange={handleImageChange}
                                                />
                                                {validationErrors.appointmentletter && <div className="invalid-feedback">{validationErrors.appointmentletter}</div>}
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label>Employeement Letter<span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    name="employeementletter"
                                                    type="file"
                                                    className={`form-control-file ${inputClass('employeementletter')}`}
                                                    onChange={handleImageChange}
                                                />
                                                {validationErrors.employeementletter && <div className="invalid-feedback">{validationErrors.employeementletter}</div>}
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label>Experience Letter<span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    name="experienceletter"
                                                    type="file"
                                                    className={`form-control-file ${inputClass('experienceletter')}`}
                                                    onChange={handleImageChange}
                                                />
                                                {validationErrors.experienceletter && <div className="invalid-feedback">{validationErrors.experienceletter}</div>}
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label>Other Photo</label>
                                                <input
                                                    name="otherPhoto"
                                                    type="file"
                                                    className={`form-control-file ${inputClass('otherPhoto')}`}
                                                    onChange={handleImageChange}
                                                />
                                                {validationErrors.otherPhoto && <div className="invalid-feedback">{validationErrors.otherPhoto}</div>}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary">Save</button>
                            <button type="button" className="btn btn-default" onClick={onClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddEmployeeModal;
