
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import SearchBar from '../../components/sidebar/SearchBar';
// import Sidebar from '../../components/sidebar/Sidebar';
// import AddLoanRepayment from './AddLoanRepayment'; // Import the AddLoanRepayment modal
// import AddLoan from './AddLoan';


// const LoanList = ({ handleLogout, username }) => {
//     const [employees, setEmployees] = useState([]);
//     const [selectedEmployee, setSelectedEmployee] = useState('');
//     const [employeeAndLoanDetails, setEmployeeAndLoanDetails] = useState(null);
//     const [repaymentDetails, setRepaymentDetails] = useState([]);

//     // State for AddLoanRepayment modal
//     const [showAddRepaymentModal, setShowAddRepaymentModal] = useState(false);
//     const [selectedLoan, setSelectedLoan] = useState(null);
//     // Add Loan 
//     const [isAddLoanModalOpen, setIsAddLoanModalOpen] = useState(false);


//     useEffect(() => {
//         fetchLoanEmployees();
//     }, []);

//     // Function to fetch all employees with loan details
//     const fetchLoanEmployees = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/loanDetails`);
//             console.log('Fetched loan employees:', response.data); // Log fetched employees
//             setEmployees(response.data);
//         } catch (error) {
//             console.error('Error fetching loan employees:', error);
//         }
//     };

//     // Function to fetch repayment details for a specific employee
//     const fetchRepaymentDetails = async (employeeId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/repaymentdetails/${employeeId}`);
//             console.log('Fetched loan Repayment Details :', response.data); // Log fetched Repayment Details
//             setRepaymentDetails(response.data);
//         } catch (error) {
//             console.error('Error fetching loan Repayment Details:', error);
//         }
//     };

//     // Function to fetch specific employee and their loan details
//     const fetchEmployeeAndLoanDetails = async (employeeId) => {
//         try {
//             const [employeeResponse, loanResponse] = await Promise.all([
//                 axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`),
//                 axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/loandetails/${employeeId}`)
//             ]);

//             console.log('Fetched employee details:', employeeResponse.data); // Log fetched employee details
//             console.log('Fetched loan details:', loanResponse.data); // Log fetched loan details

//             // Combine employee and loan details into one object
//             const combinedData = {
//                 employee: employeeResponse.data.length > 0 ? employeeResponse.data[0] : null,
//                 loans: loanResponse.data
//             };

//             setEmployeeAndLoanDetails(combinedData); // Set state with combined data
//         } catch (error) {
//             console.error('Error fetching employee and loan details:', error);
//         }
//     };

//     // useEffect to fetch employee and loan details when selectedEmployee changes
//     useEffect(() => {
//         if (selectedEmployee) {
//             fetchEmployeeAndLoanDetails(selectedEmployee);
//             fetchRepaymentDetails(selectedEmployee);
//         }
//     }, [selectedEmployee]);

//     // Function to handle opening AddLoanRepayment modal
//     const handleAddRepayment = (loan) => {
//         setSelectedLoan(loan);
//         setShowAddRepaymentModal(true);
//     };
//     // Add Loan Modal 

//     const handleAddLoanModal = () => {
//         setIsAddLoanModalOpen(true);
//     };

//     const handleCloseLoanModal = () => {
//         setIsAddLoanModalOpen(false);
//     };
//     const handleUpdate=()=>{
//         toast.success("successfully uploaded");
//     }


//     return (
//         <div className='d-flex w-100 h-100'>
//             <Sidebar />
//             <div className='w-100'>
//                 <SearchBar username={username} handleLogout={handleLogout} />
//                 <div className="container-fluid">
//                     <ToastContainer />
//                     <div className="row">
//                         <div className="col-sm-12 col-md-12">
//                             <div className="panel panel-bd">
//                                 <div className="panel-heading">
//                                     <div className="panel-title d-flex align-items-center justify-content-between p-1">
//                                         <h4 className='p-1 text-black fw-bolder'>Loan List</h4>
//                                         <button onClick={handleAddLoanModal} className="btn btn-outline-primary">
//                                             <i className="fa fa-plus"></i> Add Loan
//                                         </button>
//                                     </div>
//                                 </div>
//                                 <div className="panel-body shadow-sm bg-white rounded rounded p-2" style={{ maxHeight: "calc(100vh - 140px)", overflowY: "auto", overflowX: "hidden" }}>
//                                     <div className="form-row bg-light rounded p-2 ">
//                                         <div className='form-group col-md-4'>
//                                             <div className='bg-white rounded p-1 shadow-sm' >
//                                                 <div style={{ maxHeight: "350px", overflowY: "auto" }}>
//                                                     <table className='table table-bordered'>
//                                                         <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
//                                                             <tr>
//                                                                 <th>Employee Names</th>
//                                                             </tr>
//                                                         </thead>
//                                                         <tbody>
//                                                             {employees.map(employee => (
//                                                                 <tr key={employee.employeeId} onClick={() => setSelectedEmployee(employee.employeeId)}>
//                                                                     <td>{employee.employeeName}</td>
//                                                                 </tr>
//                                                             ))}
//                                                         </tbody>
//                                                     </table>
//                                                 </div>
//                                             </div>

//                                         </div>
//                                         <div className="form-group col-md-8 rounded bg-white shadow-sm">
//                                             {employeeAndLoanDetails && employeeAndLoanDetails.loans && employeeAndLoanDetails.loans.length > 0 ? (
//                                                 <div className='' style={{ maxHeight: "350px", overflowY: "auto", overflowX: "hidden" }}>
//                                                     <div className='p-1'>
//                                                         {employeeAndLoanDetails.loans.map((loan, index) => (
//                                                             <div className='bg-light rounded d-flex justify-content-around p-2 border m-1' key={index}>
//                                                                 <div className='w-75'>
//                                                                     <h5 className='text-dark fw-bold'> Loan Number: <span className='text-dark fw-normal'> {loan.loanNumber} </span></h5>
//                                                                     <div className='d-flex align-items-center justify-content-between p-2 '>
//                                                                         <div>
//                                                                             <h6>Department: {employeeAndLoanDetails.employee.departmentName}</h6>
//                                                                             <h6>Name: {employeeAndLoanDetails.employee.employeeName}</h6>
//                                                                         </div>
//                                                                         <div>
//                                                                             <h6>Loan Number: {loan.loanNumber}</h6>
//                                                                             <h6>Loan Amount: {loan.loanAmount}</h6>
//                                                                         </div>
//                                                                         <div>
//                                                                             <h6>Loan Date: {new Date(loan.loanDate).toLocaleDateString()}</h6>
//                                                                             <h6>Loan Type: {loan.loanFor}</h6>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                                 <div className='d-flex flex-column gap-2'>
//                                                                     <button className="btn btn-outline-primary">View</button>
//                                                                     <button className="btn btn-outline-success" onClick={() => handleAddRepayment(loan)}>Add Repayment</button>
//                                                                     <button className="btn btn-outline-primary" >View Repayment</button>
//                                                                 </div>
//                                                             </div>
//                                                         ))}
//                                                     </div>
//                                                 </div>
//                                             ) : (
//                                                 <h4 className='text-center p-3'>No loan details found for this employee.</h4>
//                                             )}
//                                         </div>
//                                     </div>
//                                     <div className='row mt-4 bg-white rounded m-1 '>
//                                         <div className='col-sm-12 col-md-12 '>
//                                             <div className='panel-heading'>
//                                                 <div className='panel-title'>
//                                                     <h5 className='p-2 text-black fw-bolder'>Repayment List</h5>
//                                                 </div>
//                                             </div>
//                                             <div className='panel-body' style={{ maxHeight: "250px", overflowY: "auto" }}>
//                                                 {repaymentDetails.length > 0 ? (
//                                                     <table className='table table-bordered'>
//                                                         <thead>
//                                                             <tr>
//                                                                 <th>Employee Name</th>
//                                                                 <th>Loan Number</th>
//                                                                 <th>Loan Amount</th>
//                                                                 <th>Repayment Amount</th>
//                                                                 <th>Repayment Date</th>
//                                                                 <th>Repayment mode</th>
//                                                             </tr>
//                                                         </thead>
//                                                         <tbody>
//                                                             {repaymentDetails.map(record => (
//                                                                 <tr key={record.id}>
//                                                                     <td className='text-capitalize'>{record.employeeName}</td>
//                                                                     <td>{record.loanNumber}</td>
//                                                                     <td>{record.loanAmount}</td>
//                                                                     <td>{record.repaymentAmount.toFixed(2)}</td>
//                                                                     <td>{new Date(record.repaymentDate).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</td>
//                                                                     <td>{record.repaymentMode}</td>
//                                                                 </tr>
//                                                             ))}
//                                                         </tbody>
//                                                     </table>
//                                                 ) : (
//                                                     <p>No repayment records found for this employee.</p>
//                                                 )}
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             {/* AddLoanRepayment modal */}
//             {showAddRepaymentModal && selectedLoan && (
//                 <AddLoanRepayment
//                     onClose={() => setShowAddRepaymentModal(false)}
//                     onUpdate={() => fetchEmployeeAndLoanDetails(selectedEmployee)}
//                     employee={employeeAndLoanDetails.employee}
//                     loan={selectedLoan}
//                 />
//             )}
//             {isAddLoanModalOpen && <AddLoan onClose={handleCloseLoanModal} onUpdate={handleUpdate} />}
//         </div>
//     );
// };

// export default LoanList;



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchBar from '../../components/sidebar/SearchBar';
import Sidebar from '../../components/sidebar/Sidebar';
import AddLoanRepayment from './AddLoanRepayment'; // Import the AddLoanRepayment modal
import AddLoan from './AddLoan';
import LoanDetailsModal from './LoanDetailsModal';


const LoanList = ({ handleLogout, username }) => {
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [employeeAndLoanDetails, setEmployeeAndLoanDetails] = useState(null);
    const [repaymentDetails, setRepaymentDetails] = useState([]);
    const [selectedLoanForRepayment, setSelectedLoanForRepayment] = useState(null);

    // State for AddLoanRepayment modal
    const [showAddRepaymentModal, setShowAddRepaymentModal] = useState(false);
    const [selectedLoan, setSelectedLoan] = useState(null);
    // View Loan Details 
    const [showLoanDetailsModal, setShowLoanDetailsModal] = useState(false);
    const [selectedViewLoan, setSelectedViewLoan] = useState(null);
    const [isAddLoanModalOpen, setIsAddLoanModalOpen] = useState(false);


    useEffect(() => {
        fetchLoanEmployees();
    }, []);

    // Function to fetch all employees with loan details
    const fetchLoanEmployees = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/loanDetails`);
            console.log('Fetched loan employees:', response.data); // Log fetched employees
            setEmployees(response.data);
        } catch (error) {
            console.error('Error fetching loan employees:', error);
        }
    };

    // Function to fetch repayment details for a specific loan
    const fetchRepaymentDetails = async (loanId) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/repaymentdetailsHistory/${loanId}`);
          console.log('Fetched loan Repayment Details :', response.data);
          setRepaymentDetails(response.data);
        } catch (error) {
          console.error('Error fetching loan Repayment Details:', error);
        }
      };

    // Function to fetch specific employee and their loan details
    const fetchEmployeeAndLoanDetails = async (employeeId) => {
        try {
            const [employeeResponse, loanResponse] = await Promise.all([
                axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`),
                axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/loandetails/${employeeId}`)
            ]);

            console.log('Fetched employee details:', employeeResponse.data); // Log fetched employee details
            console.log('Fetched loan details:', loanResponse.data); // Log fetched loan details

            // Combine employee and loan details into one object
            const combinedData = {
                employee: employeeResponse.data.length > 0 ? employeeResponse.data[0] : null,
                loans: loanResponse.data
            };

            setEmployeeAndLoanDetails(combinedData); // Set state with combined data
        } catch (error) {
            console.error('Error fetching employee and loan details:', error);
        }
    };

    // useEffect to fetch employee and loan details when selectedEmployee changes
    useEffect(() => {
        if (selectedEmployee) {
            fetchEmployeeAndLoanDetails(selectedEmployee);
            fetchRepaymentDetails(selectedEmployee);
        }
    }, [selectedEmployee]);

    // Function to handle opening AddLoanRepayment modal
    const handleAddRepayment = (loan) => {
        setSelectedLoan(loan);
        setShowAddRepaymentModal(true);
    };

    // Function to handle opening AddLoanRepayment modal
    const handleViewLoanDetails = (loan) => {
        setSelectedViewLoan(loan);
        setShowLoanDetailsModal(true);
    };

    // Function to handle viewing repayment details for a specific loan
        const handleViewRepayment = (loan) => {
            setSelectedLoanForRepayment(loan.id);
            fetchRepaymentDetails(loan.id);
        };


    // Add Loan Modal 
    const handleAddLoanModal = () => {
        setIsAddLoanModalOpen(true);
    };

    const handleCloseLoanModal = () => {
        setIsAddLoanModalOpen(false);
    };
    const handleUpdate = () => {
        toast.success("Successfully uploaded");
        fetchEmployeeAndLoanDetails(selectedEmployee);
        fetchRepaymentDetails(selectedLoanForRepayment);
    };

    const totalRepaymentAmount = repaymentDetails.reduce((total, repayment) => total + repayment.repaymentAmount, 0);

    return (
        <div className='d-flex w-100 h-100'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    <div className="row shadow-sm bg-white rounded py-2">
                        <div className="col-sm-12 col-md-12">
                            <div className="panel panel-bd">
                                <div className="panel-heading">
                                    <div className="panel-title d-flex align-items-center justify-content-between p-1">
                                        <h4 className='p-1 text-black fw-bolder'>Loan List</h4>
                                        <button onClick={handleAddLoanModal} className="btn btn-outline-primary">
                                            <i className="fa fa-plus"></i> Add Loan
                                        </button>
                                    </div>
                                </div>
                                <div className="panel-body shadow-sm bg-white rounded rounded p-2" style={{ maxHeight: "calc(100vh - 140px)", overflowY: "auto", overflowX: "hidden" }}>
                                    <div className="form-row bg-light rounded p-2 ">
                                        <div className='form-group col-md-4'>
                                            <div className='bg-white rounded p-1 shadow-sm' >
                                                <div style={{ maxHeight: "350px", overflowY: "auto" }}>
                                                    <table className='table table-bordered'>
                                                        <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                            <tr>
                                                                <th>Employee Names</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {employees.map(employee => (
                                                                <tr key={employee.employeeId} onClick={() => setSelectedEmployee(employee.employeeId)}>
                                                                    <td>{employee.employeeName}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="form-group col-md-8 rounded bg-white shadow-sm">
                                            {employeeAndLoanDetails && employeeAndLoanDetails.loans && employeeAndLoanDetails.loans.length > 0 ? (
                                                <div className='' style={{ maxHeight: "350px", overflowY: "auto", overflowX: "hidden" }}>
                                                    <div className='p-1'>
                                                        {employeeAndLoanDetails.loans.map((loan, index) => (
                                                            <div className='bg-light rounded d-flex justify-content-around p-2 border m-1' key={index}>
                                                                <div className='w-75'>
                                                                    <h5 className='text-dark fw-bold'> Loan Number: <span className='text-dark fw-normal'> {loan.loanNumber} </span></h5>
                                                                    <div className='d-flex align-items-center justify-content-between p-2 '>
                                                                        <div>
                                                                            <h6>Department: {employeeAndLoanDetails.employee.departmentName}</h6>
                                                                            <h6>Name: {employeeAndLoanDetails.employee.employeeName}</h6>
                                                                        </div>
                                                                        <div>
                                                                            <h6>Loan Number: {loan.loanNumber}</h6>
                                                                            <h6>Loan Amount: {loan.loanAmount}</h6>
                                                                        </div>
                                                                        <div>
                                                                            <h6>Loan Date: {new Date(loan.loanDate).toLocaleDateString()}</h6>
                                                                            <h6>Loan Type: {loan.loanFor}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex flex-column gap-2'>
                                                                    <button className="btn btn-outline-primary" onClick={() => handleViewLoanDetails(loan)}>View</button>
                                                                    <button className="btn btn-outline-success" onClick={() => handleAddRepayment(loan)}>Add Repayment</button>
                                                                    <button className="btn btn-outline-primary" onClick={() => handleViewRepayment(loan)} >View Repayment</button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : (
                                                <h4 className='text-center p-3'>No loan details found for this employee.</h4>
                                            )}
                                        </div>
                                    </div>
                                    <div className='row mt-4 rounded m-1 rounded bg-light shadow-sm '>
                                        <div className='col-sm-12 col-md-12 '>
                                            <div className='panel-heading'>
                                                <div className='panel-title d-flex gap-2 p-2'>
                                                    <h5 className='p-2 text-black fw-bolder'>Repayment List</h5>
                                                    <button className='btn btn-success'>Total Repayment: {totalRepaymentAmount}</button>
                                                   
                                                </div>
                                            </div>
                                            <div className='panel-body' style={{ maxHeight: "250px", overflowY: "auto" }}>
                                                {selectedLoanForRepayment && repaymentDetails.length > 0 ? (
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th>Employee Name</th>
                                                                <th>Loan Number</th>
                                                                <th>Loan Amount</th>
                                                                <th>Repayment Amount</th>
                                                                <th>Repayment Date</th>
                                                                <th>Repayment mode</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {repaymentDetails.map((repayment) => (
                                                                <tr key={repayment.loanRepaymentId}>
                                                                    <td>{employeeAndLoanDetails.employee.employeeName}</td>
                                                                    <td>{repayment.loanNumber}</td>
                                                                    <td>{repayment.loanAmount}</td>
                                                                    <td>{repayment.repaymentAmount}</td>
                                                                    <td>{new Date(repayment.repaymentDate).toLocaleDateString()}</td>
                                                                    <td>{repayment.repaymentType}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                ) : (
                                                    <h5 className='text-center'>No repayment details found for this loan.</h5>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* AddLoanRepayment modal */}
            {showAddRepaymentModal && selectedLoan && (
                <AddLoanRepayment
                    onClose={() => setShowAddRepaymentModal(false)}
                    onUpdate={() => fetchEmployeeAndLoanDetails(selectedEmployee)}
                    employee={employeeAndLoanDetails.employee}
                    loan={selectedLoan}
                />
            )}
            {showLoanDetailsModal && selectedViewLoan && (
                <LoanDetailsModal
                    onClose={() => setShowLoanDetailsModal(false)}
                    onUpdate={() => fetchEmployeeAndLoanDetails(selectedEmployee)}
                    employee={employeeAndLoanDetails.employee}
                    loan={selectedViewLoan}
                />
            )}
            {isAddLoanModalOpen && <AddLoan onClose={handleCloseLoanModal} onUpdate={handleUpdate} />} 
        </div>
    );
};

export default LoanList;
