
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Sidebar from '../../components/sidebar/Sidebar';
// import SearchBar from '../../components/sidebar/SearchBar';
// import ViewBonousInsentive from './ViewBonousInsentive';
// import Payment_Bonous_Insentive from './Payment_Bonous_Insentive';

// function Bonous_Insentive_List({ handleLogout, username }) {
//     const [departments, setDepartments] = useState([]);
//     const [employees, setEmployees] = useState([]);
//     const [selectedDepartment, setSelectedDepartment] = useState('');
//     const [selectedEmployee, setSelectedEmployee] = useState('');
//     const [bonusIncentive, setBonusIncentive] = useState([]);
//     const [paymentBonusIncentive, setPaymentBonusIncentive] = useState([]);
//     const [filteredBonusIncentive, setFilteredBonusIncentive] = useState([]);
//     const [loadingBonusIncentive, setLoadingBonusIncentive] = useState(false);
//     const [loadingPaymentBonusIncentive, setLoadingPaymentBonusIncentive] = useState(false);
//     const [startDate, setStartDate] = useState('');
//     const [endDate, setEndDate] = useState('');
//     // view Bonous Insentive details    
//     const [viewBonousInsentive, setviewBonousInsentive] = useState(null);
//     const [isviewBonousInsentive, setIsviewBonousInsentive] = useState(false);
//      // Payment Bonous Insentive 
//      const [paymentBonousInsentive, setpaymentBonousInsentive] = useState(null);
//      const [ispaymentBonousInsentive, setIspaymentBonousInsentive] = useState(false);

//     useEffect(() => {
//         fetchDepartments();
//         fetchAllEmployees(); // Fetch all employees when component mounts
//     }, []);

//     useEffect(() => {
//         filterBonusIncentiveByDate();
//     }, [startDate, endDate, bonusIncentive]);

//     const fetchDepartments = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/departments`);
//             setDepartments(response.data);
//         } catch (error) {
//             console.error('Error fetching departments:', error);
//         }
//     };

//     const fetchAllEmployees = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
//             setEmployees(response.data);
//         } catch (error) {
//             console.error('Error fetching all employees:', error);
//         }
//     };

//     const fetchEmployees = async (departmentId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employee_department/${departmentId}`);
//             setEmployees(response.data);
//         } catch (error) {
//             console.error('Error fetching employees:', error);
//         }
//     };

//     const fetchBonusHistory = async (employeeId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/bonousinsentivehistory/${employeeId}`);
//             return response.data;
//         } catch (error) {
//             console.error("Error fetching bonus/incentive history:", error);
//             return [];
//         }
//     };

//     const fetchBonusPaymentHistory = async (employeeId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/bonousinsentive/paymenthistory/${employeeId}`);
//             return response.data;
//         } catch (error) {
//             console.error("Error fetching bonus/incentive payment history:", error);
//             return [];
//         }
//     };

//     const handleDepartmentChange = (departmentId) => {
//         setSelectedDepartment(departmentId);
//         setSelectedEmployee('');
//         setBonusIncentive([]);
//         setPaymentBonusIncentive([]);
//         if (departmentId) {
//             fetchEmployees(departmentId);
//         }
//     };

//     const handleEmployeeChange = async (employeeId) => {
//         setSelectedEmployee(employeeId);
//         setBonusIncentive([]);
//         setPaymentBonusIncentive([]);
//         if (employeeId) {
//             setLoadingBonusIncentive(true);
//             const bonusHistory = await fetchBonusHistory(employeeId);
//             const paymentHistory = await fetchBonusPaymentHistory(employeeId);
//             setBonusIncentive(bonusHistory);
//             setPaymentBonusIncentive(paymentHistory);
//             setLoadingBonusIncentive(false);
//         }
//     };

//     const filterBonusIncentiveByDate = () => {
//         if (startDate && endDate) {
//             const start = new Date(startDate).setHours(0, 0, 0, 0);
//             const end = new Date(endDate).setHours(0, 0, 0, 0);
//             const filtered = bonusIncentive.filter(record => {
//                 const declarationDate = new Date(record.declarationDate).setHours(0, 0, 0, 0);
//                 return declarationDate >= start && declarationDate <= end;
//             });
//             setFilteredBonusIncentive(filtered);
//         } else {
//             setFilteredBonusIncentive(bonusIncentive);
//         }
//     };

//     const handleAllButtonClick = async () => {
//         setSelectedDepartment('');
//         setSelectedEmployee('');
//         setLoadingBonusIncentive(true);
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
//             const employees = response.data;
//             setEmployees(employees);

//             const bonusHistories = await Promise.all(employees.map(emp => fetchBonusHistory(emp.id)));
//             const paymentHistories = await Promise.all(employees.map(emp => fetchBonusPaymentHistory(emp.id)));

//             const allBonusIncentives = bonusHistories.flat();
//             const allPaymentBonusIncentives = paymentHistories.flat();

//             setBonusIncentive(allBonusIncentives);
//             setPaymentBonusIncentive(allPaymentBonusIncentives);
//         } catch (error) {
//             console.error("Error fetching all employees and their bonus/incentive histories:", error);
//         } finally {
//             setLoadingBonusIncentive(false);
//         }
//     };

//     const calculateTotalPayments = (bonusId) => {
//         const payments = paymentBonusIncentive.filter(payment => payment.bonousinsentiveId === bonusId);
//         return payments.reduce((total, payment) => total + parseFloat(payment.paymentAmount), 0);
//     };

//     const getStatusStyle = (status) => {
//         switch (status) {
//             case 'Completed':
//                 return { backgroundColor: 'blue', color: 'white' };
//             case 'Partially Paid':
//                 return { backgroundColor: 'yellow', color: 'black' };
//             case 'Pending':
//                 return { backgroundColor: 'red', color: 'white' };
//             default:
//                 return {};
//         }
//     };

//     // Both Buttons 
//     const handleviewBonousInsentive = (record) => {
//         console.log("record", record)
//         setviewBonousInsentive(record);
//         setIsviewBonousInsentive(true);
//     };
//     const handlePaymentBonousInsentive = (record) => {
//         console.log("record", record)
//         setpaymentBonousInsentive(record);
//         setIspaymentBonousInsentive(true);
//     };
//     const handleUpdateEmployees = () => {
//         toast.success('Data uploaded successfully');
//         fetchDepartments();
//         fetchAllEmployees(); // Fetch all employees when component mounts
//     };

//     return (
//         <div className='d-flex w-100 h-100'>
//             <Sidebar />
//             <div className='w-100'>
//                 <SearchBar className="searchbarr" username={username} handleLogout={handleLogout} />
//                 <div className="container-fluid">
//                     <ToastContainer />
//                     <div className="row">
//                         <div className="col-xl-12">
//                             <div className="card shadow mb-4">
//                                 <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
//                                     <h6 className="m-0 font-weight-bold text-primary">Bonus and Incentive List</h6>
//                                     <div className='d-flex align-items-center justify-content-center gap-1'>
//                                         <label className='pt-2 text-black fw-bolder'>Department: </label>
//                                         <select
//                                             className="form-control"
//                                             value={selectedDepartment}
//                                             onChange={(e) => handleDepartmentChange(e.target.value)}
//                                         >
//                                             <option value="">Select Department</option>
//                                             {departments.map(dept => (
//                                                 <option key={dept.id} value={dept.id}>{dept.name}</option>
//                                             ))}
//                                         </select>
//                                     </div>
//                                     <div className='d-flex align-items-center justify-content-center gap-1'>
//                                         <label className='pt-2 text-black fw-bolder'>Employee:</label>
//                                         <select
//                                             className="form-control"
//                                             value={selectedEmployee}
//                                             onChange={(e) => handleEmployeeChange(e.target.value)}
//                                             disabled={!selectedDepartment}
//                                         >
//                                             <option value="">Select Employee</option>
//                                             {employees.map(emp => (
//                                                 <option key={emp.id} value={emp.id}>{emp.employeeName}</option>
//                                             ))}
//                                         </select>
//                                     </div>
//                                     <div className="d-flex align-items-center justify-content-center gap-2">
//                                         <label htmlFor="" className="m-0 font-weight-bold text-black">Filter: </label>
//                                         <input
//                                             type="date"
//                                             value={startDate}
//                                             onChange={(e) => setStartDate(e.target.value)}
//                                             className="form-control datepicker"
//                                         />
//                                         <input
//                                             type="date"
//                                             value={endDate}
//                                             onChange={(e) => setEndDate(e.target.value)}
//                                             className="form-control datepicker"
//                                         />
//                                     </div>
//                                     <button className="btn btn-primary" onClick={handleAllButtonClick}>All</button>
//                                 </div>
//                                 <div className="card-body form-row">
//                                     <div className='col-md-12' style={{ maxHeight: "450px", overflowY: "auto" }}>
//                                         {loadingBonusIncentive || loadingPaymentBonusIncentive ? (
//                                             <div className="d-flex align-items-center justify-content-center">
//                                                 <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
//                                                 <strong>Loading...</strong>
//                                             </div>
//                                         ) : (
//                                             <table className="table table-striped table-bordered" style={{ width: "100%" }}>
//                                                 <thead>
//                                                     <tr>
//                                                         <th>Payment Date</th>
//                                                         <th>Employee Name</th>
//                                                         <th>Payment Mode</th>
//                                                         {/* <th>Periode</th> */}
//                                                         <th>Amount</th>
//                                                         <th>Paid Amount</th>
//                                                         <th>Amount Due</th>
//                                                         <th>Status</th>
//                                                         <th>Action</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {filteredBonusIncentive.length === 0 ? (
//                                                         <tr>
//                                                             <td colSpan="12" className="text-center">No records found.</td>
//                                                         </tr>
//                                                     ) : (
//                                                         filteredBonusIncentive.map((record, index) => {
//                                                             const employee = employees.find(emp => emp.id === record.employeeId);
//                                                             const department = departments.find(dept => dept.id === employee?.departmentId);
//                                                             const totalPayments = calculateTotalPayments(record.id);
//                                                             const amountDue = parseFloat(record.amount) - totalPayments;
//                                                             let paymentStatus;
//                                                             if (amountDue === 0) {
//                                                                 paymentStatus = 'Completed';
//                                                             } else if (amountDue < parseFloat(record.amount)) {
//                                                                 paymentStatus = 'Partially Paid';
//                                                             } else {
//                                                                 paymentStatus = 'Pending';
//                                                             }

//                                                             return (
//                                                                 <React.Fragment key={record.id}>
//                                                                     <tr>
//                                                                         <td>{new Date(record.declarationDate).toLocaleDateString()}</td>
//                                                                         <td>{record.employeeName}</td>
//                                                                         <td>{record.paymentMode}</td>
//                                                                         {/* <td>{new Date(record.fromDate).toLocaleDateString('en-US')} to {new Date(record.toDate).toLocaleDateString('en-US')}</td> */}
//                                                                         <td>{parseFloat(record.amount).toFixed(2)}</td>
//                                                                         <td>{totalPayments.toFixed(2)}</td>
//                                                                         <td>{amountDue.toFixed(2)}</td>
//                                                                         <td>
//                                                                             <span className="badge p-2" style={getStatusStyle(paymentStatus)}>
//                                                                                 {paymentStatus}
//                                                                             </span>
//                                                                         </td>

//                                                                         <td className="d-flex gap-1">
//                                                                             <button className="btn btn-outline-primary p-1" onClick={() => handleviewBonousInsentive(record)}>View</button>
//                                                                             <button className="btn btn-outline-success p-1" onClick={() => handlePaymentBonousInsentive(record)}>Add Payment</button>
//                                                                         </td>
//                                                                     </tr>
//                                                                 </React.Fragment>                                                              
//                                                             );
//                                                         })
//                                                     )}
//                                                 </tbody>
//                                             </table>
//                                         )}
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             {isviewBonousInsentive && (
//                 <ViewBonousInsentive
//                     record={viewBonousInsentive}
//                     onClose={() => setIsviewBonousInsentive(false)}
//                     onUpdate={handleUpdateEmployees}
//                 />
//             )}
//             {ispaymentBonousInsentive && (
//                 <Payment_Bonous_Insentive
//                     bonousinsentive={paymentBonousInsentive}
//                     onClose={() => setIspaymentBonousInsentive(false)}
//                     onUpdate={handleUpdateEmployees}
//                 />
//             )}
//         </div>
//     );
// }

// export default Bonous_Insentive_List;





import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/sidebar/Sidebar';
import SearchBar from '../../components/sidebar/SearchBar';
import ViewBonousInsentive from './ViewBonousInsentive';
import Payment_Bonous_Insentive from './Payment_Bonous_Insentive';

function Bonous_Insentive_List({ handleLogout, username }) {
    const [departments, setDepartments] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [bonusIncentive, setBonusIncentive] = useState([]);
    const [paymentBonusIncentive, setPaymentBonusIncentive] = useState([]);
    const [filteredBonusIncentive, setFilteredBonusIncentive] = useState([]);
    const [loadingBonusIncentive, setLoadingBonusIncentive] = useState(false);
    const [loadingPaymentBonusIncentive, setLoadingPaymentBonusIncentive] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    // view Bonous Insentive details    
    const [viewBonousInsentive, setviewBonousInsentive] = useState(null);
    const [isviewBonousInsentive, setIsviewBonousInsentive] = useState(false);
     // Payment Bonous Insentive 
     const [paymentBonousInsentive, setpaymentBonousInsentive] = useState(null);
     const [ispaymentBonousInsentive, setIspaymentBonousInsentive] = useState(false);

    useEffect(() => {
        fetchDepartments();
        fetchAllEmployees(); // Fetch all employees when component mounts
    }, []);

    useEffect(() => {
        filterBonusIncentiveByDate();
    }, [startDate, endDate, bonusIncentive]);

    const fetchDepartments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/departments`);
            setDepartments(response.data);
        } catch (error) {
            console.error('Error fetching departments:', error);
        }
    };

    const fetchAllEmployees = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            setEmployees(response.data);
        } catch (error) {
            console.error('Error fetching all employees:', error);
        }
    };

    const fetchEmployees = async (departmentId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employee_department/${departmentId}`);
            setEmployees(response.data);
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    const fetchBonusHistory = async (employeeId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/bonousinsentivehistory/${employeeId}`);
            return response.data;
        } catch (error) {
            console.error("Error fetching bonus/incentive history:", error);
            return [];
        }
    };

    const fetchBonusPaymentHistory = async (employeeId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/bonousinsentive/paymenthistory/${employeeId}`);
            return response.data;
        } catch (error) {
            console.error("Error fetching bonus/incentive payment history:", error);
            return [];
        }
    };

    const handleDepartmentChange = (departmentId) => {
        setSelectedDepartment(departmentId);
        setSelectedEmployee('');
        setBonusIncentive([]);
        setPaymentBonusIncentive([]);
        if (departmentId) {
            fetchEmployees(departmentId);
        }
    };

    const handleEmployeeChange = async (employeeId) => {
        setSelectedEmployee(employeeId);
        setBonusIncentive([]);
        setPaymentBonusIncentive([]);
        if (employeeId) {
            setLoadingBonusIncentive(true);
            const bonusHistory = await fetchBonusHistory(employeeId);
            const paymentHistory = await fetchBonusPaymentHistory(employeeId);
            setBonusIncentive(bonusHistory);
            setPaymentBonusIncentive(paymentHistory);
            setLoadingBonusIncentive(false);
        }
    };

    const filterBonusIncentiveByDate = () => {
        if (startDate && endDate) {
            const start = new Date(startDate).setHours(0, 0, 0, 0);
            const end = new Date(endDate).setHours(0, 0, 0, 0);
            const filtered = bonusIncentive.filter(record => {
                const declarationDate = new Date(record.declarationDate).setHours(0, 0, 0, 0);
                return declarationDate >= start && declarationDate <= end;
            });
            setFilteredBonusIncentive(filtered);
        } else {
            setFilteredBonusIncentive(bonusIncentive);
        }
    };

    const handleAllButtonClick = async () => {
        setSelectedDepartment('');
        setSelectedEmployee('');
        setLoadingBonusIncentive(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            const employees = response.data;
            setEmployees(employees);

            const bonusHistories = await Promise.all(employees.map(emp => fetchBonusHistory(emp.id)));
            const paymentHistories = await Promise.all(employees.map(emp => fetchBonusPaymentHistory(emp.id)));

            const allBonusIncentives = bonusHistories.flat();
            const allPaymentBonusIncentives = paymentHistories.flat();

            setBonusIncentive(allBonusIncentives);
            setPaymentBonusIncentive(allPaymentBonusIncentives);
        } catch (error) {
            console.error("Error fetching all employees and their bonus/incentive histories:", error);
        } finally {
            setLoadingBonusIncentive(false);
        }
    };

    const calculateTotalPayments = (bonusId) => {
        const payments = paymentBonusIncentive.filter(payment => payment.bonousinsentiveId === bonusId);
        return payments.reduce((total, payment) => total + parseFloat(payment.paymentAmount), 0);
    };

    const getStatusStyle = (status) => {
        switch (status) {
            case 'Completed':
                return { backgroundColor: 'blue', color: 'white' };
            case 'Partially Paid':
                return { backgroundColor: 'yellow', color: 'black' };
            case 'Pending':
                return { backgroundColor: 'red', color: 'white' };
            default:
                return {};
        }
    };

    // Both Buttons 
    const handleviewBonousInsentive = (record) => {
        console.log("record", record)
        setviewBonousInsentive(record);
        setIsviewBonousInsentive(true);
    };
    const handlePaymentBonousInsentive = (record) => {
        console.log("record", record)
        setpaymentBonousInsentive(record);
        setIspaymentBonousInsentive(true);
    };
    const handleUpdateEmployees = () => {
        toast.success('Data uploaded successfully');
        fetchDepartments();
        fetchAllEmployees(); // Fetch all employees when component mounts
    };

    return (
        <div className='d-flex w-100 h-100'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar className="searchbarr" username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 className="m-0 font-weight-bold text-primary">Bonus and Incentive List</h6>
                                    <div className='d-flex align-items-center justify-content-center gap-1'>
                                        <label className='pt-2 text-black fw-bolder'>Department: </label>
                                        <select
                                            className="form-control"
                                            value={selectedDepartment}
                                            onChange={(e) => handleDepartmentChange(e.target.value)}
                                        >
                                            <option value="">Select Department</option>
                                            {departments.map(dept => (
                                                <option key={dept.id} value={dept.id}>{dept.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-center gap-1'>
                                        <label className='pt-2 text-black fw-bolder'>Employee:</label>
                                        <select
                                            className="form-control"
                                            value={selectedEmployee}
                                            onChange={(e) => handleEmployeeChange(e.target.value)}
                                            disabled={!selectedDepartment}
                                        >
                                            <option value="">Select Employee</option>
                                            {employees.map(emp => (
                                                <option key={emp.id} value={emp.id}>{emp.employeeName}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center gap-2">
                                        <label htmlFor="" className="m-0 font-weight-bold text-black">Filter: </label>
                                        <input
                                            type="date"
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                            className="form-control datepicker"
                                        />
                                        <input
                                            type="date"
                                            value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                            className="form-control datepicker"
                                        />
                                    </div>
                                    <button className="btn btn-primary" onClick={handleAllButtonClick}>All</button>
                                </div>
                                <div className="card-body form-row">
                                    <div className='col-md-12' style={{ maxHeight: "450px", overflowY: "auto" }}>
                                        {loadingBonusIncentive || loadingPaymentBonusIncentive ? (
                                            <div className="d-flex align-items-center justify-content-center">
                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                <strong>Loading...</strong>
                                            </div>
                                        ) : (
                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Payment Date</th>
                                                        <th>Employee Name</th>
                                                        <th>Payment Mode</th>
                                                        {/* <th>Periode</th> */}
                                                        <th>Amount</th>
                                                        <th>Paid Amount</th>
                                                        <th>Amount Due</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredBonusIncentive.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="12" className="text-center">No records found.</td>
                                                        </tr>
                                                    ) : (
                                                        filteredBonusIncentive.map((record, index) => {
                                                            const employee = employees.find(emp => emp.id === record.employeeId);
                                                            const department = departments.find(dept => dept.id === employee?.departmentId);
                                                            const totalPayments = calculateTotalPayments(record.id);
                                                            const amountDue = parseFloat(record.amount) - totalPayments;
                                                            let paymentStatus;
                                                            if (amountDue === 0) {
                                                                paymentStatus = 'Completed';
                                                            } else if (amountDue < parseFloat(record.amount)) {
                                                                paymentStatus = 'Partially Paid';
                                                            } else {
                                                                paymentStatus = 'Pending';
                                                            }

                                                            return (
                                                                <React.Fragment key={record.id}>
                                                                    <tr>
                                                                        <td>{new Date(record.declarationDate).toLocaleDateString()}</td>
                                                                        <td>{record.employeeName}</td>
                                                                        <td>{record.paymentMode}</td>
                                                                        {/* <td>{new Date(record.fromDate).toLocaleDateString('en-US')} to {new Date(record.toDate).toLocaleDateString('en-US')}</td> */}
                                                                        <td>{parseFloat(record.amount).toFixed(2)}</td>
                                                                        <td>{totalPayments.toFixed(2)}</td>
                                                                        <td>{amountDue.toFixed(2)}</td>
                                                                        <td>
                                                                            <span className="badge p-2" style={getStatusStyle(paymentStatus)}>
                                                                                {paymentStatus}
                                                                            </span>
                                                                        </td>

                                                                        <td className="d-flex gap-1">
                                                                            <button className="btn btn-outline-primary p-1" onClick={() => handleviewBonousInsentive(record)}>View</button>
                                                                            <button className="btn btn-outline-success p-1" onClick={() => handlePaymentBonousInsentive(record)}>Add Payment</button>
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>                                                              
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isviewBonousInsentive && (
                <ViewBonousInsentive
                    record={viewBonousInsentive}
                    onClose={() => setIsviewBonousInsentive(false)}
                    onUpdate={handleUpdateEmployees}
                />
            )}
            {ispaymentBonousInsentive && (
                <Payment_Bonous_Insentive
                    bonousinsentive={paymentBonousInsentive}
                    onClose={() => setIspaymentBonousInsentive(false)}
                    onUpdate={handleUpdateEmployees}
                />
            )}
        </div>
    );
}

export default Bonous_Insentive_List;
