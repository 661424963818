import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddDepartmentModal from './AddDepartmentModal'; // Replace with your actual modal component
import DepartmentDesc from './DepartmentDesc'; // Replace with actual DepartmentDesc component
import AddPositionModal from './AddPositionModal'; // Replace with actual AddPositionModal component
import DeleteConfirmationModal from '../DeleteConfirmationModal'; // Replace with actual DeleteConfirmationModal path
import Sidebar from '../../components/sidebar/Sidebar'; // Replace with actual Sidebar path
import SearchBar from '../../components/sidebar/SearchBar'; // Replace with actual SearchBar path
import { toast, ToastContainer } from 'react-toastify'; // Import toast components
import 'react-toastify/dist/ReactToastify.css';

function DepartmentList({ handleLogout, username }) {
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [showDepartmentDetails, setShowDepartmentDetails] = useState(false);
    const [isAddDepartmentModalOpen, setIsAddDepartmentModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editDepartment, setEditDepartment] = useState(null);
    const [deleteDepartment, setDeleteDepartment] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");

    const fetchDepartments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/departments`);
            setDepartments(response.data);
        } catch (error) {
            console.error('Error fetching departments:', error);
        }
    };

    useEffect(() => {
        fetchDepartments();
    }, []);

    const handleAddDepartment = () => {
        setIsAddDepartmentModalOpen(true);
    };

    const handleCloseDepartmentModal = () => {
        setIsAddDepartmentModalOpen(false);
        setIsEditModalOpen(false);
        setIsDeleteModalOpen(false);
    };

    const handleDepartmentDetails = (department) => {
        setSelectedDepartment(department);
        setShowDepartmentDetails(true);
    };

    const handleEditDepartment = (department) => {
        setEditDepartment(department);
        setIsEditModalOpen(true);
    };

    const handleDeleteDepartment = (department) => {
        setDeleteDepartment(department);
        setIsDeleteModalOpen(true);
    };

    const confirmDeleteDepartment = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/departments/${deleteDepartment.id}`);
            const deletedDepartment = { ...deleteDepartment, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedDepartment);
            setDepartments((prevDepartments) =>
                prevDepartments.filter((department) => department.id !== deleteDepartment.id)
            );
            setIsDeleteModalOpen(false);
        } catch (error) {
            console.error("Error deleting Department:", error);
        }
    };

    const handleUpdateDepartment = async (updatedDepartment) => {
        try {
            await axios.put(`${process.env.REACT_APP_LOCAL_URL}/departments/${updatedDepartment.id}`, updatedDepartment);
            setDepartments((prevDepartments) =>
                prevDepartments.map((department) => (department.id === updatedDepartment.id ? updatedDepartment : department))
            );
            setIsEditModalOpen(false);
        } catch (error) {
            console.error("Error updating department:", error);
        }
    };

    const handleUpdateDepartments = () => {
        toast.success('Department data updated successfully'); // Display toast notification
        fetchDepartments();
    };

    return (
        <div className='d-flex w-100 h-100'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} /> {/* Pass username and handleLogout props */}
                <div className="container-fluid">
                    <ToastContainer />
                    {showDepartmentDetails ? (
                        <DepartmentDesc department={selectedDepartment} onClose={() => setShowDepartmentDetails(false)} />
                    ) : (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">Department List</h6>

                                        <button onClick={handleAddDepartment} className="btn btn-outline-primary">
                                            Add New Department
                                        </button>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th>Department Name</th>
                                                    <th>Description</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <style>
                            {`.hyperlink:hover {color: blue;}`}
                          </style>
                                                {departments.map((department) => (
                                                    <tr key={department.id}>
                                                        <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleDepartmentDetails(department)}>{department.name}</td>
                                                        <td>{department.description}</td>

                                                        <td>
                                                            <div className="d-flex align-item-center justify-content-start gap-3">
                                                                <div className="btn-group">
                                                                    <button className="btn btn-sm btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu actionmenu" x-placement="bottom-start">
                                                                        <a className="dropdown-item" href="javascript:void(0);" onClick={() => handleDepartmentDetails(department)}>
                                                                            <i className="fa fa-file"></i> Details
                                                                        </a>
                                                                        {/* <a className="dropdown-item" href="#" onClick={() => handleEditDepartment(department)}>
                                      <i className="fas fa-edit"></i> Edit
                                    </a> */}
                                                                        {/* <a className="dropdown-item" href="#" onClick={() => handleDeleteDepartment(department)}>
                                      <i className="fa fa-trash"></i> Delete
                                    </a> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {isAddDepartmentModalOpen && <AddDepartmentModal onClose={handleCloseDepartmentModal} onUpdate={handleUpdateDepartments} />}
                    {isEditModalOpen && <AddPositionModal department={editDepartment} onClose={handleCloseDepartmentModal} onUpdate={handleUpdateDepartment} />}
                    <DeleteConfirmationModal
                        isOpen={isDeleteModalOpen}
                        itemName={deleteDepartment ? deleteDepartment.departmentName : ""}
                        onDelete={confirmDeleteDepartment}
                        onClose={() => setIsDeleteModalOpen(false)}
                        deleteReason={deleteReason}
                        setDeleteReason={setDeleteReason}
                    />
                </div>
            </div>
        </div>
    );
}

export default DepartmentList;
