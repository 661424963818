

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Sidebar from '../../components/sidebar/Sidebar';
// import SearchBar from '../../components/sidebar/SearchBar';
// import SalaryPreview from './SalaryPreview';
// import PaymentForm from './PaymentForm';
// import PaymentHistory from './PaymentHistory';
// import EditSalarySlip from './EditSalarySlip';
// import { Link, useNavigate } from 'react-router-dom';

// function SalaryList({ handleLogout, username }) {
//     const [departments, setDepartments] = useState([]);
//     const [employees, setEmployees] = useState([]);
//     const [payroll, setPayroll] = useState([]);
//     const [filteredPayroll, setFilteredPayroll] = useState([]);
//     const [selectedDepartment, setSelectedDepartment] = useState('');
//     const [selectedEmployee, setSelectedEmployee] = useState('');
//     const [selectedMonth, setSelectedMonth] = useState(''); // Initialize with empty string
//     const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initialize with current year
//     const [showSidebar, setShowSidebar] = useState(true);
//     const [showSearchBar, setShowSearchBar] = useState(true);
//     const [showSalaryPreview, setShowSalaryPreview] = useState(false);
//     const [selectedRecord, setSelectedRecord] = useState(null); // State to hold the selected record
//     const [paymentForm, setPaymentForm] = useState(null);
//     const [isPaymentForm, setIsPaymentForm] = useState(false);
//     const [paymentDetails, setPaymentDetails] = useState({}); // New state to store payment details
//     // Payment History 
//     const [paymentFormHistory, setPaymentFormHistory] = useState(null);
//     const [isPaymentHistory, setIsPaymentHistory] = useState(false);
//     // Edit Salary List 
//     const navigate = useNavigate();
//     console.log("editsalaryslip");


//     const handleEditClick = (record) => {
//         if (record) {
//             navigate('/editsalaryslip', { state: { salarydata: record } });
//         } else {
//             console.error('Salary data is not ready.');
//         }
//     };

//     useEffect(() => {
//         fetchDepartments();
//     }, []);

//     useEffect(() => {
//         if (selectedDepartment) {
//             fetchEmployees(selectedDepartment);
//             setSelectedEmployee(''); // Reset selected employee when department changes
//             fetchPayrollByDepartment(selectedDepartment);
//         }
//     }, [selectedDepartment]);

//     useEffect(() => {
//         if (selectedEmployee) {
//             fetchPayrollByEmployee(selectedEmployee);
//         } else {
//             // Fetch payroll by department if no employee is selected
//             if (selectedDepartment) {
//                 fetchPayrollByDepartment(selectedDepartment);
//             }
//         }
//     }, [selectedEmployee]);

//     useEffect(() => {
//         filterPayroll();
//     }, [selectedYear, selectedMonth, payroll]);

//     useEffect(() => {
//         fetchPaymentDetails(); // Fetch payment details when payroll data changes
//     }, [payroll]);

//     const fetchDepartments = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/departments`);
//             setDepartments(response.data);
//         } catch (error) {
//             console.error('Error fetching departments:', error);
//         }
//     };

//     const fetchEmployees = async (departmentId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employee_department/${departmentId}`);
//             setEmployees(response.data);
//         } catch (error) {
//             console.error('Error fetching employees:', error);
//         }
//     };

//     const fetchPayrollByDepartment = async (departmentId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/payroll/department/${departmentId}`);
//             setPayroll(response.data);
//             filterPayroll(response.data);
//         } catch (error) {
//             console.error('Error fetching payroll by department:', error);
//         }
//     };

//     const fetchPayrollByEmployee = async (employeeId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/payroll/${employeeId}`);
//             setPayroll(response.data);
//             filterPayroll(response.data);
//         } catch (error) {
//             console.error('Error fetching payroll by employee:', error);
//         }
//     };

//     const fetchPaymentDetails = async () => {
//         try {
//             const details = {};
//             for (const record of payroll) {
//                 const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/paymentform/${record.id}`);
//                 details[record.id] = response.data.reduce((sum, payment) => sum + payment.amountPaid, 0);
//             }
//             setPaymentDetails(details);
//         } catch (error) {
//             console.error('Error fetching payment details:', error);
//         }
//     };

//     const filterPayroll = (data = payroll) => {
//         const filteredRecords = data.filter(record =>
//             record.year === selectedYear &&
//             (selectedMonth === '' || record.month === parseInt(selectedMonth))
//         );
//         setFilteredPayroll(filteredRecords);
//     };

//     const handleOpenSalaryPreview = (record) => {
//         setSelectedRecord(record); // Set the selected record
//         setShowSidebar(false); // Hide sidebar
//         setShowSearchBar(false);
//         setShowSalaryPreview(true);
//     };

//     const handleClosePreview = () => {
//         setShowSalaryPreview(false);
//         setShowSidebar(true);
//         setShowSearchBar(true);
//         setSelectedRecord(null); // Clear the selected record
//     };

//     // Payment Form 
//     const handlePaymentForm = (record) => {
//         setPaymentForm(record);
//         setIsPaymentForm(true);
//     };

//     // Payment History
//     const handlePaymentHistory = (record) => {
//         console.log("re", record)
//         setPaymentFormHistory(record);
//         setIsPaymentHistory(true);
//     };


//     const handleUpdate = () => {
//         toast.success('Data uploaded successfully');
//         fetchEmployees(selectedDepartment);
//         setSelectedEmployee(''); // Reset selected employee when department changes
//         fetchPayrollByDepartment(selectedDepartment);
//     };

//     const monthNames = [
//         "January", "February", "March", "April", "May", "June",
//         "July", "August", "September", "October", "November", "December"
//     ];

//     return (
//         <div className='d-flex w-100 h-100'>
//             {showSidebar && <Sidebar />}
//             <div className='w-100'>
//                 {showSearchBar && <SearchBar className="searchbarr" username={username} handleLogout={handleLogout} />}
//                 <div className="container-fluid">
//                     <ToastContainer />
//                     {showSalaryPreview ? (
//                         <SalaryPreview
//                             record={selectedRecord}
//                             onClose={handleClosePreview}
//                         />
//                     ) : (
//                         <div className="row">
//                             <div className="col-xl-12">
//                                 <div className="card shadow mb-4">
//                                     <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
//                                         <h6 className="m-0 font-weight-bold text-primary">Salary List</h6>
//                                         <div className='d-flex align-items-center justify-content-center gap-1 w-50'>
//                                             <label className='pt-2 text-black fw-bolder'>Department: </label>
//                                             <select className="form-control" value={selectedDepartment}
//                                                 onChange={(e) => setSelectedDepartment(e.target.value)}
//                                             >
//                                                 <option value="" disabled>Select Department</option>
//                                                 {departments.map(dept => (
//                                                     <option key={dept.id} value={dept.id}>{dept.name}</option>
//                                                 ))}
//                                             </select>
//                                         </div>
//                                         <div className='d-flex align-items-center justify-content-center gap-1'>
//                                             <label className='pt-2 text-black fw-bolder'>Employee:</label>
//                                             <select className="form-control" value={selectedEmployee}
//                                                 onChange={(e) => setSelectedEmployee(e.target.value)}
//                                             >
//                                                 <option value="">Select Employee</option>
//                                                 {employees.map(emp => (
//                                                     <option key={emp.id} value={emp.id}>{emp.employeeName}</option>
//                                                 ))}
//                                             </select>
//                                         </div>
//                                         <div className='d-flex align-items-center justify-content-center gap-1'>
//                                             <label className='pt-2 text-black fw-bolder'>Filter:</label>
//                                             <select className="form-control" value={selectedMonth}
//                                                 onChange={(e) => setSelectedMonth(e.target.value)}
//                                             >
//                                                 <option value="">Month</option>
//                                                 {Array.from({ length: 12 }, (_, i) => (
//                                                     <option key={i} value={i + 1}>{monthNames[i]}</option>
//                                                 ))}
//                                             </select>
//                                             <select className="form-control" value={selectedYear}
//                                                 onChange={(e) => setSelectedYear(parseInt(e.target.value))}
//                                             >
//                                                 <option value="">Select Year</option>
//                                                 {Array.from({ length: 10 }, (_, i) => (
//                                                     <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
//                                                 ))}
//                                             </select>
//                                         </div>
//                                     </div>
//                                     <div className="card-body form-row">
//                                         <div className='col-md-12' style={{ maxHeight: "500px", overflowY: "auto" }}>
//                                             <table className="table table-striped table-bordered" style={{ width: "100%" }}>
//                                                 <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
//                                                     <tr>
//                                                         <th>Employee Name </th>
//                                                         <th>Salary Period</th>
//                                                         <th>Total In-Hand Salary</th>
//                                                         <th>Net Salary Payable</th>
//                                                         <th>Amount Paid</th> {/* Updated column */}
//                                                         <th>Amount Due</th> {/* Updated column */}
//                                                         <th>Action</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {filteredPayroll.length > 0 ? (
//                                                         filteredPayroll.map(record => {
//                                                             const amountPaid = paymentDetails[record.id] || 0;
//                                                             const amountDue = record.netSalaryPayableMonth - amountPaid;
//                                                             const showAddPaymentButton = amountDue > 0;

//                                                             return (
//                                                                 <tr key={record.id}>
//                                                                     <td>
//                                                                         {record.employeeName} <br />
//                                                                         <small>{record.departmentName}</small>
//                                                                     </td>
//                                                                     <td>{monthNames[record.month - 1]} - {record.year}</td>
//                                                                     <td>&#x20B9;{record.salaryWithContributiontoFixed(2) || '0'}</td>
//                                                                     <td>&#x20B9;{record.netSalaryPayableMonth.toFixed(2) || '0'}</td>
//                                                                     <td>&#x20B9;{amountPaid.toFixed(2) || '0.00'}</td> {/* Display total amount paid */}
//                                                                     <td>&#x20B9;{amountDue.toFixed(2) || '0.00'}</td> {/* Display amount due */}
//                                                                     <td className='d-flex flex-column'>
//                                                                         <button className="m-1 btn btn-success btn-sm" onClick={() => handleOpenSalaryPreview(record)}>
//                                                                             <i className="fa fa-download" aria-hidden="true"></i> Print
//                                                                         </button>
//                                                                         <button className="m-1 btn btn-info btn-sm" onClick={() => handlePaymentHistory(record)}>
//                                                                             <i className="fa fa-eye" aria-hidden="true"></i> View History
//                                                                         </button>
//                                                                         <button className="m-1 btn btn-info btn-sm" onClick={() => handleEditClick(record)}>
//                                                                             <i className="fa fa-pencil" aria-hidden="true"></i> Edit Salary
//                                                                         </button>
//                                                                         {showAddPaymentButton && (
//                                                                             <button className="m-1 btn btn-primary btn-sm" onClick={() => handlePaymentForm(record)}>
//                                                                                 <i className="fa fa-plus" aria-hidden="true"></i> Add Payment
//                                                                             </button>
//                                                                         )}
//                                                                     </td>
//                                                                 </tr>
//                                                             );
//                                                         })
//                                                     ) : (
//                                                         <tr>
//                                                             <td colSpan="7" style={{ textAlign: 'center' }}>
//                                                                 <h5>No salary history found for this employee.</h5>
//                                                             </td>
//                                                         </tr>
//                                                     )}
//                                                 </tbody>

//                                             </table>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                     {isPaymentForm && (
//                         <PaymentForm
//                             record={paymentForm}
//                             onClose={() => setIsPaymentForm(false)}
//                             onUpdate={handleUpdate}
//                         />
//                     )}
//                     {isPaymentHistory && (
//                         <PaymentHistory
//                             record={paymentFormHistory}
//                             onClose={() => setIsPaymentHistory(false)}
//                             onUpdate={handleUpdate}
//                         />
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default SalaryList;



















































import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/sidebar/Sidebar';
import SearchBar from '../../components/sidebar/SearchBar';
import SalaryPreview from './SalaryPreview';
import PaymentForm from './PaymentForm';
import PaymentHistory from './PaymentHistory';
import EditSalarySlip from './EditSalarySlip';
import { Link, useNavigate } from 'react-router-dom';

function SalaryList({ handleLogout, username }) {
    const [departments, setDepartments] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [payroll, setPayroll] = useState([]);
    const [filteredPayroll, setFilteredPayroll] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [selectedMonth, setSelectedMonth] = useState(''); // Initialize with empty string
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initialize with current year
    const [showSidebar, setShowSidebar] = useState(true);
    const [showSearchBar, setShowSearchBar] = useState(true);
    const [showSalaryPreview, setShowSalaryPreview] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null); // State to hold the selected record
    const [paymentForm, setPaymentForm] = useState(null);
    const [isPaymentForm, setIsPaymentForm] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState({}); // New state to store payment details
    // Payment History 
    const [paymentFormHistory, setPaymentFormHistory] = useState(null);
    const [isPaymentHistory, setIsPaymentHistory] = useState(false);
    // Edit Salary List 
    const navigate = useNavigate();
    console.log("editsalaryslip");


    const handleEditClick = (record) => {
        if (record) {
            navigate('/editsalaryslip', { state: { salarydata: record } });
        } else {
            console.error('Salary data is not ready.');
        }
    };

    useEffect(() => {
        fetchDepartments();
    }, []);

    useEffect(() => {
        if (selectedDepartment) {
            fetchEmployees(selectedDepartment);
            setSelectedEmployee(''); // Reset selected employee when department changes
            fetchPayrollByDepartment(selectedDepartment);
        }
    }, [selectedDepartment]);

    useEffect(() => {
        if (selectedEmployee) {
            fetchPayrollByEmployee(selectedEmployee);
        } else {
            // Fetch payroll by department if no employee is selected
            if (selectedDepartment) {
                fetchPayrollByDepartment(selectedDepartment);
            }
        }
    }, [selectedEmployee]);

    useEffect(() => {
        filterPayroll();
    }, [selectedYear, selectedMonth, payroll]);

    useEffect(() => {
        fetchPaymentDetails(); // Fetch payment details when payroll data changes
    }, [payroll]);

    const fetchDepartments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/departments`);
            setDepartments(response.data);
        } catch (error) {
            console.error('Error fetching departments:', error);
        }
    };

    const fetchEmployees = async (departmentId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employee_department/${departmentId}`);
            setEmployees(response.data);
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    const fetchPayrollByDepartment = async (departmentId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/payroll/department/${departmentId}`);
            setPayroll(response.data);
            filterPayroll(response.data);
        } catch (error) {
            console.error('Error fetching payroll by department:', error);
        }
    };

    const fetchPayrollByEmployee = async (employeeId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/payroll/${employeeId}`);
            setPayroll(response.data);
            filterPayroll(response.data);
        } catch (error) {
            console.error('Error fetching payroll by employee:', error);
        }
    };

    const fetchPaymentDetails = async () => {
        try {
            const details = {};
            for (const record of payroll) {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/paymentform/${record.id}`);
                details[record.id] = response.data.reduce((sum, payment) => sum + payment.amountPaid, 0);
            }
            setPaymentDetails(details);
        } catch (error) {
            console.error('Error fetching payment details:', error);
        }
    };

    const filterPayroll = (data = payroll) => {
        const filteredRecords = data.filter(record =>
            record.year === selectedYear &&
            (selectedMonth === '' || record.month === parseInt(selectedMonth))
        );
        setFilteredPayroll(filteredRecords);
    };

    const handleOpenSalaryPreview = (record) => {
        setSelectedRecord(record); // Set the selected record
        setShowSidebar(false); // Hide sidebar
        setShowSearchBar(false);
        setShowSalaryPreview(true);
    };

    const handleClosePreview = () => {
        setShowSalaryPreview(false);
        setShowSidebar(true);
        setShowSearchBar(true);
        setSelectedRecord(null); // Clear the selected record
    };

    // Payment Form 
    const handlePaymentForm = (record) => {
        setPaymentForm(record);
        setIsPaymentForm(true);
    };

    // Payment History
    const handlePaymentHistory = (record) => {
        console.log("re", record)
        setPaymentFormHistory(record);
        setIsPaymentHistory(true);
    };


    const handleUpdate = () => {
        toast.success('Data uploaded successfully');
        fetchEmployees(selectedDepartment);
        setSelectedEmployee(''); // Reset selected employee when department changes
        fetchPayrollByDepartment(selectedDepartment);
    };

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    return (
        <div className='d-flex w-100 h-100'>
            {showSidebar && <Sidebar />}
            <div className='w-100'>
                {showSearchBar && <SearchBar className="searchbarr" username={username} handleLogout={handleLogout} />}
                <div className="container-fluid">
                    <ToastContainer />
                    {showSalaryPreview ? (
                        <SalaryPreview
                            record={selectedRecord}
                            onClose={handleClosePreview}
                        />
                    ) : (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">Salary List</h6>
                                        <div className='d-flex align-items-center justify-content-center gap-1 w-50'>
                                            <label className='pt-2 text-black fw-bolder'>Department: </label>
                                            <select className="form-control" value={selectedDepartment}
                                                onChange={(e) => setSelectedDepartment(e.target.value)}
                                            >
                                                <option value="" disabled>Select Department</option>
                                                {departments.map(dept => (
                                                    <option key={dept.id} value={dept.id}>{dept.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center gap-1'>
                                            <label className='pt-2 text-black fw-bolder'>Employee:</label>
                                            <select className="form-control" value={selectedEmployee}
                                                onChange={(e) => setSelectedEmployee(e.target.value)}
                                            >
                                                <option value="">Select Employee</option>
                                                {employees.map(emp => (
                                                    <option key={emp.id} value={emp.id}>{emp.employeeName}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center gap-1'>
                                            <label className='pt-2 text-black fw-bolder'>Filter:</label>
                                            <select className="form-control" value={selectedMonth}
                                                onChange={(e) => setSelectedMonth(e.target.value)}
                                            >
                                                <option value="">Month</option>
                                                {Array.from({ length: 12 }, (_, i) => (
                                                    <option key={i} value={i + 1}>{monthNames[i]}</option>
                                                ))}
                                            </select>
                                            <select className="form-control" value={selectedYear}
                                                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                                            >
                                                <option value="">Select Year</option>
                                                {Array.from({ length: 10 }, (_, i) => (
                                                    <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="card-body form-row">
                                        <div className='col-md-12' style={{ maxHeight: "500px", overflowY: "auto" }}>
                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                    <tr>
                                                        <th>Employee Name </th>
                                                        <th>Salary Period</th>
                                                        <th>Salary With Contribution </th>
                                                        <th>Net Salary Payable</th>
                                                        <th>Amount Paid</th> {/* Updated column */}
                                                        <th>Amount Due</th> {/* Updated column */}
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredPayroll.length > 0 ? (
                                                        filteredPayroll.map(record => {
                                                            const amountPaid = paymentDetails[record.id] || 0;
                                                            const amountDue = record.netSalaryPayableMonth - amountPaid;
                                                            const showAddPaymentButton = amountDue > 0;

                                                            return (
                                                                <tr key={record.id}>
                                                                    <td>
                                                                        {record.employeeName} <br />

                                                                        <small>{record.departmentName}</small>
                                                                    </td>

                                                                    <td>{monthNames[record.month - 1]} - {record.year}</td>
                                                                    <td>&#x20B9;{record.salaryWithContribution != null ? record.salaryWithContribution.toFixed(2) : '0.00'}</td>
                                                                    <td>&#x20B9;{record.netSalaryPayableMonth.toFixed(2) || '0'}</td>
                                                                    <td>&#x20B9;{amountPaid.toFixed(2) || '0.00'}</td> {/* Display total amount paid */}
                                                                    <td>&#x20B9;{amountDue.toFixed(2) || '0.00'}</td> {/* Display amount due */}
                                                                    <td className='d-flex flex-column'>
                                                                        <button className="m-1 btn btn-success btn-sm" onClick={() => handleOpenSalaryPreview(record)}>
                                                                            <i className="fa fa-download" aria-hidden="true"></i> Print
                                                                        </button>
                                                                        <button className="m-1 btn btn-info btn-sm" onClick={() => handlePaymentHistory(record)}>
                                                                            <i className="fa fa-eye" aria-hidden="true"></i> View History
                                                                        </button>
                                                                        <button className="m-1 btn btn-info btn-sm" onClick={() => handleEditClick(record)}>
                                                                            <i className="fa fa-pencil" aria-hidden="true"></i> Edit Salary
                                                                        </button>
                                                                        {showAddPaymentButton && (
                                                                            <button className="m-1 btn btn-primary btn-sm" onClick={() => handlePaymentForm(record)}>
                                                                                <i className="fa fa-plus" aria-hidden="true"></i> Add Payment
                                                                            </button>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="7" style={{ textAlign: 'center' }}>
                                                                <h5>No salary history found for this employee.</h5>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {isPaymentForm && (
                        <PaymentForm
                            record={paymentForm}
                            onClose={() => setIsPaymentForm(false)}
                            onUpdate={handleUpdate}
                        />
                    )}
                    {isPaymentHistory && (
                        <PaymentHistory
                            record={paymentFormHistory}
                            onClose={() => setIsPaymentHistory(false)}
                            onUpdate={handleUpdate}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default SalaryList;






















