// import axios from 'axios';
// import React, { useEffect, useState } from 'react';

// function SalaryPreview({ record, onClose }) {
//     console.log("record", record);
//     const [setting, setSetting] = useState({});
//     const [attendanceRecords, setAttendanceRecords] = useState([]);
//     const [employeeDetails, setEmployeeDetails] = useState({});
//     const [loanRecords, setLoanRecords] = useState([]);
//     const [repaymentRecords, setRepaymentRecords] = useState([]);
//     const [filteredAttendance, setFilteredAttendance] = useState([]);
//     const [totalAttendance, setTotalAttendance] = useState(0);
//     const [totalPresent, setTotalPresent] = useState(0);
//     const [totalAbsent, setTotalAbsent] = useState(0);
//     const [totalHalfDay, setTotalHalfDay] = useState(0);
//     const [totalPaidLeave, setTotalPaidLeave] = useState(0);
//     const [totalUnpaidLeave, setTotalUnpaidLeave] = useState(0);
//     const [totalOvertime, setTotalOvertime] = useState(0);
//     const [totalWeeklyOff, setTotalWeeklyOff] = useState(0);

//     useEffect(() => {
//         const fetchSetting = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
//                 setSetting(response.data);
//                 console.log("Settings fetched:", response.data);
//             } catch (error) {
//                 console.error('Error fetching Dashboard Logo', error);
//             }
//         };

//         fetchSetting();
//         fetchEmployeeDetails(record.employeeId);
//         fetchAttendanceRecords(record.employeeId);
//         fetchLoanDetails(record.employeeId);
//         fetchRepaymentDetails(record.employeeId);

//     }, [record.employeeId]);

//     const fetchAttendanceRecords = async (employeeId) => {
//         try {
//             // Fetch attendance records
//             const attendanceResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/attendance/${employeeId}`);
//             const attendanceData = attendanceResponse.data || [];
//             setAttendanceRecords(attendanceData);
//             console.log("Attendance records fetched:", attendanceData);

//             // Extract the month to filter by
//             const monthToFilter = new Date(record.month).getMonth(); // Assuming `record.month` is in a date format or YYYY-MM format

//             // Filter attendance and calculate totals
//             const filtered = attendanceData.filter(att => {
//                 const attDate = new Date(att.date);
//                 return attDate.getMonth() === monthToFilter;
//             });

//             setFilteredAttendance(filtered);
//             setTotalAttendance(filtered.length);
//             setTotalPresent(filtered.filter(att => att.status === 'Present').length);
//             setTotalAbsent(filtered.filter(att => att.status === 'Absent').length);
//             setTotalHalfDay(filtered.filter(att => att.status === 'Half Day').length);
//             setTotalPaidLeave(filtered.filter(att => att.status === 'Paid Leave').length);
//             setTotalUnpaidLeave(filtered.filter(att => att.status === 'Unpaid Leave').length);
//             setTotalOvertime(filtered.filter(att => att.status === 'Overtime').length);
//             setTotalWeeklyOff(filtered.filter(att => att.status === 'Weekly Off').length);

//         } catch (error) {
//             console.error('Error fetching attendance records:', error);
//         }

//     };

//     const fetchEmployeeDetails = async (employeeId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`);
//             setEmployeeDetails(response.data);
//             console.log('Employee details fetched:', response.data);
//         } catch (error) {
//             console.error('Error fetching employee details:', error);
//         }
//     };

//     const fetchLoanDetails = async (employeeId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/loandetails/${employeeId}`);
//             setLoanRecords(response.data);
//             console.log('Loan records fetched:', response.data);
//         } catch (error) {
//             console.error("Error fetching loan details:", error);
//         }
//     };

//     const fetchRepaymentDetails = async (employeeId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/repaymentdetails/${employeeId}`);
//             setRepaymentRecords(response.data);
//             console.log('Repayment records fetched:', response.data);
//         } catch (error) {
//             console.error("Error fetching repayment details:", error);
//         }
//     };


//     const handlePrint = () => {
//         window.print();
//     };

//     return (
//         <div className="container-fluid">
//             <div className="row py-2 px-3">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <div>
//                             <div className='logo w-100 h-40 p-2'>
//                                 <img className='w-50 h-75' src={`${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${setting.landingPageLogo}`} alt="LOGO" />
//                             </div>
//                         </div>
//                         <button type="button" className="btn-close" onClick={onClose}></button>
//                     </div>
//                     <div className="d-flex gap-3 flex-column">
//                         <div className="bg-success text-white p-2 text-center border rounded">Present: {totalPresent}</div>
//                         <div className="bg-danger text-white p-2 text-center border rounded">Absent: {totalAbsent}</div>
//                         <div className="bg-info text-white p-2 text-center border rounded">Half Day: {totalHalfDay}</div>
//                         <div className="bg-warning text-white p-2 text-center border rounded">Weekly Off: {totalWeeklyOff}</div>
//                         <div className="bg-warning text-white p-2 text-center border rounded">Unpaid Leave: {totalUnpaidLeave}</div>
//                         <div className="bg-warning text-white p-2 text-center border rounded">Paid Leave: {totalPaidLeave}</div>
//                         <div className="bg-primary text-white p-2 text-center border rounded">Overtime: {totalOvertime}</div>
//                     </div>
//                     <div className="modal-footer">
//                         <button onClick={handlePrint} className="btn btn-success print-button"><i className="fa fa-download"></i> Print</button>
//                         <button onClick={onClose} className="btn btn-danger print-button"><i className="fa fa-arrow-left"></i> Back</button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default SalaryPreview;




// import axios from 'axios';
// import React, { useEffect, useState } from 'react';

// function SalaryPreview({ record, onClose }) {
//     const [setting, setSetting] = useState({});
//     const [attendanceRecords, setAttendanceRecords] = useState([]);
//     const [employeeDetails, setEmployeeDetails] = useState({});
//     const [totalPresent, setTotalPresent] = useState(0);
//     const [totalAbsent, setTotalAbsent] = useState(0);
//     const [totalHalfDay, setTotalHalfDay] = useState(0);
//     const [totalPaidLeave, setTotalPaidLeave] = useState(0);
//     const [totalUnpaidLeave, setTotalUnpaidLeave] = useState(0);
//     const [totalOvertime, setTotalOvertime] = useState(0);
//     const [totalWeeklyOff, setTotalWeeklyOff] = useState(0);

//     useEffect(() => {
//         const fetchSetting = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
//                 setSetting(response.data);
//             } catch (error) {
//                 console.error('Error fetching settings', error);
//             }
//         };

//         fetchSetting();
//         if (record.employeeId) {
//             fetchEmployeeDetails(record.employeeId);
//             fetchAttendanceRecords(record.employeeId, record.month, record.year);
//         }
//     }, [record.employeeId, record.month, record.year]);

//     const fetchAttendanceRecords = async (employeeId, selectedMonth, selectedYear) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/attendance/${employeeId}`);
//             const attendanceData = response.data || [];
//             setAttendanceRecords(attendanceData);
//             filterAttendanceRecords(selectedMonth, selectedYear, attendanceData);
//         } catch (error) {
//             console.error('Error fetching attendance records:', error);
//         }
//     };

//     const fetchEmployeeDetails = async (employeeId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`);
//             setEmployeeDetails(response.data);
//         } catch (error) {
//             console.error('Error fetching employee details:', error);
//         }
//     };

//     const filterAttendanceRecords = (selectedMonth, selectedYear, data) => {
//         const month = selectedMonth - 1; // JS months are 0-based
//         const year = selectedYear;

//         const filteredRecords = data.filter(record => {
//             const recordDate = new Date(record.date);
//             return recordDate.getMonth() === month && recordDate.getFullYear() === year;
//         });

//         const presentCount = filteredRecords.filter(record =>
//             ['present', 'overtime'].includes(record.status.toLowerCase())
//         ).length;
//         const absentCount = filteredRecords.filter(record =>
//             ['absent'].includes(record.status.toLowerCase())
//         ).length;
//         const halfDayCount = filteredRecords.filter(record =>
//             ['half day'].includes(record.status.toLowerCase())
//         ).length;
//         const paidLeaveCount = filteredRecords.filter(record =>
//             ['paid leave'].includes(record.status.toLowerCase())
//         ).length;
//         const unpaidLeaveCount = filteredRecords.filter(record =>
//             ['unpaid leave'].includes(record.status.toLowerCase())
//         ).length;
//         const overtimeCount = filteredRecords.filter(record =>
//             ['overtime'].includes(record.status.toLowerCase())
//         ).length;
//         const weeklyOffCount = filteredRecords.filter(record =>
//             ['weekly off'].includes(record.status.toLowerCase())
//         ).length;

//         setTotalPresent(presentCount);
//         setTotalAbsent(absentCount);
//         setTotalHalfDay(halfDayCount);
//         setTotalPaidLeave(paidLeaveCount);
//         setTotalUnpaidLeave(unpaidLeaveCount);
//         setTotalOvertime(overtimeCount);
//         setTotalWeeklyOff(weeklyOffCount);
//     };

//     // loan Details 
//     useEffect(() => {
//         fetchLoanDetails();
//         fetchRepaymentDetails();
//     }, []);

//     const fetchLoanDetails = async () => {
//         try {
//             setLoading(true);
//             const response = await axios.get(
//                 `${process.env.REACT_APP_LOCAL_URL}/api/loandetails/${employee.id}`
//             );
//             setLoanRecords(response.data);
//         } catch (error) {
//             console.error("Error fetching loan details:", error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const fetchRepaymentDetails = async () => {
//         try {
//             setLoading(true);
//             const response = await axios.get(
//                 `${process.env.REACT_APP_LOCAL_URL}/api/repaymentdetails/${employee.id}`
//             );
//             setRepaymentRecords(response.data);
//         } catch (error) {
//             console.error("Error fetching repayment details:", error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handlePaymentBonusIncentive = (record) => {
//         // Handle the payment addition logic here
//     };

//     const calculateTotalPayments = (bonusId) => {
//         const payments = paymentBonusIncentive.filter(payment => payment.bonousinsentiveId === bonusId);
//         return payments.reduce((total, payment) => total + parseFloat(payment.paymentAmount), 0);
//     };

//     const getStatusStyle = (status) => {
//         switch (status) {
//             case 'Completed':
//                 return { backgroundColor: 'blue', color: 'white' };
//             case 'Partially Paid':
//                 return { backgroundColor: 'yellow', color: 'black' };
//             case 'Pending':
//                 return { backgroundColor: 'red', color: 'white' };
//             default:
//                 return {};
//         }
//     };

//     const getTotalRepaymentAmount = (loanId) => {
//         // Filter repayment records for the specific loanId
//         const repaymentsForLoan = repaymentRecords.filter(repayment => repayment.loanId === loanId);

//         // Calculate total repayment amount
//         const totalRepaymentAmount = repaymentsForLoan.reduce((total, repayment) => {
//             return total + parseFloat(repayment.repaymentAmount || 0);
//         }, 0);

//         return totalRepaymentAmount.toFixed(2); // Adjust as per your requirement
//     };

//     const calculateLoanDue = (loanAmount, totalRepaymentAmount) => {
//         const loanDue = parseFloat(loanAmount) - parseFloat(totalRepaymentAmount);
//         return loanDue.toFixed(2); // Adjust as per your requirement
//     };

//     const getLoanStatus = (loanDue) => {
//         return parseFloat(loanDue) === 0 ? 'Close' : 'Open';
//     };


//     // loan Details 

//     const handlePrint = () => {
//         window.print();
//     };

//     return (
//         <div className="container-fluid">
//             <div className="row py-2 px-3">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <div>
//                             <div className='logo w-100 h-40 p-2'>
//                                 <img className='w-50 h-75' src={`${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${setting.landingPageLogo}`} alt="LOGO" />
//                             </div>
//                         </div>
//                         <button type="button" className="btn-close" onClick={onClose}></button>
//                     </div>
//                     <div className="d-flex gap-3 ">
//                         <div className="bg-success text-white p-2 text-center border rounded">Present: {totalPresent}</div>
//                         <div className="bg-danger text-white p-2 text-center border rounded">Absent: {totalAbsent}</div>
//                         <div className="bg-info text-white p-2 text-center border rounded">Half Day: {totalHalfDay}</div>
//                         <div className="bg-warning text-white p-2 text-center border rounded">Weekly Off: {totalWeeklyOff}</div>
//                         <div className="bg-warning text-white p-2 text-center border rounded">Unpaid Leave: {totalUnpaidLeave}</div>
//                         <div className="bg-warning text-white p-2 text-center border rounded">Paid Leave: {totalPaidLeave}</div>
//                         <div className="bg-primary text-white p-2 text-center border rounded">Overtime: {totalOvertime}</div>
//                     </div>
//                     <div className='col-md-12' style={{ maxHeight: "250px", overflowY: "auto" }}>
//                         <table className="table table-striped table-bordered" style={{ width: "100%" }}>
//                             <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
//                                 <tr>
//                                     <th>Loan Number</th>
//                                     <th>Loan Date</th>
//                                     <th>Loan Type</th>
//                                     <th>Loan Amt.</th>
//                                     <th>Repayment Amt.</th>
//                                     <th>Loan Due</th>
//                                     <th>Loan Status.</th>
//                                     <th>Action</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {loanRecords.length === 0 ? (
//                                     <tr>
//                                         <td colSpan="8" className="text-center">No loan records found.</td>
//                                     </tr>
//                                 ) : (
//                                     loanRecords.map(record => (
//                                         <tr className="bg-dark text-capitalize" key={`${record.id}-${record.date}`}>
//                                             <td>{record.loanNumber}</td>
//                                             <td>{new Date(record.loanDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
//                                             <td>{record.loanFor}</td>
//                                             <td>{record.loanAmount}</td>
//                                             <td>{getTotalRepaymentAmount(record.id)}</td>
//                                             <td>{calculateLoanDue(record.loanAmount, getTotalRepaymentAmount(record.id))}</td>
//                                             <td>{getLoanStatus(calculateLoanDue(record.loanAmount, getTotalRepaymentAmount(record.id)))}</td>
//                                             <td><button className="btn btn-outline-success" onClick={() => handleloanRepaymentHistory(record)}>View Repayment</button></td>
//                                         </tr>
//                                     ))
//                                 )}
//                             </tbody>
//                         </table>
//                     </div>
//                     <div className="modal-footer">
//                         <button onClick={handlePrint} className="btn btn-success print-button"><i className="fa fa-download"></i> Print</button>
//                         <button onClick={onClose} className="btn btn-danger print-button"><i className="fa fa-arrow-left"></i> Back</button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default SalaryPreview;



import axios from 'axios';
import React, { useEffect, useState } from 'react';

function SalaryPreview({ record, onClose }) {
    const [setting, setSetting] = useState({});
    const [attendanceRecords, setAttendanceRecords] = useState([]);
    const [employeeDetails, setEmployeeDetails] = useState([]);
    const [loanRecords, setLoanRecords] = useState([]);
    const [repaymentRecords, setRepaymentRecords] = useState([]);
    const [totalPresent, setTotalPresent] = useState(0);
    const [totalAbsent, setTotalAbsent] = useState(0);
    const [totalHalfDay, setTotalHalfDay] = useState(0);
    const [totalPaidLeave, setTotalPaidLeave] = useState(0);
    const [totalUnpaidLeave, setTotalUnpaidLeave] = useState(0);
    const [totalOvertime, setTotalOvertime] = useState(0);
    const [totalWeeklyOff, setTotalWeeklyOff] = useState(0);
    const [totalAttencance, setTotalAttencance] = useState(0);
    // Hr Manager 
    const [hrManager, setHrManager] = useState([]);

    useEffect(() => {
        fetchHrManager();
    }, []);


    useEffect(() => {
        const fetchSetting = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
                setSetting(response.data);
                console.log("setting ", response.data)
            } catch (error) {
                console.error('Error fetching settings', error);
            }
        };

        fetchSetting();

        if (record.employeeId) {
            fetchEmployeeDetails(record.employeeId);
            fetchAttendanceRecords(record.employeeId, record.month, record.year);
            fetchLoanDetails(record.employeeId);
            fetchRepaymentDetails(record.employeeId);
        }
    }, [record.employeeId, record.month, record.year]);

    const fetchHrManager = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/hrmanager`);
            setHrManager(response.data);
        } catch (error) {
            console.error("Error fetching timelines:", error);
        }
    };

    const fetchAttendanceRecords = async (employeeId, selectedMonth, selectedYear) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/attendance/${employeeId}`);
            const attendanceData = response.data || [];
            setAttendanceRecords(attendanceData);
            filterAttendanceRecords(selectedMonth, selectedYear, attendanceData);
        } catch (error) {
            console.error('Error fetching attendance records:', error);
        }
    };

    const fetchEmployeeDetails = async (employeeId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`);

            // Assuming the response data is an array with one object
            const employeeData = response.data.length > 0 ? response.data[0] : null;

            setEmployeeDetails(employeeData);
            console.log("employeeDetails", employeeData);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };


    const fetchLoanDetails = async (employeeId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/loandetails/${employeeId}`);
            setLoanRecords(response.data || []);
        } catch (error) {
            console.error('Error fetching loan details:', error);
        }
    };

    const fetchRepaymentDetails = async (employeeId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/repaymentdetails/${employeeId}`);
            setRepaymentRecords(response.data || []);

        } catch (error) {
            console.error('Error fetching repayment details:', error);
        }
    };

    const filterAttendanceRecords = (selectedMonth, selectedYear, data) => {
        const month = selectedMonth - 1; // JS months are 0-based
        const year = selectedYear;

        const filteredRecords = data.filter(record => {
            const recordDate = new Date(record.date);
            return recordDate.getMonth() === month && recordDate.getFullYear() === year;
        });

        const presentCount = filteredRecords.filter(record =>
            ['present'].includes(record.status.toLowerCase())
        ).length;
        const absentCount = filteredRecords.filter(record =>
            ['absent'].includes(record.status.toLowerCase())
        ).length;
        const halfDayCount = filteredRecords.filter(record =>
            ['half day'].includes(record.status.toLowerCase())
        ).length;
        const paidLeaveCount = filteredRecords.filter(record =>
            ['paid leave'].includes(record.status.toLowerCase())
        ).length;
        const unpaidLeaveCount = filteredRecords.filter(record =>
            ['unpaid leave'].includes(record.status.toLowerCase())
        ).length;
        const overtimeCount = filteredRecords.filter(record =>
            ['overtime'].includes(record.status.toLowerCase())
        ).length;
        const weeklyOffCount = filteredRecords.filter(record =>
            ['weekly off'].includes(record.status.toLowerCase())
        ).length;

        const TotalAttendance = presentCount + absentCount + halfDayCount + paidLeaveCount + unpaidLeaveCount + overtimeCount + weeklyOffCount;
        setTotalAttencance(TotalAttendance)
        setTotalPresent(presentCount);
        setTotalAbsent(absentCount);
        setTotalHalfDay(halfDayCount);
        setTotalPaidLeave(paidLeaveCount);
        setTotalUnpaidLeave(unpaidLeaveCount);
        setTotalOvertime(overtimeCount);
        setTotalWeeklyOff(weeklyOffCount);
    };

    const getTotalRepaymentAmount = (loanId) => {
        // Filter repayment records for the specific loanId
        const repaymentsForLoan = repaymentRecords.filter(repayment => repayment.loanId === loanId);

        // Calculate total repayment amount
        const totalRepaymentAmount = repaymentsForLoan.reduce((total, repayment) => {
            return total + parseFloat(repayment.repaymentAmount || 0);
        }, 0);

        return totalRepaymentAmount.toFixed(2); // Adjust as per your requirement
    };

    const calculateLoanDue = (loanAmount, totalRepaymentAmount) => {
        const loanDue = parseFloat(loanAmount) - parseFloat(totalRepaymentAmount);
        return loanDue.toFixed(2); // Adjust as per your requirement
    };

    const getLoanStatus = (loanDue) => {
        return parseFloat(loanDue) === 0 ? 'Closed' : 'Open';
    };

    const handlePrint = () => {
        window.print();
    };

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    return (
        <div className="container-fluid px-4 py-2">
            <div className="row ">
                <div className="modal-content ">
                    <div className="modal-header d-flex align-item-center justify-content-center">
                        <div className=' d-flex flex-column align-items-center justify-content-center'>
                            <div style={{ height: "100%", width: "100%", maxWidth: "250px" }} className='logo p-1'>
                                <img className='w-100 h-100' src={`${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${setting.landingPageLogo}`} alt="LOGO" />
                            </div>
                            <h6 className='fw-bolder text-black'>{setting.title}</h6>
                            <h6 className='fw-bolder text-black'>{setting.address}</h6>
                        </div>
                    </div>
                    <div>
                        <h3 className='text-center pt-1 fw-bolder m-0 text-black'>SALARY SLIP</h3>
                        <hr className='m-0' />
                        <div className='d-flex align-items-center justify-content-between px-4 py-1'>
                            <div>
                                <h5 className='fw-bolder text-black'>Employee Name: {employeeDetails.employeeName}</h5>
                                <h6 className='fw-bolder text-black'>Employee Code: {employeeDetails.employeeCode}</h6>
                                <h6 className='fw-bolder text-black'>Employee Designation: {employeeDetails.positionName}</h6>
                                <h6 className='fw-bolder text-black'>Employee Department: {employeeDetails.departmentName}</h6>
                            </div>
                            <div>
                                <h5 className='fw-bolder text-black'>Salary Month: {monthNames[record.month - 1]}</h5>
                                <h5 className='fw-bolder text-black'>Salary Year:  {record.year}</h5>
                            </div>
                        </div>

                    </div>
                    <div>
                        <div className='rounded border bg-white'>
                            <div className='d-flex'>
                                <div className='col-md-6 p-2'>
                                    <h6 style={{ backgroundColor: "#3F4D67" }} className='text-white w-100 fw-bolder m-0 p-2'> Total Attendance Details :</h6>
                                    <table style={{ backgroundColor: "#3F4D67" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
                                        <tbody >
                                            <div className='d-flex justify-content-between '>
                                                <div className='p-0 w-100 '>
                                                    <table className="table table-striped table-bordered m-0">
                                                        <tbody>
                                                            <tr>
                                                                <td bgcolor="#f2f3f4" >
                                                                    <p className="mb-0 fw-bold text-success"> Present </p>
                                                                </td>
                                                                <td>
                                                                    <p className="mb-0">: {totalPresent || '0'} Days</p>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td bgcolor="#f2f3f4" >
                                                                    <p className="mb-0 fw-bold text-success"> Weekly Off </p>
                                                                </td>
                                                                <td>
                                                                    <p className="mb-0">: {totalWeeklyOff || '0'} Days</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td bgcolor="#f2f3f4" >
                                                                    <p className="mb-0 fw-bold text-success"> Half Day </p>
                                                                </td>
                                                                <td>
                                                                    <p className="mb-0">: {totalHalfDay || '0'} Days</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td bgcolor="#f2f3f4" >
                                                                    <p className="mb-0 fw-bold text-success"> Over Time </p>
                                                                </td>
                                                                <td>
                                                                    <p className="mb-0">: {totalOvertime || '0'} Days</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className='p-0 w-100'>
                                                    <table className="table table-striped table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <td bgcolor="#f2f3f4" >
                                                                    <p className="mb-0 fw-bold text-success"> Paid Leave </p>
                                                                </td>
                                                                <td>
                                                                    <p className="mb-0">: {totalPaidLeave || '0'} Days</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td bgcolor="#f2f3f4" >
                                                                    <p className="mb-0 fw-bold text-danger"> UnPaid Leave </p>
                                                                </td>
                                                                <td>
                                                                    <p className="mb-0">: {totalUnpaidLeave || '0'} Days</p>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td bgcolor="#f2f3f4" >
                                                                    <p className="mb-0 fw-bold text-danger"> Absent</p>
                                                                </td>
                                                                <td>
                                                                    <p className="mb-0">: {totalAbsent || '0'} Days</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td bgcolor="#f2f3f4">
                                                                    <p className="mb-0 fw-bold text-black"> Total Days </p>
                                                                </td>
                                                                <td>
                                                                    <p className="mb-0">: {totalAttencance || '0'} Days</p>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </tbody>
                                    </table>
                                    <h6 style={{ backgroundColor: "#3F4D67" }} className='text-white w-100 fw-bolder m-0 p-2'> Salary Details :</h6>
                                    <table style={{ backgroundColor: "#3F4D67" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
                                        <tbody >
                                            <div className='d-flex justify-content-between bg-danger'>
                                                <div className='p-0 w-100'>
                                                    <table className="table table-striped table-bordered ">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <p className="mb-0 fw-bold text-black">1. Basic </p>
                                                                </td>
                                                                <td>
                                                                    <p className="mb-0">&#x20B9;{record.basicSalaryMonth != null ? record.basicSalaryMonth.toFixed(2) : '0.00'}</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <p className="mb-0 fw-bold text-black">3. VDA </p>
                                                                </td>
                                                                <td>
                                                                    <p className="mb-0">&#x20B9;{record.VDAmonth != null ? record.VDAmonth.toFixed(2) : '0.00'}</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <p className="mb-0 fw-bold text-black">5. HRA </p>
                                                                </td>
                                                                <td>
                                                                    <p className="mb-0">&#x20B9;{record.houserentallowances != null ? record.houserentallowances.toFixed(2) : '0.00'}</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <tr >
                                                        <td>
                                                            <p className="mb-0 fw-bolder font-bold">7. Gross Salary </p>
                                                        </td>
                                                    </tr>

                                                </div>
                                                <div className='p-0 w-100'>
                                                    <table className="table table-striped table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <p className="mb-0 fw-bold text-black">2. CA </p>
                                                                </td>
                                                                <td>
                                                                    <p className="mb-0">&#x20B9;{record.conveyanceallowances != null ? record.conveyanceallowances.toFixed(2) : '0.00'}</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <p className="mb-0 fw-bold text-black">4. DA</p>
                                                                </td>
                                                                <td>
                                                                    <p className="mb-0"> &#x20B9;{record.dearnessallowances != null ? record.dearnessallowances.toFixed(2) : '0.00'}</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <p className="mb-0 fw-bold text-black">6. SA </p>
                                                                </td>
                                                                <td>
                                                                    <p className="mb-0">&#x20B9;{record.specialallowances != null ? record.specialallowances.toFixed(2) : '0.00'}</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <tr>
                                                        <td className=''>
                                                            <p className="mb-0 fw-bolder ">: &#x20B9; {record.grossSalaryMonth != null ? record.grossSalaryMonth.toFixed(2) : '0.00'}</p>
                                                        </td>
                                                    </tr>
                                                </div>
                                            </div>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='col-md-6 p-2'>
                                    <table style={{ backgroundColor: "#3F4D67" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
                                        <tbody >

                                            <h6 className='text-white fw-bolder m-0 p-2'>EPF ESIC Contribution :</h6>
                                            <tr>
                                                <td width="200">
                                                    <p className="mb-0">EPF - ESIC Applicable</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">
                                                        {record.epfesicApplicableMonth === 1 ? 'Yes' : record.epfesicApplicableMonth === 0 ? 'No' : ''}
                                                    </p>

                                                </td>
                                            </tr>
                                            <h6 className='text-white fw-bolder m-0 p-2'>Employeer Contribution :</h6>
                                            <tr>
                                                <td bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0">EPF 12% <span>: &#x20B9;{record.epfEmployerMonth || '0'}</span> </p>
                                                </td>

                                                <td bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0"> ESIC 3.25% <span>: &#x20B9;{record.esicEmployerMonth || '0'}</span> </p>
                                                </td>

                                            </tr>
                                            <tr>
                                                <td className='bg-body-secondary' bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0 fw-bolder">18. Total Employer Contribution </p>
                                                </td>
                                                <td className='bg-body-secondary'>
                                                    <p className="mb-0 fw-bolder">: &#x20B9;{record.totalEmployerContributionMonth || '0'}</p>
                                                </td>
                                            </tr>
                                            <h6 className='text-white fw-bolder m-0 p-2'>Employee Contribution :</h6>
                                            <tr>
                                                <td bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0">EPF 12% <span className="mb-0">: &#x20B9;{record.epfEmployeeMonth || '0'}</span></p>
                                                </td>

                                                <td bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0">ESIC 0.75% <span className="mb-0">: &#x20B9;{record.esicEmployeeMonth || '0'}</span> </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-body-secondary' bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0 fw-bolder">19. Total Employee Deduction </p>
                                                </td>
                                                <td className='bg-body-secondary'>
                                                    <p className="mb-0 fw-bolder">: &#x20B9;{record.totalEmployeeDeductionMonth || '0'}</p>
                                                </td>
                                            </tr>
                                            <h6 className='text-white fw-bolder m-0 p-2'>TDS :</h6>
                                            <tr>
                                                <td bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0">TDS Applicable</p>
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center justify-content-between gap-4">
                                                        <p className="mb-0">
                                                            {record.tdsApplicableMonth === 1 ? 'Yes' : record.tdsApplicableMonth === 0 ? 'No' : ''}
                                                        </p>
                                                        {record.tdsApplicableMonth === 1 && (
                                                            <p className="mb-0">: {record.tdsDeductionPercentage != null ? record.tdsDeductionPercentage.toFixed(1) : '0.0'}%</p>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className='bg-body-secondary' bgcolor="#f2f3f4" width="300">
                                                    <p className="mb-0 fw-bolder">20. TDS Deduction Amt.</p>
                                                </td>
                                                <td className='bg-body-secondary'>
                                                    <p className="mb-0 fw-bolder">: &#x20B9;{record.totalTdsDeductionMonth != null ? record.totalTdsDeductionMonth.toFixed(2) : '0.00'}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <hr className='m-0' />
                            <div className='d-flex'>
                                <div className='col-md-6 p-2'>
                                    <table style={{ backgroundColor: "#3F4D67" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
                                        <tbody >
                                            {/* <h6 className='text-white fw-bolder m-0 p-2'>Overtime/Half Day :</h6> */}

                                            <tr>
                                                <td bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0 font-bold">8. Over Time </p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">: &#x20B9;{record.overtimeMonth != null ? record.overtimeMonth.toFixed(2) : '0.00'} </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0 font-bold">9. Half Day</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">: &#x20B9;{record.halfDayMonth != null ? record.halfDayMonth.toFixed(2) : '0.00'}</p>
                                                </td>
                                            </tr>
                                            {/* <h6 className='text-white fw-bolder m-0 p-2'>Total Salary :</h6> */}
                                            <tr>
                                                <td className='bg-body-secondary' bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0 font-bold">10. Total Salary </p>
                                                </td>
                                                <td className='bg-body-secondary' bgcolor="#f2f3f4" width="200">
                                                <p className="mb-0 fw-bolder ">: &#x20B9;{record.totalsalary != null ? record.totalsalary.toFixed(2) : '0.00'}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0 font-bold">11. Salary With Contribution</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">: &#x20B9; {record.salaryWithContribution != null ? record.salaryWithContribution.toFixed(2) : '0.00'}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0 font-bold">12. Salary After Deduction </p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">: &#x20B9;{record.salaryAfterDeduction != null ? record.salaryAfterDeduction.toFixed(2) : '0.00'}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0 font-bold">13. Additional Allowance: </p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">: &#x20B9; {record.additionalAllowance != null ? record.additionalAllowance.toFixed(2) : '0.00'}</p>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0 font-bold">Allowance Description: </p>
                                                </td>
                                                <td>
                                                    <small className="mb-0">: {record.allowanceDescription || 'NA'}</small>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0 font-bold">14. Salary Deduction: </p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">: &#x20B9; {record.salaryDeduction != null ? record.salaryDeduction.toFixed(2) : '0.00'}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0 font-bold">Deduction Description: </p>
                                                </td>
                                                <td>
                                                    <small className="mb-0">: {record.deductionDescription || 'NA'}</small>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0 fw-bolder font-bold">15. Gross in Hand Salary  </p>
                                                </td>
                                                <td>
                                                    <p className="mb-0 fw-bolder">: &#x20B9; {record.totalInHandSalaryMonth != null ? record.totalInHandSalaryMonth.toFixed(2) : '0.00'}<span className='text-secondary'> </span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0 font-bold">Total Advance Amt. </p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">: &#x20B9;{record.totalAdvanceAmount != null ? record.totalAdvanceAmount.toFixed(2) : '0.00'}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0 font-bold">16. Advance Deduction </p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">: &#x20B9;{record.advanceDeduction != null ? record.advanceDeduction.toFixed(2) : '0.00'}</p>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className='bg-body-secondary' bgcolor="#f2f3f4" width="200">
                                                    <p className="mb-0 fw-bolder font-bold">17. Net Salary Payable  </p>
                                                </td>
                                                <td className='bg-body-secondary'>
                                                    <p className="mb-0 fw-bolder">: &#x20B9;{record.netSalaryPayableMonth != null ? record.netSalaryPayableMonth.toFixed(2) : '0.00'}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='col-md-6 p-2 d-flex  flex-column '>
                                    <div>
                                        <table className="table-striped table-bordered bg-dark" style={{ width: "100%" }}>
                                            <thead >
                                                <tr>
                                                    <td style={{ backgroundColor: "#3F4D67" }} className='text-white fw-bolder m-0 p-2'>Loan No.</td>
                                                    <td style={{ backgroundColor: "#3F4D67" }} className='text-white fw-bolder m-0 p-2'>Loan Amt.</td>
                                                    <td style={{ backgroundColor: "#3F4D67" }} className='text-white fw-bolder m-0 p-2'>Loan Due</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loanRecords.length === 0 ? (
                                                    <tr>
                                                        <td colSpan="8" className="text-center text-black bg-white p-1">No loan records found.</td>
                                                    </tr>
                                                ) : (
                                                    loanRecords.filter(record => {
                                                        const totalRepayment = calculateLoanDue(record.loanAmount, getTotalRepaymentAmount(record.id));
                                                        return totalRepayment !== 0 && totalRepayment !== "0.00" && totalRepayment !== "0" && totalRepayment !== null && totalRepayment !== undefined;
                                                    }).map(record => (
                                                        <tr className="bg-dark text-capitalize" key={`${record.id}-${record.date}`}>
                                                            <td className="text-black bg-white p-1">{record.loanNumber}</td>
                                                            <td className="text-black bg-white p-1">{record.loanAmount}</td>
                                                            {/* <td className="text-black bg-white p-1">{getTotalRepaymentAmount(record.id)}</td> */}
                                                            <td className="text-black bg-white p-1">{calculateLoanDue(record.loanAmount, getTotalRepaymentAmount(record.id))}</td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='bg-light my-1 p-3'>
                                        <div className='d-flex align-align-items-center w-100 justify-content-around '>
                                            <div className='shadow-sm bg-white w-50 border rounded p-1 m-1 d-flex align-items-center justify-content-center flex-column'>
                                                <h5 className='text-dark m-0 fw-bolder'>Salary With Contribution</h5>
                                                <small className='text-center border-bottom'>(10-19-20)</small>
                                                <h1 style={{ fontSize: "30px" }} className='font-bold text-center text-black fw-bold text-primary'> &#x20B9;{record.salaryWithContribution != null ? record.salaryWithContribution.toFixed(2) : '0.00'}</h1>
                                            </div>
                                            <div className='shadow-sm bg-white w-50 border rounded p-1 m-1 d-flex align-items-center justify-content-center flex-column'>
                                                <h5 className='text-dark m-0 fw-bolder'>Salary After Deduction</h5>
                                                <small className='text-center border-bottom'>(12+13-14)</small>
                                                <h1 style={{ fontSize: "30px" }} className='font-bold text-center text-black fw-bold text-primary'> &#x20B9;{record.salaryAfterDeduction != null ? record.salaryAfterDeduction.toFixed(2) : '0.00'}</h1>
                                            </div>
                                        </div>
                                        <div className='d-flex rounded  w-100 align-items-center justify-content-center flex-column p-1'>
                                            <div className='shadow-sm bg-white w-100 border rounded p-1 m-1 d-flex align-items-center justify-content-center flex-column '>
                                                <h5 className='text-dark m-0 fw-bolder'>Net Payable Salary</h5>
                                                <small className='text-center border-bottom'>(15-16)</small>
                                                <h1 style={{ fontSize: "30px" }} className='font-bold text-center text-black fw-bold text-primary'> &#x20B9;{record.netSalaryPayableMonth != null ? record.netSalaryPayableMonth.toFixed(2) : '0.00'}</h1>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=' p-2 rounded border border-1'>
                                        <h6 className='fw-bolder text-black'>Description :- </h6>
                                        <p>{setting.description}</p>
                                        <hr className='m-0' />
                                        <p style={{ fontSize: "57%" }} className='m-0'>VDA- (Variable Dearness Allowance), HRA- (House Rent Allowance), CA- (Conveyance Allowance), SA- (Special Allowance ), DA- (Dearness Allowance )</p>
                                    </div>
                                    <small className='fw-bold text-end w-100 p-1'>HR Manager - {hrManager.employeeName}</small>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="modal-footer">
                        <button onClick={handlePrint} className="btn btn-success print-button"><i className="fa fa-download"></i> Print</button>
                        <button onClick={onClose} className="btn btn-danger print-button">Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SalaryPreview;
