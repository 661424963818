// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const E_CheckStatus = () => {
//     const [employeeData, setEmployeeData] = useState([]);
//     const [selectedEmployee, setSelectedEmployee] = useState(null);
//     const [status, setStatus] = useState('');

//     const employeeId = localStorage.getItem('employeeId');

//     useEffect(() => {
//         if (employeeId) {
//             fetchEmployeeDetails(employeeId);
//             fetchApprovedDetails(employeeId);
//         }
//     }, [employeeId]);

//     const fetchEmployeeDetails = async (employeeId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`);
//             if (response.data.length > 0) {
//                 const employee = response.data[0];
//                 setSelectedEmployee(employee);
//                 setStatus(employee.status);
//                 console.log("Employee Details:", employee);
//             }
//         } catch (error) {
//             console.error('Error fetching employee details:', error);
//         }
//     };

// const fetchApprovedDetails = async (employeeId) => {
//     try {
//         const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/latest-employee-data/${employeeId}`);
//         if (response.data.length > 0) {
//             setEmployeeData(response.data);
//             console.log("Approved Employee Data:", response.data);
//         }
//     } catch (error) {
//         console.error('Error fetching approved employee details:', error);
//     }
// };

//     return (
//         <div>
//             <h1>Employee Data</h1>
//             {employeeData.length > 0 ? (
//                 <ul>
//                     {employeeData.map(emp => (
//                         <li key={emp.id}>
//                             {emp.employeeName}: {emp.fromDate} to {emp.toDate} ({emp.status})
//                         </li>
//                     ))}
//                 </ul>
//             ) : (
//                 <p>No active employee data available.</p>
//             )}
//         </div>
//     );
// };

// export default E_CheckStatus;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import SidebarEmployee from '../../components/sidebar/SidebarEmployee';
// import SearchBarEmployee from '../../components/sidebar/SearchBarEmployee';
// import '../Dashboard.css';

// function E_EmployeeLeaveList({ handleLogout, username }) {
//     const [employeeDetails, setEmployeeDetails] = useState(null); // State for employee details
//     const [employeeHistory, setEmployeeHistory] = useState([]);
//     const [currentPage, setCurrentPage] = useState(1); // State for current page in pagination
//     const itemsPerPage = 10; // Number of items per page for pagination
//     const [employeeData, setEmployeeData] = useState([]);
//     const [selectedEmployee, setSelectedEmployee] = useState(null);
//     const [status, setStatus] = useState('');

//     const employeeId = localStorage.getItem('employeeId'); // Retrieve employeeId from localStorage

//     useEffect(() => {
//         if (employeeId) { // Fetch only if employeeId exists
//             fetchEmployeeDetails(employeeId);
//             fetchEmployeeHistory(employeeId); // Fetch employee history when employeeId is available
//             fetchApprovedDetails(employeeId);
//         }
//     }, [employeeId]);

//     const fetchEmployeeDetails = async (employeeId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`);
//             const employeeData = response.data.length > 0 ? response.data[0] : null;
//             setEmployeeDetails(employeeData);
//             console.log("employeeDetails", employeeData);
//         } catch (error) {
//             console.error('Error fetching employee details:', error);
//             toast.error('Error fetching employee details');
//         }
//     };

//     const fetchEmployeeHistory = async (employeeId) => {
//         try {
//             const response = await axios.get(
//                 `${process.env.REACT_APP_LOCAL_URL}/activeinactive_employee/${employeeId}`
//             );
//             const history = response.data;
//             const groupedHistory = groupLeaveAndActive(history);
//             setEmployeeHistory(groupedHistory);
//         } catch (error) {
//             console.error("Error fetching check-in/out history:", error);
//             toast.error('Error fetching employee history');
//         }
//     };

//     const fetchApprovedDetails = async (employeeId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/latest-employee-data/${employeeId}`);
//             if (response.data.length > 0) {
//                 setEmployeeData(response.data);
//                 console.log("Approved Employee Data:", response.data);
//             }
//         } catch (error) {
//             console.error('Error fetching approved employee details:', error);
//         }
//     };

//     const groupLeaveAndActive = (history) => {
//         const grouped = [];
//         let currentGroup = null;

//         history.forEach(entry => {
//             if (entry.status === 'request_leave') {
//                 if (currentGroup) {
//                     grouped.push(currentGroup);
//                 }
//                 // Create a new group for 'request_leave' entries
//                 currentGroup = { ...entry, activeDate: null };
//             } else if (entry.status === 'active') {
//                 if (currentGroup) {
//                     currentGroup.activeDate = entry.fromDate;
//                     currentGroup.activeDescription = entry.description;
//                     grouped.push(currentGroup);
//                     currentGroup = null;
//                 }
//             }
//         });

//         // Push the last group if there is one
//         if (currentGroup) {
//             grouped.push(currentGroup);
//         }

//         return grouped;
//     };


//     const formatDate = (dateString) => {
//         const date = new Date(dateString);
//         return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
//     };

//     const paginate = (pageNumber) => {
//         setCurrentPage(pageNumber);
//     };

//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItemsemployeehistory = employeeHistory ? employeeHistory.slice(indexOfFirstItem, indexOfLastItem) : [];

//     return (
//         <div className='d-flex w-100 h-100'>
//             <SidebarEmployee />
//             <div className='w-100'>
//                 <SearchBarEmployee />
//                 <div className="container-fluid my-2">
//                     <ToastContainer />
//                     <div className="row">
//                         <div className="col-xl-12">
//                             <div className="card shadow mb-4">
//                                 <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
//                                     <h6 className="m-0 font-weight-bold text-primary">Leave List</h6>
//                                 </div>
//                                 <div style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden" }} className="card-body">
//                                     <div className="row">
//                                         {/* <!-- Leave and Active Card --> */}
//                                         {currentItemsemployeehistory.length > 0 ? (
//                                             currentItemsemployeehistory
//                                                 .filter(entry => entry.status === 'request_leave') // Filter entries by status
//                                                 .map((entry) => {
//                                                     return (
//                                                         <div className="col-xl-3 col-md-6 mb-4" key={entry.id}>
//                                                             <div className="card shadow h-100 py-1">
//                                                                 <div className="card-body">
//                                                                     <div className="row no-gutters align-items-center">
//                                                                         <div className="col-md-12">
//                                                                             <div className="row">
//                                                                                 <div className="col-sm">
//                                                                                     Name
//                                                                                 </div>
//                                                                                 <div className="col-sm">
//                                                                                     <p className="m-0">: {employeeDetails?.employeeName || 'N/A'}</p>
//                                                                                 </div>
//                                                                             </div>
//                                                                             <div className="row">
//                                                                                 <div className="col-sm">
//                                                                                     Status
//                                                                                 </div>
//                                                                                 <div className="col-sm">
//                                                                                     <p className="m-0">: {entry.status}</p>
//                                                                                 </div>
//                                                                             </div>
//                                                                             <div className="row">
//                                                                                 <div className="col-sm">
//                                                                                     Reason
//                                                                                 </div>
//                                                                                 <div className="col-sm">
//                                                                                     <p className="m-0">: {entry.reason}</p>
//                                                                                 </div>
//                                                                             </div>
//                                                                             <div className="row">
//                                                                                 <div className="col-sm">
//                                                                                     From Date
//                                                                                 </div>
//                                                                                 <div className="col-sm">
//                                                                                     <p className="m-0">: {formatDate(entry.fromDate)}</p>
//                                                                                 </div>
//                                                                             </div>
//                                                                             <div className="row">
//                                                                                 <div className="col-sm">
//                                                                                     To Date
//                                                                                 </div>
//                                                                                 <div className="col-sm">
//                                                                                     <p className="m-0">: {entry.toDate ? formatDate(entry.toDate) : 'N/A'}</p>
//                                                                                 </div>
//                                                                             </div>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     );
//                                                 })
//                                         ) : (
//                                             <div className="col-12" style={{ textAlign: 'center' }}>
//                                                 <h5>No Leave Taken.</h5>
//                                             </div>
//                                         )}
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default E_EmployeeLeaveList;






import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SidebarEmployee from '../../components/sidebar/SidebarEmployee';
import SearchBarEmployee from '../../components/sidebar/SearchBarEmployee';
import '../Dashboard.css';

function E_CheckStatus ({ handleLogout, username }) {
    const [employeeDetails, setEmployeeDetails] = useState(null);
    const [employeeHistory, setEmployeeHistory] = useState([]);
    const [approvedLeaveDetails, setApprovedLeaveDetails] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const employeeId = localStorage.getItem('employeeId');

    useEffect(() => {
        if (employeeId) {
            fetchEmployeeData(employeeId);
        }
    }, [employeeId]);

    const fetchEmployeeData = async (employeeId) => {
        try {
            const [detailsResponse, historyResponse, dataResponse] = await Promise.all([
                axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`),
                axios.get(`${process.env.REACT_APP_LOCAL_URL}/activeinactive_employee/${employeeId}`),
                axios.get(`${process.env.REACT_APP_LOCAL_URL}/latest-employee-data/${employeeId}`)
            ]);
    
            console.log('Employee Details:', detailsResponse.data);
            console.log('Employee History:', historyResponse.data);
            console.log('Approved Leave Details:', dataResponse.data);
    
            setEmployeeDetails(detailsResponse.data.length > 0 ? detailsResponse.data[0] : null);
            setEmployeeHistory(groupLeaveAndActive(historyResponse.data));
            setApprovedLeaveDetails(dataResponse.data.filter(item => item.status === 'approved'));
        } catch (error) {
            console.error('Error fetching employee data:', error);
            toast.error('Error fetching employee data');
        }
    };
    

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    };

    const groupLeaveAndActive = (history) => {
        const grouped = [];
        let currentGroup = null;

        history.forEach(entry => {
            if (entry.status === 'request_leave') {
                if (currentGroup) {
                    grouped.push(currentGroup);
                }
                currentGroup = { ...entry, activeDate: null };
            } else if (entry.status === 'active') {
                if (currentGroup) {
                    currentGroup.activeDate = entry.fromDate;
                    currentGroup.activeDescription = entry.description;
                    grouped.push(currentGroup);
                    currentGroup = null;
                }
            }
        });

        if (currentGroup) {
            grouped.push(currentGroup);
        }

        return grouped;
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = employeeHistory.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(employeeHistory.length / itemsPerPage);

    return (
        <div className='d-flex w-100 h-100'>
            <SidebarEmployee />
            <div className='w-100'>
                <SearchBarEmployee username={username} handleLogout={handleLogout}/>
                <div className="container-fluid my-2">
                    <ToastContainer />
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 className="m-0 font-weight-bold text-primary">Request Leave</h6>
                                </div>
                                <div style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden" }} className="card-body">
                                    <div className="row">
                                        {currentItems.length > 0 ? (
                                            currentItems
                                                .filter(entry => entry.status === 'request_leave')
                                                .map((entry) => (
                                                    <div className="col-xl-3 col-md-6 mb-4" key={entry.id}>
                                                        <div className="card shadow h-100 py-1">
                                                            <div className="card-body">
                                                                <div className="row no-gutters align-items-center">
                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                            <div className="col-sm">Name</div>
                                                                            <div className="col-sm">
                                                                                <p className="m-0">: {employeeDetails?.employeeName || 'N/A'}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm">Status</div>
                                                                            <div className="col-sm">
                                                                                <p className="m-0">: {entry.status}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm">Reason</div>
                                                                            <div className="col-sm">
                                                                                <p className="m-0">: {entry.reason}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm">From Date</div>
                                                                            <div className="col-sm">
                                                                                <p className="m-0">: {formatDate(entry.fromDate)}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm">To Date</div>
                                                                            <div className="col-sm">
                                                                                <p className="m-0">: {entry.toDate ? formatDate(entry.toDate) : 'N/A'}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                        ) : (
                                            <div className="col-12" style={{ textAlign: 'center' }}>
                                                <h5>No Leave Taken.</h5>
                                            </div>
                                        )}
                                    </div>
                                    <hr />
                                    <h6 className="font-weight-bold text-primary">Status of the Request Leave</h6>
                                    <div className="row">
                                        {approvedLeaveDetails.length > 0 ? (
                                            approvedLeaveDetails.map((entry) => (
                                                <div className="col-xl-3 col-md-6 mb-4" key={entry.id}>
                                                    <div className="card shadow h-100 py-1">
                                                        <div className="card-body">
                                                            <div className="row no-gutters align-items-center">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-sm">Name</div>
                                                                        <div className="col-sm">
                                                                            <p className="m-0">: {entry.employeeName || 'N/A'}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm">Status</div>
                                                                        <div className="col-sm">
                                                                            <p className="m-0">: {entry.status}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm">Reason</div>
                                                                        <div className="col-sm">
                                                                            <p className="m-0">: {entry.description || 'N/A'}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm">From Date</div>
                                                                        <div className="col-sm">
                                                                            <p className="m-0">: {formatDate(entry.fromDate)}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm">To Date</div>
                                                                        <div className="col-sm">
                                                                            <p className="m-0">: {entry.toDate ? formatDate(entry.toDate) : 'N/A'}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="col-12" style={{ textAlign: 'center' }}>
                                                <h5>No Approved Leave Details.</h5>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {employeeHistory.length > itemsPerPage && (
                                    <div className="card-footer py-3">
                                        <nav aria-label="Page navigation">
                                            <ul className="pagination justify-content-center mb-0">
                                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                    <button className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>Previous</button>
                                                </li>
                                                {[...Array(totalPages)].map((_, index) => (
                                                    <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                        <button className="page-link" onClick={() => setCurrentPage(index + 1)}>{index + 1}</button>
                                                    </li>
                                                ))}
                                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                    <button className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default E_CheckStatus ;
