import React, { useState } from 'react';

const CreateUser = ({ onClose, employee ,onUpdate }) => {
    const [username, setUsername] = useState(employee ? employee.employeeName : '');
    const [email, setEmail] = useState(employee ? employee.employeeEmail : '');
    const [employeeId, setemployeeId] = useState(employee ? employee.id : '');
    const [password, setPassword] = useState('');
    const [userType, setUserType] = useState('user'); // Default to 'user'
    console.log("employee",employee);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_LOCAL_URL}/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, email, password, userType,employeeId }),
            });
            const data = await response.json();
            // Check if user creation is successful
            if (response.ok) {
                onUpdate();
                setTimeout(() => {
                    onClose();
                    window.location.reload();
                }, 1000); // 1 second delay
            } else {
                console.error('Error:');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div id="add" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
                        <div className="modal-header">
                            <h5 className="modal-title">Employee Signup</h5>
                            <button type="button" className="close" onClick={onClose}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Username (Employee Name)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Email (Employee Email)</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    value={password}
                                    placeholder='Password'
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>User Type</label>
                                <select
                                    id="user-type"
                                    className="form-control"
                                    value={userType}
                                    onChange={(e) => setUserType(e.target.value)}
                                    required
                                >
                                    <option value="user">User</option>
                                    <option value="manager">Manager</option>
                                    <option value="admin">Admin</option>
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary">Signup</button>
                            <button type="button" className="btn btn-default" onClick={onClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateUser;
