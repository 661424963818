import React, { useEffect, useState } from 'react';
import myLogo from '../../images/salary.jpg'; // Updated image reference for leave
import axios from 'axios';

const EmployeeResignTerminationpreview = ({ record, onClose }) => {
    // Correctly access filteredEmployee from record.employeeData.filteredEmployee
    const filteredEmployee = record.employeeData?.filteredEmployee || []; // Update this line to access the array correctly
    const month = record.selectedMonth;
    const year = record.selectedYear;

    const [setting, setSetting] = useState({});

    useEffect(() => {
        const fetchSetting = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
                setSetting(response.data);
            } catch (error) {
                console.error('Error fetching settings', error);
            }
        };
        fetchSetting();
    }, []);

    // State to hold the grand totals
    const [grandTotals, setGrandTotals] = useState({
        totalEmployees: 0,
    });

    // Effect to calculate totals whenever filteredEmployee changes
    useEffect(() => {
        calculateTotals();
    }, [filteredEmployee]);

    // Function to calculate the grand totals
    const calculateTotals = () => {
        const totals = filteredEmployee.reduce(
            (acc, employee) => {
                acc.totalEmployees += 1;
                return acc;
            },
            {
                totalEmployees: 0,
            }
        );

        setGrandTotals(totals);
    };

    // Function to get month name from number
    const getMonthName = (monthNumber) => {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return months[monthNumber - 1]; // monthNumber is 1-based, array is 0-based
    };

    const handlePrint = () => {
        window.print();
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };
    return (
        <div className="container-fluid">
            <div className="row p-1">
                <div className="modal-content">
                    <div className="modal-header m-0 p-2 d-flex align-items-center justify-content-between px-3">
                        <div>
                            <div style={{ height: "50px", width: "100%" }} className='logo p-1'>
                                <img
                                    src={
                                        setting.landingPageLogo
                                            ? `${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${setting.landingPageLogo}`
                                            : myLogo
                                    }
                                    style={{ height: "100%", objectFit: "cover" }}
                                    alt="LOGO"
                                />
                            </div>
                        </div>
                        <h4
                            style={{ color: "#3F4D67" }}
                            className="title-detail text-uppercase fw-bolder font-bold m-0"
                        >
                            EMPLOYEE RESIGN/TERMINATION REPORT
                        </h4>
                        <div>
                            <h5
                                style={{ color: "#3F4D67" }}
                                className="title-detail text-uppercase fw-bolder font-bold m-0"
                            >
                                {setting.title || 'Title'}
                            </h5>
                            <small
                                style={{ color: "#3F4D67" }}
                                className="title-detail text-uppercase fw-bolder font-bold m-0"
                            >
                                Date - {getMonthName(month)} {year}
                            </small>
                        </div>
                    </div>

                    <div className="card-body form-row">
                        <div className="col-md-12">
                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                    <tr>
                                        <th>Date</th>
                                        <th>Employee Name</th>
                                        <th>Employee Code</th>
                                        <th>Phone Number</th>
                                        <th>Department Name</th>
                                        <th>Position</th>
                                        <th>Joining Company</th>
                                        <th>Joining Office</th>
                                        <th>Employee Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredEmployee.length === 0 ? (
                                        <tr>
                                            <td colSpan="9" className="text-center">
                                                No Employee Details Found for This Month.
                                            </td>
                                        </tr>
                                    ) : (
                                        filteredEmployee.map((employee, index) => (
                                            <tr key={index}>
                                                <td className='text-nowrap'>{formatDate(employee.resignFromDate)}</td>
                                                <td>{employee.employeeName}</td>
                                                <td>{employee.employeeCode}</td>
                                                <td>{employee.employeePhone}</td>
                                                <td>{employee.departmentName}</td>
                                                <td>{employee.positionName}</td>
                                                <td>{employee.joiningCompany}</td>
                                                <td>{employee.joiningOffice}</td>
                                                <td>{employee.employeeType}</td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>

                                        <th>Totals - {grandTotals.totalEmployees}</th>
                                        <th colSpan="7"></th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer p-1 d-flex align-items-center justify-content-between">
                        <div>
                            <small className="p-0 m-0">Powered By - Salary Fal</small>
                        </div>
                        <div className="d-flex gap-2">
                            <button onClick={handlePrint} className="btn btn-success print-button">
                                <i className="fa fa-download"></i> Print
                            </button>
                            <button onClick={onClose} className="btn btn-danger print-button">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeResignTerminationpreview;
