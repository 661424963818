import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import Advancepayment from '../SalaryMaster/AdvancePayment';

const AddAdvanceRepaymentForm = ({ onClose, onUpdate,advanceRepayment,employeeId}) => {
    const [formData, setFormData] = useState({
        ...advanceRepayment,
        date: '',
        receivingMode: '',
        amount: '',
        bankName: '',
        accountNumber: '',
        ifscCode: '',
        branchName: '',
        username: localStorage.getItem('username'),
    });

    useEffect(() => {
        console.log("advanceRepayment",advanceRepayment)
        console.log("employeeId",employeeId)
    }, [])
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/api/repayment`, {
                ...formData,
                employee_id:employeeId,
                advancePaymentId:advanceRepayment[0].id,
            });
            console.log('Data uploaded successfully:', response.data);
            onClose();
            onUpdate();
        } catch (error) {
            console.error('Error uploading data:', error);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div id="addAdvanceRepayment" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                        <div className="modal-header">
                            <h5 className="modal-title">Add Advance Repayment</h5>
                            <button type="button" className="close" onClick={handleClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                            <div>
                                <div className="form-group">
                                    <label>Receiving Date<span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="date"
                                        type="date"
                                       className="form-control"
                                        value={formData.date}
                                        onChange={handleChange}
                                        required
                                    />
                                   
                                </div>

                                <div className="form-group">
                                    <label>Receiving Mode<span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="receivingMode"
                                        className="form-control"
                                        value={formData.receivingMode}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="" disabled hidden>Select Mode</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Bank">Bank</option>
                                    </select>
                                    
                                </div>

                                {formData.receivingMode === 'Bank' && (
                                    <div>
                                        <div className="form-group">
                                            <label>Bank Name</label>
                                            <input
                                                name="bankName"
                                                type="text"
                                                className="form-control"
                                                placeholder="Bank Name"
                                                value={formData.bankName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Account Number</label>
                                            <input
                                                name="accountNumber"
                                                type="text"
                                                className="form-control"
                                                placeholder="Account Number"
                                                value={formData.accountNumber}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>IFSC Code</label>
                                            <input
                                                name="ifscCode"
                                                type="text"
                                                className="form-control"
                                                placeholder="IFSC Code"
                                                value={formData.ifscCode}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Branch Name</label>
                                            <input
                                                name="branchName"
                                                type="text"
                                                className="form-control"
                                                placeholder="Branch Name"
                                                value={formData.branchName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                )}

                                <div className="form-group">
                                    <label>Receiving Amount<span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="amount"
                                        type="number"
                                       className="form-control"
                                        placeholder="Receiving Amount"
                                        value={formData.amount}
                                        onChange={handleChange}
                                        required
                                    />
                                  
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary">Save</button>
                            <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddAdvanceRepaymentForm;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Advancepayment from '../SalaryMaster/AdvancePayment';

// const AddAdvanceRepaymentForm = ({ onClose, onUpdate, advanceRepayment }) => {
//     const [formData, setFormData] = useState({
//         ...advanceRepayment,
//         date: '',
//         receivingMode: '',
//         amount: '',
//         bankName: '',
//         accountNumber: '',
//         ifscCode: '',
//         branchName: '',
//         username: localStorage.getItem('username'),
//     });
//     const [totalAdvanceAmount, setTotalAdvanceAmount] = useState(0);
//     // Calculate total advance amount from advanceRepayment
//     useEffect(() => {
//         if (advanceRepayment && advanceRepayment.length > 0) {
//             const totalAmount = advanceRepayment.reduce((total, entry) => total + entry.amount, 0);
//             setTotalAdvanceAmount(totalAmount);
//         }
//     }, [advanceRepayment]);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({
//             ...formData,
//             [name]: value,
//         });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             // Send form data to the server for the first request
//             await axios.post(`${process.env.REACT_APP_LOCAL_URL}/api/advance-repayment/lazer`, {
//                 ...formData,
//                 month: advanceRepayment[0].month, // Assuming all advanceRepayments have the same month
//                 employeeId: advanceRepayment[0].employeeId,
//                 advancePaymentId: advanceRepayment[0].id,
//             });

//             // Check if the first request was successful before proceeding with the second request
//             await axios.post(`${process.env.REACT_APP_LOCAL_URL}/api/advanceRepayment`, {
//                 ...formData,
//                 month: advanceRepayment[0].month, // Assuming all advanceRepayments have the same month
//                 employeeId: advanceRepayment[0].employeeId,
//                 advancePaymentId: advanceRepayment[0].id,
//             });

//             // Check if the second request was successful


//             onClose();
//             onUpdate();
//             // Refresh salary records after submission

//             // Show success toast notification
            
//         } catch (error) {
//             console.error('Error saving salary:', error);
//             // toast.error('Failed to record salary');
//         }
//     };



//     const handleClose = () => {
//         onClose();
//     };

//     return (
//         <div id="addAdvanceRepayment" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
//             <div className="modal-dialog modal-lg">
//                 <div className="modal-content">
//                     <form onSubmit={handleSubmit} autoComplete="off" noValidate>
//                         <div className="modal-header">
//                             <h5 className="modal-title">Add Advance Repayment</h5>
//                             <button type="button" className="close" onClick={handleClose}>&times;</button>
//                         </div>
//                         <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
//                             <div>
//                                 <div className="form-group">
//                                     <label>Receiving Date<span style={{ color: "red" }}>*</span></label>
//                                     <input
//                                         name="date"
//                                         type="date"
//                                         className="form-control"
//                                         value={formData.date}
//                                         onChange={handleChange}
//                                         required
//                                     />

//                                 </div>

//                                 <div className="form-group">
//                                     <label>Receiving Mode<span style={{ color: "red" }}>*</span></label>
//                                     <select
//                                         name="receivingMode"
//                                         className="form-control"
//                                         value={formData.receivingMode}
//                                         onChange={handleChange}
//                                         required
//                                     >
//                                         <option value="" disabled hidden>Select Mode</option>
//                                         <option value="Cash">Cash</option>
//                                         <option value="Bank">Bank</option>
//                                     </select>

//                                 </div>

//                                 {formData.receivingMode === 'Bank' && (
//                                     <div>
//                                         <div className="form-group">
//                                             <label>Bank Name</label>
//                                             <input
//                                                 name="bankName"
//                                                 type="text"
//                                                 className="form-control"
//                                                 placeholder="Bank Name"
//                                                 value={formData.bankName}
//                                                 onChange={handleChange}
//                                             />
//                                         </div>
//                                         <div className="form-group">
//                                             <label>Account Number</label>
//                                             <input
//                                                 name="accountNumber"
//                                                 type="text"
//                                                 className="form-control"
//                                                 placeholder="Account Number"
//                                                 value={formData.accountNumber}
//                                                 onChange={handleChange}
//                                             />
//                                         </div>
//                                         <div className="form-group">
//                                             <label>IFSC Code</label>
//                                             <input
//                                                 name="ifscCode"
//                                                 type="text"
//                                                 className="form-control"
//                                                 placeholder="IFSC Code"
//                                                 value={formData.ifscCode}
//                                                 onChange={handleChange}
//                                             />
//                                         </div>
//                                         <div className="form-group">
//                                             <label>Branch Name</label>
//                                             <input
//                                                 name="branchName"
//                                                 type="text"
//                                                 className="form-control"
//                                                 placeholder="Branch Name"
//                                                 value={formData.branchName}
//                                                 onChange={handleChange}
//                                             />
//                                         </div>
//                                     </div>
//                                 )}

//                                 <div className="form-group">
//                                     <label>Receiving Amount<span style={{ color: "red" }}>*</span></label>
//                                     <input
//                                         name="amount"
//                                         type="number"
//                                         className="form-control"
//                                         placeholder="Receiving Amount"
//                                         value={formData.amount}
//                                         onChange={handleChange}
//                                         required
//                                     />

//                                 </div>

//                                 <div className="form-group">
//                                     <label>Total Advance Amount: {totalAdvanceAmount}</label>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="modal-footer">
//                             <button type="submit" className="btn btn-primary">Save</button>
//                             <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AddAdvanceRepaymentForm;




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const AdvanceRepayment = () => {
//     const [employees, setEmployees] = useState([]);
//     const [employeeId, setEmployeeId] = useState('');
//     const [amount, setAmount] = useState('');
//     const [date, setDate] = useState('');

//     useEffect(() => {
//         axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employees`)
//             .then(response => setEmployees(response.data))
//             .catch(error => console.error(error));
//     }, []);

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         axios.post(`${process.env.REACT_APP_LOCAL_URL}/api/repayment`, { employee_id: employeeId, amount, date })
//             .then(response => {
//                 alert(response.data);
//                 setAmount('');
//                 setDate('');
//             })
//             .catch(error => console.error(error));
//     };

//     return (
//         <div>
//             <h2>Advance Repayment</h2>
//             <form onSubmit={handleSubmit}>
//                 <div>
//                     <label>Employee</label>
//                     <select value={employeeId} onChange={e => setEmployeeId(e.target.value)}>
//                         <option value="">Select Employee</option>
//                         {employees.map(employee => (
//                             <option key={employee.id} value={employee.id}>{employee.employeeName}</option>
//                         ))}
//                     </select>
//                 </div>
//                 <div>
//                     <label>Amount</label>
//                     <input
//                         type="number"
//                         value={amount}
//                         onChange={e => setAmount(e.target.value)}
//                     />
//                 </div>
//                 <div>
//                     <label>Date</label>
//                     <input
//                         type="date"
//                         value={date}
//                         onChange={e => setDate(e.target.value)}
//                     />
//                 </div>
//                 <button type="submit">Submit</button>
//             </form>
//         </div>
//     );
// };

// export default AdvanceRepayment;
