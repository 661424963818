import React, { useEffect, useState } from 'react';
import myLogo from '../../images/salary.jpg'; // Updated image reference for leave
import axios from 'axios';

const LeaveReportPreview = ({ record, onClose }) => {
    // Correctly access filteredLeaves from record.leaveData
    const filteredLeaves = record.leaveData?.filteredLeaves || [];
    const month = record.selectedMonth;
    const year = record.selectedYear;

    const [setting, setSetting] = useState({});

    useEffect(() => {
        const fetchSetting = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
                setSetting(response.data);
            } catch (error) {
                console.error('Error fetching settings', error);
            }
        };
        fetchSetting();
    }, []);

    // State to hold the grand totals
    const [grandTotals, setGrandTotals] = useState({
        totalEmployees: 0,
    });

    // Effect to calculate totals whenever filteredLeaves changes
    useEffect(() => {
        calculateTotals();
    }, [filteredLeaves]);

    // Function to calculate the grand totals
    const calculateTotals = () => {
        const totals = filteredLeaves.reduce((acc, leave) => {
            acc.totalEmployees += 1;
            return acc;
        }, {
            totalEmployees: 0,
        });

        setGrandTotals(totals);
    };

    // Function to get month name from number
    const getMonthName = (monthNumber) => {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return months[monthNumber - 1]; // monthNumber is 1-based, array is 0-based
    };

    const handlePrint = () => {
        window.print();
    };

    return (
        <div className="container-fluid">
            <div className="row p-1">
                <div className="modal-content">
                    <div className="modal-header m-0 p-2 d-flex align-items-center justify-content-between px-3">
                        <div>
                            <div style={{ height: "50px", width: "100%" }} className='logo p-1'>
                                <img
                                    src={setting.landingPageLogo
                                        ? `${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${setting.landingPageLogo}`
                                        : myLogo}
                                    style={{ height: "100%", objectFit: "cover" }}
                                    alt="LOGO"
                                />
                            </div>
                        </div>
                        <h4 style={{ color: "#3F4D67" }} className='title-detail text-uppercase fw-bolder font-bold m-0'>LEAVE REPORT</h4>
                        <div>
                            <h5 style={{ color: "#3F4D67" }} className='title-detail text-uppercase fw-bolder font-bold m-0'>{setting.title || 'Title'}</h5>
                            <small style={{ color: "#3F4D67" }} className='title-detail text-uppercase fw-bolder font-bold m-0'>Date - {getMonthName(month)} {year}</small>
                        </div>
                    </div>

                    <div className="card-body form-row">
                        <div className='col-md-12'>
                            <table className="table table-striped table-bordered rounded border">
                                <thead>
                                    <tr>
                                        <th>Month</th>
                                        <th>Employee</th>
                                        <th>Employee Code</th>
                                        <th>Status</th>
                                        <th>Period</th>
                                        <th>Reason</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredLeaves.length === 0 ? (
                                        <tr>
                                            <td colSpan="6" className="text-center">No Leave Details Found.</td>
                                        </tr>
                                    ) : (
                                        filteredLeaves.map((leave, index) => (
                                            <tr key={index}>
                                                <td>{new Date(record.date).toLocaleString('default', { month: 'long', year: 'numeric' })}</td>
                                                <td>{leave.employeeName}</td>
                                                <td>{leave.employeeCode}</td>
                                                <td>{leave.status}</td>
                                                <td>{new Date(leave.fromDate).toLocaleDateString()} - {new Date(leave.toDate).toLocaleDateString()}</td>
                                                <td>{leave.reason}</td>
                                                <td>{leave.description}</td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>Totals</th>
                                        <th>{grandTotals.totalEmployees}</th>
                                        {/* Add other totals if needed */}
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer p-1 d-flex align-items-center justify-content-between">
                        <div>
                            <small className='p-0 m-0'>Powered By - Salary Fal</small>
                        </div>
                        <div className='d-flex gap-2'>
                            <button onClick={handlePrint} className="btn btn-success print-button"><i className="fa fa-download"></i> Print</button>
                            <button onClick={onClose} className="btn btn-danger print-button">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeaveReportPreview;
