
// import axios from 'axios';
// import React from 'react';
// import { useEffect, useState } from 'react';
// import "./EmployeePrint.css"
// import myImage from '../../images/employee_profile.png';

// function EmployeePrint({ record, onClose }) {
//     console.log("record", record);
//     const [setting, setSetting] = useState({});

//     useEffect(() => {
//         const fetchSetting = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);

//                 setSetting(response.data);
//             } catch (error) {
//                 console.error('Error fetching Dashboard Logo', error);
//             }
//         };
//         fetchSetting();
//     }, []);

//     const handlePrint = () => {

//         window.print();
//     };
//     // Helper function to format the date
//     const formatDate = (dateString) => {
//         const date = new Date(dateString);
//         return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
//     };


//     return (
//         <div className="container-fluid" >
//             <div className="row p-1 bg-white rounded shadow-sm">
//                 <div className="d-flex gap-1 m-1 bg-black">
//                     <div style={{ width: "80%" }} className=' d-flex align-items-center justify-content-start gap-2 bg-info p-2'>
//                         <div className='bg-danger d-flex align-items-center justify-content-center ' style={{ width: "20%" }}>
//                             <div style={{ height: "50%", width: "50%", maxWidth: "250px", backgroundColor: "white" }} className='d-flex align-items-center justify-content-center'>
//                                 <img
//                                     src={record.passportSizePhoto
//                                         ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${record.passportSizePhoto}`
//                                         : myImage}
//                                     className='w-100 h-100'
//                                     alt="Employee"
//                                 />
//                             </div>
//                         </div>
//                         <div style={{ width: "80%" }} className='bg-warning'>
//                             <div>
//                                 <h2 style={{ color: "#00509d" }} className="title-detail fw-bolder font-bold m-0">
//                                     {record.employeeName}
//                                 </h2>
//                                 <hr />
//                                 <p className="m-0">
//                                     <i className="fa fa-code"></i> <span> Employee code: {record.departmentName || "N/A"}</span>
//                                 </p>

//                                 <p className="m-0">
//                                     <i class="fa fa-envelope" aria-hidden="true"></i> <span> {record.employeeEmail || "N/A"}</span>
//                                 </p>
//                                 <p className="m-0">
//                                     <i class="fa fa-phone"></i> <span> {record.employeePhone || "N/A"}</span>
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                     <div style={{ width: "20%" }} className=' d-flex align-item-center justify-content-center bg-dark p-2'>
//                         <div className=' d-flex flex-column align-items-center justify-content-center '>
//                             <div style={{ height: "50%", width: "80%", maxWidth: "250px" }} className='logo p-1 '>
//                                 <img className='w-100 h-100' src={`${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${setting.landingPageLogo}`} alt="LOGO" />
//                             </div>
//                             <h6 className='fw-bolder text-black'>{setting.title}</h6>
//                         </div>
//                     </div>
//                 </div>
//                 <div className='gap-1 m-1 bg-danger'>
//                     <div  className='bg-primary d-flex align-items-center justify-content-center gap-1'>
//                         <div style={{ width: "60%", minHeight: "600px" }} className=' bg-success'>
//                             <h1>Basic Info</h1>
//                             <div className='d-flex align-items-center justify-content-between'>
//                                 <div className='w-50 bg-dark'>
//                                     <h5>Name : <span style={{ fontSize: "18px" }}>{record.employeeName}</span></h5>
//                                     <h5>Phone No. : <span style={{ fontSize: "18px" }}>{record.employeePhone}</span></h5>
//                                     <h5>Email : <span style={{ fontSize: "18px" }}>{record.employeeEmail}</span></h5>
//                                     <h5>Gender : <span style={{ fontSize: "18px" }}>{record.employeeGender}</span></h5>
//                                     <h5>PAN No. : <span style={{ fontSize: "18px" }}>{record.employeePan}</span></h5>
//                                 </div>
//                                 <div className='w-50 bg-dark'>
//                                     <h5>Employee code : <span style={{ fontSize: "18px" }}>{record.employeeCode}</span></h5>
//                                     <h5>Alternative Phone : <span style={{ fontSize: "18px" }}>{record.employeeAltPhone}</span></h5>
//                                     <h5>DOB : <span style={{ fontSize: "18px" }}>{record.employeeDOB}</span></h5>
//                                     <h5>Blood Group : <span style={{ fontSize: "18px" }}>{record.employeeBloodGroup}</span></h5>
//                                     <h5>Adhar Card No. : <span style={{ fontSize: "18px" }}>{record.employeeAadhar}</span></h5>
//                                 </div>
//                             </div>
//                             <h3>Current Address</h3>
//                             <h5>Address : <span style={{ fontSize: "18px" }}>{record.employeeAddress1}</span></h5>
//                             <div className='d-flex align-items-center justify-content-between'>
//                                 <h5>State : <span style={{ fontSize: "18px" }}>{record.employeeState1}</span></h5>
//                                 <h5>city : <span style={{ fontSize: "18px" }}>{record.employeeCity1}</span></h5>
//                                 <h5>Pincode : <span style={{ fontSize: "18px" }}>{record.employeePincode1}</span></h5>
//                             </div>
//                             <h3>Permanent Address : </h3>
//                             <h5>Address : <span style={{ fontSize: "18px" }}>{record.employeeAddress2}</span></h5>
//                             <div className='d-flex align-items-center justify-content-between'>
//                                 <h5>State : <span style={{ fontSize: "18px" }}>{record.employeeState2}</span></h5>
//                                 <h5>City : <span style={{ fontSize: "18px" }}>{record.employeeCity2}</span></h5>
//                                 <h5>Pincode : <span style={{ fontSize: "18px" }}>{record.employeePincode2}</span></h5>
//                             </div>

//                         </div>
//                         <div style={{ width: "40%", height: "100%" }} className='bg-black d-flex justify-content-around flex-column gap-1'>
//                             <div style={{ width: "100%", height: "60%" }} className=' bg-success'>
//                                 <h1>Job Details</h1>
//                                 <h5>Department Name <span style={{ fontSize: "18px" }}>{record.departmentName}</span></h5>
//                                 <h5>Designation Name  <span style={{ fontSize: "18px" }}>{record.positionName}</span></h5>
//                                 <h5>Joining Company  <span style={{ fontSize: "18px" }}>{record.joiningCompany}</span></h5>
//                                 <h5>Joining Office   <span style={{ fontSize: "18px" }}>{record.joiningOffice}</span></h5>
//                                 <h5>Employee Type  <span style={{ fontSize: "18px" }}>{record.employeeType}</span></h5>
//                                 <h5>Joining Date  <span style={{ fontSize: "18px" }}>{record.joiningDate}</span></h5>
//                                 <h5>Inter/Contractual End Date <span style={{ fontSize: "18px" }}>{record.interncontractual}</span></h5>
//                             </div>
//                             <div style={{ width: "100%", height: "40%" }} className=' bg-warning'>
//                                 <h1>Account Details</h1>
//                                 <h5>Account Holder Name <span style={{ fontSize: "18px" }}>{record.accountHolderName}</span></h5>
//                                 <h5>Account Number<span style={{ fontSize: "18px" }}>{record.accountNumber}</span></h5>
//                                 <h5>Bank Name <span style={{ fontSize: "18px" }}>{record.bankName}</span></h5>
//                                 <h5>IFSC Code <span style={{ fontSize: "18px" }}>{record.ifscCode}</span></h5>
//                                 <h5>Branch Name  <span style={{ fontSize: "18px" }}>{record.branchName}</span></h5>
//                             </div>
//                         </div>
//                     </div >
//                     <div className='bg-primary d-flex align-items-center justify-content-center gap-1'>
//                         <div style={{ width: "100%" }} className=''>
//                             <h1>Family Details</h1>
//                             <h5>Father Name <span style={{ fontSize: "18px" }}>{record.fatherName}</span></h5>
//                             <h5>Mother Name <span style={{ fontSize: "18px" }}>{record.motherName}</span></h5>
//                             <h5>Marital Status: <span style={{ fontSize: "18px" }}>{record.employeeMaritalStatus}</span></h5>
//                             <h5>Emergency Contact Person Name 1 <span style={{ fontSize: "18px" }}>{record.emergencyContactPerson1}</span></h5>
//                             <h5>Emergency Contact Person Contact No. 1 <span style={{ fontSize: "18px" }}>{record.emergencyContactNumber1}</span></h5>
//                             <h5>Emergency Contact Person Name 2 <span style={{ fontSize: "18px" }}>{record.emergencyContactPerson2}</span></h5>
//                             <h5>Emergency Contact Person Contact No. 2 <span style={{ fontSize: "18px" }}>{record.emergencyContactNumber2}</span></h5>
//                         </div>

//                     </div >
//                 </div>

//             </div>
//         </div>

//     );
// }

// export default EmployeePrint;



import axios from 'axios';
import React from 'react';
import { useEffect, useState } from 'react';
import "./EmployeePrint.css"
import myImage from '../../images/employee_profile.png';

function EmployeePrint({ record, onClose }) {
    console.log("record", record);
    const [setting, setSetting] = useState({});

    useEffect(() => {
        const fetchSetting = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);

                setSetting(response.data);
            } catch (error) {
                console.error('Error fetching Dashboard Logo', error);
            }
        };
        fetchSetting();
    }, []);

    const handlePrint = () => {
        window.print();
    };
    // Helper function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    };

    return (
        <div className="container-fluid p-1" >
            <div className="row py-3 px-0 bg-white rounded shadow-sm ">
                <div className="d-flex gap-1 m-1 ">
                    <div style={{ width: "80%", border: "1px dotted #D3D4D6", borderRadius: "20px", backgroundColor: "rgb(182 184 200)" }} className=' d-flex align-items-center shadow-sm justify-content-start gap-2 p-2'>
                        <div className=' d-flex align-items-center justify-content-center ' style={{ width: "20%" }}>
                            <div style={{ height: "70%", width: "70%", maxWidth: "250px", backgroundColor: "white" }} className='d-flex align-items-center justify-content-center'>
                                <img
                                    src={record.passportSizePhoto
                                        ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${record.passportSizePhoto}`
                                        : myImage}
                                    className='w-100 h-100'
                                    alt="Employee"
                                />
                            </div>
                        </div>
                        <div style={{ width: "80%" }} >
                            <div>
                                <h2 className="title-detai text-black fw-bolder font-bold m-0">
                                    {record.employeeName}
                                </h2>
                                <hr />
                                <p style={{ color: "#5A5C69" }} className="m-0">
                                    <i style={{ color: "#5A5C69" }} className="fa fa-code"></i> <span style={{ color: "#5A5C69" }}> Employee code: {record.departmentName || "N/A"}</span>
                                </p>

                                <p style={{ color: "#5A5C69" }} className="m-0">
                                    <i style={{ color: "#5A5C69" }} class="fa fa-envelope" aria-hidden="true"></i> <span style={{ color: "#5A5C69" }}> {record.employeeEmail || "N/A"}</span>
                                </p>
                                <p style={{ color: "#5A5C69" }} className="m-0">
                                    <i style={{ rotate: "90deg", color: "#5A5C69" }} class="fa fa-phone" aria-hidden="true"></i><span style={{ color: "#5A5C69" }}> {record.employeePhone || "N/A"}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "20%" }} className=' d-flex align-item-center justify-content-center  p-2'>
                        <div className=' d-flex flex-column align-items-center justify-content-center '>
                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }} className='logo p-1 '>
                                <img className='w-100 h-auto' src={`${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${setting.landingPageLogo}`} alt="LOGO" />
                            </div>
                            <h5 className='fw-bolder text-black text-uppercase m-0'>Salary - <span style={{ fontSize: "25px", color: "red" }}>फल</span></h5>
                        </div>
                    </div>
                </div>
                <div className='gap-1 mt-3 '>
                    <div className=''>
                        <div style={{ border: "1px dotted #D3D4D6", borderRadius: "20px" }} className=' bg-success w-100 p-4 shadow-sm bg-light'>
                            <h1 className='fw-bolder'>Basic Info</h1>
                            <hr />
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='w-50 '>
                                    <h5>Name : <span style={{ fontSize: "18px" }}>{record.employeeName}</span></h5>
                                    <h5>Phone No. : <span style={{ fontSize: "18px" }}>{record.employeePhone}</span></h5>
                                    <h5>Email : <span style={{ fontSize: "18px" }}>{record.employeeEmail}</span></h5>
                                    <h5>Gender : <span style={{ fontSize: "18px" }}>{record.employeeGender}</span></h5>
                                    <h5>PAN No. : <span style={{ fontSize: "18px" }}>{record.employeePan}</span></h5>
                                </div>
                                <div className='w-50 '>
                                    <h5>Employee code : <span style={{ fontSize: "18px" }}>{record.employeeCode}</span></h5>
                                    <h5>Alternative Phone : <span style={{ fontSize: "18px" }}>{record.employeeAltPhone}</span></h5>
                                    <h5>DOB : <span style={{ fontSize: "18px" }}>{formatDate(record.employeeDOB)}</span></h5>
                                    <h5>Blood Group : <span style={{ fontSize: "18px" }}>{record.employeeBloodGroup}</span></h5>
                                    <h5>Adhar Card No. : <span style={{ fontSize: "18px" }}>{record.employeeAadhar}</span></h5>
                                </div>
                            </div>
                            <h4 className='fw-bolder'>Current Address :</h4>
                            <div className='d-flex  justify-content-between'>
                                <div className='w-50 '>
                                    <h5>Address : <span style={{ fontSize: "18px" }}>{record.employeeAddress1}</span></h5>
                                    {/* <h5>State : <span style={{ fontSize: "18px" }}>{record.employeeState1}</span></h5> */}
                                </div>
                                <div className='w-50 '>
                                    <h5>City : <span style={{ fontSize: "18px", textAlign: "start" }}>{record.employeeCity1}</span></h5>
                                    {/* <h5>Pincode : <span style={{ fontSize: "18px" }}>{record.employeePincode1}</span></h5> */}
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='w-50 '>
                                    {/* <h5>Address : <span style={{ fontSize: "18px" }}>{record.employeeAddress1}</span></h5> */}
                                    <h5>State : <span style={{ fontSize: "18px" }}>{record.employeeState1}</span></h5>
                                </div>
                                <div className='w-50 '>
                                    {/* <h5>city : <span style={{ fontSize: "18px" }}>{record.employeeCity1}</span></h5> */}
                                    <h5>Pincode : <span style={{ fontSize: "18px" }}>{record.employeePincode1}</span></h5>
                                </div>
                            </div>
                            <h4 className='fw-bolder'>Permanent Address : </h4>
                            <div className='d-flex  justify-content-between'>
                                <div className='w-50 '>
                                    <h5>Address : <span style={{ fontSize: "18px" }}>{record.employeeAddress2}</span></h5>
                                    {/* <h5>State : <span style={{ fontSize: "18px" }}>{record.employeeState2}</span></h5> */}
                                </div>
                                <div className='w-50 '>
                                    <h5>City : <span style={{ fontSize: "18px" }}>{record.employeeCity2}</span></h5>
                                    {/* <h5>Pincode : <span style={{ fontSize: "18px" }}>{record.employeePincode1}</span></h5> */}
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='w-50 '>
                                    {/*<h5>Address : <span style={{ fontSize: "18px" }}>{record.employeeAddress2}</span></h5> */}
                                    <h5>State : <span style={{ fontSize: "18px" }}>{record.employeeState2}</span></h5>
                                </div>
                                <div className='w-50 '>
                                    {/* <h5>city : <span style={{ fontSize: "18px" }}>{record.employeeCity1}</span></h5> */}
                                    <h5>Pincode : <span style={{ fontSize: "18px" }}>{record.employeePincode2}</span></h5>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "100%" }} className=' d-flex justify-content-center mt-3  gap-3 '>
                            <div className='bg-light shadow-sm p-4' style={{ width: "50%", border: "1px dotted #D3D4D6", borderRadius: "20px" }} >
                                <h1 className='fw-bolder'>Job Details</h1>
                                <hr />
                                <h5>Department Name : <span style={{ fontSize: "18px" }}>{record.departmentName}</span></h5>
                                <h5>Designation Name  : <span style={{ fontSize: "18px" }}>{record.positionName}</span></h5>
                                <h5>Joining Company  : <span style={{ fontSize: "18px" }}>{record.joiningCompany}</span></h5>
                                <h5>Joining Office   : <span style={{ fontSize: "18px" }}>{record.joiningOffice}</span></h5>
                                <h5>Employee Type  : <span style={{ fontSize: "18px" }}>{record.employeeType}</span></h5>
                                <h5>Joining Date  : <span style={{ fontSize: "18px" }}>{formatDate(record.joiningDate)}</span></h5>
                                <h5>Inter/Contractual End Date : <span style={{ fontSize: "18px" }}>{formatDate(record.interncontractual)}</span></h5>
                            </div>
                            <div className='bg-light shadow-sm p-4' style={{ width: "50%", border: "1px dotted #D3D4D6", borderRadius: "20px" }} >
                                <h1 className='fw-bolder'>Family Details</h1>
                                <hr />
                                <h5>Father Name : <span style={{ fontSize: "18px" }}>{record.fatherName}</span></h5>
                                <h5>Mother Name : <span style={{ fontSize: "18px" }}>{record.motherName}</span></h5>
                                <h5>Marital Status: <span style={{ fontSize: "18px" }}>{record.employeeMaritalStatus}</span></h5>

                                {record.employeeMaritalStatus === 'married' && (
                                    <>
                                        {record.spouseName && (
                                            <h5>Spouse Name: <span style={{ fontSize: "18px" }}>{record.spouseName}</span></h5>
                                        )}

                                        {record.haveChildren === 'yes' && record.children && (
                                            <>
                                                <h5>Children:</h5>
                                                {JSON.parse(record.children).map((child, index) => (
                                                    <p key={index} style={{ marginLeft: "20px" }}>
                                                        <span style={{ fontSize: "16px" }}>Name: {child.name}, DOB: {child.dob}</span>
                                                    </p>
                                                ))}
                                            </>
                                        )}
                                    </>
                                )}

                                {(record.employeeMaritalStatus === 'widowed' || record.employeeMaritalStatus === 'divorced') && (
                                    <>
                                        {record.haveChildren === 'yes' && record.children && (
                                            <>
                                                <h5>Children:</h5>
                                                {JSON.parse(record.children).map((child, index) => (
                                                    <p key={index} style={{ marginLeft: "20px" }}>
                                                        <span style={{ fontSize: "16px" }}>Name: {child.name}, DOB: {child.dob}</span>
                                                    </p>
                                                ))}
                                            </>
                                        )}
                                    </>
                                )}

                                <h5>Contact Name 1: <span style={{ fontSize: "18px" }}>{record.emergencyContactName1}</span></h5>
                                <h5>Contact Number 1: <span style={{ fontSize: "18px" }}>{record.emergencyContactNumber1}</span></h5>
                                <h5>Contact Relation 1: <span style={{ fontSize: "18px" }}>{record.emergencyContactRelation1}</span></h5>

                                <h5>Contact Name 2: <span style={{ fontSize: "18px" }}>{record.emergencyContactName2}</span></h5>
                                <h5>Contact Number 2: <span style={{ fontSize: "18px" }}>{record.emergencyContactNumber2}</span></h5>
                                <h5>Contact Relation 2: <span style={{ fontSize: "18px" }}>{record.emergencyContactRelation2}</span></h5>
                            </div>
                        </div>
                    </div>
                    <div style={{ border: "1px dotted #D3D4D6", borderRadius: "20px" }} className=' mt-3 w-100 p-4 shadow-sm bg-light'>
                        <div className=''>
                            <h1 className='fw-bolder'>Account Details</h1>
                            <hr />
                            <h5>Account Holder Name  : <span style={{ fontSize: "18px" }}>{record.accountHolderName}</span></h5>
                            <h5>Account Number  : <span style={{ fontSize: "18px" }}>{record.accountNumber}</span></h5>
                            <h5>Bank Name  : <span style={{ fontSize: "18px" }}>{record.bankName}</span></h5>
                            <h5>IFSC Code  : <span style={{ fontSize: "18px" }}>{record.ifscCode}</span></h5>
                            <h5>Branch Name   : <span style={{ fontSize: "18px" }}>{record.branchName}</span></h5>
                        </div>
                    </div >
                </div>
                <div className="modal-footer">
                    <button onClick={handlePrint} className="btn btn-success print-button"><i className="fa fa-download"></i> Print</button>
                    <button onClick={onClose} className="btn btn-danger print-button">Close</button>
                </div>
            </div>

        </div>

    );
}

export default EmployeePrint;

