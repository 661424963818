import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios'; // Import axios for API calls
import { toast, ToastContainer } from 'react-toastify'; // Import ToastContainer for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for react-toastify
import myLogo from '../images/salary.jpg';
import './signup.css'; // Import your external CSS file

function Signup() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    userType: 'user', // Default userType is 'user'
  });
  const [message, setMessage] = useState('');
  const [dashboardLogo, setDashboardLogo] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDashboardLogo = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
        setDashboardLogo(response.data);
      } catch (error) {
        console.error('Error fetching Dashboard Logo', error);
      }
    };

    fetchDashboardLogo();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_LOCAL_URL}/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Use formData for the request body
      });

      const data = await response.json();
      setMessage(data.message);
      if (data.success) {
        toast.success("User created successfully");
        window.location.href = '/';
      } else {
        toast.error(data.message || "Failed to create user");
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Failed to create user');
      toast.error("Failed to create user");
    }
  };







  const handleForgotPassword = () => {
    navigate('/forgotpassword');
  };

  return (
    <div className="container-fluid d-flex flex-column justify-content-between align-items-center bg-body-tertiary" style={{ minHeight: '100vh' }}>
      <ToastContainer /> {/* Toast container */}
      <nav className="bg-transparent">
        <div style={{ height: '50px' }}>
          {/* <img
            src={`${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${dashboardLogo.landingPageLogo}`}
            style={{ height: '100%', objectFit: 'cover' }}
            alt="LOGO"
          /> */}
          <img
            src={dashboardLogo.landingPageLogo
              ? `${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${dashboardLogo.landingPageLogo}`
              : myLogo}
              style={{ height: "100%", objectFit: "cover" }}
              alt="LOGO"
          />
        </div>
      </nav>
      <h1 className="text-black ml-3">HRM SOFTWARE</h1>
      <div className="d-flex flex-column justify-content-between align-items-center gap-3 w-25">
        <div className='bg-white p-3 rounded shadow-sm w-100' >
          <div className='' >
            <h2 className='text-center text-black fw-bolder'>Signup</h2>
            <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
              <div className="form-group">
                <label className='text-black' htmlFor="username">Username</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                  placeholder='Username'
                  className='form-control'
                />
              </div>
              <div className="form-group">
                <label className='text-black' htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder='Email'
                  className='form-control'
                />
              </div>
              <div className="form-group">
                <label className='text-black' htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  placeholder='Password'
                  className='form-control'
                />
              </div>
              <div className="form-group">
                <label className='text-black' htmlFor="userType">userType</label>
                <select
                  id="userType"
                  name="userType"
                  value={formData.userType}
                  onChange={handleChange}
                  required
                  className='form-control'
                >
                  <option value="user">User</option>
                  <option value="manager">Manager</option>
                  <option value="admin">Admin</option>
                </select>
              </div>
              <div className='text-center'>
                <button type="submit" className="btn btn-primary">Signup</button>
              </div>
            </form>
            <Link onClick={handleForgotPassword} className="forgot-password text-center">Forgot Password?</Link>

          </div>
        </div>
        <footer className='p-2'>
          Version 1.0 &copy; Developed by Prospect Digital
        </footer>
      </div>
    </div>
  );
}

export default Signup;


















































