// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const AddDocumention = ({ employee, onClose, onUpdate }) => {
//     const [docName, setDocName] = useState('');
//     const [docFile, setDocFile] = useState(null);
    


//     useEffect(() => {
//         console.log("Employee:", employee);
//     }, [employee]);

//     const handleNameChange = (e) => {
//         setDocName(e.target.value);
//     };

//     const handleFileChange = (e) => {
//         setDocFile(e.target.files[0]);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         const formData = new FormData();
//         formData.append('documentName', docName);
//         formData.append('documentFile', docFile);

//         try {
//             const response = await axios.post(
//                 `${process.env.REACT_APP_LOCAL_URL}/addDocumentation/${employee.id}`,
//                 formData,
//                 { headers: { 'Content-Type': 'multipart/form-data' } }
//             );

//             // Update the employee data with new documentation
//             onUpdate(response.data);
           
//             setTimeout(() => {
//                 onClose();
//                 window.location.reload();
//             }, 1000); // 1 second delay
//         } catch (error) {
//             console.error('Error updating documentation:', error);
//         }
//     };

//     const handleClose = () => {
//         onClose();
//     };

//     return (
//         <div>
//             <div id="changeDocumentionModal" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
//                 <div className="modal-dialog modal-lg">
//                     <div className="modal-content">
//                         <form onSubmit={handleSubmit} encType="multipart/form-data" autoComplete="off" noValidate>
//                             <div className="modal-header">
//                                 <h5 className="modal-title">Documentation Upload/Update</h5>
//                                 <button type="button" className="close" onClick={handleClose}>&times;</button>
//                             </div>
//                             <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
//                                 <div>
//                                     <h4 className='text-dark py-2 fw-bolder'>Upload Documents</h4>
//                                     <div className="form-row">
//                                         <div className="form-group col-md-6">
//                                             <label>Document Name <span style={{ color: "red" }}>*</span></label>
//                                             <input
//                                                 type="text"
//                                                 className='form-control'
//                                                 value={docName}
//                                                 onChange={handleNameChange}
//                                                 required
//                                             />
//                                         </div>
//                                         <div className="form-group col-md-6">
//                                             <label>Upload Document <span style={{ color: "red" }}>*</span></label>
//                                             <input
//                                                 type="file"
//                                                 className='form-control'
//                                                 onChange={handleFileChange}
//                                                 required
//                                             />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="modal-footer">
//                                 <button type="submit" className="btn btn-primary">Update Documentation</button>
//                                 <button type="button" className="btn btn-secondary" onClick={handleClose}>
//                                     Close
//                                 </button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AddDocumention;



import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddDocumentation = ({ employee, onClose, onUpdate }) => {
    const [docName, setDocName] = useState('');
    const [docFile, setDocFile] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [error, setError] = useState('');

    useEffect(() => {
        console.log("Employee:", employee);
    }, [employee]);

    const handleNameChange = (e) => {
        setDocName(e.target.value);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        // Validation conditions
        const maxSize = 200 * 1024; // 200KB
        const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];

        let errors = {};

        if (file) {
            // Check file type
            if (!allowedTypes.includes(file.type)) {
                errors.docFile = 'Only JPG, PNG, and PDF files are allowed.';
            }

            // Check file size
            if (file.size > maxSize) {
                errors.docFile = 'File size should be less than or equal to 200KB.';
            }

            if (Object.keys(errors).length === 0) {
                setDocFile(file); // Set the file only if there are no errors
            }

            setValidationErrors(errors);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!docName || !docFile) {
            setValidationErrors({
                docName: !docName ? 'Document name is required.' : '',
                docFile: !docFile ? 'Document file is required.' : ''
            });
            return;
        }

        const formData = new FormData();
        formData.append('documentName', docName);
        formData.append('documentFile', docFile);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_LOCAL_URL}/addDocumentation/${employee.id}`,
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );

            onUpdate(response.data);

            setTimeout(() => {
                onClose();
                window.location.reload();
            }, 1000); // 1 second delay
        } catch (error) {
            console.error('Error updating documentation:', error);
            setError('Failed to update documentation. Please try again.');
        }
    };

    const handleClose = () => {
        onClose();
    };

    const inputClass = (field) => validationErrors[field] ? 'form-control is-invalid' : 'form-control';
    const fileInputClass = validationErrors.docFile ? 'form-control-file is-invalid' : 'form-control-file';

    return (
        <div>
            <div id="changeDocumentionModal" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <form onSubmit={handleSubmit} encType="multipart/form-data" autoComplete="off" noValidate>
                            <div className="modal-header">
                                <h5 className="modal-title">Documentation Upload/Update</h5>
                                <button type="button" className="close" onClick={handleClose}>&times;</button>
                            </div>
                            <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                                <div>
                                    <h4 className='text-dark py-2 fw-bolder'>Upload Documents</h4>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Document Name <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                type="text"
                                                className={inputClass('docName')}
                                                value={docName}
                                                placeholder='Document Name '
                                                onChange={handleNameChange}
                                                required
                                            />
                                            {validationErrors.docName && <div className="invalid-feedback">{validationErrors.docName}</div>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Upload Document <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                type="file"
                                                className={`form-control ${fileInputClass}`}
                                                onChange={handleFileChange}
                                                required
                                            />
                                            {validationErrors.docFile && <div className="invalid-feedback">{validationErrors.docFile}</div>}
                                        </div>
                                    </div>
                                </div>
                                {error && <div className="alert alert-danger mt-3">{error}</div>}
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Update Documentation</button>
                                <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                    Close
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddDocumentation;
