import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchBar from '../../components/sidebar/SearchBar';
import Sidebar from '../../components/sidebar/Sidebar';

const AdvancePayment = ({ handleLogout, username }) => {
    const [formData, setFormData] = useState({
        departmentId: '',
        departmentName: '',
        employee_id: '',
        employeeName: '',
        paymentType: '',
        amount: '',
        paymentMode: '',
        date: '',
        description: '',
        username: localStorage.getItem('username'),
    });
    const [departments, setDepartments] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [salaryRecords, setSalaryRecords] = useState([]);
    const [paymentTypeModes, setPaymentTypeModes] = useState([]);
    const [errors, setErrors] = useState({});

    // Fetch departments from the API
    const fetchDepartments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/departments`);
            setDepartments(response.data);
        } catch (error) {
            toast.error('Error fetching departments');
        }
    };

    // Fetch employees based on selected department
    const fetchEmployees = async (departmentId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employee_department/${departmentId}`);
            setEmployees(response.data);
        } catch (error) {
            toast.error('Error fetching employees');
        }
    };

    // Fetch payment type modes from the API
    const fetchPaymentTypeModes = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/addPaymentModes`);
            setPaymentTypeModes(response.data);
        } catch (error) {
            toast.error('Error fetching payment modes');
        }
    };

    // Fetch salary records for the selected employee
    const fetchSalaryRecords = async (employee_id) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/advancepayment/${employee_id}`);
            setSalaryRecords(response.data);
        } catch (error) {
            toast.error('Error fetching salary records');
        }
    };

    // Fetch initial data on component mount
    useEffect(() => {
        fetchDepartments();
        fetchPaymentTypeModes();
    }, []);

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'departmentId') {
            const department = departments.find((dep) => dep.id === parseInt(value, 10));
            setFormData({
                ...formData,
                departmentId: value,
                departmentName: department ? department.name : '',
            });
            fetchEmployees(value);
        } else if (name === 'employee_id') {
            const employee = employees.find((emp) => emp.id === parseInt(value, 10));
            setFormData({
                ...formData,
                employee_id: value,
                employeeCode: employee ? employee.employeeCode : '',
                employeeName: employee ? employee.employeeName : '',
            });
            fetchSalaryRecords(value);
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    // Basic form validation
    const validateForm = () => {
        const errors = {};
        if (!formData.departmentId) errors.departmentId = 'Department is required';
        if (!formData.employee_id) errors.employee_id = 'Employee is required';
        if (!formData.paymentType) errors.paymentType = 'Payment Type is required';
        if (!formData.amount) errors.amount = 'Amount is required';
        if (!formData.paymentMode) errors.paymentMode = 'Payment Mode is required';
        if (!formData.date) errors.date = 'Payment Date is required';
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            toast.error('Please fill in all required fields');
            return;
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/api/advance-payment`, formData);
            toast.success('Advance payment recorded successfully');
            // setTimeout(() => {
            //     window.location.reload();
            // }, 1000); // 1 second delay
        } catch (error) {
            toast.error('Error submitting advance payment');
        }
    };

    return (
        <div className="d-flex w-100 h-100">
            <Sidebar />
            <div className="w-100">
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    <div className="row bg-white rounded shadow">
                        <div className="col-sm-12 col-md-12">
                            <div className="panel panel-bd">
                                <div className="panel-heading">
                                    <div className="panel-title">
                                        <h4 className="py-3 px-2 text-black fw-bolder m-0">Advance Payment</h4>
                                    </div>
                                </div>
                                <div className="panel-body bg-light border p-2 rounded" style={{ maxHeight: 'calc(100vh - 140px)', overflowY: 'auto', overflowX: 'hidden' }}>
                                    <form onSubmit={handleSubmit} className="bg-white p-2 rounded">
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>
                                                    Department<span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <select className="form-control" name="departmentId" value={formData.departmentId} onChange={handleChange}>
                                                    <option value="">Select Department</option>
                                                    {departments.map((dept) => (
                                                        <option key={dept.id} value={dept.id}>
                                                            {dept.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.departmentId && <span style={{ color: 'red' }}>{errors.departmentId}</span>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>
                                                    Employee<span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <select className="form-control" name="employee_id" value={formData.employee_id} onChange={handleChange}>
                                                    <option value="">Select Employee</option>
                                                    {employees.map((emp) => (
                                                        <option key={emp.id} value={emp.id}>
                                                            {emp.employeeName}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.employee_id && <span style={{ color: 'red' }}>{errors.employee_id}</span>}
                                            </div>
                                            <div className="form-group col-md-12 bg-light border rounded p-3">
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="paymentType">
                                                            Payment Type<span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <select
                                                            className="form-control"
                                                            id="paymentType"
                                                            name="paymentType"
                                                            value={formData.paymentType}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">Select Payment Type</option>
                                                            <option value="advance">Advance</option>
                                                        </select>
                                                        {errors.paymentType && <span style={{ color: 'red' }}>{errors.paymentType}</span>}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="amount">
                                                            Amount<span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="amount"
                                                            className="form-control"
                                                            placeholder="Amount"
                                                            id="amount"
                                                            value={formData.amount}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.amount && <span style={{ color: 'red' }}>{errors.amount}</span>}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="paymentMode">
                                                            Payment Mode<span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <select
                                                            className="form-control"
                                                            id="paymentMode"
                                                            name="paymentMode"
                                                            value={formData.paymentMode}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">Select Payment Mode</option>
                                                            {paymentTypeModes.map((payment) => (
                                                                <option key={payment.id} value={payment.paymentModeName}>
                                                                    {payment.paymentModeName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {errors.paymentMode && <span style={{ color: 'red' }}>{errors.paymentMode}</span>}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="date">
                                                            Payment Date<span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input
                                                            type="date"
                                                            name="date"
                                                            className="form-control"
                                                            placeholder="Payment Date"
                                                            id="date"
                                                            value={formData.date}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.date && <span style={{ color: 'red' }}>{errors.date}</span>}
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label htmlFor="description">Description</label>
                                                        <textarea
                                                            type="text"
                                                            name="description"
                                                            className="form-control"
                                                            placeholder="Description"
                                                            id="description"
                                                            value={formData.description}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <button type="submit" className="btn btn-primary">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='from-row bg-light py-2 my-2 rounded border border-1'>
                                <div className='col-sm-12 col-md-12'>
                                    <div className='panel panel-bd'>
                                        <div className='panel-heading'>
                                            <div className='panel-title'>
                                                <h5 className='p-1 text-black fw-bolder'>Advance Records</h5>
                                            </div>
                                        </div>
                                        <div className='panel-body' style={{ maxHeight: "450px", overflowY: "auto" }}>
                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                    <tr>
                                                        <th>Employee Name </th>
                                                        <th>Payment Date</th>
                                                        <th>Amount</th>
                                                        <th>Payment Mode Name</th>
                                                        <th>Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {salaryRecords.length > 0 ? (
                                                        salaryRecords.map(record => (
                                                            <tr key={record.id}>
                                                                <td>{record.employeeName}</td>
                                                                <td>{new Date(record.date).toLocaleDateString()}</td>
                                                                <td className='text-end'>&#x20B9;{record.amount != null ? record.amount.toFixed(2) : '0.00'}</td>
                                                                <td>{record.paymentModeName}</td>
                                                                <td>{record.description}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="4" className='text-center'>No advance records found.</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdvancePayment;
