// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import '../style1.css';

// function RejectStatus({ onClose }) {
//     const [currentPage, setCurrentPage] = useState(1);
//     const [itemsPerPage, setItemsPerPage] = useState(10);
//     const [ApprovedStatus, setApprovedStatus] = useState([]);

//     useEffect(() => {
//         fetchCombinedData();
//     }, []);

//     const fetchCombinedData = async () => {
//         try {
//             const employeesResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
//             const ApprovedStatusResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/approved`);

//             const ApprovedStatusData = ApprovedStatusResponse.data;

//             const combinedData = employeesResponse.data
//                 .filter(employee => ApprovedStatusData.some(ai => ai.employeeId === employee.id))
//                 .map(employee => {
//                     const matchingApprovedStatus = ApprovedStatusData.find(ai => ai.employeeId === employee.id);
//                     return {
//                         ...employee,
//                         activeInactiveDetails: matchingApprovedStatus || {}
//                     };
//                 });

//             setApprovedStatus(combinedData);
//         } catch (error) {
//             console.error("Error fetching combined data:", error);
//             toast.error("Error fetching data. Please try again later.");
//         }
//     };
//         // Function to format the date
//         const formatDate = (dateString) => {
//             return new Date(dateString).toLocaleDateString('en-US', {
//                 year: 'numeric',
//                 month: '2-digit',
//                 day: '2-digit'
//             });
//         };    

//     // Logic to get current items
//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItems = ApprovedStatus.slice(indexOfFirstItem, indexOfLastItem);

//     // Change page
//     const paginate = (pageNumber) => setCurrentPage(pageNumber);

//     const handleBack = () => {
//         onClose(); // Call the onClose function passed from the parent component
//     };

//     return (
//         <div className="container-fluid p-0">
//             <ToastContainer />
//             <div className="row">
//                 <div className="col-xl-12">
//                     <div className="card shadow mb-4">
//                         <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
//                             <h6 className="m-0 font-weight-bold text-primary">
//                              Reject Status
//                             </h6>
//                             <button className="btn btn-outline-success" onClick={handleBack}>Leave List</button>
//                         </div>
//                         <div className="card-body">
//                             <table className="table table-striped table-bordered" style={{ width: "100%" }}>
//                                 <thead>
//                                     <tr>
//                                         <th>Employee Photo</th>
//                                         <th>Employee Name</th>
//                                         <th>Status</th>
//                                         <th>Date</th>
//                                         <th>Description</th>                                    
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {currentItems.map((status) => (
//                                         <tr key={status.id}>
//                                             <td>
//                                                 <img
//                                                     src={`${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${status.passportSizePhoto}`}
//                                                     style={{ width: "90px" }}
//                                                     alt="Employee"
//                                                 />
//                                             </td>
//                                             <td>{status.employeeName}</td>
//                                             <td>{status.activeInactiveDetails.status}</td>
//                                             <td>{formatDate(status.activeInactiveDetails.createdAt)}</td>
//                                             <td>{status.activeInactiveDetails.description}</td>
//                                         </tr>
//                                     ))}
//                                 </tbody>
//                             </table>
//                             {/* Pagination */}
//                             <ul className="pagination">
//                                 <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                                     <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
//                                 </li>
//                                 {Array.from({ length: Math.ceil(ApprovedStatus.length / itemsPerPage) }, (_, i) => (
//                                     <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
//                                         <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
//                                     </li>
//                                 ))}
//                                 <li className={`page-item ${currentPage === Math.ceil(ApprovedStatus.length / itemsPerPage) ? 'disabled' : ''}`}>
//                                     <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
//                                 </li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default RejectStatus;


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import '../style1.css';

// function RejectStatus({ onClose, showApprovedStatusModal }) {
//     const [currentPage, setCurrentPage] = useState(1);
//     const [itemsPerPage, setItemsPerPage] = useState(10);
//     const [RejectStatus, setRejectStatus] = useState([]);
//     const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); // Initialize with current month
//     const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initialize with current year

//     useEffect(() => {
//         fetchCombinedData();
//     }, []);

//     const fetchCombinedData = async () => {
//         try {
//             const employeesResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
//             const RejectStatusResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/approved/reject`);

//             const RejectStatusData = RejectStatusResponse.data;

//             const combinedData = employeesResponse.data
//                 .filter(employee => RejectStatusData.some(ai => ai.employeeId === employee.id))
//                 .map(employee => {
//                     const matchingRejectStatus = RejectStatusData.find(ai => ai.employeeId === employee.id);
//                     return {
//                         ...employee,
//                         activeInactiveDetails: matchingRejectStatus || {}
//                     };
//                 });

//             setRejectStatus(combinedData);
//         } catch (error) {
//             console.error("Error fetching combined data:", error);
//             toast.error("Error fetching data. Please try again later.");
//         }
//     };

//     const formatDate = (dateString) => {
//         return new Date(dateString).toLocaleDateString('en-US', {
//             year: 'numeric',
//             month: '2-digit',
//             day: '2-digit'
//         });
//     };

//     const handlePageChange = (pageNumber) => {
//         setCurrentPage(pageNumber);
//     };

//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItems = RejectStatus.slice(indexOfFirstItem, indexOfLastItem);

//     return (
//         <div className="container-fluid p-0">
//             <ToastContainer />
//             <div className="card shadow mb-4">
//                 <div className="card-header py-3 d-flex flex-row align-items-center gap-3">
//                     <h6 className="m-0 font-weight-bold text-primary">
//                         Reject Status
//                     </h6>
//                     <h6 style={{ cursor: "pointer" }} className="m-0 font-weight-bold text-info" onClick={() => onClose()}>
//                         Requests Leave
//                     </h6>
//                     <h6 style={{ cursor: "pointer" }} className="m-0 font-weight-bold text-info" onClick={() => showApprovedStatusModal()}>
//                         Approved Status
//                     </h6>
//                 </div>
//                 <div className='d-flex align-items-center justify-content-center gap-1'>
//                     <label className='pt-2 text-black fw-bolder'>Filter:</label>
//                     <select className="form-control" value={selectedMonth}
//                         onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
//                     >
//                         <option value="">Select Month</option>
//                         {Array.from({ length: 12 }, (_, i) => (
//                             <option key={i} value={i}>{new Date(2000, i).toLocaleString('default', { month: 'long' })}</option>
//                         ))}
//                     </select>
//                     <select className="form-control" value={selectedYear}
//                         onChange={(e) => setSelectedYear(parseInt(e.target.value))}
//                     >
//                         <option value="">Select Year</option>
//                         {Array.from({ length: 10 }, (_, i) => (
//                             <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
//                         ))}
//                     </select>
//                 </div>
//                 <div className="card-body">
//                     <table className="table table-striped table-bordered" style={{ width: "100%" }}>
//                         <thead>
//                             <tr>
//                                 <th>Employee Photo</th>
//                                 <th>Employee Name</th>
//                                 <th>Status</th>
//                                 <th>Start Date</th>
//                                 <th>End Date</th>
//                                 <th>Description</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {currentItems.map((RejectStatus, index) => (
//                                 <tr key={index}>
//                                     <td>
//                                         <img
//                                             src={`${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${RejectStatus.passportSizePhoto}`}
//                                             style={{ width: "90px" }}
//                                             alt="Employee"
//                                         />
//                                     </td>
//                                     <td>{RejectStatus.employeeName}</td>
//                                     <td>{RejectStatus.activeInactiveDetails.status}</td>
//                                     <td>{formatDate(RejectStatus.activeInactiveDetails.fromDate)}</td>
//                                     <td>{formatDate(RejectStatus.activeInactiveDetails.toDate)}</td>
//                                     <td>{RejectStatus.activeInactiveDetails.description}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                     <ul className="pagination">
//                         {Array.from({ length: Math.ceil(RejectStatus.length / itemsPerPage) }, (_, i) => (
//                             <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
//                                 <button className="page-link" onClick={() => handlePageChange(i + 1)}>
//                                     {i + 1}
//                                 </button>
//                             </li>
//                         ))}
//                     </ul>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default RejectStatus;



// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import '../style1.css';
// import myImage from '../../images/employee_profile.png';

// function RejectStatus({ onClose, showApprovedStatusModal }) {
//     const [currentPage, setCurrentPage] = useState(1);
//     const [itemsPerPage, setItemsPerPage] = useState(10);
//     const [RejectStatus, setRejectStatus] = useState([]);
//     const [selectedMonth, setSelectedMonth] = useState((new Date().getMonth())); // Initialize with current month
//     const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initialize with current year

//     useEffect(() => {
//         fetchCombinedData();
//     }, [selectedMonth, selectedYear]);

//     const fetchCombinedData = async () => {
//         try {
//             const employeesResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
//             const rejectStatusResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/approved/reject`);

//             const rejectStatusData = rejectStatusResponse.data;

//             console.log("Reject Status Data:", rejectStatusData); // Debugging line
//             console.log("Employees Data:", employeesResponse.data); // Debugging line

//             // Filter based on the selected month and year
//             const filteredRejectStatusData = rejectStatusData.filter(item => {
//                 const itemDate = new Date(item.fromDate);
//                 return (
//                     (selectedMonth === '' || selectedMonth === itemDate.getMonth()) &&
//                     (selectedYear === '' || selectedYear === itemDate.getFullYear())
//                 );
//             });

//             const combinedData = employeesResponse.data
//                 .filter(employee => filteredRejectStatusData.some(ai => ai.employeeId === employee.id))
//                 .map(employee => {
//                     const matchingRejectStatus = filteredRejectStatusData.find(ai => ai.employeeId === employee.id);
//                     return {
//                         ...employee,
//                         activeInactiveDetails: matchingRejectStatus || {}
//                     };
//                 });

//             console.log("Combined Data:", combinedData); // Debugging line

//             setRejectStatus(combinedData);
//         } catch (error) {
//             console.error("Error fetching combined data:", error);
//             toast.error("Error fetching data. Please try again later.");
//         }
//     };


//     const formatDate = (dateString) => {
//         return new Date(dateString).toLocaleDateString('en-US', {
//             year: 'numeric',
//             month: '2-digit',
//             day: '2-digit'
//         });
//     };

//     const handlePageChange = (pageNumber) => {
//         setCurrentPage(pageNumber);
//     };

//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItems = RejectStatus.slice(indexOfFirstItem, indexOfLastItem);

//     return (
//         <div className="container-fluid p-0">
//             <ToastContainer />
//             <div className="card shadow mb-4">
//                 <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
//                     <div className="d-flex flex-row align-items-center gap-3">
//                         <h6 className="m-0 font-weight-bold text-primary">
//                             Reject Status
//                         </h6>
//                         <h6 style={{ cursor: "pointer" }} className="m-0 font-weight-bold text-info" onClick={() => onClose()}>
//                             Requests Leave
//                         </h6>
//                         <h6 style={{ cursor: "pointer" }} className="m-0 font-weight-bold text-info" onClick={() => showApprovedStatusModal()}>
//                             Approved Status
//                         </h6>
//                     </div>
//                     <div className='d-flex align-items-center justify-content-center gap-1'>
//                         <label className='pt-2 text-black fw-bolder'>Filter:</label>
//                         <select className="form-control" value={selectedMonth || ''}
//                             onChange={(e) => setSelectedMonth(e.target.value === '' ? '' : parseInt(e.target.value))}
//                         >
//                             <option value="">Select Month</option>
//                             {Array.from({ length: 12 }, (_, i) => (
//                                 <option key={i} value={i}>{new Date(2000, i).toLocaleString('default', { month: 'long' })}</option>
//                             ))}
//                         </select>
//                         <select className="form-control" value={selectedYear || ''}
//                             onChange={(e) => setSelectedYear(e.target.value === '' ? '' : parseInt(e.target.value))}
//                         >
//                             <option value="">Select Year</option>
//                             {Array.from({ length: 10 }, (_, i) => (
//                                 <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
//                             ))}
//                         </select>

//                     </div>

//                 </div>

//                 <div className="card-body">
//                     <table className="table table-striped table-bordered" style={{ width: "100%" }}>
//                         <thead>
//                             <tr>
//                                 <th>Employee Photo</th>
//                                 <th>Employee Name</th>
//                                 <th>Status</th>
//                                 <th>Start Date</th>
//                                 <th>End Date</th>
//                                 <th>Description</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {currentItems.map((item, index) => (
//                                 <tr key={index}>
//                                     <td>

//                                         <img
//                                             src={item.passportSizePhoto
//                                                 ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${item.passportSizePhoto}`
//                                                 : myImage}
//                                             style={{ width: "90px" }}
//                                             alt="Employee"
//                                         />
//                                     </td>
//                                     <td>{item.employeeName}</td>
//                                     <td>{item.activeInactiveDetails.status}</td>
//                                     <td>{formatDate(item.activeInactiveDetails.fromDate)}</td>
//                                     <td>{formatDate(item.activeInactiveDetails.toDate)}</td>
//                                     <td>{item.activeInactiveDetails.description}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                     <ul className="pagination">
//                         {Array.from({ length: Math.ceil(RejectStatus.length / itemsPerPage) }, (_, i) => (
//                             <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
//                                 <button className="page-link" onClick={() => handlePageChange(i + 1)}>
//                                     {i + 1}
//                                 </button>
//                             </li>
//                         ))}
//                     </ul>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default RejectStatus;


import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../style1.css';
import myImage from '../../images/employee_profile.png';

function RejectStatus({ onClose, showApprovedStatusModal }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [RejectStatus, setRejectStatus] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); // Initialize with current month
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initialize with current year

    useEffect(() => {
        fetchCombinedData();
    }, [selectedMonth, selectedYear]);

    const fetchCombinedData = async () => {
        try {
            const employeesResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            const rejectStatusResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/approved/reject`);

            const rejectStatusData = rejectStatusResponse.data;

            console.log("Reject Status Data:", rejectStatusData); // Debugging line
            console.log("Employees Data:", employeesResponse.data); // Debugging line

            // Filter based on the selected month and year
            const filteredRejectStatusData = rejectStatusData.filter(item => {
                const itemDate = new Date(item.fromDate);
                return (
                    (selectedMonth === '' || selectedMonth === itemDate.getMonth()) &&
                    (selectedYear === '' || selectedYear === itemDate.getFullYear())
                );
            });

            const combinedData = employeesResponse.data
                .filter(employee => filteredRejectStatusData.some(ai => ai.employeeId === employee.id))
                .map(employee => {
                    const matchingRejectStatus = filteredRejectStatusData.find(ai => ai.employeeId === employee.id);
                    return {
                        ...employee,
                        activeInactiveDetails: matchingRejectStatus || {}
                    };
                });

            console.log("Combined Data:", combinedData); // Debugging line

            setRejectStatus(combinedData);
        } catch (error) {
            console.error("Error fetching combined data:", error);
            toast.error("Error fetching data. Please try again later.");
        }
    };
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = RejectStatus.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className="container-fluid p-0">
            <ToastContainer />
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <div className="d-flex flex-row align-items-center gap-3">
                        <h6 className="m-0 font-weight-bold text-primary">
                            Reject Status
                        </h6>
                        <h6 style={{ cursor: "pointer" }} className="m-0 font-weight-bold text-info" onClick={() => onClose()}>
                            Requests Leave
                        </h6>
                        <h6 style={{ cursor: "pointer" }} className="m-0 font-weight-bold text-info" onClick={() => showApprovedStatusModal()}>
                            Approved Status
                        </h6>
                    </div>
                    <div className='d-flex align-items-center justify-content-center gap-1'>
                        <label className='pt-2 text-black fw-bolder'>Filter:</label>
                        <select className="form-control" value={selectedMonth || ''}
                            onChange={(e) => setSelectedMonth(e.target.value === '' ? '' : parseInt(e.target.value))}
                        >
                            <option value="">Select Month</option>
                            {Array.from({ length: 12 }, (_, i) => (
                                <option key={i} value={i}>{new Date(2000, i).toLocaleString('default', { month: 'long' })}</option>
                            ))}
                        </select>
                        <select className="form-control" value={selectedYear || ''}
                            onChange={(e) => setSelectedYear(e.target.value === '' ? '' : parseInt(e.target.value))}
                        >
                            <option value="">Select Year</option>
                            {Array.from({ length: 10 }, (_, i) => (
                                <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                            ))}
                        </select>

                    </div>

                </div>

                <div className="card-body">
                    <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <th>Employee Photo</th>
                                <th>Employee Name</th>
                                <th>Status</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <img
                                            src={item.passportSizePhoto
                                                ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${item.passportSizePhoto}`
                                                : myImage}
                                            style={{ width: "90px" }}
                                            alt="Employee"
                                        />
                                    </td>
                                    <td>{item.employeeName}</td>
                                    <td>{item.activeInactiveDetails.status}</td>
                                    <td>{formatDate(item.activeInactiveDetails.fromDate)}</td>
                                    <td>{formatDate(item.activeInactiveDetails.toDate)}</td>
                                    <td>{item.activeInactiveDetails.description}</td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                    <ul className="pagination">
                        {Array.from({ length: Math.ceil(RejectStatus.length / itemsPerPage) }, (_, i) => (
                            <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                <button className="page-link" onClick={() => handlePageChange(i + 1)}>
                                    {i + 1}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default RejectStatus;
