import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Dashboard.css';
import Sidebar from '../components/sidebar/Sidebar';
import SearchBar from '../components/sidebar/SearchBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddInward from './Inword/AddInward';
import AddOutward from './Outward/AddOutWard';
import RequestLeave from './LeaveSection/RequestLeave';
import myImage from '../images/employee_profile.png';



function Dashboard({ handleLogout, username }) {
    // for Dashboard 
    const [employee, setEmployee] = useState(0);
    const [vendors, setvendors] = useState(0);
    const [inward, setInward] = useState(0);
    const [outward, setOutward] = useState(0);
    const [leaveRequests, setLeaveRequests] = useState([]);
    const [leave, setLeave] = useState([]);
    const [filteredLeaveRequests, setFilteredLeaveRequests] = useState([]);
    const [filteredLeave, setFilteredLeave] = useState([]);
    // addinward modal 
    const [isAddRequestLeaveModalopen, setIsAddRequestLeaveModalopen] = useState(false);
    // add outwardModal 
    const [isAddOutwardModalOpen, setIsAddOutwardModalOpen] = useState(false);
    // for the salary details is showing  
    const currentYear = new Date().getFullYear();
    const startYear = new Date().getMonth() >= 3 ? currentYear : currentYear - 1;
    const defaultFinancialYear = `${startYear}-${startYear + 1}`;
    const [selectedYear, setSelectedYear] = useState(defaultFinancialYear);
    const [payroll, setPayroll] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [filteredPayroll, setFilteredPayroll] = useState([]);
    const [monthNames] = useState(["April", "May", "June", "July", "August", "September", "October", "November", "December", "January", "February", "March"]);

    // Payroll Previous Month 
    const [totalNetSalary, setTotalNetSalary] = useState(0);
    const [totalInHandSalary, setTotalInHandSalary] = useState(0);
    const [previousMonth, setPreviousMonth] = useState('');
    const [previousYear, setPreviousYear] = useState('');

    // Leave Approved deatils 
    const [filteredapprovedata, setFilteredapprovedata] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredcurrentllyleave, setFilteredcurrentllyleave] = useState([]);


    // salary Details 
    useEffect(() => {
        fetchPayrollByEmployee();
        fetchPaymentDetails();
    }, [selectedYear]);

    const fetchPaymentDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/paymentforms`);
            setPaymentDetails(response.data);
        } catch (error) {
            console.error('Error fetching payment details:', error);
        }
    };

    const fetchPayrollByEmployee = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/payrolls`);
            setPayroll(response.data);
            filterPayroll(response.data);
        } catch (error) {
            console.error('Error fetching payroll by employee:', error);
        }
    };

    const filterPayroll = (data) => {
        const [startYear, endYear] = selectedYear.split('-').map(Number);
        const filtered = data.filter(record => {
            if (record.month >= 4 && record.year === startYear) {
                return true;
            } else if (record.month <= 3 && record.year === endYear) {
                return true;
            }
            return false;
        });
        setFilteredPayroll(filtered);
    };

    const calculateTotals = () => {
        const totals = Array.from({ length: 12 }, () => ({
            totalEmployees: 0,
            totalInHandSalary: 0,
            totalNetSalary: 0,
            totalPaidAmount: 0,
            totalAmountDue: 0
        }));

        filteredPayroll.forEach(record => {
            const monthIndex = record.month >= 4 ? record.month - 4 : record.month + 8;

            totals[monthIndex].totalEmployees += 1;
            totals[monthIndex].totalInHandSalary += record.salaryWithContribution;
            totals[monthIndex].totalNetSalary += record.netSalaryPayableMonth;

            // Find all payments for the current employee, month, and year
            const payments = paymentDetails.filter(p => p.employeeId === record.employeeId && p.month === record.month && p.year === record.year);

            // Calculate the total amount paid for the current employee, month, and year
            const amountPaid = payments.reduce((sum, payment) => sum + payment.amountPaid, 0);
            totals[monthIndex].totalPaidAmount += amountPaid;
            totals[monthIndex].totalAmountDue += (record.netSalaryPayableMonth - amountPaid);
        });

        return totals;
    };

    const monthlyTotals = calculateTotals();

    // Calculate grand totals
    const grandTotals = monthlyTotals.reduce((acc, total) => {
        acc.totalEmployees += total.totalEmployees;
        acc.totalInHandSalary += total.totalInHandSalary;
        acc.totalNetSalary += total.totalNetSalary;
        acc.totalPaidAmount += total.totalPaidAmount;
        acc.totalAmountDue += total.totalAmountDue;
        return acc;
    }, {
        totalEmployees: 0,
        totalInHandSalary: 0,
        totalNetSalary: 0,
        totalPaidAmount: 0,
        totalAmountDue: 0
    });

    // salary Details 
    const itemsPerPage = 5; // Define the number of items per page
    useEffect(() => {
        fetchEmployees();
        fetchVendors();
        fetchOutwards();
        fetchCombinedLeaveRequestData();
        fetchCombinedLeaveData();
        fetchpreviousPayroll();
    }, []);

    const fetchEmployees = async () => {
        try {
            const employeeResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            console.log(employeeResponse.data);
            if (Array.isArray(employeeResponse.data)) {
                setEmployee(employeeResponse.data.length);
            } else {
                console.error('Invalid response format for total Employee count');
            }
        } catch (error) {
            console.error('Error fetching employee:', error);
        }
    };

    const fetchVendors = async () => {
        try {
            const vendorResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/vendors`);
            console.log(vendorResponse.data);
            if (Array.isArray(vendorResponse.data)) {
                setvendors(vendorResponse.data.length);
            } else {
                console.error('Invalid response format for total asset count');
            }
        } catch (error) {
            console.error('Error fetching employee:', error);
        }
    };

    const fetchpreviousPayroll = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/payrolls`);
            const payrollData = response.data;

            calculateTotalNetSalary(payrollData);
        } catch (error) {
            console.error('Error fetching payroll by employee:', error);
        }
    };

    const fetchOutwards = async () => {
        try {
            const inwardResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/outwards`);
            console.log(inwardResponse.data);
            if (Array.isArray(inwardResponse.data)) {
                setOutward(inwardResponse.data.length);
            } else {
                console.error('Invalid response format for total asset count');
            }
        } catch (error) {
            console.error('Error fetching employee:', error);
        }
    };


    const fetchCombinedLeaveRequestData = async () => {
        try {
            const employeesResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            const activeInactiveResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/activeinactive`);

            const activeInactiveData = activeInactiveResponse.data;

            const combinedData = employeesResponse.data
                .filter(employee => activeInactiveData.some(ai => ai.employeeId === employee.id))
                .map(employee => {
                    const matchingActiveInactive = activeInactiveData.find(ai => ai.employeeId === employee.id);
                    return {
                        ...employee,
                        activeInactiveDetails: matchingActiveInactive || {}
                    };
                });

            setLeaveRequests(combinedData);
            setFilteredLeaveRequests(combinedData.slice(0, itemsPerPage));
        } catch (error) {
            console.error("Error fetching combined data:", error);
        }
    };

    const fetchCombinedLeaveData = async () => {
        try {
            const employeesResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            const activeInactiveResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/approved`);

            const activeInactiveData = activeInactiveResponse.data.filter(ai => ai.status === 'approved');

            const combinedData = employeesResponse.data
                .filter(employee => activeInactiveData.some(ai => ai.employeeId === employee.id))
                .map(employee => {
                    const matchingActiveInactive = activeInactiveData.find(ai => ai.employeeId === employee.id);
                    return {
                        ...employee,
                        activeInactiveDetails: matchingActiveInactive || {}
                    };
                });

            setLeave(combinedData);
            setFilteredLeave(combinedData.slice(0, itemsPerPage));
            console.log("Combine", combinedData);
        } catch (error) {
            console.error("Error fetching combined data:", error);
        }
    };

    // Fetch combined approve/reject data
    const fetchCombinedapproverejectData = async () => {
        try {
            // Fetch approved data from the backend API
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/approved/list/tilldate`);
            // Update state with the approved details
            setFilteredapprovedata(response.data);
        } catch (error) {
            console.error("Error fetching combined data:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch data when the component mounts
    useEffect(() => {
        fetchCombinedapproverejectData();
    }, []);

    // Fetch combined leave and employee data
    const fetchCombinedCurrentllyLeaveData = async () => {
        try {
            // Fetch employee data
            const employeesResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            const employees = employeesResponse.data;

            // Fetch active inactive (leave) data
            const activeInactiveResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/activeinactive/currentleave`);
            const activeInactiveData = activeInactiveResponse.data;

            // Filter employees whose status is 'leave' in the employee details table
            const employeesOnLeave = employees.filter(emp => emp.status.toLowerCase() === 'leave');

            // For each employee on leave, get their last activeinactive entry
            const combinedData = employeesOnLeave.map(employee => {
                // Find the latest activeinactive entry for the employee
                const lastEntry = activeInactiveData
                    .filter(entry => entry.employeeId === employee.id)
                    .sort((a, b) => new Date(b.date) - new Date(a.date))[0]; // Assuming 'date' field for sorting entries

                // Check if the last activeinactive entry status is also 'leave'
                if (lastEntry && lastEntry.status.toLowerCase() === 'leave') {
                    return {
                        ...lastEntry,
                        employeeName: employee.employeeName,
                        passportSizePhoto: employee.passportSizePhoto,
                        employeeEmail: employee.employeeEmail,
                        employeePhone: employee.employeePhone,
                    };
                }
                return null;
            }).filter(item => item !== null);

            // Update state with filtered employees
            setFilteredcurrentllyleave(combinedData);
        } catch (error) {
            console.error("Error fetching combined data:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch data when the component mounts
    useEffect(() => {
        fetchCombinedCurrentllyLeaveData();
    }, []);


    



    const calculateTotalNetSalary = (data) => {
        // Get the current date
        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.getMonth() + 1; // Months are zero-indexed

        // Calculate the previous month
        const prevMonth = currentMonth === 1 ? 12 : currentMonth - 1;
        const prevYear = currentMonth === 1 ? currentYear - 1 : currentYear;

        // Format month and year for display
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        setPreviousMonth(monthNames[prevMonth - 1]);
        setPreviousYear(prevYear);

        // Filter the data for the previous month
        const filteredData = data.filter(item =>
            item.month === prevMonth && item.year === prevYear
        );

        // Calculate the total net salary payable for the previous month
        const totalnet = filteredData.reduce((sum, item) => sum + parseFloat(item.netSalaryPayableMonth) || 0, 0);
        const totalhand = filteredData.reduce((sum, item) => sum + parseFloat(item.salaryWithContribution) || 0, 0);
        // const totalRepayment = filteredData.reduce((sum, item) => sum + parseFloat(item.netSalaryPayableMonth) || 0, 0);
        console.log("totalnet", totalnet)
        console.log("totalhand", totalhand)
        setTotalNetSalary(totalnet);
        setTotalInHandSalary(totalhand);
    };


    // Add Inward Modal 
    const handleAddRequestLeave = () => {
        setIsAddRequestLeaveModalopen(true);
    };

    const handleCloseRequestLeaveModal = () => {
        setIsAddRequestLeaveModalopen(false);
    };

    // Out Ward Modal 
    const handleAddAttendanceModal = () => {
        setIsAddOutwardModalOpen(true);
    };

    const handleCloseOutWardModal = () => {
        setIsAddOutwardModalOpen(false);
    };

    const handleUpdate = () => {
        toast.success("successfully uploaded");
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };


    return (
        <div className='d-flex w-100 h-100 '>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} /> {/* Pass username and handleLogout props */}
                <div className="container-fluid">
                    <ToastContainer />
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                        <div className='d-flex gap-4'>
                            <a onClick={handleAddRequestLeave} className="d-none d-sm-inline-block btn btn-sm btn-outline-primary shadow-sm">Request Leave <i className="fa fa-arrow-right"></i>  </a>
                            <Link to="/addattendance" className="d-none d-sm-inline-block btn btn-sm btn-outline-success shadow-sm"> Add Attendance <i className="fa fa-arrow-right"></i> </Link>
                        </div>

                    </div>
                    {/*  <!-- Content Row --> */}
                    <div className="row">
                        {/*  <!-- Total employee Card Example --> */}
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card  border-left-success shadow h-100 py-1">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1" style={{ fontSize: '1rem' }}>
                                                Total Employee <i className="fa fa-arrow-right"></i></div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{employee}</div> {/* Updated amount */}
                                        </div>
                                    </div>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col-md-12">
                                            <hr className="my-2" />
                                            <div className="stats">
                                                <i className="fa fa-angle-double-right text-success"></i>
                                                <span><Link className="text-success" to="/employeelist">More info</Link></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*  <!-- Total Inward Card Example --> */}
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-primary shadow h-100 py-1">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1" style={{ fontSize: '0.9rem' }}>
                                                Salary With Contribution - <small >{previousYear}</small></div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">&#x20B9;{grandTotals.totalInHandSalary.toFixed(2)}</div> {/* Updated amount */}
                                        </div>
                                    </div>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col-md-12">
                                            <hr className="my-2" /> {/* Line added here */}
                                            <div className="stats">
                                                <i className="fa fa-angle-double-right text-primary"></i>
                                                <span><Link className="text-primary" to="/salarylist">More info</Link></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*  <!-- Total Previous Payroll Card Example --> */}
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-success shadow h-100 py-1">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1" style={{ fontSize: '1rem' }}>
                                                Net Salary Payable- <small>{previousYear}</small></div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">&#x20B9;{grandTotals.totalNetSalary.toFixed(2)}</div> {/* Updated amount */}
                                        </div>
                                    </div>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col-md-12">
                                            <hr className="my-2" /> {/* Line added here */}
                                            <div className="stats">
                                                <i className="fa fa-angle-double-right text-success"></i>
                                                <span><Link className="text-success" to="/salarylist">More info</Link></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        {/*  <!-- Total outward Card Example --> */}
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-danger shadow h-100 py-1">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-danger text-uppercase mb-1" style={{ fontSize: '1rem' }}>
                                                Total Salary Due - <small>{previousYear}</small></div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">&#x20B9;{grandTotals.totalAmountDue.toFixed(2)}</div> {/* Updated amount */}
                                        </div>
                                    </div>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col-md-12">
                                            <hr className="my-2" /> {/* Line added here */}
                                            <div className="stats">
                                                <i className="fa fa-angle-double-right text-danger"></i>
                                                <span><Link className="text-danger" to="/salarylist">More info</Link></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  <!-- Content Row --> */}
                    <div className="row">
                        {/*  <!-- Total Leave Request Card Example --> */}
                        <div className="col-xl-6 col-md-6 mb-4">
                            <div className="card border-left-danger shadow h-100 py-2">
                                <div className="card-body py-1">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2 d-flex gap-4">
                                            <div
                                                className="text-xs font-weight-bold text-danger text-uppercase mb-1"
                                                style={{ fontSize: '1rem' }}
                                            >
                                                Leave Request
                                            </div>
                                            <div className="stats">
                                                <span>
                                                    <Link className="text-danger" to="/requestleavelist">
                                                        More info
                                                    </Link>
                                                </span>
                                                <i className="fa fa-angle-double-right text-danger"></i>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa fa-arrow-right fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="card-body p-0">
                                        <div
                                            className="leave-requests"
                                            style={{ height: '270px', overflowY: 'auto', overflowX: 'hidden' }}
                                        >
                                            {filteredLeaveRequests.length === 0 ? (
                                                <p className="text-center text-muted">No leave requests</p>
                                            ) : (
                                                filteredLeaveRequests.map((request, index) => (
                                                    <div
                                                        key={index}
                                                        className="d-flex justify-content-between align-items-center border-bottom p-1"
                                                    >
                                                        <div className="d-flex align-items-start gap-3">
                                                            <img
                                                                className="dashboard-user-img"
                                                                src={
                                                                    request.passportSizePhoto
                                                                        ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${request.passportSizePhoto}`
                                                                        : myImage
                                                                }
                                                                style={{ width: '50px' }}
                                                                alt="Employee"
                                                            />
                                                            <div>
                                                                <p className="mb-2 lh-1 fs-18 fw-semi-bold text-color-1">
                                                                    {request.employeeName}
                                                                </p>
                                                                <p className="mb-2 lh-1 fs-14 fw-normal">
                                                                    Reason: {request.activeInactiveDetails.reason}
                                                                </p>
                                                                <p className="mb-0 lh-1 fs-14 fw-medium">
                                                                    Description: {request.activeInactiveDetails.description}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className="mb-1 lh-1 fs-18 fw-semi-bold text-color-1">
                                                                {formatDate(request.activeInactiveDetails.fromDate)}
                                                            </p>
                                                            <p className="mb-1 text-center lh-1 fs-18 fw-semi-bold text-color-1">
                                                                to
                                                            </p>
                                                            <p className="mb-1 lh-1 fs-18 fw-semi-bold text-color-1">
                                                                {formatDate(request.activeInactiveDetails.toDate)}
                                                            </p>
                                                        </div>
                                                        <div className={`bg-soft-success w-px-90 rounded-3 p-1`}>
                                                            <p className="mb-0 p-1 fs-14 bg-[#FFF8DF] fw-bold text-warning text-center text-capitalize bg-light rounded">
                                                                {request.activeInactiveDetails.status}
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* Leave Card Example */}
                        <div className="col-xl-6 col-md-6 mb-4">
                            <div className="card border-left-success shadow h-100 py-2">
                                <div className="card-body py-1">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2 d-flex gap-4">
                                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1" style={{ fontSize: '1rem' }}>
                                                Currently On Leave
                                            </div>
                                            <div className="stats">
                                                <span><Link className="text-success" to="/requestleavelist">More info </Link></span>
                                                <i className="fa fa-angle-double-right text-success"></i>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa fa-arrow-right fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="card-body p-0">
                                        <div className="leave-requests" style={{ height: "270px", overflowY: "auto", overflowX: "hidden" }}>
                                            {loading ? (
                                                <p>Loading...</p>
                                            ) : filteredcurrentllyleave.length === 0 ? (
                                                <p>No employee leave</p>
                                            ) : (
                                                filteredcurrentllyleave.map((request, index) => (
                                                    <div key={index} className="d-flex justify-content-between align-items-center border-bottom p-1">
                                                        <div className="d-flex align-items-start gap-3">
                                                            <img
                                                                className="dashboard-user-img"
                                                                src={request.passportSizePhoto
                                                                    ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${request.passportSizePhoto}`
                                                                    : myImage}
                                                                style={{ width: "50px" }}
                                                                alt="Employee"
                                                            />
                                                            <div>
                                                                <p className="mb-2 lh-1 fs-18 fw-semi-bold text-color-1">
                                                                    {request.employeeName}
                                                                </p>
                                                                <p className="mb-2 lh-1 fs-14 fw-normal text-capitalize">
                                                                    Status: {request.status}
                                                                </p>
                                                                <p className="mb-0 lh-1 fs-14 fw-medium">
                                                                    Description: {request.description}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className="mb-1 lh-1 fs-18 fw-semi-bold text-color-1">
                                                                {formatDate(request.fromDate)}
                                                            </p>
                                                            <p className="mb-1 text-center lh-1 fs-18 fw-semi-bold text-color-1">
                                                                to
                                                            </p>
                                                            <p className="mb-1 lh-1 fs-18 fw-semi-bold text-color-1">
                                                                {formatDate(request.toDate)}
                                                            </p>
                                                        </div>
                                                        <div className={`bg-soft-success w-px-90 rounded-3 p-1`}>
                                                            <p className="mb-0 p-1 fs-14 bg-[#FFF8DF] fw-bold text-success text-center text-capitalize bg-light rounded">
                                                                {request.status}
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Approve/Reject Card Example */}
                        <div className="col-xl-12 col-md-6 mb-4">
                            <div className="card border-left-success shadow h-100 py-2">
                                <div className="card-body py-1">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2 d-flex gap-4">
                                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1" style={{ fontSize: '1rem' }}>
                                                Currently On Approve/Reject
                                            </div>
                                            <div className="stats">
                                                <span><Link className="text-success" to="/requestapproverejectlist">More info </Link></span>
                                                <i className="fa fa-angle-double-right text-success"></i>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa fa-arrow-right fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="card-body p-0">
                                        <div className="approvereject-requests" style={{ height: "270px", overflowY: "auto", overflowX: "hidden" }}>
                                            {loading ? (
                                                <p>Loading...</p>
                                            ) : filteredcurrentllyleave.length === 0 ? (
                                                <p>No employee Approved Data</p>
                                            ) : (
                                                filteredapprovedata.map((request, index) => (
                                                    <div key={index} className="d-flex justify-content-between align-items-center border-bottom p-1">
                                                        <div className="d-flex align-items-start gap-3">
                                                            <img
                                                                className="dashboard-user-img"
                                                                src={request.passportSizePhoto
                                                                    ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${request.passportSizePhoto}`
                                                                    : myImage}
                                                                style={{ width: "50px" }}
                                                                alt="Employee"
                                                            />
                                                            <div>
                                                                <p className="mb-2 lh-1 fs-18 fw-semi-bold text-color-1">
                                                                    {request.employeeName}
                                                                </p>
                                                                <p className="mb-2 lh-1 fs-14 fw-normal text-capitalize">
                                                                    Status: {request.status}
                                                                </p>
                                                                <p className="mb-0 lh-1 fs-14 fw-medium">
                                                                    Description: {request.description}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className="mb-1 lh-1 fs-18 fw-semi-bold text-color-1">
                                                                {formatDate(request.fromDate)}
                                                            </p>
                                                            <p className="mb-1 text-center lh-1 fs-18 fw-semi-bold text-color-1">
                                                                to
                                                            </p>
                                                            <p className="mb-1 lh-1 fs-18 fw-semi-bold text-color-1">
                                                                {formatDate(request.toDate)}
                                                            </p>
                                                        </div>
                                                        <div className={`bg-soft-success w-px-90 rounded-3 p-1`}>
                                                            <p className="mb-0 p-1 fs-14 bg-[#FFF8DF] fw-bold text-success text-center text-capitalize bg-light rounded">
                                                                {request.status}
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 col-md-6 mb-4">
                            <div className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body py-1">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2 d-flex gap-4">
                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1" style={{ fontSize: '1rem' }}>
                                                Salary Details
                                            </div>
                                            <div className="stats">
                                                <span><Link className="text-primary" to="/salarylist">More info </Link></span>
                                                <i className="fa fa-angle-double-right text-primary"></i>
                                            </div>
                                        </div>
                                        <div className='col-auto d-flex align-items-center justify-content-center gap-1'>
                                            <label className='pt-2 text-black fw-bolder'>Filter:</label>
                                            <select className="form-control" value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
                                                <option value="">Select Financial Year</option>
                                                {Array.from({ length: 10 }, (_, i) => {
                                                    const startYear = new Date().getMonth() >= 3 ? currentYear - i : currentYear - i - 1;
                                                    const endYear = startYear + 1;
                                                    return (
                                                        <option key={i} value={`${startYear}-${endYear}`}>
                                                            {startYear}-{endYear}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="card-body p-0">
                                        <div className='col-md-12' style={{ maxHeight: "500px", overflowY: "auto" }}>
                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                    <tr>
                                                        <th>Month's</th>
                                                        <th>Employees</th>
                                                        <th>Salary With Contribution</th>
                                                        <th>Net Salary Payable</th>
                                                        <th>Amount Paid</th>
                                                        <th>Amount Due</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {monthlyTotals.map((total, index) => (
                                                        <tr key={index}>
                                                            <td>{monthNames[index]}</td>
                                                            <td>{total.totalEmployees}</td>
                                                            <td>&#x20B9;{total.totalInHandSalary.toFixed(2)}</td>
                                                            <td>&#x20B9;{total.totalNetSalary.toFixed(2)}</td>
                                                            <td>&#x20B9;{total.totalPaidAmount.toFixed(2)}</td>
                                                            <td>&#x20B9;{total.totalAmountDue.toFixed(2)}</td>
                                                        </tr>
                                                    ))}
                                                    {filteredPayroll.length === 0 && (
                                                        <tr>
                                                            <td colSpan="6" style={{ textAlign: 'center' }}>
                                                                <h5>No salary history found for this financial year.</h5>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Totals</th>
                                                        <th>{grandTotals.totalEmployees}</th>
                                                        <th>&#x20B9;{grandTotals.totalInHandSalary.toFixed(2)}</th>
                                                        <th>&#x20B9;{grandTotals.totalNetSalary.toFixed(2)}</th>
                                                        <th>&#x20B9;{grandTotals.totalPaidAmount.toFixed(2)}</th>
                                                        <th>&#x20B9;{grandTotals.totalAmountDue.toFixed(2)}</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {isAddRequestLeaveModalopen && <RequestLeave onClose={handleCloseRequestLeaveModal} onUpdate={handleUpdate} />}
            {isAddOutwardModalOpen && <AddOutward onClose={handleCloseOutWardModal} onUpdate={handleUpdate} />}
        </div>
    )
}

export default Dashboard;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import myImage from '../images/employee_profile.png'; // Default image for employee

// const Dashboard = () => {
//     const [filteredcurrentllyleave, setFilteredcurrentllyleave] = useState([]);
//     const [loading, setLoading] = useState(true);

//     // Function to format dates in a readable format
//     const formatDate = (dateString) => {
//         const options = { year: 'numeric', month: 'long', day: 'numeric' };
//         return new Date(dateString).toLocaleDateString(undefined, options);
//     };

//     // Fetch combined leave and employee data
//     const fetchCombinedCurrentllyLeaveData = async () => {
//         try {
//             // Fetch employee data
//             const employeesResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
//             const employees = employeesResponse.data;

//             // Fetch active inactive (leave) data
//             const activeInactiveResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/activeinactive/currentleave`);
//             const activeInactiveData = activeInactiveResponse.data;

//             // Filter employees whose status is 'leave' in the employee details table
//             const employeesOnLeave = employees.filter(emp => emp.status.toLowerCase() === 'leave');

//             // For each employee on leave, get their last activeinactive entry
//             const combinedData = employeesOnLeave.map(employee => {
//                 // Find the latest activeinactive entry for the employee
//                 const lastEntry = activeInactiveData
//                     .filter(entry => entry.employeeId === employee.id)
//                     .sort((a, b) => new Date(b.date) - new Date(a.date))[0]; // Assuming 'date' field for sorting entries

//                 // Check if the last activeinactive entry status is also 'leave'
//                 if (lastEntry && lastEntry.status.toLowerCase() === 'leave') {
//                     return {
//                         ...lastEntry,
//                         employeeName: employee.employeeName,
//                         passportSizePhoto: employee.passportSizePhoto,
//                         employeeEmail: employee.employeeEmail,
//                         employeePhone: employee.employeePhone,
//                     };
//                 }
//                 return null;
//             }).filter(item => item !== null);

//             // Update state with filtered employees
//             setFilteredcurrentllyleave(combinedData);
//         } catch (error) {
//             console.error("Error fetching combined data:", error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Fetch data when the component mounts
//     useEffect(() => {
//         fetchCombinedCurrentllyLeaveData();
//     }, []);

//     return (
//         <div>
//             {/* Leave Card Example */}
//             <div className="col-xl-6 col-md-6 mb-4">
//                 <div className="card border-left-success shadow h-100 py-2">
//                     <div className="card-body py-1">
//                         <div className="row no-gutters align-items-center">
//                             <div className="col mr-2 d-flex gap-4">
//                                 <div className="text-xs font-weight-bold text-success text-uppercase mb-1" style={{ fontSize: '1rem' }}>
//                                     Currently On Leave
//                                 </div>
//                                 <div className="stats">
//                                     <span><Link className="text-success" to="/requestleavelist">More info </Link></span>
//                                     <i className="fa fa-angle-double-right text-success"></i>
//                                 </div>
//                             </div>
//                             <div className="col-auto">
//                                 <i className="fas fa fa-arrow-right fa-2x text-gray-300"></i>
//                             </div>
//                         </div>
//                         <hr />
//                         <div className="card-body p-0">
//                             <div className="leave-requests" style={{ height: "270px", overflowY: "auto", overflowX: "hidden" }}>
//                                 {loading ? (
//                                     <p>Loading...</p>
//                                 ) : (
//                                     filteredcurrentllyleave.map((request, index) => (
//                                         <div key={index} className="d-flex justify-content-between align-items-center border-bottom p-1">
//                                             <div className="d-flex align-items-start gap-3">
//                                                 <img
//                                                     className="dashboard-user-img"
//                                                     src={request.passportSizePhoto
//                                                         ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${request.passportSizePhoto}`
//                                                         : myImage}
//                                                     style={{ width: "50px" }}
//                                                     alt="Employee"
//                                                 />
//                                                 <div>
//                                                     <p className="mb-2 lh-1 fs-18 fw-semi-bold text-color-1">
//                                                         {request.employeeName}
//                                                     </p>
//                                                     <p className="mb-2 lh-1 fs-14 fw-normal text-capitalize">
//                                                         Status: {request.status}
//                                                     </p>
//                                                     <p className="mb-0 lh-1 fs-14 fw-medium">
//                                                         Description: {request.description || 'N/A'}
//                                                     </p>
//                                                 </div>
//                                             </div>
//                                             <div>
//                                                 <p className="mb-1 lh-1 fs-18 fw-semi-bold text-color-1">
//                                                     {formatDate(request.fromDate)}
//                                                 </p>
//                                                 <p className="mb-1 text-center lh-1 fs-18 fw-semi-bold text-color-1">
//                                                     to
//                                                 </p>
//                                                 <p className="mb-1 lh-1 fs-18 fw-semi-bold text-color-1">
//                                                     {formatDate(request.toDate)}
//                                                 </p>
//                                             </div>
//                                             <div className={`bg-soft-success w-px-90 rounded-3 p-1`}>
//                                                 <p className="mb-0 p-1 fs-14 bg-[#FFF8DF] fw-bold text-success text-center text-capitalize bg-light rounded">
//                                                     {request.status}
//                                                 </p>
//                                             </div>
//                                         </div>
//                                     ))
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Dashboard;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import myImage from '../images/employee_profile.png'; // Default image for employee

// const Dashboard = () => {
//     const [filteredapprovedata, setFilteredapprovedata] = useState([]);
//     const [loading, setLoading] = useState(true);

//     // Function to format dates in a readable format
//     const formatDate = (dateString) => {
//         const options = { year: 'numeric', month: 'long', day: 'numeric' };
//         return new Date(dateString).toLocaleDateString(undefined, options);
//     };

//     // Fetch combined approve/reject data
//     const fetchCombinedapproverejectData = async () => {
//         try {
//             // Fetch approved data from the backend API
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/approved/list/tilldate`);
//             // Update state with the approved details
//             setFilteredapprovedata(response.data);
//         } catch (error) {
//             console.error("Error fetching combined data:", error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Fetch data when the component mounts
//     useEffect(() => {
//         fetchCombinedapproverejectData();
//     }, []);

//     return (
//         <div>
//             {/* Approve/Reject Card Example */}
//             <div className="col-xl-6 col-md-6 mb-4">
//                 <div className="card border-left-success shadow h-100 py-2">
//                     <div className="card-body py-1">
//                         <div className="row no-gutters align-items-center">
//                             <div className="col mr-2 d-flex gap-4">
//                                 <div className="text-xs font-weight-bold text-success text-uppercase mb-1" style={{ fontSize: '1rem' }}>
//                                     Currently On Approve/Reject
//                                 </div>
//                                 <div className="stats">
//                                     <span><Link className="text-success" to="/requestapproverejectlist">More info </Link></span>
//                                     <i className="fa fa-angle-double-right text-success"></i>
//                                 </div>
//                             </div>
//                             <div className="col-auto">
//                                 <i className="fas fa fa-arrow-right fa-2x text-gray-300"></i>
//                             </div>
//                         </div>
//                         <hr />
//                         <div className="card-body p-0">
//                             <div className="approvereject-requests" style={{ height: "270px", overflowY: "auto", overflowX: "hidden" }}>
//                                 {loading ? (
//                                     <p>Loading...</p>
//                                 ) : (
//                                     filteredapprovedata.map((request, index) => (
//                                         <div key={index} className="d-flex justify-content-between align-items-center border-bottom p-1">
//                                             <div className="d-flex align-items-start gap-3">
//                                                 <img
//                                                     className="dashboard-user-img"
//                                                     src={request.passportSizePhoto
//                                                         ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${request.passportSizePhoto}`
//                                                         : myImage}
//                                                     style={{ width: "50px" }}
//                                                     alt="Employee"
//                                                 />
//                                                 <div>
//                                                     <p className="mb-2 lh-1 fs-18 fw-semi-bold text-color-1">
//                                                         {request.employeeName}
//                                                     </p>
//                                                     <p className="mb-2 lh-1 fs-14 fw-normal text-capitalize">
//                                                         Status: {request.status}
//                                                     </p>
//                                                     <p className="mb-0 lh-1 fs-14 fw-medium">
//                                                         Description: {request.description}
//                                                     </p>
//                                                 </div>
//                                             </div>
//                                             <div>
//                                                 <p className="mb-1 lh-1 fs-18 fw-semi-bold text-color-1">
//                                                     {formatDate(request.fromDate)}
//                                                 </p>
//                                                 <p className="mb-1 text-center lh-1 fs-18 fw-semi-bold text-color-1">
//                                                     to
//                                                 </p>
//                                                 <p className="mb-1 lh-1 fs-18 fw-semi-bold text-color-1">
//                                                     {formatDate(request.toDate)}
//                                                 </p>
//                                             </div>
//                                             <div className={`bg-soft-success w-px-90 rounded-3 p-1`}>
//                                                 <p className="mb-0 p-1 fs-14 bg-[#FFF8DF] fw-bold text-success text-center text-capitalize bg-light rounded">
//                                                     {request.status}
//                                                 </p>
//                                             </div>
//                                         </div>
//                                     ))
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Dashboard;
