// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const Leave = ({ onClose, onUpdate, employee, client, site, userType }) => {
//     const [status, setStatus] = useState(employee?.status || client?.status || site?.status || '');
//     const [reason, setReason] = useState('');
//     const [fromDate, setFromDate] = useState('');
//     const [toDate, setToDate] = useState('');
//     const [description, setDescription] = useState('');
//     const [error, setError] = useState('');

//     // Dynamic reasons based on status
//     const reasonOptions = status === 'active' ?
//         ['Rejoin', 'Back From Paid Leave', 'Back From Non Paid Leave', 'Back from Holidays', 'Back from Deputation'] :
//         ['Left From Job', 'Cessation from Job', 'Retired', 'On Paid Leave', 'Deputation to other Company', 'On Non Paid Leave', 'On Holidays', 'Resign', 'Termination'];

//     useEffect(() => {
//         console.log("employee");
//         console.log(employee);
//     }, [employee]);

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         // Validate required fields
//         const formData = { status, reason, fromDate, description };
//         for (const field in formData) {
//             if (!formData[field]) {
//                 setError(`Please fill in the ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`);
//                 return;
//             }
//         }
//         try {
//             if (employee) {
//                 await axios.put(`${process.env.REACT_APP_LOCAL_URL}/employee_status/${employee.id}`, { status });
//             }
//             if (client) {
//                 await axios.put(`${process.env.REACT_APP_LOCAL_URL}/client_status/${client.id}`, { status });
//             }
//             if (site) {
//                 await axios.put(`${process.env.REACT_APP_LOCAL_URL}/site_status/${site.id}`, { status });
//             }

//             const activeInactiveFormData = {
//                 employeeName: employee?.employeeName,
//                 employeeId: employee?.id,
//                 clientId: client?.id,
//                 siteId: site?.id,
//                 status,
//                 reason,
//                 fromDate,
//                 toDate: status === 'active' ? null : toDate,
//                 description
//             };

//             await axios.post(`${process.env.REACT_APP_LOCAL_URL}/activeinactive`, activeInactiveFormData);

//             console.log('Status updated successfully');
//             onUpdate();
//             setTimeout(() => {
//                 onClose();
//                 window.location.reload();
//             }, 1000); // 1 second delay
//         } catch (error) {
//             console.error('Error updating status:', error);
//             setError('Failed to update status. Please try again.');
//         }
//     };

//     const handleClose = () => {
//         onClose();
//     };

//     const getStatusOptions = (userType) => {
//         if (userType === 'user') {
//             return (
//                 <>
//                     <option value="request_leave">Leave</option>
//                 </>
//             );
//         } else if (userType === 'manager' || userType === 'admin') {
//             return (
//                 <>
//                     <option value="active">Active</option>
//                     <option value="request_leave">Request Leave</option>
//                     <option value="resign_terminate">Resign/Terminate</option>
//                 </>
//             );
//         }
//     };

//     return (
//         <div id="statusModal" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
//             <div className="modal-dialog modal-lg">
//                 <div className="modal-content">
//                     <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
//                         <div className="modal-header">
//                             <h5 className="modal-title">Status - <small>is {employee?.status}</small> </h5>
//                             <button type="button" className="close" onClick={handleClose}>&times;</button>
//                         </div>
//                         <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
//                             {error && <div className="alert alert-danger">{error}</div>}
//                             <div className="form-row">
//                                 <div className="form-group col-md-6">
//                                     <label>Name<span style={{ color: "red" }}>*</span></label>
//                                     <input name="entityName" type="text" className="form-control" required value={employee?.employeeName || client?.clientName || site?.siteName} readOnly />
//                                 </div>
//                                 <div className="form-group col-md-6">
//                                     <label>Status<span style={{ color: "red" }}>*</span></label>
//                                     <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
//                                         <option value="" disabled hidden>Select status</option>
//                                         {getStatusOptions(userType)}
//                                     </select>
//                                 </div>
//                                 <div className="form-group col-md-4">
//                                     <label>Reason<span style={{ color: "red" }}>*</span></label>
//                                     <select className="form-control" value={reason} onChange={(e) => setReason(e.target.value)} required>
//                                         <option value="" disabled hidden>Select a reason</option>
//                                         {reasonOptions.map((option, index) => (
//                                             <option key={index} value={option}>{option}</option>
//                                         ))}
//                                     </select>
//                                 </div>
//                                 <div className="form-group col-md-4">
//                                     <label>From Date </label>
//                                     <input type="date" className="form-control" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
//                                 </div>
//                                 {status !== 'resign_terminate' && status !== 'active' && (
//                                     <div className="form-group col-md-4">
//                                         <label>To Date </label>
//                                         <input type="date" className="form-control" value={toDate} onChange={(e) => setToDate(e.target.value)} />
//                                     </div>
//                                 )}
//                                 <div className="form-group col-md-12">
//                                     <label>Description<span style={{ color: "red" }}>*</span></label>
//                                     <textarea className="form-control" placeholder='Description' value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="modal-footer">
//                             <button type="submit" className="btn btn-primary">Save</button>
//                             <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Leave;





// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const ActiveInactiveModal = ({ onClose, onUpdate, employee, userType }) => {
//     const [status, setStatus] = useState(employee?.status || '');
//     const [reason, setReason] = useState('');
//     const [fromDate, setFromDate] = useState('');
//     const [toDate, setToDate] = useState('');
//     const [description, setDescription] = useState('');
//     const [error, setError] = useState('');

//     // Dynamic reasons based on status
//     const reasonOptions = status === 'active'
//         ? ['Rejoin', 'Back From Paid Leave', 'Back From Non Paid Leave', 'Back from Holidays', 'Back from Deputation']
//         : ['Left From Job', 'Cessation from Job', 'Retired', 'On Paid Leave', 'Deputation to other Company', 'On Non Paid Leave', 'On Holidays', 'Resign', 'Termination'];

//     useEffect(() => {
//         // Log employee details if available
//         if (employee) {
//             console.log('Employee Details:', employee);
//         }
//     }, [employee]);

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         // Validate required fields
//         if (!status || !reason || !fromDate || !description) {
//             setError('Please fill in all required fields.');
//             return;
//         }

//         try {
            
//             const activeInactiveFormData = {
//                 employeeName: employee?.employeeName,
//                 employeeId: employee?.id,
//                 status,
//                 reason,
//                 fromDate,
//                 toDate: status === 'active' ? null : toDate,
//                 description,
//             };

//             await axios.post(`${process.env.REACT_APP_LOCAL_URL}/activeinactive`, activeInactiveFormData);
//             console.log('Status updated successfully');
//             onUpdate();
//             onClose();

//             // setTimeout(() => {
//             //     onClose();
//             //     window.location.reload();
//             // }, 1000); // 1 second delay
//         } catch (error) {
//             console.error('Error updating status:', error);
//             setError('Failed to update status. Please try again.');
//         }
//     };

//     const handleClose = () => {
//         onClose();
//     };

//     const getStatusOptions = () => {
//         switch (userType) {
//             case 'user':
//                 return (
//                     <option value="request_leave">Leave</option>
//                 );
//             case 'manager':
//             case 'admin':
//                 return (
//                     <>
//                         <option value="active">Active</option>
//                         <option value="request_leave">Request Leave</option>
//                         <option value="resign_terminate">Resign/Terminate</option>
//                     </>
//                 );
//             default:
//                 return null;
//         }
//     };

//     return (
//         <div id="statusModal" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
//             <div className="modal-dialog modal-lg">
//                 <div className="modal-content">
//                     <form onSubmit={handleSubmit} autoComplete="off" noValidate>
//                         <div className="modal-header">
//                             <h5 className="modal-title">Status - <small>is {employee?.status}</small></h5>
//                             <button type="button" className="close" onClick={handleClose}>&times;</button>
//                         </div>
//                         <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
//                             {error && <div className="alert alert-danger">{error}</div>}
//                             <div className="form-row">
//                                 <div className="form-group col-md-6">
//                                     <label>Name<span style={{ color: "red" }}>*</span></label>
//                                     <input
//                                         name="entityName"
//                                         type="text"
//                                         className="form-control"
//                                         value={employee?.employeeName || ''}
//                                         readOnly
//                                     />
//                                 </div>
//                                 <div className="form-group col-md-6">
//                                     <label>Status<span style={{ color: "red" }}>*</span></label>
//                                     <select
//                                         className="form-control"
//                                         value={status}
//                                         onChange={(e) => setStatus(e.target.value)}
//                                         required
//                                     >
//                                         <option value="" disabled hidden>Select status</option>
//                                         {getStatusOptions()}
//                                     </select>
//                                 </div>
//                                 <div className="form-group col-md-4">
//                                     <label>Reason<span style={{ color: "red" }}>*</span></label>
//                                     <select
//                                         className="form-control"
//                                         value={reason}
//                                         onChange={(e) => setReason(e.target.value)}
//                                         required
//                                     >
//                                         <option value="" disabled hidden>Select a reason</option>
//                                         {reasonOptions.map((option, index) => (
//                                             <option key={index} value={option}>{option}</option>
//                                         ))}
//                                     </select>
//                                 </div>
//                                 <div className="form-group col-md-4">
//                                     <label>From Date <span style={{ color: "red" }}>*</span></label>
//                                     <input
//                                         type="date"
//                                         className="form-control"
//                                         value={fromDate}
//                                         onChange={(e) => setFromDate(e.target.value)}
//                                         required
//                                     />
//                                 </div>
//                                 {status !== 'resign_terminate' && status !== 'active' && (
//                                     <div className="form-group col-md-4">
//                                         <label>To Date</label>
//                                         <input
//                                             type="date"
//                                             className="form-control"
//                                             value={toDate}
//                                             onChange={(e) => setToDate(e.target.value)}
//                                         />
//                                     </div>
//                                 )}
//                                 <div className="form-group col-md-12">
//                                     <label>Description<span style={{ color: "red" }}>*</span></label>
//                                     <textarea
//                                         className="form-control"
//                                         placeholder='Description'
//                                         value={description}
//                                         onChange={(e) => setDescription(e.target.value)}
//                                         required
//                                     ></textarea>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="modal-footer">
//                             <button type="submit" className="btn btn-primary">Save</button>
//                             <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default ActiveInactiveModal;




import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ActiveInactiveModal = ({ onClose, onUpdate, employee, userType }) => {
    const [status, setStatus] = useState(employee?.status || '');
    const [reason, setReason] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');

    // Dynamic reasons based on status
    const reasonOptions = status === 'active'
        ? ['Rejoin', 'Back From Paid Leave', 'Back From Non Paid Leave', 'Back from Holidays', 'Back from Deputation']
        : ['Left From Job', 'Cessation from Job', 'Retired', 'On Paid Leave', 'Deputation to other Company', 'On Non Paid Leave', 'On Holidays', 'Resign', 'Termination'];

    useEffect(() => {
        // Log employee details if available
        if (employee) {
            console.log('Employee Details:', employee);
        }
    }, [employee]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate required fields
        if (!status || !reason || !fromDate || !description) {
            setError('Please fill in all required fields.');
            return;
        }

        try {
            // Update employee status only if the status is "active"
            if (status === 'active' && employee) {
                await axios.put(`${process.env.REACT_APP_LOCAL_URL}/employee_status/${employee.id}`, { status });
            }

            const activeInactiveFormData = {
                employeeName: employee?.employeeName,
                employeeCode: employee?.employeeCode,
                employeeId: employee?.id,
                status,
                reason,
                fromDate,
                toDate: status === 'active' ? null : toDate,
                description,
            };

            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/activeinactive`, activeInactiveFormData);
            console.log('Status updated successfully');
            onUpdate();
            setTimeout(() => {
                onClose();
                window.location.reload();
            }, 1000); // 1 second delay
        } catch (error) {
            console.error('Error updating status:', error);
            setError('Failed to update status. Please try again.');
        }
    };

    const handleClose = () => {
        onClose();
    };

    const getStatusOptions = () => {
        switch (userType) {
            case 'user':
                return (
                    <option value="request_leave">Leave</option>
                );
            case 'manager':
            case 'admin':
                return (
                    <>
                        <option value="active">Active</option>
                        <option value="request_leave">Request Leave</option>
                        <option value="resign_terminate">Resign/Terminate</option>
                    </>
                );
            default:
                return null; 
        }
    };

    return (
        <div id="statusModal" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                        <div className="modal-header">
                            <h5 className="modal-title">Status - <small>is {employee?.status}</small></h5>
                            <button type="button" className="close" onClick={handleClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>Name<span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="entityName"
                                        type="text"
                                        className="form-control"
                                        value={employee?.employeeName || ''}
                                        readOnly
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Status<span style={{ color: "red" }}>*</span></label>
                                    <select
                                        className="form-control"
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                        required
                                    >
                                        <option value="" disabled hidden>Select status</option>
                                        {getStatusOptions()}
                                    </select>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Reason<span style={{ color: "red" }}>*</span></label>
                                    <select
                                        className="form-control"
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                        required
                                    >
                                        <option value="" disabled hidden>Select a reason</option>
                                        {reasonOptions.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>From Date <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={fromDate}
                                        onChange={(e) => setFromDate(e.target.value)}
                                        required
                                    />
                                </div>
                                {status !== 'resign_terminate' && status !== 'active' && (
                                    <div className="form-group col-md-4">
                                        <label>To Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={toDate}
                                            onChange={(e) => setToDate(e.target.value)}
                                        />
                                    </div>
                                )}
                                <div className="form-group col-md-12">
                                    <label>Description<span style={{ color: "red" }}>*</span></label>
                                    <textarea
                                        className="form-control"
                                        placeholder='Description'
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        required
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary">Save</button>
                            <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ActiveInactiveModal;
