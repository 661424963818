import React, { useEffect, useState } from 'react';
import axios from 'axios';

const RequestLeave = ({ onClose, onUpdate }) => {
    const [departments, setDepartments] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [employeeDetails, setEmployeeDetails] = useState({});
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [status, setStatus] = useState('');
    const [reason, setReason] = useState('');
    const [leaveRequest, setLeaveRequest] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');

    // Dynamic reasons based on status
    const reasonOptions = ['On Paid Leave', 'On Non Paid Leave', 'On Holidays', 'Deputation to other Company'];

    useEffect(() => {
        const fetchDepartments = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/departments`);
                setDepartments(response.data);
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        };

        fetchDepartments();
    }, []);

    const fetchEmployees = async (departmentId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`, {
                params: {
                    departmentId,
                    status: 'active'
                }
            });
            setEmployees(response.data);
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    const fetchEmployeeDetails = async (employeeId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`);

            // Assuming the response data is an array with one object
            const employeeData = response.data.length > 0 ? response.data[0] : null;
            setEmployeeDetails(employeeData);
            setStatus(employeeData.status);
            console.log("employeeDetails", employeeData);
            console.log("employeeDetails", employeeData.status);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    const handleDepartmentChange = (e) => {
        const departmentId = e.target.value;
        setSelectedDepartment(departmentId);
        fetchEmployees(departmentId);
    };

    const handleEmployeeChange = (e) => {
        const employeeId = e.target.value;
        setSelectedEmployee(employeeId);
        fetchEmployeeDetails(employeeId);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate required fields
        const formData = { selectedEmployee, leaveRequest, reason, fromDate, description };
        for (const field in formData) {
            if (!formData[field]) {
                setError(`Please fill in the ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`);
                return;
            }
        }

        if (status !== 'active') {
            setError('The selected employee is inactive. Please select an active employee.');
            return;
        }

        try {
            const requestLeaveData = {
                employeeId: selectedEmployee,
                employeeName: employeeDetails.employeeName,
                status: leaveRequest,
                reason,
                fromDate,
                toDate,
                description
            };
            await axios.put(`${process.env.REACT_APP_LOCAL_URL}/employee_status/${employeeDetails.id}`, { status: leaveRequest });

            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/activeinactive`, requestLeaveData);

            console.log('Leave request submitted successfully');
            onUpdate();
            onClose();
        } catch (error) {
            console.error('Error submitting leave request:', error);
            setError('Failed to submit leave request. Please try again.');
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div id="requestLeaveModal" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
                        <div className="modal-header">
                            <h5 className="modal-title">Request Leave</h5>
                            <button type="button" className="close" onClick={handleClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>Department<span style={{ color: "red" }}>*</span></label>
                                    <select className="form-control" value={selectedDepartment} onChange={handleDepartmentChange} required>
                                        <option value="" disabled hidden>Select department</option>
                                        {departments.map((department) => (
                                            <option key={department.id} value={department.id}>{department.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Employee<span style={{ color: "red" }}>*</span></label>
                                    <select className="form-control" value={selectedEmployee} onChange={handleEmployeeChange} required>
                                        <option value="" disabled hidden>Select employee</option>
                                        {employees.map((employee) => (
                                            <option key={employee.id} value={employee.id}>{employee.employeeName}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Current Status<span style={{ color: "red" }}>*</span></label>
                                    <input name="status" type="text" className="form-control" placeholder="Current Status" value={status} readOnly />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Apply To Leave<span style={{ color: "red" }}>*</span></label>
                                    <select className="form-control" value={leaveRequest} onChange={(e) => setLeaveRequest(e.target.value)} required>
                                        <option value="" disabled hidden>Select a reason</option>
                                        <option value="request_leave">Request Leave</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Reason<span style={{ color: "red" }}>*</span></label>
                                    <select className="form-control" value={reason} onChange={(e) => setReason(e.target.value)} required>
                                        <option value="" disabled hidden>Select a reason</option>
                                        {reasonOptions.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>From Date<span style={{ color: "red" }}>*</span></label>
                                    <input type="date" className="form-control" value={fromDate} onChange={(e) => setFromDate(e.target.value)} required />
                                </div>
                                <div className="form-group col-md-4">
                                    <label>To Date</label>
                                    <input type="date" className="form-control" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Description<span style={{ color: "red" }}>*</span></label>
                                    <textarea className="form-control" placeholder='Description' value={description} onChange={(e) => setDescription(e.target.value)} required></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary">Submit</button>
                            <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default RequestLeave;
