import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/sidebar/Sidebar';
import SearchBar from '../../components/sidebar/SearchBar';
import EmployeeResignTerminationpreview from './EmployeeResignTerminationpreview';

function EmployeeResignTermination({ handleLogout, username }) {
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Initialize with current month (+1 since months are 0-indexed)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initialize with current year
    const [employeeData, setEmployeeData] = useState([]); // State to hold employee resignation data
    const [filteredEmployee, setFilteredEmployee] = useState([]); // State for filtered employee data
    const [grandTotals, setGrandTotals] = useState({ totalEmployees: 0 }); // State for grand totals
    const [showSidebar, setShowSidebar] = useState(true); // State to control sidebar visibility
    const [showSearchBar, setShowSearchBar] = useState(true); // State to control search bar visibility
    const [employeeDetailsVisible, setEmployeeDetailsVisible] = useState(false); // State for employee details modal
    const [selectedRecord, setSelectedRecord] = useState(null); // State for selected record

    useEffect(() => {
        fetchEmployees();
    }, []);

    useEffect(() => {
        filterEmployee();
    }, [selectedMonth, selectedYear, employeeData]);

    useEffect(() => {
        calculateTotals();
    }, [filteredEmployee]);

    // const fetchEmployees = async () => {
    //     try {
    //         // Fetch employees with status 'resign_terminate' from employee_details
    //         const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
    //         const employeeDetails = response.data.filter(
    //             employee => employee.status === 'resign_terminate'
    //         );

    //         // Fetch the latest active_inactive status for these employees
    //         const activeInactiveResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/activeinactive/last`);
    //         const activeInactiveData = activeInactiveResponse.data;

    //         // Filter employees where the active_inactive status is 'resign_terminate'
    //         const filteredEmployees = employeeDetails.map(employee => {
    //             const activeInactiveRecord = activeInactiveData.find(record => record.employeeId === employee.id && record.status === 'resign_terminate');
    //             if (activeInactiveRecord) {
    //                 return {
    //                     ...employee,
    //                     resignFromDate: activeInactiveRecord.fromDate
    //                 };
    //             }
    //             return null;
    //         }).filter(employee => employee !== null);

    //         setEmployeeData(filteredEmployees);
    //     } catch (error) {
    //         console.error("Error fetching employees:", error);
    //         toast.error("Failed to fetch employee data.");
    //     }
    // };
    const fetchEmployees = async () => {
        try {
            // Fetch employees with status 'resign_terminate' from employee_details
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            const employeeDetails = response.data.filter(
                employee => employee.status === 'resign_terminate'
            );

            // Fetch the latest active_inactive status for each employee individually
            const employeePromises = employeeDetails.map(async (employee) => {
                try {
                    const activeInactiveResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/activeinactive_status/latest/${employee.id}`);
                    const activeInactiveRecord = activeInactiveResponse.data;

                    if (activeInactiveRecord.status === 'resign_terminate') {
                        return {
                            ...employee,
                            resignFromDate: activeInactiveRecord.fromDate
                        };
                    } else {
                        return null;
                    }
                } catch (error) {
                    console.error(`Error fetching active/inactive status for employee ${employee.id}:`, error);
                    return null;
                }
            });

            // Wait for all promises to resolve
            const activeInactiveData = await Promise.all(employeePromises);

            // Filter out null values (employees for whom the status was not 'resign_terminate')
            const filteredEmployees = activeInactiveData.filter(employee => employee !== null);

            setEmployeeData(filteredEmployees);
        } catch (error) {
            console.error("Error fetching employees:", error);
            toast.error("Failed to fetch employee data.");
        }
    };

    const filterEmployee = () => {
        // Filter the employee data based on the selected month and year
        const filtered = employeeData.filter(employee => {
            const resignDate = new Date(employee.resignFromDate);
            return (
                resignDate.getMonth() + 1 === selectedMonth && // +1 because JS months are 0-based
                resignDate.getFullYear() === selectedYear
            );
        });
        setFilteredEmployee(filtered);
    };

    const calculateTotals = () => {
        // Calculate the total number of employees who resigned in the selected month and year
        const totalEmployees = filteredEmployee.length;
        setGrandTotals({ totalEmployees });
    };

    const handleEmployeeDetails = () => {
        setSelectedRecord({
            date: new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
            status: filteredEmployee.length > 0 ? filteredEmployee[0].status : '',
            employeeData: { filteredEmployee },
            selectedMonth,
            selectedYear
        });

        setEmployeeDetailsVisible(true);
        setShowSidebar(false);
        setShowSearchBar(false);
    };

    const handleClosePreview = () => {
        setShowSidebar(true); // Show sidebar when closing preview
        setShowSearchBar(true); // Show search bar when closing preview
        setEmployeeDetailsVisible(false); // Hide employee details
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };
    return (
        <div className='d-flex w-100 h-100'>
            {showSidebar && <Sidebar />}
            <div className='w-100'>
                {showSearchBar && <SearchBar className="searchbarr" username={username} handleLogout={handleLogout} />}
                <div className="container-fluid">
                    <ToastContainer />
                    {employeeDetailsVisible ? (
                        <EmployeeResignTerminationpreview
                            record={selectedRecord}
                            onClose={handleClosePreview}
                        />
                    ) : (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">Employee Resignation Report</h6>
                                        <div className='d-flex align-items-center justify-content-center gap-1'>
                                            <label className='pt-2 text-black fw-bolder'>Filter:</label>
                                            <select
                                                className="form-control"
                                                value={selectedMonth}
                                                onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                                            >
                                                <option value="">Select Month</option>
                                                {Array.from({ length: 12 }, (_, i) => (
                                                    <option key={i} value={i + 1}>{new Date(2000, i).toLocaleString('default', { month: 'long' })}</option>
                                                ))}
                                            </select>
                                            <select
                                                className="form-control"
                                                value={selectedYear}
                                                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                                            >
                                                <option value="">Select Year</option>
                                                {Array.from({ length: 10 }, (_, i) => (
                                                    <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center gap-1'>
                                            <button className="btn btn-outline-primary" onClick={handleEmployeeDetails}><i className="fa fa-download"></i> PDF</button>
                                        </div>
                                    </div>
                                    <div className="card-body form-row">
                                        <div className='col-md-12' style={{ maxHeight: "450px", overflowY: "auto" }}>
                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Employee</th>
                                                        <th>Employee Code</th>
                                                        <th>Employee Number</th>
                                                        <th>Department Name</th>
                                                        <th>Designation Name</th>
                                                        <th>Joining Office</th>
                                                        <th>Employee Type</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredEmployee.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="7" className="text-center">No Employee Resignation Details Found for This Month.</td>
                                                        </tr>
                                                    ) : (
                                                        filteredEmployee.map((employee, index) => (
                                                            <tr key={index}>

                                                                <td className='text-nowrap'>{formatDate(employee.resignFromDate)}</td>
                                                                <td>{employee.employeeName}</td>
                                                                <td>{employee.employeeCode}</td>
                                                                <td>{employee.employeePhone}</td>
                                                                <td>{employee.departmentName}</td>
                                                                <td>{employee.positionName}</td>
                                                                <td>{employee.joiningOffice}</td>
                                                                <td>{employee.employeeType}</td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Totals</th>
                                                        <th>{grandTotals.totalEmployees}</th>
                                                        <th colSpan="5"></th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default EmployeeResignTermination;
