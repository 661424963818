import axios from 'axios';
import React, { useEffect, useState } from 'react';

const RepaymentHistory = ({ onClose, loan }) => {
  const [repaymentDetails, setRepaymentDetails] = useState([]);
  useEffect(() => {
    console.log("loan", loan);
    fetchRepaymentDetails();
  }, [loan]);

  const handleClose = () => {
    onClose();
  };

  // Function to fetch repayment details for a specific loan
  const fetchRepaymentDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/repaymentdetailsHistory/${loan.id}`);
      console.log('Fetched loan Repayment Details :', response.data);
      setRepaymentDetails(response.data);
    } catch (error) {
      console.error('Error fetching loan Repayment Details:', error);
    }
  };

  // Format loan date and repayment date to display in the desired format
  const formattedLoanDate = new Date(loan.loanDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });

  const formattedRepaymentDate = new Date(loan.loanRepaymentDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });

  const totalRepaymentAmount = repaymentDetails.reduce((total, repayment) => total + repayment.repaymentAmount, 0);


  return (
    <div id="addRepayment" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Repayment Details</h4>
            <button type="button" className="close" onClick={handleClose}>&times;</button>
          </div>
          <div className="modal-body p-0" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
            {repaymentDetails.length > 0 ? (
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Loan Number</th>
                    <th>Loan Amount</th>
                    <th>Repayment Amount</th>
                    <th>Repayment Date</th>
                    <th>Repayment mode</th>
                  </tr>
                </thead>
                <tbody >
                  {repaymentDetails.map((repayment) => (
                    <tr key={repayment.loanRepaymentId}>
                      <td className='text-capitalize'>{repayment.employeeName}</td>
                      <td>{repayment.loanNumber || "-"}</td>
                      <td>{repayment.loanAmount}</td>
                      <td>{repayment.repaymentAmount}</td>
                      <td>{new Date(repayment.repaymentDate).toLocaleDateString()}</td>
                      <td>{repayment.repaymentMode}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h5 className='text-center'>No repayment details found for this loan.</h5>
            )}
          </div>
          <div className="modal-footer d-flex align-items-center justify-content-between">
          <p><strong>Total Repayment: </strong> {totalRepaymentAmount}</p>
            <button type="button" className="btn btn-danger" onClick={handleClose}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepaymentHistory;
