
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PaymentHistory = ({ onClose, record }) => {

    const [formData, setFormData] = useState({ ...record });
    const [paymentDetails, setPaymentDetails] = useState([]);

    console.log("reeee", record)

    useEffect(() => {
        fetchPaymentForm();
    }, []);

    const fetchPaymentForm = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/paymentform/${formData.id}`);
            setPaymentDetails(response.data);
        } catch (error) {
            console.error('Error fetching payment modes:', error);
        }
    };

    const handleClose = () => {
        onClose();
    };

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    return (
        <div id="paymentHistory" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Payment History</h4>
                        <button type="button" className="close" onClick={handleClose}>&times;</button>
                    </div>
                    <div className="modal-body p-0" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                        {paymentDetails.length > 0 ? paymentDetails.map((payment, index) => (
                            <div className='bg-light rounded d-flex justify-content-around border m-1' key={index}>
                                <div className='w-100'>
                                    <div className='w-100 p-2 d-flex justify-content-between flex-column'>
                                        <div className=' d-flex justify-content-between'>
                                            <div>
                                                <p className='p-1 m-0'><strong>Department Name:</strong> {payment.departmentName}</p>
                                                <p className='p-1 m-0'><strong>Employee Name:</strong> {payment.employeeName}</p>
                                                <p className='p-1 m-0'><strong>Net Salary Payable for {monthNames[payment.month - 1]} {payment.year}:</strong> {payment.netSalaryPayableMonth}</p>
                                               

                                            </div>
                                            <div>
                                                <p className='p-1 m-0'><strong>Payment Mode:</strong> {payment.paymentModeName}</p>
                                                <p className='p-1 m-0'><strong>Amount Date:</strong> {new Date(payment.amountDate).toLocaleDateString()}</p>
                                                <p className='p-1 m-0'><strong>Amount Paid:</strong> {payment.amountPaid}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <p className='p-1 m-0'><strong className='text-black'>Payment Description:</strong> {payment.paymentDescription}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : (
                            <h5 className='text-center'>No Payment history found for this employee.</h5>
                        )}
                    </div>
                    <div className="modal-footer d-flex align-items-center justify-content-between">
                        <button type="button" className="btn btn-danger" onClick={handleClose}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentHistory;
