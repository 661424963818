
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const AddCompany = ({ onClose, onUpdate }) => {
//     const [formData, setFormData] = useState({
//         companyName: '',
//         companyAddress: '',
//         companyEmail: '',
//         companyPhone: '',
//         companyPAN: '',
//         companyGST: '',
//         username: localStorage.getItem('username'),
//     });
//     const [qrCodeData, setQRCodeData] = useState(null);
//     const [errors, setErrors] = useState({});

//     useEffect(() => {
//         console.log('qrCodeData updated:', qrCodeData);
//     }, [qrCodeData]);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({
//             ...formData,
//             [name]: value
//         });
//     };

//     const validate = () => {
//         let formErrors = {};
//         if (!formData.companyName) formErrors.companyName = 'Company Name is required';
//         if (!formData.companyEmail) formErrors.companyEmail = 'Company Email is required';
//         if (!formData.companyPhone) formErrors.companyPhone = 'Company Phone is required';
//         if (!formData.companyAddress) formErrors.companyAddress = 'Company Address is required';
//         if (!formData.companyPAN) formErrors.companyPAN = 'Company PAN is required';
//         if (!formData.companyGST) formErrors.companyGST = 'Company GST No is required';
      
//         setErrors(formErrors);
//         return Object.keys(formErrors).length === 0;
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         console.log('Form submitted:', formData);
//         if (!validate()) {
//             return;
//         }      

//         const companyInfoString = JSON.stringify(formData);
//         setQRCodeData(companyInfoString);

//         const formDataToSend = new FormData();
//         Object.keys(formData).forEach(key => {
//             formDataToSend.append(key, formData[key]);
//         });

//         formDataToSend.append('qrCodeData', companyInfoString);

//         try {
//             const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/addCompany`, formData);
//             console.log('Data uploaded successfully:', response.data);
//             onClose();
//             onUpdate();
//         } catch (error) {
//             console.error('Error uploading data:', error);
//         }
//     };

//     const handleClose = () => {
//         onClose();
//     };

//     return (
//         <div id="add" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
//             <div className="modal-dialog modal-lg">
//                 <div className="modal-content">
//                     <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
//                         <div className="modal-header">
//                             <h5 className="modal-title">Add Company</h5>
//                             <button type="button" className="close" onClick={handleClose}>&times;</button>
//                         </div>
//                         <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
//                             <div className="form-group">
//                                 <label>Company Name<span style={{ color: "red" }}>*</span></label>
//                                 <input
//                                     name="companyName"
//                                     type="text"
//                                     className={`form-control ${errors.companyName ? 'is-invalid' : ''}`}
//                                     required
//                                     placeholder="Company Name"
//                                     onChange={handleChange}
//                                 />
//                                 {errors.companyName && <div className="invalid-feedback">{errors.companyName}</div>}
//                             </div>
//                             <div className="form-row">
//                                 <div className="form-group col-md-6">
//                                     <label>Company Email<span style={{ color: "red" }}>*</span></label>
//                                     <input
//                                         name="companyEmail"
//                                         type="email"
//                                         className={`form-control ${errors.companyEmail ? 'is-invalid' : ''}`}
//                                         required
//                                         placeholder="Company Email"
//                                         onChange={handleChange}
//                                     />
//                                     {errors.companyEmail && <div className="invalid-feedback">{errors.companyEmail}</div>}
//                                 </div>
//                                 <div className="form-group col-md-6">
//                                     <label>Company Phone<span style={{ color: "red" }}>*</span></label>
//                                     <input
//                                         name="companyPhone"
//                                         type="text"
//                                         className={`form-control ${errors.companyPhone ? 'is-invalid' : ''}`}
//                                         required
//                                         placeholder="Company Phone"
//                                         onChange={handleChange}
//                                     />
//                                     {errors.companyPhone && <div className="invalid-feedback">{errors.companyPhone}</div>}
//                                 </div>
//                             </div>
//                             <div className="form-group">
//                                 <label>Company Address<span style={{ color: "red" }}>*</span></label>
//                                 <input
//                                     name="companyAddress"
//                                     type="text"
//                                     className={`form-control ${errors.companyAddress ? 'is-invalid' : ''}`}
//                                     required
//                                     placeholder="Company Address"
//                                     onChange={handleChange}
//                                 />
//                                 {errors.companyAddress && <div className="invalid-feedback">{errors.companyAddress}</div>}
//                             </div>
//                             <div className="form-row">
//                                 <div className="form-group col-md-6">
//                                     <label>Company PAN<span style={{ color: "red" }}>*</span></label>
//                                     <input
//                                         name="companyPAN"
//                                         type="text"
//                                         className={`form-control ${errors.companyPAN ? 'is-invalid' : ''}`}
//                                         required
//                                         placeholder="Company PAN"
//                                         onChange={handleChange}
//                                     />
//                                     {errors.companyPAN && <div className="invalid-feedback">{errors.companyPAN}</div>}
//                                 </div>
//                                 <div className="form-group col-md-6">
//                                     <label>Company GST<span style={{ color: "red" }}>*</span></label>
//                                     <input
//                                         name="companyGST"
//                                         type="text"
//                                         className={`form-control ${errors.companyGST ? 'is-invalid' : ''}`}
//                                         required
//                                         placeholder="Company GST"
//                                         onChange={handleChange}
//                                     />
//                                     {errors.companyGST && <div className="invalid-feedback">{errors.companyGST}</div>}
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="modal-footer">
//                             <button type="submit" className="btn btn-primary" id="save">Save</button>
//                             <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AddCompany;











import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddCompany = ({ onClose, onUpdate }) => {
    const [formData, setFormData] = useState({
        companyName: '',
        companyAddress: '',
        companyEmail: '',
        companyPhone: '',
        companyPAN: '',
        companyGST: '',
        username: localStorage.getItem('username'),
    });
    const [qrCodeData, setQRCodeData] = useState(null);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        console.log('qrCodeData updated:', qrCodeData);
    }, [qrCodeData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validate = () => {
        let formErrors = {};
        if (!formData.companyName) formErrors.companyName = 'Company Name is required';
        if (!formData.companyEmail) formErrors.companyEmail = 'Company Email is required';
        if (!formData.companyPhone) formErrors.companyPhone = 'Company Phone is required';
        if (!formData.companyAddress) formErrors.companyAddress = 'Company Address is required';
        if (!formData.companyPAN) formErrors.companyPAN = 'Company PAN is required';
        if (!formData.companyGST) formErrors.companyGST = 'Company GST No is required';
      
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
        if (!validate()) {
            return;
        }      

        const companyInfoString = JSON.stringify(formData);
        setQRCodeData(companyInfoString);

        const formDataToSend = new FormData();
        Object.keys(formData).forEach(key => {
            formDataToSend.append(key, formData[key]);
        });

        formDataToSend.append('qrCodeData', companyInfoString);

        try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/addCompany`, formData);
            console.log('Data uploaded successfully:', response.data);
            
            onUpdate();
            setTimeout(() => {
                onClose();
                window.location.reload();
            }, 1000); // 1 second delay
        } catch (error) {
            console.error('Error uploading data:', error);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div id="add" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Company</h5>
                            <button type="button" className="close" onClick={handleClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                            <div className="form-group">
                                <label>Company Name<span style={{ color: "red" }}>*</span></label>
                                <input
                                    name="companyName"
                                    type="text"
                                    className={`form-control ${errors.companyName ? 'is-invalid' : ''}`}
                                    required
                                    placeholder="Company Name"
                                    onChange={handleChange}
                                />
                                {errors.companyName && <div className="invalid-feedback">{errors.companyName}</div>}
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>Company Email<span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="companyEmail"
                                        type="email"
                                        className={`form-control ${errors.companyEmail ? 'is-invalid' : ''}`}
                                        required
                                        placeholder="Company Email"
                                        onChange={handleChange}
                                    />
                                    {errors.companyEmail && <div className="invalid-feedback">{errors.companyEmail}</div>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Company Phone<span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="companyPhone"
                                        type="text"
                                        className={`form-control ${errors.companyPhone ? 'is-invalid' : ''}`}
                                        required
                                        placeholder="Company Phone"
                                        onChange={handleChange}
                                    />
                                    {errors.companyPhone && <div className="invalid-feedback">{errors.companyPhone}</div>}
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Company Address<span style={{ color: "red" }}>*</span></label>
                                <input
                                    name="companyAddress"
                                    type="text"
                                    className={`form-control ${errors.companyAddress ? 'is-invalid' : ''}`}
                                    required
                                    placeholder="Company Address"
                                    onChange={handleChange}
                                />
                                {errors.companyAddress && <div className="invalid-feedback">{errors.companyAddress}</div>}
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>Company PAN<span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="companyPAN"
                                        type="text"
                                        className={`form-control ${errors.companyPAN ? 'is-invalid' : ''}`}
                                        required
                                        placeholder="Company PAN"
                                        onChange={handleChange}
                                    />
                                    {errors.companyPAN && <div className="invalid-feedback">{errors.companyPAN}</div>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Company GST<span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="companyGST"
                                        type="text"
                                        className={`form-control ${errors.companyGST ? 'is-invalid' : ''}`}
                                        required
                                        placeholder="Company GST"
                                        onChange={handleChange}
                                    />
                                    {errors.companyGST && <div className="invalid-feedback">{errors.companyGST}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" id="save">Save</button>
                            <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddCompany;
