import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/sidebar/Sidebar';
import SearchBar from '../../components/sidebar/SearchBar';
import BonusReportPreview from './BonusReportPreview';

function BonusReport({ handleLogout, username }) {
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Initialize with current month (+1 since months are 0-indexed)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initialize with current year
    const [bonusIncentiveData, setBonusIncentiveData] = useState([]); // State to hold bonus and incentive data
    const [filteredRecords, setFilteredRecords] = useState([]); // State for filtered records
    const [grandTotals, setGrandTotals] = useState({}); // State for grand totals
    const [showSidebar, setShowSidebar] = useState(true); // State to control sidebar visibility
    const [showSearchBar, setShowSearchBar] = useState(true); // State to control search bar visibility
    const [detailsVisible, setDetailsVisible] = useState(false); // State for details modal
    const [selectedRecord, setSelectedRecord] = useState(null); // State for selected record

    useEffect(() => {
        fetchBonusIncentiveData();
    }, []);

    useEffect(() => {
        filterRecords();
    }, [selectedMonth, selectedYear, bonusIncentiveData]);

    useEffect(() => {
        calculateTotals();
    }, [filteredRecords]);

    const fetchBonusIncentiveData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/bonusincentive`);
            setBonusIncentiveData(response.data);
        } catch (error) {
            console.error('Error fetching bonus and incentive data:', error);
            toast.error('Error fetching bonus and incentive data');
        }
    };

    const filterRecords = () => {
        // Filter the bonus and incentive data based on selected month and year
        const filtered = bonusIncentiveData.filter(record => {
            const date = new Date(record.declarationDate);
            return (
                date.getMonth() + 1 === selectedMonth && // +1 because JS months are 0-based
                date.getFullYear() === selectedYear
            );
        });
        setFilteredRecords(filtered);
    };

    const calculateTotals = () => {
        const totals = filteredRecords.reduce((acc, record) => {
            acc.totalEmployees += 1;
            acc.totalAmount += record.amount ? parseFloat(record.amount) : 0;
            return acc;
        }, {
            totalEmployees: 0,
            totalAmount: 0,
        });

        setGrandTotals(totals);
    };

    const handleDetails = () => {
        setSelectedRecord({
            date: new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
            status: filteredRecords.length > 0 ? filteredRecords[0].status : '',
            recordData: filteredRecords,
            selectedMonth,
            selectedYear
        });

        setDetailsVisible(true);
        setShowSidebar(false);
        setShowSearchBar(false);
    };

    const handleClosePreview = () => {
        setShowSidebar(true); // Show sidebar when closing preview
        setShowSearchBar(true); // Show search bar when closing preview
        setDetailsVisible(false); // Hide details
    };

    return (
        <div className='d-flex w-100 h-100'>
            {showSidebar && <Sidebar />}
            <div className='w-100'>
                {showSearchBar && <SearchBar className="searchbarr" username={username} handleLogout={handleLogout} />}
                <div className="container-fluid">
                    <ToastContainer />
                    {detailsVisible ? (
                        <BonusReportPreview
                            record={selectedRecord}
                            onClose={handleClosePreview}
                        />
                    ) : (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">Bonus and Incentive Report</h6>
                                        <div className='d-flex align-items-center justify-content-center gap-1'>
                                            <label className='pt-2 text-black fw-bolder'>Filter:</label>
                                            <select
                                                className="form-control"
                                                value={selectedMonth}
                                                onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                                            >
                                                <option value="">Select Month</option>
                                                {Array.from({ length: 12 }, (_, i) => (
                                                    <option key={i} value={i + 1}>{new Date(2000, i).toLocaleString('default', { month: 'long' })}</option>
                                                ))}
                                            </select>
                                            <select
                                                className="form-control"
                                                value={selectedYear}
                                                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                                            >
                                                <option value="">Select Year</option>
                                                {Array.from({ length: 10 }, (_, i) => (
                                                    <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center gap-1'>
                                            <button className="btn btn-outline-primary" onClick={handleDetails}><i className="fa fa-download"></i> PDF</button>
                                        </div>
                                    </div>
                                    <div className="card-body form-row">
                                        <div className='col-md-12' style={{ maxHeight: "450px", overflowY: "auto" }}>
                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                    <tr>
                                                        <th>Month</th>
                                                        <th>Employee</th>
                                                        <th>Employee Code</th>
                                                        <th>Payment Type</th>
                                                        <th>Payment Mode</th>
                                                        <th>Amount</th>
                                                        <th>Period</th>
                                                        <th>Reason</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredRecords.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="7" className="text-center">No Bonus/Incentive Details Found.</td>
                                                        </tr>
                                                    ) : (
                                                        filteredRecords.map((record, index) => (
                                                            <tr key={index}>
                                                                <td>{new Date(record.declarationDate).toLocaleString('default', { month: 'long', year: 'numeric' })}</td>
                                                                <td>{record.employeeName}</td>
                                                                <td>{record.employeeCode}</td>
                                                                <td>{record.paymentType}</td>
                                                                <td>{record.paymentMode}</td>
                                                                <td className='text-end'>&#x20B9;{record.amount != null ? record.amount.toFixed(2) : '0.00'}</td>
                                                                <td>{new Date(record.fromDate).toLocaleDateString()} - {new Date(record.toDate).toLocaleDateString()}</td>
                                                                <td>{record.reason}</td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Totals</th>
                                                        <th>{grandTotals.totalEmployees}</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th className='text-end'>&#x20B9;{grandTotals.totalAmount != null ? grandTotals.totalAmount.toFixed(2) : '0.00'}</th>
                                                        <th></th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default BonusReport;
