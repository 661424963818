import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ViewBonousInsentive = ({ onClose, record }) => {
    const [payments, setPayments] = useState([]);

    useEffect(() => {
        console.log("record", record);
        if (record && record.id) {
            fetchBonousPaymentHistory();
        }
    }, [record]);

    const handleClose = () => {
        onClose();
    };

    const fetchBonousPaymentHistory = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_LOCAL_URL}/bonousinsentive/viewbonous/${record.id}`
            );
            setPayments(response.data);
        } catch (error) {
            console.error("Error fetching bonus/incentive payment history:", error);
        }
    };

    return (
        <div id="ViewBonousInsentiveModal" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Bonus Incentive</h4>
                        <button type="button" className="close" onClick={handleClose}>&times;</button>
                    </div>
                    <div className="modal-body p-0" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                        <div className='p-4 bg-light'>
                            <h5 className='text-black fw-bolder py-1'>Bonus Incentive Details :- </h5>
                            <div class="row">
                                <div class="col-sm">
                                    <h6>Employee Name: {record.employeeName}</h6>
                                    <h6>Employee Code: {record.employeeCode}</h6>
                                    <h6>Department: {record.departmentName}</h6>
                                    <h6>Amount: {record.amount}</h6>
                                </div>
                                <div class="col-sm">
                                    <h6>Payment Type: {record.paymentType}</h6>
                                    <h6>Payment Mode: {record.paymentMode}</h6>
                                    <h6>From Date: {new Date(record.fromDate).toLocaleDateString()}</h6>
                                    <h6>To Date: {new Date(record.toDate).toLocaleDateString()}</h6>
                                </div>
                                <div class="col-sm">
                                    <h6>Payment Date: {new Date(record.paymentDate).toLocaleDateString()}</h6>
                                    <h6>Reason: {record.reason}</h6>
                                    <h6>Remark: {record.remark}</h6>
                                </div>
                            </div>
                        </div>
                    
                        <div className=''>
                            <h5 className='text-black fw-bolder p-3'>Payment History :- </h5>
                            <div className=' bg-light p-1'>
                                <table className="table table-bordered ">
                                    <thead>
                                        <tr>
                                            <th>Payment Amount</th>
                                            <th>Payment Date</th>
                                            <th>Payment Mode</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {payments.map(payment => (
                                            <tr key={payment.id}>
                                                <td>{payment.paymentAmount ? payment.paymentAmount.toFixed(2) : "-"}</td>
                                                <td>{new Date(payment.paymentDate).toLocaleDateString('en-US') || "-"}</td>
                                                <td>{payment.paymentMode || "-"}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>

                        </div>
                    </div>
                    <div className="modal-footer d-flex align-items-center justify-content-between">
                        <button type="button" className="btn btn-danger" onClick={handleClose}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewBonousInsentive;
