import React, { useState, useEffect } from "react";
import axios from 'axios';
import imageCompression from 'browser-image-compression';

const AddInward = ({ onClose, onUpdate }) => {
    const [formData, setFormData] = useState({
        clientName: "",
        clientCode: "",
        address: "",
        invoice: "",
        courierCompany: "",
        consignmentNo: "",
        consignmentdate: "",
        shipFrom: "",
        shipTo: "",
        receiptDate: "",
        receiptBy: "",
        consignmentType: "",
        photo: null,
        remark: "",
        office_id: "" // Added office_id field to formData
    });
    const [clients, setClients] = useState([]);
    const [courierCompany, setCourierCompany] = useState([]);
    const [ourOffice, setOurOffice] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [error, setError] = useState("");

    useEffect(() => {
        fetchClients();
        fetchCourierCompany();
        fetchOurOffice();
        fetchEmployees();
    }, []);

    const fetchClients = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/clients`);
            setClients(response.data);
        } catch (error) {
            console.error("Error fetching Clients:", error);
        }
    };

    const fetchCourierCompany = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/couriers`);
            setCourierCompany(response.data);
        } catch (error) {
            console.error("Error fetching courier Company:", error);
        }
    };

    const fetchOurOffice = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/offices`);
            setOurOffice(response.data);
        } catch (error) {
            console.error("Error fetching our offices:", error);
        }
    };

    const fetchEmployees = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            setEmployees(response.data);
        } catch (error) {
            console.error("Error fetching Employees:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "clientName") {
            const selectedClient = clients.find(client => client.clientName === value);
            setFormData({
                ...formData,
                [name]: value,
                client_id: selectedClient ? selectedClient.id : "",
                clientCode: selectedClient ? selectedClient.clientCode : "",
            });
        } else if (name === "courierCompany") {
            const selectedCourier = courierCompany.find(company => company.courierCompanyName === value);
            setFormData({
                ...formData,
                [name]: value,
                courierCompany_id: selectedCourier ? selectedCourier.id : "",
            });
        } else if (name === "shipTo") {
            const selectedShipTo = ourOffice.find(office => office.officeName === value);
            setFormData({
                ...formData,
                [name]: value,
                office_id: selectedShipTo ? selectedShipTo.id : "", // Set office_id based on selected office
            });
        } else if (name === "ename") {
            const selectedEmployee = employees.find(employee => employee.ename === value);
            setFormData({
                ...formData,
                [name]: value,
                employee_id: selectedEmployee ? selectedEmployee.id : "",
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        try {
            const options = {
              maxSizeMB: 0.2,
              maxWidthOrHeight: 800,
              useWebWorker: true,
            };
    
            const compressedFile = await imageCompression(file, options);
    
            // Create a new File object with the compressed file and original file name
            const fileWithMetaData = new File([compressedFile], file.name, { type: compressedFile.type });
    
            setFormData({
                ...formData,
                photo: fileWithMetaData, // Set the compressed file with metadata
            });
        } catch (error) {
            console.error('Image compression error:', error);
        }
    };
         
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            for (const key in formData) {
                // Do not append photo key
                if (key !== 'photo') {
                    formDataToSend.append(key, formData[key]);
                }
            }
            // Append the compressed file to the form data
            formDataToSend.append("photo", formData.photo);
    
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/addInward`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log("Add Inward data uploaded successfully:", response.data);
            onClose();
            onUpdate(formData);
            setFormData({
                clientName: "",
                clientCode: "",
                address: "",
                invoice:"",
                courierCompany: "",
                consignmentNo: "",
                consignmentdate: "",
                shipFrom: "",
                shipTo: "",
                receiptDate: "",
                receiptBy: "",
                consignmentType: "",
                photo: null,
                remark: "",
                office_id: "" // Reset office_id
            });
        } catch (error) {
            console.error("Error adding inward data:", error);
            setError("An error occurred. Please try again.");
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div id="addClient" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} encType="multipart/form-data" autoComplete="off" noValidate="novalidate">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Inward</h5>
                            <button type="button" className="close" onClick={handleClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <div className="form-group">
                                <label htmlFor="clientName">Client Name</label>
                                <select
                                    name="clientName"
                                    id="clientName"
                                    className="form-control"
                                    value={formData.clientName}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" disabled hidden>Select Client</option>
                                    {clients.map((client) => (
                                        <option key={client.id} value={client.clientName}>
                                            {client.clientName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="clientCode">Client Code</label>
                                <input
                                    type="text"
                                    id="clientCode"
                                    name="clientCode"
                                    className="form-control"
                                    value={formData.clientCode}
                                    placeholder="Client Code"
                                    readOnly
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="address">Address</label>
                                <input
                                    type="text"
                                    id="address"
                                    name="address"
                                    className="form-control"
                                    value={formData.address}
                                    onChange={handleChange}
                                    placeholder="Address"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="courierCompany">Courier Company</label>
                                <select
                                    name="courierCompany"
                                    id="courierCompany"
                                    className="form-control"
                                    value={formData.courierCompany}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" disabled hidden>Select Courier Company</option>
                                    {courierCompany.map((company) => (
                                        <option key={company.id} value={company.courierCompanyName}>
                                            {company.courierCompanyName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="consignmentNo">Consignment No</label>
                                <input
                                    type="text"
                                    id="consignmentNo"
                                    name="consignmentNo"
                                    className="form-control"
                                    value={formData.consignmentNo}
                                    onChange={handleChange}
                                    placeholder="Consignment No."
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="consignmentdate">Consignment Date</label>
                                <input
                                    type="date"
                                    id="consignmentdate"
                                    name="consignmentdate"
                                    className="form-control"
                                    value={formData.consignmentdate}
                                    onChange={handleChange}
                                />
                            </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="invoice">Invoice Number</label>
                                <input
                                    type="text"
                                    id="invoice"
                                    name="invoice"
                                    className="form-control"
                                    value={formData.invoice}
                                    onChange={handleChange}
                                    placeholder="Invoice Number"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="shipFrom">Ship From</label>
                                <input
                                    type="text"
                                    id="shipFrom"
                                    name="shipFrom"
                                    className="form-control"
                                    value={formData.shipFrom}
                                    onChange={handleChange}
                                    placeholder="Ship From"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="shipTo">Ship To</label>
                                <select
                                    name="shipTo"
                                    id="shipTo"
                                    className="form-control"
                                    value={formData.shipTo}
                                    onChange={handleChange}
                                    required
                                    placeholder="Ship To"
                                >
                                    <option value="" disabled hidden>Select Ship To</option>
                                    {ourOffice.map((office) => (
                                        <option key={office.id} value={office.officeName}>
                                            {office.officeName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="receiptDate">Receipt Date</label>
                                <input
                                    type="date"
                                    id="receiptDate"
                                    name="receiptDate"
                                    className="form-control"
                                    value={formData.receiptDate}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="receiptBy">Receipt By</label>
                                <input
                                    type="text"
                                    id="receiptBy"
                                    name="receiptBy"
                                    className="form-control"
                                    value={formData.receiptBy}
                                    onChange={handleChange}
                                    required
                                    placeholder="Receipt By"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="consignmentType">Consignment Type</label>
                                <input
                                    type="text"
                                    id="consignmentType"
                                    name="consignmentType"
                                    className="form-control"
                                    value={formData.consignmentType}
                                    onChange={handleChange}
                                    required
                                    placeholder="Consignment Type"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="ename">Employee Name</label>
                                <select
                                    name="ename"
                                    id="ename"
                                    className="form-control"
                                    value={formData.ename}
                                    onChange={handleChange}
                                    required
                                    placeholder="Employee Name "
                                >
                                    <option value="" >Select Employee</option>
                                    {employees.map((employee) => (
                                        <option key={employee.id} value={employee.ename}>
                                            {employee.ename}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="remark">Remark</label>
                                <textarea
                                    id="remark"
                                    name="remark"
                                    className="form-control"
                                    value={formData.remark}
                                    onChange={handleChange}
                                    rows="3"
                                    placeholder="Remark "
                                ></textarea>
                            </div>
                            <div className="form-group">
                                <label htmlFor="photo">Photo</label>
                                <input
                                    type="file"
                                    id="photo"
                                    name="photo"
                                    className="form-control"
                                    onChange={handleImageChange}
                                    required
                                />
                            </div> 
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" id="saveAsset">Save</button>
                            <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddInward;
