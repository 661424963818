import React, { useState, useEffect } from "react";
import axios from "axios";
import EditOffice from "./EditOffice";

const OfficeDesc = ({ office, onClose }) => {
    const [officeHistory, setOfficeHistory] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        const fetchOfficeHistory = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/api/office/history/${office.id}`
                );
                setOfficeHistory(response.data);
            } catch (error) {
                console.error("Error fetching office history:", error);
            }
        };

        fetchOfficeHistory();
    }, [office]);

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const handleEditOffice = () => {
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = officeHistory.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div>
            <div className="card-body p-4 bg-white rounded shadow-sm">
                <div className="row">
                    
                    <div className="col-md-9 d-flex  justify-content-between px-3">
                        <div>
                            <h2 style={{ color: "#00509d" }} className="title-detail fw-bolder font-bold m-0">
                             {office.officeName}
                            </h2>
                           
                            <small>Created By - {office.username || "N/A"}</small>
                        </div>
                        
                        <div>
                            <p className="m-0">
                                <i class="fas fa-users"></i> <span> Enployee Name: {office.ename || "N/A"}</span>
                            </p>
                            <p className="m-0">
                                <i class="fa fa-envelope" aria-hidden="true"></i> <span> Email: {office.email1 || "N/A"}</span>
                            </p>
                            <p className="m-0">
                                <i class="fa fa-phone"></i> <span> Phone: {office.mobile1 || "N/A"}</span>
                            </p>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="p-2 barcode-inner ">
                            <div className="d-flex gap-2 align-items-center justify-content-center">
                                <button onClick={onClose} className="btn btn-outline-primary">
                                <i className="fa fa-arrow-left"></i>   Back
                                </button>
                                <button onClick={handleEditOffice} className="btn btn-outline-primary">
                                <i className="fa fa-edit"></i>  Edit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a
                                    className="nav-link active show"
                                    id="details-tab"
                                    data-toggle="tab"
                                    href="#details"
                                    role="tab"
                                    aria-controls="details"
                                    aria-selected="true"
                                >
                                    Office Details
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div
                                className="tab-pane fade active show"
                                id="details"
                                role="tabpanel"
                                aria-labelledby="details-tab"
                            >
                                <div className="row">
                                    <div className="col-md-9"  style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto", overflowX:"hidden" }}>
                                        <table className="table table-hover" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Office Name </p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 ">: {office.officeName || "N/A"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Person Name </p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0">: {office.ename || "N/A"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Mobile 1 </p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0">: {office.mobile1 || "N/A"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Mobile 2 </p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0">: {office.mobile2 || "N/A"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Mobile 3 </p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0">: {office.mobile3 || "N/A"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Email ID 1 </p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0">: {office.email1 || "N/A"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Email ID 2 </p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0">: {office.email2 || "N/A"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Address </p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0">: {office.address || "N/A"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">City </p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 ">: {office.city || "N/A"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">State </p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 ">: {office.state || "N/A"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Pincode </p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 ">: {office.pincode || "N/A"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Remark </p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 ">: {office.remarks || "N/A"}</p>
                                                    </td>
                                                </tr>
                                                {/* Add other office details here */}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-3 pt-2 text-center">
                                        <img
                                            src={`${process.env.REACT_APP_LOCAL_URL}/uploads/office/${office.picture}`}
                                            style={{ width: "200px" }}
                                            alt="Office Picture"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Office History */}
                            <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Event</th>
                                                    <th>Date</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.map((entry) => (
                                                    <tr key={entry.event_id}>
                                                        <td>: {entry.event}</td>
                                                        <td>: {formatDate(entry.date)}</td>
                                                        <td>: {entry.description}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {/* Pagination */}
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                            </li>
                                            {Array.from({ length: Math.ceil(officeHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(i + 1)}> {i + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(officeHistory.length / itemsPerPage) && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isEditModalOpen && <EditOffice
                office={office}
                onClose={handleCloseEditModal}
            />}
        </div>
    );
};

export default OfficeDesc;
