import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from 'react-select'; // Import Select component
import imageCompression from 'browser-image-compression';

const AddVendor = ({ onClose, onUpdateVendors }) => {
    const [formData, setFormData] = useState({
        vendorName: "",
        vendorCode: "",
        gstNo: "",
        panNo: "",
        contactPersonName1: "",
        contactPersonName2: "",
        mobileNo1: "",
        mobileNo2: "",
        mobileNo3: "",
        emailId1: "",
        emailId2: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        isSupplier: false,
        selectedServices: [], // Now stores objects {id, name} instead of just names
        remark: "",
        upload1: null,
        upload2: null,
        upload3: null,
        upload4: null,
        upload5: null,
    });

    const [services, setServices] = useState([]);
    const [error, setError] = useState("");
    const [lastVendorId, setLastVendorId] = useState(0)


    useEffect(() => {
        fetchServices();
        fetchLastVendorId();
    }, []);

    const fetchServices = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/service_product`);
            const services = response.data.filter(item => item.type === "Service");
            setServices(services);
        } catch (error) {
            console.error('Error fetching Services:', error);
        }
    };
        
    const fetchLastVendorId = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/lastVendorId`);
            setLastVendorId(parseInt(response.data.lastVendorId));
        } catch (error) {
            console.error('Error fetching last Client ID:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const currentYear = new Date().getFullYear();
        console.log(lastVendorId)
        const generatedCode = `VENDOR${currentYear}${(lastVendorId + 1).toString().padStart(3, '0')}`;
        console.log(generatedCode)
        setFormData({
            ...formData,
            [name]: value,
            vendorCode: generatedCode,
        });
    };

    const handleImageChange = async (e, uploadField) => {
        const file = e.target.files[0];
        try {
            const options = {
                maxSizeMB: 0.2,
                maxWidthOrHeight: 800,
                useWebWorker: true,
            };
    
            const compressedFile = await imageCompression(file, options);
    
            // Create a new File object with the compressed file and original file name
            const fileWithMetaData = new File([compressedFile], file.name, { type: compressedFile.type });
    
            // Update the state using computed property names
            setFormData(prevState => ({
                ...prevState,
                [uploadField]: fileWithMetaData, // Set the compressed file with metadata for the specific upload field
            }));
        } catch (error) {
            console.error('Image compression error:', error);
        }
    };
    

    const handleSupplierChange = (selectedOptions) => {
        setFormData({
            ...formData,
            selectedServices: selectedOptions.map(option => ({ id: option.value, name: option.label })),
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Validation logic
        const requiredFields = ["vendorName", "vendorCode", "gstNo", "panNo", "contactPersonName1", "mobileNo1", "emailId1", "city", "state", "pincode", "upload1"];
        for (const field of requiredFields) {
            if (!formData[field]) {
                setError(`Please fill in the ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`);
                return;
            }
        }
        setError("");
    
        console.log("formData", formData);
    
        try {
            const formDataToSend = new FormData();
    
            // Append vendor data fields
            for (const key in formData) {
                if (formData.hasOwnProperty(key)) {
                    if (key.startsWith("upload") && formData[key]) {
                        // If it's an upload field and not null, append the file to the FormData
                        formDataToSend.append(key, formData[key]);
                    } else if (key === "selectedServices") {
                        // Format selected services array and append to FormData as JSON string
                        formDataToSend.append(key, JSON.stringify(formData[key]));
                    } else {
                        // Otherwise, append the data as usual
                        formDataToSend.append(key, formData[key]);
                    }
                }
            }
    
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/vendordata`, formDataToSend);
            console.log("Data uploaded successfully:", response.data);
    
            onClose();
            onUpdateVendors();
        } catch (error) {
            setError('Failed to add vendor');
            console.error("Error uploading data:", error);
        }
    };
    

    
    const handleClose = () => {
        onClose();
    };

    return (
        <div
            id="addVendor"
            className="modal fade show"
            role="dialog"
            style={{ display: "block", paddingRight: "17px" }}
        >
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form
                        action=""
                        id="formAddVendor"
                        autoComplete="off"
                        noValidate="novalidate"
                        onSubmit={handleSubmit}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">Add Vendor</h5>
                            <button type="button" className="close" onClick={handleClose}>
                                &times;
                            </button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <div className="row">
                                <div className="col-md-12">
                                    {/* Vendor details */}
                                    <div className="form-group">
                                        <label>Vendor Name <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="vendorName"
                                            type="text"
                                            className="form-control"
                                            value={formData.vendorName}
                                            onChange={handleChange}
                                            required
                                            placeholder="Vendor Name"
                                        />
                                    </div>
                                    {/* Similar input fields as in the AddClientModal */}
                                    <div className="form-group">
                                        <label>Vendor Code <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="vendorCode"
                                            type="text"
                                            className="form-control"
                                            value={formData.vendorCode}
                                            onChange={handleChange}
                                            required
                                            readOnly
                                            placeholder="Vendor Code"
                                        />
                                    </div>
                                    {/* Remaining input fields */}
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>GST No <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="gstNo"
                                                type="text"
                                                className="form-control"
                                                value={formData.gstNo}
                                                onChange={handleChange}
                                                required
                                                placeholder="GST No."
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>PAN No <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="panNo"
                                                type="text"
                                                className="form-control"
                                                value={formData.panNo}
                                                onChange={handleChange}
                                                required
                                                placeholder="PAN No."
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Name of the Contact Person 1 <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="contactPersonName1"
                                            type="text"
                                            className="form-control"
                                            value={formData.contactPersonName1}
                                            onChange={handleChange}
                                            required
                                            placeholder="Name of the Contact Person"
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Name of the Contact Person 2 <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="contactPersonName2"
                                            type="text"
                                            className="form-control"
                                            value={formData.contactPersonName2}
                                            onChange={handleChange}
                                            required
                                            placeholder="Name of the Contact Person"
                                        />
                                    </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>Mobile No. 1 <span style={{ color: "red" }}> * </span></label>
                                            <input
                                                name="mobileNo1"
                                                type="tel"
                                                className="form-control"
                                                value={formData.mobileNo1}
                                                onChange={handleChange}
                                                required
                                                placeholder="Mobile No. 1"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                        <label >Mobile No. 2</label>
                                            <input
                                                name="mobileNo2"
                                                type="tel"
                                                className="form-control"
                                                value={formData.mobileNo2}
                                                onChange={handleChange}
                                                placeholder="Mobile No. 2"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Mobile No. 3</label>
                                            <input
                                                name="mobileNo3"
                                                type="tel"
                                                className="form-control"
                                                value={formData.mobileNo3}
                                                onChange={handleChange}
                                                placeholder="Mobile No. 3"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Email Id 1 <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="emailId1"
                                                type="email"
                                                className="form-control"
                                                value={formData.emailId1}
                                                onChange={handleChange}
                                                required
                                                placeholder="Email Id 1"
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Email Id 2</label>
                                            <input
                                                name="emailId2"
                                                type="email"
                                                className="form-control"
                                                value={formData.emailId2}
                                                onChange={handleChange}
                                                placeholder="Email Id 2"
                                            />
                                        </div>
                                    </div>
                                    <label htmlFor=""> Address details : -</label>
                                    <div className="form-group">
                                        <label>Address <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="address"
                                            type="text"
                                            className="form-control"
                                            value={formData.address}
                                            onChange={handleChange}
                                            required
                                            placeholder="Address"
                                        />
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>City <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="city"
                                                type="text"
                                                className="form-control"
                                                value={formData.city}
                                                onChange={handleChange}
                                                required
                                                placeholder="City"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>State <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="state"
                                                type="text"
                                                className="form-control"
                                                value={formData.state}
                                                onChange={handleChange}
                                                required
                                                placeholder="State"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Pincode <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="pincode"
                                                type="text"
                                                className="form-control"
                                                value={formData.pincode}
                                                onChange={handleChange}
                                                required
                                                placeholder="Pincode"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group d-flex gap-4">
                                        <label>Supplier of : -</label>
                                        <Select
                                            isMulti
                                            options={services.map(service => ({ value: service.id, label: service.name }))}
                                            onChange={handleSupplierChange}
                                        />
                                    </div>

                                    {/* Remark */}
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea
                                            name="remark"
                                            className="form-control"
                                            rows="5"
                                            value={formData.remark}
                                            onChange={handleChange}
                                            placeholder="Remark"
                                        ></textarea>
                                    </div>
                                    {/* Upload fields */}
                                    <div className="form-group">
                                        <label>Upload Image 1 <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="upload1"
                                            type="file"
                                            className="form-control-file"
                                            onChange={(e) => handleImageChange(e, "upload1")}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Upload Image 2 <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="upload2"
                                            type="file"
                                            className="form-control-file"
                                            onChange={(e) => handleImageChange(e, "upload2")}
                                            required
                                        />
                                    </div><div className="form-group">
                                        <label>Upload Image 3 <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="upload3"
                                            type="file"
                                            className="form-control-file"
                                            onChange={(e) => handleImageChange(e, "upload3")}
                                            required
                                        />
                                    </div><div className="form-group">
                                        <label>Upload Image 4 <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="upload4"
                                            type="file"
                                            className="form-control-file"
                                            onChange={(e) => handleImageChange(e, "upload4")}
                                            required
                                        />
                                    </div><div className="form-group">
                                        <label>Upload Image 5 <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="upload5"
                                            type="file"
                                            className="form-control-file"
                                            onChange={(e) => handleImageChange(e, "upload5")}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" id="save">
                                Save
                            </button>
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddVendor;








