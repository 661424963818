

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Sidebar from "../../components/sidebar/Sidebar";
// import SearchBar from "../../components/sidebar/SearchBar";
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import AddApprovedModal from "./AddApprovedModal";
// import ApprovedStatus from "./ApprovedStatus";

// function LeaveList({ handleLogout, username }) {
//     const [leaveRequests, setLeaveRequests] = useState([]);
//     const [filteredLeaveRequests, setFilteredLeaveRequests] = useState([]);
//     const [isAddActiveInactiveModal, setIsAddActiveInactiveModal] = useState(false);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [itemsPerPage] = useState(10);
//     const [employeeData, setEmployeeData] = useState(null);
//     const [showApprovedStatus, setShowApprovedStatus] = useState(false);
//     const [selectedApprovedStatus, setSelectedApprovedStatus] = useState(null);

//     useEffect(() => {
//         fetchCombinedData();
//     }, []);

//     const fetchCombinedData = async () => {
//         try {
//             const employeesResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
//             const activeInactiveResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/activeinactive`);

//             const activeInactiveData = activeInactiveResponse.data;

//             const combinedData = employeesResponse.data
//                 .filter(employee => activeInactiveData.some(ai => ai.employeeId === employee.id))
//                 .map(employee => {
//                     const matchingActiveInactive = activeInactiveData.find(ai => ai.employeeId === employee.id);
//                     return {
//                         ...employee,
//                         activeInactiveDetails: matchingActiveInactive || {}
//                     };
//                 });

//             setLeaveRequests(combinedData);
//             setFilteredLeaveRequests(combinedData.slice(0, itemsPerPage));
//         } catch (error) {
//             console.error("Error fetching combined data:", error);
//         }
//     };

//     const formatDate = (dateString) => {
//         return new Date(dateString).toLocaleDateString('en-US', {
//             year: 'numeric',
//             month: '2-digit',
//             day: '2-digit'
//         });
//     };

//     const paginate = (pageNumber) => {
//         setCurrentPage(pageNumber);
//         const indexOfLastItem = pageNumber * itemsPerPage;
//         const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//         setFilteredLeaveRequests(leaveRequests.slice(indexOfFirstItem, indexOfLastItem));
//     };

//     const handleAddActiveInactiveModal = (employee) => {
//         setEmployeeData(employee);
//         setIsAddActiveInactiveModal(true);
//     };

//     const handleCloseActiveInactiveModal = () => {
//         setIsAddActiveInactiveModal(false);
//     };

//     const handleApprovedStatusModal = (ApprovedStatus) => {
//         setSelectedApprovedStatus(ApprovedStatus);
//         setShowApprovedStatus(true);
//     };

//     const handleCloseApprovedStatusModal = () => {
//         setShowApprovedStatus(false);
//     };

//     const handleupdateleavelist= ()=>{
//         fetchCombinedData();

//     }

//     return (
//         <div className='d-flex w-100 h-100'>
//             <Sidebar />
//             <div className='w-100'>
//                 <SearchBar username={username} handleLogout={handleLogout} />
//                 <div className="container-fluid">
//                     <ToastContainer />
//                     {showApprovedStatus ? (
//                         <ApprovedStatus ApprovedStatus={selectedApprovedStatus} onClose={handleCloseApprovedStatusModal} />
//                     ) : (
//                         <div className="card shadow mb-4">
//                             <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
//                                 <h6 className="m-0 font-weight-bold text-primary">
//                                     Leave Requests
//                                 </h6>
//                                 <button className="btn btn-outline-success" onClick={() => setShowApprovedStatus(true)}>
//                                     ApprovedStatus
//                                 </button>
//                             </div>

//                             <div className="card-body">
//                                 <table className="table table-striped table-bordered" style={{ width: "100%" }}>
//                                     <thead>
//                                         <tr>
//                                             <th>Employee Photo</th>
//                                             <th>Employee Name</th>
//                                             <th>Status</th>
//                                             <th>Start Date</th>
//                                             <th>End Date</th>
//                                             <th>Action</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {filteredLeaveRequests.map((leaveRequest, index) => (
//                                             <React.Fragment key={index}>
//                                                 <tr>
//                                                     <td>
//                                                         <img
//                                                             src={`${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${leaveRequest.passportSizePhoto}`}
//                                                             style={{ width: "90px" }}
//                                                             alt="Employee"
//                                                         />
//                                                     </td>
//                                                     <td>{leaveRequest.employeeName}</td>
//                                                     <td>{leaveRequest.activeInactiveDetails.status}</td>
//                                                     <td>{formatDate(leaveRequest.activeInactiveDetails.fromDate)}</td>
//                                                     <td>{formatDate(leaveRequest.activeInactiveDetails.toDate)}</td>
//                                                     <td>
//                                                         <button onClick={() => handleAddActiveInactiveModal(leaveRequest)} className="btn btn-outline-primary">
//                                                             Action
//                                                         </button>
//                                                     </td>
//                                                 </tr>
//                                                 <tr>
//                                                     <td colSpan="6">
//                                                         <span style={{ fontWeight: "700" }}>Description: </span>{leaveRequest.activeInactiveDetails.description}
//                                                     </td>
//                                                 </tr>
//                                             </React.Fragment>
//                                         ))}
//                                     </tbody>
//                                 </table>
//                                 <ul className="pagination">
//                                     <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                                         <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
//                                     </li>
//                                     {Array.from({ length: Math.ceil(leaveRequests.length / itemsPerPage) }, (_, i) => (
//                                         <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
//                                             <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
//                                         </li>
//                                     ))}
//                                     <li className={`page-item ${currentPage === Math.ceil(leaveRequests.length / itemsPerPage) ? 'disabled' : ''}`}>
//                                         <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
//                                     </li>
//                                 </ul>
//                             </div>
//                         </div>
//                     )}
//                     {isAddActiveInactiveModal && <AddApprovedModal employee={employeeData} onUpdate={handleupdateleavelist} onClose={handleCloseActiveInactiveModal} />}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default LeaveList;






// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Sidebar from "../../components/sidebar/Sidebar";
// import SearchBar from "../../components/sidebar/SearchBar";
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import AddApprovedModal from "./AddApprovedModal";
// import ApprovedStatus from "./ApprovedStatus";
// import RejectStatus from "./RejectStatus";

// function RequestLeaveList({ handleLogout, username }) {
//     const [leaveRequests, setLeaveRequests] = useState([]);
//     const [filteredLeaveRequests, setFilteredLeaveRequests] = useState([]);
//     const [isAddActiveInactiveModal, setIsAddActiveInactiveModal] = useState(false);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [itemsPerPage] = useState(10);
//     const [employeeData, setEmployeeData] = useState(null);
//     const [showApprovedStatus, setShowApprovedStatus] = useState(false);
//     const [selectedApprovedStatus, setSelectedApprovedStatus] = useState(null);
//     // Leave Status 
//     const [showRejectStatus, setShowRejectStatus] = useState(false);
//     const [selectedRejectStatus, setSelectedRejectStatus] = useState(null);

//     useEffect(() => {
//         fetchCombinedData();
//     }, []);

//     const fetchCombinedData = async () => {
//         try {
//             const employeesResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
//             const activeInactiveResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/activeinactive`);

//             const activeInactiveData = activeInactiveResponse.data;

//             const combinedData = employeesResponse.data
//                 .filter(employee => activeInactiveData.some(ai => ai.employeeId === employee.id))
//                 .map(employee => {
//                     const matchingActiveInactive = activeInactiveData.find(ai => ai.employeeId === employee.id);
//                     return {
//                         ...employee,
//                         activeInactiveDetails: matchingActiveInactive || {}
//                     };
//                 });

//             setLeaveRequests(combinedData);
//             setFilteredLeaveRequests(combinedData.slice(0, itemsPerPage));
//         } catch (error) {
//             console.error("Error fetching combined data:", error);
//         }
//     };

//     const formatDate = (dateString) => {
//         return new Date(dateString).toLocaleDateString('en-US', {
//             year: 'numeric',
//             month: '2-digit',
//             day: '2-digit'
//         });
//     };

//     const paginate = (pageNumber) => {
//         setCurrentPage(pageNumber);
//         const indexOfLastItem = pageNumber * itemsPerPage;
//         const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//         setFilteredLeaveRequests(leaveRequests.slice(indexOfFirstItem, indexOfLastItem));
//     };

//     const handleAddActiveInactiveModal = (employee) => {
//         setEmployeeData(employee);
//         setIsAddActiveInactiveModal(true);
//     };

//     const handleCloseActiveInactiveModal = () => {
//         setIsAddActiveInactiveModal(false);
//     };

//     const handleApprovedStatusModal = (ApprovedStatus) => {
//         setSelectedApprovedStatus(ApprovedStatus);
//         setShowApprovedStatus(true);
//     };

//     const handleCloseApprovedStatusModal = () => {
//         setShowApprovedStatus(false);
//     };

//     const handleRejectStatusModal = (RejectStatus) => {
//         setSelectedRejectStatus(RejectStatus);
//         setShowRejectStatus(true);
//     };

//     const handleCloseRejectStatusModal = () => {
//         setShowRejectStatus(false);
//     };

//     const handleupdateleavelist = () => {
//         fetchCombinedData();

//     }

//     return (
//         <div className='d-flex w-100 h-100'>
//             <Sidebar />
//             <div className='w-100'>
//                 <SearchBar username={username} handleLogout={handleLogout} />
//                 <div className="container-fluid">
//                     <ToastContainer />
//                     {showApprovedStatus ? (
//                         <ApprovedStatus ApprovedStatus={selectedApprovedStatus} onClose={handleCloseApprovedStatusModal} />
//                     ) : showRejectStatus ? (
//                         <RejectStatus RejectStatus={selectedRejectStatus} onClose={handleCloseRejectStatusModal} />
//                     ) : (
//                         <div className="card shadow mb-4">
//                             <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
//                                 <h6 className="m-0 font-weight-bold text-primary">
//                                     Leave Requests
//                                 </h6>
//                                 <button className="btn btn-outline-success" onClick={() => setShowApprovedStatus(true)}>
//                                     ApprovedStatus
//                                 </button>
//                                 <button className="btn btn-outline-success" onClick={() => setShowRejectStatus(true)}>
//                                     Reject Status
//                                 </button>
//                             </div>
//                             <div className="card-body">
//                                 <table className="table table-striped table-bordered" style={{ width: "100%" }}>
//                                     <thead>
//                                         <tr>
//                                             <th>Employee Photo</th>
//                                             <th>Employee Name</th>
//                                             <th>Status</th>
//                                             <th>Start Date</th>
//                                             <th>End Date</th>
//                                             <th>Action</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {filteredLeaveRequests.map((leaveRequest, index) => (
//                                             <React.Fragment key={index}>
//                                                 <tr>
//                                                     <td>
//                                                         <img
//                                                             src={`${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${leaveRequest.passportSizePhoto}`}
//                                                             style={{ width: "90px" }}
//                                                             alt="Employee"
//                                                         />
//                                                     </td>
//                                                     <td>{leaveRequest.employeeName}</td>
//                                                     <td>{leaveRequest.activeInactiveDetails.status}</td>
//                                                     <td>{formatDate(leaveRequest.activeInactiveDetails.fromDate)}</td>
//                                                     <td>{formatDate(leaveRequest.activeInactiveDetails.toDate)}</td>
//                                                     <td>
//                                                         <button onClick={() => handleAddActiveInactiveModal(leaveRequest)} className="btn btn-outline-primary">
//                                                             Action
//                                                         </button>
//                                                     </td>
//                                                 </tr>
//                                                 <tr>
//                                                     <td colSpan="6">
//                                                         <span style={{ fontWeight: "700" }}>Description: </span>{leaveRequest.activeInactiveDetails.description}
//                                                     </td>
//                                                 </tr>
//                                             </React.Fragment>
//                                         ))}
//                                     </tbody>
//                                 </table>
//                                 <ul className="pagination">
//                                     <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                                         <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
//                                     </li>
//                                     {Array.from({ length: Math.ceil(leaveRequests.length / itemsPerPage) }, (_, i) => (
//                                         <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
//                                             <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
//                                         </li>
//                                     ))}
//                                     <li className={`page-item ${currentPage === Math.ceil(leaveRequests.length / itemsPerPage) ? 'disabled' : ''}`}>
//                                         <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
//                                     </li>
//                                 </ul>
//                             </div>
//                         </div>
//                     )}
//                     {isAddActiveInactiveModal && <AddApprovedModal employee={employeeData} onUpdate={handleupdateleavelist} onClose={handleCloseActiveInactiveModal} />}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default RequestLeaveList;



import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import SearchBar from "../../components/sidebar/SearchBar";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddApprovedModal from "./AddApprovedModal";
import ApprovedStatus from "./ApprovedStatus";
import RejectStatus from "./RejectStatus";
import myImage from '../../images/employee_profile.png';

function RequestLeaveList({ handleLogout, username }) {
    const [leaveRequests, setLeaveRequests] = useState([]);
    const [filteredLeaveRequests, setFilteredLeaveRequests] = useState([]);
    const [isAddActiveInactiveModal, setIsAddActiveInactiveModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [employeeData, setEmployeeData] = useState(null);
    const [showApprovedStatus, setShowApprovedStatus] = useState(false);
    const [selectedApprovedStatus, setSelectedApprovedStatus] = useState(null);
    const [showRejectStatus, setShowRejectStatus] = useState(false);
    const [selectedRejectStatus, setSelectedRejectStatus] = useState(null);

    useEffect(() => {
        fetchCombinedData();
    }, []);

    const fetchCombinedData = async () => {
        try {
            const employeesResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            const activeInactiveResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/activeinactive`);

            const activeInactiveData = activeInactiveResponse.data;

            const combinedData = employeesResponse.data
                .filter(employee => activeInactiveData.some(ai => ai.employeeId === employee.id))
                .map(employee => {
                    const matchingActiveInactive = activeInactiveData.find(ai => ai.employeeId === employee.id);
                    return {
                        ...employee,
                        activeInactiveDetails: matchingActiveInactive || {}
                    };
                });

            setLeaveRequests(combinedData);
            setFilteredLeaveRequests(combinedData.slice(0, itemsPerPage));
        } catch (error) {
            console.error("Error fetching combined data:", error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        const indexOfLastItem = pageNumber * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setFilteredLeaveRequests(leaveRequests.slice(indexOfFirstItem, indexOfLastItem));
    };

    const handleAddActiveInactiveModal = (leaveRequest, activeInactiveDetails) => {
        const employeeData = { ...leaveRequest, activeInactiveDetails };
        setEmployeeData(employeeData);
        setIsAddActiveInactiveModal(true);
    };

    const handleCloseActiveInactiveModal = () => {
        setIsAddActiveInactiveModal(false);
    };

    const handleApprovedStatusModal = (ApprovedStatus) => {
        setSelectedApprovedStatus(ApprovedStatus);
        setShowApprovedStatus(true);
    };

    const handleCloseApprovedStatusModal = () => {
        setShowApprovedStatus(false);
    };

    const handleRejectStatusModal = (RejectStatus) => {
        setSelectedRejectStatus(RejectStatus);
        setShowRejectStatus(true);
    };

    const handleCloseRejectStatusModal = () => {
        setShowRejectStatus(false);
    };

    const handleUpdateLeaveList = () => {
        fetchCombinedData();
    }
    return (
        <div className='d-flex w-100 h-100'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    {showApprovedStatus ? (
                        <ApprovedStatus onClose={handleCloseApprovedStatusModal} showRejectStatusModal={handleRejectStatusModal} />
                    ) : showRejectStatus ? (
                        <RejectStatus onClose={handleCloseRejectStatusModal} showApprovedStatusModal={handleApprovedStatusModal} />
                    ) : (
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 d-flex flex-row align-items-center gap-3">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    Requests Leave
                                </h6>
                                <h6 style={{ cursor: "pointer" }} className="m-0 font-weight-bold text-info" onClick={() => setShowApprovedStatus(true)}>
                                    Approved Status
                                </h6>
                                <h6 style={{ cursor: "pointer" }} className="m-0 font-weight-bold text-info" onClick={() => setShowRejectStatus(true)}>
                                    Reject Status
                                </h6>
                            </div>
                            <div className="card-body">
                                <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>Employee Photo</th>
                                            <th>Employee Name</th>
                                            <th>Status</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredLeaveRequests.map((leaveRequest, index) => (
                                            <React.Fragment key={index}>
                                                <tr>
                                                    <td>
                                                        <img
                                                            src={leaveRequest.passportSizePhoto
                                                                ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${leaveRequest.passportSizePhoto}`
                                                                : myImage}
                                                            style={{ width: "90px" }}
                                                            alt="Employee"
                                                        />
                                                    </td>
                                                    <td>{leaveRequest.employeeName}</td>
                                                    <td>{leaveRequest.activeInactiveDetails.status}</td>
                                                    <td>{formatDate(leaveRequest.activeInactiveDetails.fromDate)}</td>
                                                    <td>{formatDate(leaveRequest.activeInactiveDetails.toDate)}</td>
                                                    <td>
                                                        <button onClick={() => handleAddActiveInactiveModal(leaveRequest, leaveRequest.activeInactiveDetails)} className="btn btn-outline-primary">
                                                            Action
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="6">
                                                        <span style={{ fontWeight: "700" }}>Description: </span>{leaveRequest.activeInactiveDetails.description}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                                <ul className="pagination">
                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                        <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                                    </li>
                                    {Array.from({ length: Math.ceil(leaveRequests.length / itemsPerPage) }, (_, i) => (
                                        <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                                        </li>
                                    ))}
                                    <li className={`page-item ${currentPage === Math.ceil(leaveRequests.length / itemsPerPage) ? 'disabled' : ''}`}>
                                        <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
                    {isAddActiveInactiveModal && <AddApprovedModal employee={employeeData} onUpdate={handleUpdateLeaveList} onClose={handleCloseActiveInactiveModal} />}
                </div>
            </div>
        </div>
    );
}

export default RequestLeaveList;
