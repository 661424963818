import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/sidebar/Sidebar';
import SearchBar from '../../components/sidebar/SearchBar';
import SalaryReportPreview from './SalaryReportPreview';

function SalaryReport({ handleLogout, username }) {
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Initialize with current month (+1 since months are 0-indexed)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initialize with current year
    const [payrollData, setPayrollData] = useState([]); // State to hold payroll data
    const [filteredsalaryepfesic, setFilteredsalaryepfesic] = useState([]); // State for filtered data
    const [grandTotals, setGrandTotals] = useState({}); // State for grand totals
    const [showSidebar, setShowSidebar] = useState(true); // State to control sidebar visibility
    const [showSearchBar, setShowSearchBar] = useState(true); // State to control search bar visibility
    const [salaryepfesicdetails, setsalaryepfesicdetails] = useState(false); // State for attendance details modal
    const [selectedRecord, setSelectedRecord] = useState(null); // State for selected record

    useEffect(() => {
        fetchPreviousPayroll();
    }, []);

    useEffect(() => {
        filterAttendance();
    }, [selectedMonth, selectedYear, payrollData]);

    useEffect(() => {
        calculateTotals();
    }, [filteredsalaryepfesic]);

    const fetchPreviousPayroll = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/payrolls`);
            const payrollData = response.data;
            setPayrollData(payrollData);
        } catch (error) {
            console.error('Error fetching payroll by employee:', error);
            toast.error('Error fetching payroll data');
        }
    };

    const filterAttendance = () => {
        // Filter the payroll data based on selected month and year
        const filtered = payrollData.filter(record => {
            const date = new Date(record.date);
            return (
                date.getMonth() + 1 === selectedMonth && // +1 because JS months are 0-based
                date.getFullYear() === selectedYear
            );
        });
        setFilteredsalaryepfesic(filtered);
    };

    const calculateTotals = () => {
        const totals = filteredsalaryepfesic.reduce((acc, record) => {
            acc.totalEmployees += 1;
            acc.totalSalaryWithContribution += record.salaryWithContribution || 0;
            acc.totalEpfEmployerMonth += record.epfEmployerMonth || 0;
            acc.totalEsicEmployerMonth += record.esicEmployerMonth || 0;
            acc.totalEpfEmployeeMonth += record.epfEmployeeMonth || 0;
            acc.totalEsicEmployeeMonth += record.esicEmployeeMonth || 0;
            acc.totalTdsDeductionMonth += record.totalTdsDeductionMonth || 0;
            acc.totalNetSalaryPayableMonth += record.netSalaryPayableMonth || 0;
            return acc;
        }, {
            totalEmployees: 0,
            totalSalaryWithContribution: 0,
            totalEpfEmployerMonth: 0,
            totalEsicEmployerMonth: 0,
            totalEpfEmployeeMonth: 0,
            totalEsicEmployeeMonth: 0,
            totalTdsDeductionMonth: 0,
            totalNetSalaryPayableMonth: 0
        });

        setGrandTotals(totals);
    };

    const handleSalaryEpfEsicDetails = () => {
        setSelectedRecord({
            date: new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
            status: filteredsalaryepfesic.length > 0 ? filteredsalaryepfesic[0].status : '',
            SalaryEpfEsicData: { filteredsalaryepfesic },
            selectedMonth,
            selectedYear
        });
        
        setsalaryepfesicdetails(true);
        setShowSidebar(false);
        setShowSearchBar(false);
    };

    const handleClosePreview = () => {
        setShowSidebar(true); // Show sidebar when closing preview
        setShowSearchBar(true); // Show search bar when closing preview
        setsalaryepfesicdetails(false); // Hide attendance details
    };

    return (
        <div className='d-flex w-100 h-100'>
            {showSidebar && <Sidebar />}
            <div className='w-100'>
                {showSearchBar && <SearchBar className="searchbarr" username={username} handleLogout={handleLogout} />}
                <div className="container-fluid">
                    <ToastContainer />
                    {salaryepfesicdetails ? (
                        <SalaryReportPreview
                           
                            record={selectedRecord}
                            onClose={handleClosePreview}
                        />
                    ) : (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">Salary Report</h6>
                                        <div className='d-flex align-items-center justify-content-center gap-1'>
                                            <label className='pt-2 text-black fw-bolder'>Filter:</label>
                                            <select
                                                className="form-control"
                                                value={selectedMonth}
                                                onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                                            >
                                                <option value="">Select Month</option>
                                                {Array.from({ length: 12 }, (_, i) => (
                                                    <option key={i} value={i + 1}>{new Date(2000, i).toLocaleString('default', { month: 'long' })}</option>
                                                ))}
                                            </select>
                                            <select
                                                className="form-control"
                                                value={selectedYear}
                                                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                                            >
                                                <option value="">Select Year</option>
                                                {Array.from({ length: 10 }, (_, i) => (
                                                    <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center gap-1'>
                                            <button className="btn btn-outline-primary" onClick={handleSalaryEpfEsicDetails}><i className="fa fa-download"></i> PDF</button>
                                        </div>
                                    </div>
                                    <div className="card-body form-row">
                                        <div className='col-md-12' style={{ maxHeight: "450px", overflowY: "auto" }}>
                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                    <tr>
                                                        <th>Month</th>
                                                        <th>Employee</th>
                                                        <th>Employee Code</th>
                                                        <th>Salary With Contribution</th>
                                                        <th>Employer EPF</th>
                                                        <th>Employer ESIC</th>
                                                        <th>Employee EPF</th>
                                                        <th>Employee ESIC</th>
                                                        <th>TDs Deduction</th>
                                                        <th>Total Net Salary Payable</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredsalaryepfesic.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="9" className="text-center">No Salary Details Found.</td>
                                                        </tr>
                                                    ) : (
                                                        filteredsalaryepfesic.map((record, index) => (
                                                            <tr key={index}>
                                                                <td>{new Date(record.date).toLocaleString('default', { month: 'long', year: 'numeric' })}</td>
                                                                <td>{record.employeeName}</td>
                                                                <td>{record.employeeCode}</td>
                                                                <td>&#x20B9;{record.salaryWithContribution != null ? record.salaryWithContribution.toFixed(2) : '0.00'}</td>
                                                                <td>&#x20B9;{record.epfEmployerMonth != null ? record.epfEmployerMonth.toFixed(2) : '0.00'}</td>
                                                                <td>&#x20B9;{record.esicEmployerMonth != null ? record.esicEmployerMonth.toFixed(2) : '0.00'}</td>
                                                                <td>&#x20B9;{record.epfEmployeeMonth != null ? record.epfEmployeeMonth.toFixed(2) : '0.00'}</td>
                                                                <td>&#x20B9;{record.esicEmployeeMonth != null ? record.esicEmployeeMonth.toFixed(2) : '0.00'}</td>
                                                                <td>&#x20B9;{record.totalTdsDeductionMonth != null ? record.totalTdsDeductionMonth.toFixed(2) : '0.00'}</td>
                                                                <td>&#x20B9;{record.netSalaryPayableMonth != null ? record.netSalaryPayableMonth.toFixed(2) : '0.00'}</td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Totals</th>
                                                        <th>{grandTotals.totalEmployees}</th>
                                                        <th></th>
                                                        <th>&#x20B9;{grandTotals.totalSalaryWithContribution != null ? grandTotals.totalSalaryWithContribution.toFixed(2) : '0.00'}</th>
                                                        <th>&#x20B9;{grandTotals.totalEpfEmployerMonth != null ? grandTotals.totalEpfEmployerMonth.toFixed(2) : '0.00'}</th>
                                                        <th>&#x20B9;{grandTotals.totalEsicEmployerMonth != null ? grandTotals.totalEsicEmployerMonth.toFixed(2) : '0.00'}</th>
                                                        <th>&#x20B9;{grandTotals.totalEpfEmployeeMonth != null ? grandTotals.totalEpfEmployeeMonth.toFixed(2) : '0.00'}</th>
                                                        <th>&#x20B9;{grandTotals.totalEsicEmployeeMonth != null ? grandTotals.totalEsicEmployeeMonth.toFixed(2) : '0.00'}</th>
                                                        <th>&#x20B9;{grandTotals.totalTdsDeductionMonth != null ? grandTotals.totalTdsDeductionMonth.toFixed(2) : '0.00'}</th>
                                                        <th>&#x20B9;{grandTotals.totalNetSalaryPayableMonth != null ? grandTotals.totalNetSalaryPayableMonth.toFixed(2) : '0.00'}</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SalaryReport;
