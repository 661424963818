import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddPositionModal = ({ onClose, onUpdate }) => {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        departmentId: '', // Will store the selected department ID
        departmentName: '', // Will store the selected department Name
    });
    const [departments, setDepartments] = useState([]); // State to store departments fetched from the database
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchDepartments();
    }, []);

    const fetchDepartments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/departments`);
            setDepartments(response.data); // Assuming response.data is an array of departments
        } catch (error) {
            console.error('Error fetching departments:', error);
        }
    };

    const { name, description, departmentId, departmentName } = formData;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        if (name === 'departmentName') {
            const selectedDepartment = departments.find(department => department.name === value);
            setFormData({
                ...formData,
                departmentId: selectedDepartment ? selectedDepartment.id : '',
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/positions`, {
                name,
                description,
                departmentId,
                departmentName,
            });
            console.log('Added position successfully:', response.data);
           
            onUpdate();
            setTimeout(() => {
                onClose();
                window.location.reload();
            }, 1000); // 1 second delay
        } catch (error) {
            console.error('Error adding position:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div id="addPositionModal" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Designation</h5>
                            <button type="button" className="close" onClick={handleClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                           
                            <div className="form-group">
                                <label>Department<span style={{ color: "red" }}>*</span></label>
                                <select name="departmentName" value={departmentName} onChange={handleChange} className="form-control" required>
                                    <option value="" disabled>Select Department</option>
                                    {departments.map(department => (
                                        <option key={department.id} value={department.name}>{department.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Designation Name<span style={{ color: "red" }}>*</span></label>
                                <input name="name" value={name} onChange={handleChange} type="text" className="form-control" required placeholder="Designation Name" />
                            </div>
                            <div className="form-group">
                                <label>Description</label>
                                <textarea name="description" value={description} onChange={handleChange} className="form-control" placeholder="Description"></textarea>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary">Save</button>
                            <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
            {isLoading && <div className="loader">Loading...</div>}
        </div>
    );
};

export default AddPositionModal;
